<template>
  <div class="d-flex">
    <BoxMuroVacio v-if="bloque.tipo == 'muro' || bloque.tipo == 'vacio'" 
    :bloquecardinal="bloque.cardinal"
    :tipo="bloque.tipo"
    :cardinal="cardinal"
    :comentario="bloque.comentario"
    :horizontal="horizontal"
    :bloque="bloque"
    :onlyRead="onlyRead"
    />

    <box-cama-simple v-if="bloque.tipo == 'cama' "
    :bloquecardinal="bloque.cardinal"
    :cama="bloque.camas"
    :horizontal="horizontal"
    :comentario="bloque.comentario"
    :cardinal="cardinal"
    :tipo="bloque.tipo"
    :bloque="bloque"
    :onlyRead="onlyRead" 
    />

    <box-camarote v-if="bloque.tipo == 'camarote'"
    :bloquecardinal="bloque.cardinal"
    :camas="bloque.camas"
    :horizontal="horizontal"
    :comentario="bloque.comentario"
    :cardinal="cardinal"
    :tipo="bloque.tipo"
    :bloque="bloque"
    :onlyRead="onlyRead"
    />



  </div>
</template>

<script>

import BoxCamarote from './boxcamas/boxCamarote.vue';
import boxCamaSimple from './boxcamas/boxCamaSimple.vue';
import BoxMuroVacio from './boxcamas/boxMuroVacio.vue'

export default {
  components: {
    BoxCamarote,
    boxCamaSimple,
    BoxMuroVacio
  },
  props: {
    tipo:{
        
    },
    bloque: {
      type: Object,
      required: true,
    },
    cardinal: {
      type: String,
      required: true,
    },

    horizontal: {
      type: Boolean,
      default: false,
    },
    
    onlyRead:{
      type: Boolean,
      default: false
    },

  },

  data: () => ({
    menuCamaSimple: false,
  }),

  computed: {
    tamañoMuroVacio() {
      switch (this.cama.tipo) {
        case "muro":
          return "20px";
        case "vacio":
          return "98px";
        case "cama":
          return "100px";
        case "camarote":
          return "100px";
      }
    },

    anchoTotal() {
      if (
        this.cama.tipo != "vacio" &&
        this.cama.tipo != "muro" &&
        !this.vertical
      ) {
        return "100px";
      }
      if (
        this.cama.tipo != "vacio" &&
        this.cama.tipo != "muro" &&
        this.vertical
      ) {
        return "100%";
      }
    },

    opacityStateCamaSimple() {
      if (
        this.cama.state_camasimple == "incompleta" ||
        this.cama.state_camasimple == "malograda"
      ) {
        return "disableCama";
      }
    },

    opacityStateCamaArriba() {
      if (
        this.cama.state_camaarriba == "incompleta" ||
        (this.cama.state_camaarriba == "malograda" &&
          this.cama.state_camaabajo != "")
      ) {
        return "disableCama";
      }
    },

    opacityStateCamaAbajo() {
      if (
        this.cama.state_camaabajo == "incompleta" ||
        (this.cama.state_camaabajo == "malograda" &&
          this.cama.state_camaarriba != "")
      ) {
        return "disableCama";
      }
    },

    isVertical() {
      return this.vertical ? "flex-row " : "flex-column";
    },
  },

  methods: {},
};
</script>

<style scoped>
.texto-vertical {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
.bg-bed {
  position: relative;
  /*background-image: url('../../assets/bed.png');*/
  background-position: center;
  background-size: contain;
}
.disableCama {
  opacity: 0.5;
}
</style>

<style lang="scss">
$badge-height: 50px;
$badge-min-width: 50px;
</style>
