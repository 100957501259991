import Module from './Module.vue';
const moduleRoute = {
    path: "/topico",
    component: Module,
    children: [
      {
        path: "",
        component: ()=> import("@/modules/Topico/views/HomeTopicoView.vue"),
        children: [
          {
            path: "",
            components: {
              topicoView: () => import('@/modules/Topico/components/DashboardHome.vue'),
            },
            name: "topicoHome",
            meta: {
              requiresAuth: true,
              title: "Tópico - Home",
            },
          }
        ],
      },
      {
        path:"nueva-atencion",
        component: ()=> import("@/modules/Topico/views/HomeTopicoView.vue"),
        children: [
          {
            path: "",
            components: {
              topicoView: () => import('@/modules/Topico/components/NuevaAtencionView.vue'),
            },
            name: "nuevaatencionTopico",
            meta: {
              requiresAuth: true,
              title: "Tópico - Nueva Atención",
            },
          }
        ],
      },

      {
        path:"editar-atencion/:idAtencionMedica",
        component: ()=> import("@/modules/Topico/views/HomeTopicoView.vue"),
        props: true,
        children: [
          {
            path: "",
            components: {
              topicoView: () => import('@/modules/Topico/components/NuevaAtencionView.vue'),
            },
            name: "editarAtencion",
            meta: {
              requiresAuth: true,
              title: "Tópico - Editar Atención",
            },
          }
        ],
      },
      {
        path:"historias",
        component: ()=> import("@/modules/Topico/views/HomeTopicoView.vue"),
        children: [
          {
            path: "",
            components: {
              topicoView: () => import('@/modules/Topico/components/Historias.vue'),
            },
            name: "historias",
            meta: {
              requiresAuth: true,
              title: "Tópico - Historias",
            },
          }
        ],
      },
      {
        path:"registro-diario",
        component: ()=> import("@/modules/Topico/views/HomeTopicoView.vue"),
        children: [
          {
            path: "",
            components: {
              topicoView: () => import('@/modules/Topico/components/RegistroDiariaAtenciones.vue'),
            },
            name: "diario",
            meta: {
              requiresAuth: true,
              title: "Tópico - Registro diario",
            },
          }
        ],
      },
      {
        path:"inventario",
        component: ()=> import("@/modules/Topico/views/HomeTopicoView.vue"),
        children: [
          {
            path: "",
            components: {
              topicoView: () => import('@/modules/Topico/components/Inventario.vue'),
            },
            name: "inventario",
            meta: {
              requiresAuth: true,
              title: "Tópico - Inventario",
            },
          }
        ],
      }
    ]
};
  
export default router => {
    router.addRoute(moduleRoute);
  };