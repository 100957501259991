<template>
    <div>
        <v-dialog v-model="dialgoEvidenciaSalidaPermisos.openDialog" width="800">
            <v-card v-if="dialgoEvidenciaSalidaPermisos.urlPDF != null">
                <iframe :src="dialgoEvidenciaSalidaPermisos.urlPDF" frameborder="0" height="600" width="100%"></iframe>
                <v-btn block @click="setEliminarDocumentoPermiso(dialgoEvidenciaSalidaPermisos.idpermisosalida)"
                    class="red white--text" elevation="0">Eliminar
                    documento</v-btn>
            </v-card>
            <div v-else>
                <v-card elevation="0">
                    <v-card-title class="bgCTM white--text py-2">Cargar evidencia de salida</v-card-title>
                    <v-card-text class="pt-4 font-weight-bold">
                        <div>
                            <label for="">Subir documento o imagen</label>
                            <v-file-input @change="onChangeDocumentoSalida" v-model="evidenciaSalida.file" outlined hide-details="auto" truncate-length="15"></v-file-input>
                        </div>
                        <v-btn @click="saveEvidenciaSalida(dialgoEvidenciaSalidaPermisos.idpermisosalida)" block hide-details="auto" class="blue darken-2 white--text mt-2" elevation="0">CARGAR ARCHIVO</v-btn>
                    </v-card-text>
                </v-card>
            </div>
        </v-dialog>

        <v-dialog v-model="dialgoEvidenciaIngresoPermisos.openDialog" width="800">
            <v-card v-if="dialgoEvidenciaIngresoPermisos.urlPDF != null">
                <iframe :src="dialgoEvidenciaIngresoPermisos.urlPDF"  frameborder="0" height="600" width="100%"></iframe>
                <v-btn block @click="setEliminarEvidenciaIngreso(dialgoEvidenciaIngresoPermisos.idpermisosalida)"
                    class="red white--text" elevation="0">Eliminar documento</v-btn>
            </v-card>

            <div v-else>
                <v-card elevation="0">
                    <v-card-title class="bgCTM white--text py-2">Cargar evidencia de Ingreso</v-card-title>
                    <v-card-text class="pt-4 font-weight-bold">
                        <div>
                            <label for="">Subir documento o imagen </label>
                            <v-file-input @change="onChangeDocumentoIngreso" v-model="evidenciaIngreso.file" outlined hide-details="auto" truncate-length="15"></v-file-input>
                        </div>
                        <v-btn @click="saveEvidenciaIngreso(dialgoEvidenciaIngresoPermisos.idpermisosalida)" block hide-details="auto" class="blue darken-2 white--text mt-2" elevation="0">CARGAR ARCHIVO</v-btn>
                    </v-card-text>
                </v-card>
            </div>
        </v-dialog>
    </div>



</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {

    computed: {
        ...mapState('permisos', ['dialgoEvidenciaSalidaPermisos', 'dialgoEvidenciaIngresoPermisos', 'evidenciaSalida', 'evidenciaIngreso'])
    },

    methods: {
        ...mapActions('permisos', ['setEliminarDocumentoPermiso', 'saveEvidenciaSalida', 'setEliminarEvidenciaIngreso', 'saveEvidenciaIngreso']),
        ...mapMutations('permisos', ['onChangeDocumentoSalida', 'onChangeDocumentoIngreso'])
    }
}
</script>