<template>
<v-form v-model="formValidate">
  <v-row class="black--text font-weight-bold">
    <v-col cols="12" class="px-2 py-0">
      <v-alert :type="alert.state" text dense  border="left" class="mb-1">
        <small>{{ alert.message }} </small>
      </v-alert>
      <div class="px-2">
        <h5 class="red--text font-weight-bold d-inline-block">(*) Campo requerido</h5>
      </div>
    </v-col>
    <v-col cols="6" class="pa-2">
      <label for="">Documento de Identidad: <h2 class="red--text font-weight-bold d-inline-block">*</h2></label>
      <v-text-field
        prepend-inner-icon="mdi-card-account-details"
        dense
        outlined
        hide-details="auto"
        v-model="hospedar.dni"
        @keypress.enter="buscarDNIClick()"
        :rules="[rules.required, rules.onlyNumber, rules.dni]"
      >
        <template v-slot:append>
          <v-btn @click="buscarDNIClick()" :loading="loading" icon small class="indigo white--text"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="6" class="pa-2">
      <label for="">Persona a Hospedar:<h2 class="red--text font-weight-bold d-inline-block">*</h2></label>
      <v-select
        dense
        outlined
        hide-details="auto"
        label="Selecciona"
        :items="['estudiante', 'colaborador']"
        v-model="hospedar.tipo"
        :rules="[rules.required]"
      ></v-select>
    </v-col>
    <v-col cols="12" md="12" class="pa-2">
      <div class="d-flex">
        <div style="width: 100%" class="mr-2">
          <label for="">Apellidos y Nombres:<h2 class="red--text font-weight-bold d-inline-block">*</h2></label>
          <v-text-field
            prepend-inner-icon="mdi-account-check"
            dense
            outlined
            hide-details="auto"
            v-model="hospedar.nombrecompleto"
            :rules="[rules.required]"
          ></v-text-field>
        </div>

      </div>

      <div class="d-flex my-2" v-if="hospedar.tipo == 'estudiante' ">
        <div style="width: 60%" class="mr-2">
          <label for="">Carrera:<h2 class="red--text font-weight-bold d-inline-block">*</h2></label>
          <v-text-field
            prepend-inner-icon="mdi-school"
            dense
            outlined
            hide-details="auto"
            v-model="hospedar.carrera"
            :rules="[rules.required]"
          ></v-text-field>
        </div>

        <div style="width: 40%">
          <label for="">Admisión:<h2 class="red--text font-weight-bold d-inline-block">*</h2></label>
          <v-text-field
            prepend-inner-icon="mdi-check-decagram"
            dense
            outlined
            hide-details="auto"
            v-model="hospedar.admision"
            :rules="[rules.required]"
          ></v-text-field>
        </div>
      </div>

      <div class="d-flex my-2" v-if="hospedar.tipo == 'colaborador'">
        <div class="mr-2" style="width: 50%">
          <label for="">Jefatura:<h2 class="red--text font-weight-bold d-inline-block">*</h2></label>
          <v-select
            prepend-inner-icon="mdi-sitemap"
            dense
            outlined
            hide-details="auto"
            v-model="hospedar.jefatura"
            :items="jefaturas"
            :rules="[rules.required]"
          ></v-select>
        </div>

        <div class="mr-2" style="width: 50%">
          <label for="">Cargo:<h2 class="red--text font-weight-bold d-inline-block">*</h2></label>
          <v-text-field
            prepend-inner-icon="mdi-briefcase"
            dense
            outlined
            hide-details="auto"
            v-model="hospedar.cargo"
            :rules="[rules.required]"
          ></v-text-field>
        </div>
      </div>

      <div class="d-flex my-2">
        <div style="width: 50%" class="mr-2">
          <label for="">Fecha de Ingreso:<h2 class="red--text font-weight-bold d-inline-block">*</h2></label>
          <v-text-field
            type="date"
            prepend-inner-icon="mdi-calendar-start"
            dense
            outlined
            hide-details="auto"
            v-model="hospedar.fecha_ingreso"
            :rules="[rules.required]"
          ></v-text-field>
        </div>

        <div style="width: 50%">
          <label for="">Fecha de salida (aprox):</label>
          <v-text-field
            type="date"
            prepend-inner-icon="mdi-calendar-end"
            dense
            outlined
            hide-details="auto"
            v-model="hospedar.fecha_salida"
          ></v-text-field>
        </div>
      </div>

      <div class="mx-auto">
        <label for="">Selecciona los días de la semana:</label>
        <v-rating
          length="7"
          v-model="hospedar.dias_semana"
          dense
          color="primary"
          class="d-flex justify-center"
        >
          <template v-slot:item="props">
            <template v-if="props.index === 0">
              <v-avatar
                @click="props.click"
                style="cursor: pointer"
                size="40"
                :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
                class="mr-2"
              >
                <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
                  L
                </h3>
              </v-avatar>
            </template>
            <template v-if="props.index === 1">
              <v-avatar
                @click="props.click"
                style="cursor: pointer"
                size="40"
                :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
                class="mr-2"
              >
                <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
                  M
                </h3>
              </v-avatar>
            </template>
            <template v-if="props.index === 2">
              <v-avatar
                @click="props.click"
                style="cursor: pointer"
                size="40"
                :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
                class="mr-2"
              >
                <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
                  Mc
                </h3>
              </v-avatar>
            </template>
            <template v-if="props.index === 3">
              <v-avatar
                @click="props.click"
                style="cursor: pointer"
                size="40"
                :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
                class="mr-2"
              >
                <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
                  J
                </h3>
              </v-avatar>
            </template>
            <template v-if="props.index === 4">
              <v-avatar
                @click="props.click"
                style="cursor: pointer"
                size="40"
                :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
                class="mr-2"
              >
                <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
                  V
                </h3>
              </v-avatar>
            </template>
            <template v-if="props.index === 5">
              <v-avatar
                @click="props.click"
                style="cursor: pointer"
                size="40"
                :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
                class="mr-2"
              >
                <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
                  S
                </h3>
              </v-avatar>
            </template>
            <template v-if="props.index === 6">
              <v-avatar
                @click="props.click"
                style="cursor: pointer"
                size="40"
                :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
                class="mr-2"
              >
                <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
                  D
                </h3>
              </v-avatar>
            </template>
          </template>
        </v-rating>
      </div>
    </v-col>

    <v-col cols="12">
      <label for="">Marque los implementos que se le entrega:</label>
      <div class="d-flex">
        <v-checkbox
          label="Almohada"
          class="py-2 rounded-xl px-2 mr-2"
          color="black"
          :class="hospedar.almohada? 'indigo accent-1 white--text' : 'black--text'"
          style="width: 50%; border: 2px solid #8C9EFF;"
          hide-details
          v-model="hospedar.almohada"
        ></v-checkbox>
        <v-checkbox
          label="Funda Almohada"
          color="black"
          :class="hospedar.funda_almohada? 'indigo accent-1 white--text' : 'black--text'"
          class="py-2 rounded-xl px-2"
          style="width: 50%; border: 2px solid #8C9EFF;"
          hide-details
          v-model="hospedar.funda_almohada"
        ></v-checkbox>
      </div>
      <div class="d-flex">
        <v-checkbox
          label="Frazada"
          color="black"
          :class="hospedar.frazada? 'indigo accent-1 white--text' : 'black--text'"
          class="py-2 rounded-xl px-2 mr-2"
          style="width: 50%; border: 2px solid #8C9EFF;"
          hide-details
          v-model="hospedar.frazada"
        ></v-checkbox>
        <v-checkbox
          label="Sabana"
          color="black"
          :class="hospedar.sabana? 'indigo accent-1 white--text' : 'black--text'"
          class="py-2 rounded-xl px-2"
          style="width: 50%; border: 2px solid #8C9EFF;"
          hide-details
          v-model="hospedar.sabana"
        ></v-checkbox>
      </div>

      <div class="d-flex">
        <v-checkbox
          label="Locker"
          color="black"
          :class="hospedar.locker? 'indigo accent-1 white--text' : 'black--text'"
          class="py-2 rounded-xl px-2"
          style="width: 50%; border: 2px solid #8C9EFF;"
          hide-details
          v-model="hospedar.locker"
        ></v-checkbox>
      </div>
    </v-col>

    <v-col cols="12">
      <v-btn :disabled="!formValidate" @click="setHospedarAxios(idpabellon)" color="indigo" class="white--text" block elevation="0">
       {{isEditable ? 'ACTUALIZAR DATOS ' : 'HOSPEDAR'}} </v-btn
      >
    </v-col>
  </v-row>
</v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Filters from '@/modules/Shared/Filters'
export default {
  name: "FormAsignacionCama",
  mixins:[ Filters ],
  data: () => ({
    rating: 5,
    formValidate: true,
    idpabellon: -1
  }),

  computed:{
    ...mapState('hospedaje',['hospedar', 'loading', 'alert', 'isEditable']),
  },

  methods: {
    ...mapActions('hospedaje', ['getDataEstudiantesApi','getApiReniecAxios', 'setHospedarAxios', 'getDataColaboradorApi']),
    buscarDNIClick(){
      if(this.hospedar.tipo == 'estudiante'){
        this.getDataEstudiantesApi();
      }else if(this.hospedar.tipo === 'colaborador'){
        this.getDataColaboradorApi()
      }
      else{
        this.getApiReniecAxios();
      }
    }
  },
 
  mounted(){
    if (this.$route.params.id) {
      this.idpabellon = this.$route.params.id;
    }
  }
};
</script>
