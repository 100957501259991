import axios from "axios";

export default class ApiColaboradorService{
    constructor(){
        this.axios = axios.create({
            baseURL: "https://ctm-colaboradores.cetemin.edu.pe/api/v1/",
            headers: {
                'Content-Type': "application/json",
                Accept: "application/json",
                Authorization: "Bearer 3|G7fWXbLjroLuJdQCaa7IP1DJewgnCHNLSb4tIs9a"
            }
        })
    }


    async get(numero){
        const response = await this.axios.get('/colaborador/' + numero)
        return response.data
    }
}