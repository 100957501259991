<template>
  <div class="black--text" style="width: 100%">
    <h4 class="font-weight-bold text-center py-4">MOVER HUESPED:</h4>
    <div class="pa-3 text-center blue lighten-5 rounded-lg">
      <h3 class="d-block font-weight-bold">{{ hospedar.nombrecompleto }}</h3>
      <span class="d-block">{{
        hospedar.tipo == "estudiante" ? hospedar.carrera : hospedar.cargo
      }}</span>
    </div>

    <h4 class="font-weight-bold text-center pt-4">A LA CAMA N°:</h4>
    <span class="d-block text-center">SELECCIONA UNA DE LAS CAMAS DESOCUPADAS</span>

      <v-item-group v-if="listacamas"  class="d-flex flex-wrap justify-center" v-model="cambioDeCama.cama_id">
        <div v-for="(numcama, i) in listacamas" :key="i">
          <v-item v-slot="{ active, toggle }" :value="numcama.idcama">
            <v-avatar @click="toggle" rounded="lg" class="font-weight-bold ma-1" 
            style="cursor: pointer;"
            :class="active? 'indigo white--text': 'blue-grey lighten-3'">
              {{ numcama.indexcama }}
            </v-avatar>
          </v-item>
        </div>
      </v-item-group>

      <div v-if="loadingListaCamas" class="d-flex flex-wrap justify-center">

          <v-skeleton-loader class="ma-1" type="button" width="50" v-for="(b, i) in [1,2,3,4,5,6,7,8,9,10]" :key="i"></v-skeleton-loader>

      </div>

    <v-btn @click="setCambioCamaAxios(idpabellon)" class="indigo white--text mt-2" block>REALIZAR CAMBIO</v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data:()=>({
    idpabellon : 0
  }),
  computed: {
    ...mapState("hospedaje", ["hospedar", "camaSeleccionada", "listacamas", "cambioDeCama", "loadingListaCamas"]),
  },

  methods:{
    ...mapActions('hospedaje',['setCambioCamaAxios'])
  },
  mounted() {
    console.log(this.hospedar);
    if(this.$route.params.id){
      this.idpabellon = this.$route.params.id
    }
  },
};
</script>
