<template>
  <div style="height: 100vh" class="d-flex align-center blue-grey lighten-4">
    <v-card elevation="0" class="transparent">
      <v-card-text class="black--text pa-5">
        <v-row>

          <v-col cols="6" class="py-1">
            <div>
              <label for="">Fecha y hora de Ingreso</label>
              <div v-if="visita.hora_ingreso" class="rounded-lg white font-weight-bold pa-2">
                {{ formatFechaCustom(visita.fecha, "DD MMM YYYY")  + ' / ' + visita.hora_ingreso}}
              </div>
              <div v-else class="rounded-lg white font-weight-bold pa-2">{{  formatFechaCustom(visita.fecha, "DD MMM YYYY") + ' - no ingresa'  }}</div>

            </div>
          </v-col>



          <v-col cols="6" class="py-1">
            <div>
              <label for="">Fecha y Hora Salida</label>
              <div
                v-if="visita.hora_salida"
                class="rounded-lg white font-weight-bold pa-2"
              >
                {{ formatFechaCustom(visita.fecha_salida, "DD MMM YYYY") + "  /  " + visita.hora_salida }}
              </div>
              <div v-else class="rounded-lg white font-weight-bold pa-2">pendiente</div>
            </div>
          </v-col>

          <v-col cols="12" md="6" class="py-1" v-if="visita.tipoVisita.value == 'estudiantes' || visita.tipoVisita.value == 'colaboradores' || visita.tipoVisita.value == 'visitas' || visita.tipoVisita.value == 'terceros'">
            <div>
              <label for="">Documento Identidad</label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.dni }}
              </div>
            </div>  
          </v-col>

          <v-col cols="12" md="6" class="py-1" v-if="visita.tipoVisita.value == 'estudiantes' && visita.permiso_salida">
            <div>
              <label for="">Permiso de salida:</label>
              <div class="rounded-lg white font-weight-bold pa-2 text-uppercase">
                {{ visita.permiso_salida.estado == 'enviado' ? 'VIGENTE' :  visita.permiso_salida.estado }}
              </div>
            </div>  
          </v-col>

          <v-col cols="12" class="py-1" v-if="visita.tipoVisita.value == 'estudiantes' || visita.tipoVisita.value == 'colaboradores' || visita.tipoVisita.value == 'visitas' || visita.tipoVisita.value == 'terceros'">
            <div>
              <label for="">Apellidos y Nombres</label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.nombrecompleto }}
              </div>
            </div>
          </v-col>

          
          <v-col cols="12" md="6" class="py-1" v-if="visita.tipoVisita.value == 'estudiantes'">
            <div>
              <label for="">Admisión</label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.admision }}
              </div>
            </div>
          </v-col>

          <v-col cols="12" class="py-1" v-if="visita.tipoVisita.value == 'estudiantes'">
            <div>
              <label for="">Carrera</label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.carrera }}
              </div>
            </div>
          </v-col>


          <v-col cols="12" md="6" class="py-1" v-if="visita.tipoVisita.value == 'proveedores'">
            <div>
              <label for="">RUC</label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.ruc ? visita.ruc : "NINGUNA" }}
              </div>
            </div>
          </v-col>


          <v-col cols="12" md="6" class="py-1" v-if="visita.tipoVisita.value == 'proveedores'">
            <div>
              <label for="">Razon Social</label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.entidad ? visita.entidad : "NINGUNA" }}
              </div>
            </div>
          </v-col>


          <v-col cols="12" md="6" class="py-1" v-if="visita.tipoVisita.value == 'proveedores' || visita.tipoVisita.value == 'visitas' || visita.tipoVisita.value == 'terceros'">
            <div>
              <label for="">Área a visitar</label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.area }}
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6" class="py-1" v-if="visita.tipoVisita.value == 'colaboradores'">
            <div>
              <label for="">Área </label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.areaColaborador }}
              </div>
            </div>
          </v-col>


          <v-col cols="12" md="6" class="py-1" v-if="visita.tipoVisita.value == 'terceros'">
            <div>
              <label for="">Empresa Provee. Servicios </label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.empresaservicios }}
              </div>
            </div>
          </v-col>
    

          <v-col cols="12" v-if="visita.tipoVisita.value == 'proveedores' || visita.tipoVisita.value == 'visitas'" class="py-1">
            <div>
              <label for="">Motivo</label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.motivo }}
              </div>
            </div>
          </v-col>


          <v-col cols="12" class="py-1" v-if="visita.tipoVisita.value == 'proveedores'">
            <label for=""> Personal que ingresó</label>
                <div :style="visita.personal.length > 3? 'overflow-y: auto; height: 150px;' : ''">
                  <v-list-item v-for="(p, i) in visita.personal" :key="i" dense class="white rounded-lg my-2">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">{{ p.nombrecompleto }} - <span>{{ p.dni }}</span></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                </div>
          </v-col>

          <v-col cols="12" v-if="visita.marca_vehiculo" class="py-1">
            <label for="">Vehículo ingresado</label>
            <div class="rounded-lg white font-weight-bold pa-2">
              <span>{{ visita.marca_vehiculo + " " + visita.placa_vehiculo }}</span>
            </div>
          </v-col>

          <v-col
            cols="12"
            v-if="visita.materialesIngresados.length > 0"
            class="py-1"
          >
            <label for="">Materiales Ingresados</label>
            <v-data-table
              :headers="headers"
              :items="visita.materialesIngresados"
              hide-default-footer
              fixed-header
              :height="visita.materialesIngresados.length > 2? '150' : ''"
              mobile-breakpoint="0"
              dense
            >

            <template v-slot:item.cantidad="{item}"><span>{{ item.cantidad }}</span></template>
            <template v-slot:item.descripcion="{item}"><span>{{ item.descripcion }}</span></template>
            <template v-slot:item.marca="{item}"><span>{{ item.marca }}</span></template>
            <template v-slot:item.codigo="{item}"><span>{{ item.codigo }}</span></template>
          
          </v-data-table>
          </v-col>

          <v-col cols="12" class="d-flex justify-space-between">
              <v-chip small><small class="font-weight-bold">
                <v-avatar left size="10" class="bgCTM white--text">I</v-avatar>
                {{ visita.usuario.nombres + " " + visita.usuario.apellidos }}</small></v-chip>
              <v-chip small v-if="visita.usuario_salida"><small class="font-weight-bold">
                <v-avatar left size="10" class="bgCTM white--text">S</v-avatar>
                {{ visita.usuario_salida.nombres + " " + visita.usuario_salida.apellidos }}</small></v-chip>
              <v-btn @click="dialog = !dialog" v-if="visita.evidencia_visita && visita.evidencia_visita.length > 0" small class="bgCTM white--text"><span>VER EVIDENCIA</span></v-btn>

            </v-col>


          <v-col cols="12" v-if="visita.observacion" class="py-1">
            <div>
              <label for="">Observación de Ingreso</label>
              <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.observacion }}
              </div>
            </div>
          </v-col>

          <v-col cols="12" v-if="visita.hora_salida && visita.comentario_salida" class="py-1">
            <span>Observación de Salida</span>
            <div class="rounded-lg white font-weight-bold pa-2">
                {{ visita.comentario_salida }}
              </div>
          </v-col>

          <v-col cols="12" v-if="visita.evidencia_salida">
            <v-btn @click="dialogSalida = !dialogSalida"  small class="bgCTM white--text"><span>VER EVIDENCIA DE SALIDA</span></v-btn>
          </v-col>

          <v-dialog width="800" v-model="dialog">
            <v-card elevation="0">
              <v-card-text class="pa-1">
                  <v-carousel :show-arrows="false" class="custom-carousel" height="700">
                      <v-carousel-item
                        v-for="(item,i) in visita.evidencia_visita"
                        :key="i"
                      >
                      <img :src="item" class="img-fluid">
                    </v-carousel-item>
                    </v-carousel>
                </v-card-text>
            </v-card>
          </v-dialog>


          <v-dialog width="800" v-model="dialogSalida">
            <v-card elevation="0">
              <v-card-text class="pa-1">
                  <v-carousel :show-arrows="false" class="custom-carousel" height="700">
                      <v-carousel-item
                        v-for="(item,i) in visita.evidencia_salida"
                        :key="i"
                        class="d-flex align-center" style="height: 100%;"
                      >
                      <img :src="item" class="img-fluid">
                    </v-carousel-item>
                    </v-carousel>
                </v-card-text>
            </v-card>
          </v-dialog>

        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import { mapState } from "vuex";

export default {
  mixins: [Filters],
  data() {
    return {
      dialog: false,
      dialogSalida: false,
      headers: [
        {
          text: "Cant.",
          value: "cantidad",
          sortable: false,
          width: 30,
        },
        {
          text: "Descripción.",
          value: "descripcion",
          sortable: false,
          width: 150,
        },
        {
          text: "Marca.",
          value: "marca",
          sortable: false,
          width: 90,
        },
        {
          text: "Serie.",
          value: "codigo",
          sortable: false,
          width: 90,
        },
      ],
    };
  },
  computed: {
    ...mapState("visitas", ["visita"]),
  },

};
</script>


<style>
.img-fluid {
  width: 100%;

}

.custom-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
