import axios from "axios";
import swal from "sweetalert";
import Vue from "vue";


import utiles from "@/modules/Shared/utiles";
import router from "@/router";
import store from "@/store";
export default {
  namespaced: true,
  modules: {
    utiles
  },
  state: {
    dataPabellon: {
      selectedTemplate: "AyBrectangle",
      selectdUbicacionBaño: "bañoderecha",
      genero: "",
      nombre: "",
      piso: "",
      personal_hop: "",
      capacidad_total: "",
      descripcion: "",
      listaBloques: []
    },

    listaBloques: [],

    bloque: {
      idbloque: -1,
      cardinal: "",
      tipo: "",
      camas: [],
      comentario: "",
    },

    bloqueDefault: {
      idbloque: -1,
      cardinal: "",
      tipo: "",
      camas: [],
      comentario: "",
    },

    camaSimple: {
      indexcama: "",
      state: "",
      position: "",
      is_camasimple: false,
      is_camaarriba: false,
      is_camaabajo: false,
    },

    camaArriba: {
      indexcama: "",
      state: "",
      position: "",
      is_camasimple: false,
      is_camaarriba: false,
      is_camaabajo: false,
    },

    camaAbajo: {
      indexcama: "",
      state: "",
      position: "",
      is_camasimple: false,
      is_camaarriba: false,
      is_camaabajo: false,
    },

    camaDefault: {
      indexcama: "",
      state: "",
      position: "",
      is_camasimple: false,
      is_camaarriba: false,
      is_camaabajo: false,
    },

    ubicacionCardinal: "",

    dialogAddCama: {
      open: false,
    },

    isEdit: false
  },
  mutations: {
    addcama(state) {
      state.bloque.idbloque = Math.floor(Math.random() * 1000);
      state.bloque.cardinal = state.ubicacionCardinal;
      if (state.bloque.tipo == "cama") {
        state.camaSimple.is_camasimple = true;
        state.bloque.camas = Object.assign({}, state.camaSimple);
      }
      if (state.bloque.tipo == "camarote") {
        state.camaArriba.is_camaarriba = true;
        state.camaAbajo.is_camaabajo = true;
        state.bloque.camas = [];
        state.bloque.camas.push(Object.assign({}, state.camaArriba));
        state.bloque.camas.push(Object.assign({}, state.camaAbajo));
      }
      state.listaBloques.push(Object.assign({}, state.bloque));
      console.log(state.listaBloques);
    },

    updateCama(state) {
      //Obtener el indice del bloque a actualizar
      let index = state.listaBloques
        .map((bloque) => bloque.idbloque)
        .indexOf(state.bloque.idbloque);

      if (state.bloque.tipo == "cama") {
        state.camaSimple.is_camasimple = true;

        if (index > -1) {
          state.bloque.camas = Object.assign({}, state.camaSimple);
          Vue.set(state.listaBloques, index, Object.assign({}, state.bloque));
        }
      }

      if (state.bloque.tipo == "camarote") {
        state.camaArriba.is_camaarriba = true;
        state.camaAbajo.is_camaabajo = true;

        if (index > -1) {
          state.bloque.camas = [];
          state.bloque.camas.push(Object.assign({}, state.camaArriba));
          state.bloque.camas.push(Object.assign({}, state.camaAbajo));
          Vue.set(state.listaBloques, index, Object.assign({}, state.bloque));
        }
      }

      if (state.bloque.tipo == "muro" || state.bloque.tipo == "vacio") {
        state.bloque.camas = Object.assign({}, {});
        Vue.set(state.listaBloques, index, Object.assign({}, state.bloque));
      }

      state.dialogAddCama.open = false;
    },

    clearBloque(state) {
      /**RESETEAR DATA CAMA */
      state.camaSimple = Object.assign({}, state.camaDefault);
      state.camaArriba = Object.assign({}, state.camaDefault);
      state.camaAbajo = Object.assign({}, state.camaDefault);
      state.bloque = Object.assign({}, state.bloqueDefault);
    },

    OpenDialogCardinal(state, payload) {
      /**RESETEAR DATA CAMA */
      state.camaSimple = Object.assign({}, state.camaDefault);
      state.camaArriba = Object.assign({}, state.camaDefault);
      state.camaAbajo = Object.assign({}, state.camaDefault);
      state.bloque = Object.assign({}, state.bloqueDefault);
      state.isEdit = false;

      state.dialogAddCama.open = !state.dialogAddCama.open;
      state.ubicacionCardinal = payload;
    },

    editBloqueLocal(state, payload) {
      state.isEdit = true;
      state.dialogAddCama.open = true;

      state.bloque = Object.assign({}, payload);

      if (payload.tipo == "cama") {
        state.camaSimple = Object.assign({}, payload.camas);
      }
      if (payload.tipo == "camarote") {
        state.camaSimple = Object.assign({}, payload.camas);

        payload.camas.map((cama) => {
          state.camaArriba = Object.assign(
            {},
            cama.is_camaarriba ? cama : state.camaArriba
          );
          state.camaAbajo = Object.assign(
            {},
            cama.is_camaabajo ? cama : state.camaAbajo
          );
        });
      }
    }, 

    quitarBloque(state, idbloque) {
      //Obtener el indice del bloque a actualizar
      let index = state.listaBloques
        .map((bloque) => bloque.idbloque)
        .indexOf(idbloque);
      console.log(index);
      swal({
        title: "¿Desea Eliminar el bloque?",
        buttons: ["cancelar", "ELIMINAR"],
        dangerMode: true,
        icon: "warning",
      }).then((confirma) => {
        if (!confirma) {
          return;
        }

        state.listaBloques.splice(index, 1);
        swal("Se eliminó el bloque", {
          icon: "success",
        });
      });
    },

    changeTemplate(state, payload) {
      state.dataPabellon.selectedTemplate = payload;
    },
  },
  actions: {
    editBloque({ commit, state }, payload) {
      commit("editBloqueLocal", payload);
    },

    saveBloque({ commit, state }) {
      if (!state.isEdit && state.bloque.idbloque == -1) {
        commit("addcama");
      }

      if (state.isEdit && state.bloque.idbloque > -1) {
        commit("updateCama");
      }

      commit("clearBloque");
    },

    deleteBloque({ commit, state }, idbloque) {
      commit("quitarBloque", idbloque);
    },

    genararPabellon({ commit, state }) {
      commit('utiles/resetConfigAxios');
      state.dataPabellon.listaBloques = state.listaBloques;
      utiles.state.configAxios.url = utiles.state.apiUrl + 'pabellones'
      utiles.state.configAxios.method = "POST"
      utiles.state.configAxios.data = state.dataPabellon
      utiles.state.configAxios.headers.Authorization = "Bearer " + store.state.user.token;

      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });
      axios(utiles.state.configAxios)
      .then(resp =>{
        if(resp.status == 200){
            swal({
              title: "PABELLÓN CREADO CON EXITO!",
              icon: "success"
            })

            router.push({ name: "pabellones" })
        }
      })
      .catch(error => {
        swal({
          title: "Ha ocurrido un error :("
        })
        console.log(error)
      })
    },
  },
  getters: {},
};
