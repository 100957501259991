import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class ArchivosAdjuntosService{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json", 
                Authorization: 'Bearer '+ store.state.user.token
              }, 
        })

 
        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );

    }


    async get(registro_id)
    {
        const response = await this.axios.get('/archivoadjunto', {params: {registro_id: registro_id}})
        return response.data
    }

    async add(data)
    {
        const response = await this.axios.post('/archivoadjunto', data)
        return response.data
    }

    async delete(idarchivo)
    {
        const response = await this.axios.delete('/archivoadjunto?idarchivo=' + idarchivo)
        return response.data
    }

}