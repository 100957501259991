<template>
  <div>
    <v-card elevation="0" v-if="hidenFilters" class="animate__animated animate__fadeIn mb-1">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="7">
            <v-form v-model="formvalidateFilter">
              <div class="d-flex flex-wrap align-center">
                <div class="black--text font-weight-bold mr-lg-2"
                  :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 29%'">
                  <label for="">Buscar por DNI o Nombre</label>
                  <v-text-field v-model="filtroVisitas.nombreDni" clearable outlined dense hide-details
                    placeholder="Buscar por DNI o Nombre" @keypress.enter="getVisitasHoy()" @click:clear="
      filtroVisitas.nombreDni = '';
    getVisitasHoy();
    "></v-text-field>
                </div>

                <div v-if="!$can('read', 'estudianteasistencia')" class="black--text font-weight-bold mr-lg-2"
                  :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 29%'">
                  <label for="">Buscar por tipo</label>
                  <v-select v-model="filtroVisitas.tipovisita" placeholder="Selecciona tipo de Visita" clearable dense
                    hide-details :items="tipoVisita" item-value="idtipovisita" flat outlined class="grey lighten-4"
                    @change="getVisitasHoy()">
                    <template v-slot:selection="{ item }">
                      <v-list-item style="min-height: 20px !important" class="px-0">
                        <v-list-item-icon class="ma-2">
                          <v-avatar size="20" class="bgCTM"><v-icon color="white" size="18">{{
      item.icon
    }}</v-icon></v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content class="py-1">
                          <v-list-item-title class="font-weight-bold">{{
        item.text
      }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-icon class="mr-2">
                        <v-avatar size="25" class="bgCTM"><v-icon color="white" size="20">{{
      item.icon
    }}</v-icon></v-avatar>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </div>

                <div class="black--text font-weight-bold mr-lg-2"
                  :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 29%'" v-if="hasMultipleSedes()">
                  <label for="">Buscar por sede</label>
                  <v-select v-model="filtroVisitas.selectSedes" placeholder="Selecciona sede" dense flat outlined
                    class="grey lighten-4" multiple hide-details :items="sedes" @change="getVisitasHoy()">
                  </v-select>
                </div>

                <!--<div class="mt-5">
                  <v-btn
                    :disabled="!formvalidateFilter"
                    @click="getVisitasHoy()"
                    style="
                      width: 30px !important;
                      min-width: 30px !important;
                      min-height: 40px !important;
                    "
                    class="px-1 ml-1 bgCTM white--text"
                    elevation="0"
                    ><v-icon>mdi-filter</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="!formvalidateFilter"
                    @click="resetFilterText()"
                    style="
                      width: 30px !important;
                      min-width: 30px !important;
                      min-height: 40px !important;
                    "
                    class="px-1 ml-1 grey lighten-3 text-CTM"
                    elevation="0"
                    ><v-icon>mdi-restore</v-icon></v-btn
                  >
                </div>-->
              </div>
            </v-form>
          </v-col>
          <v-col cols="12" md="4">
            <v-form v-model="formvalidateFilterDate" class="font-weight-bold black--text">
              <div class="d-flex align-center justify-space-between">
                <span>Filtrar por rango de fechas</span>
                <div class="d-flex black--text">
                  <v-checkbox v-model="filtroVisitas.solo_ingreso" label="Ingreso" class="ma-0 pa-0 mr-2" hide-details
                    style="color: #000000"></v-checkbox>
                  <v-checkbox v-model="filtroVisitas.solo_salida" label="Salida" class="ma-0 pa-0" hide-details
                    style="opacity: 1 !important;"></v-checkbox>
                </div>
              </div>
              <div class="d-flex flex-wrap align-center">
                <div class="d-flex align-center" :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 50%'">
                  <div class="mr-2 font-weight-bold">De</div>
                  <v-text-field :rules="[rules.required]" clearable v-model="filtroVisitas.fecha_inicio" type="date"
                    class="mr-2 mb-2" outlined dense hide-details></v-text-field>
                </div>
                <div class="d-flex align-center" :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 50%'">
                  <div class="mr-2 font-weight-bold">Hasta</div>
                  <v-text-field :rules="[rules.required]" clearable v-model="filtroVisitas.fecha_fin" type="date"
                    outlined dense hide-details></v-text-field>
                  <v-btn :disabled="!formvalidateFilterDate" @click="getVisitasHoy()" style="
                      width: 30px !important;
                      min-width: 30px !important;
                      min-height: 40px !important;
                    " class="px-1 ml-1 bgCTM white--text" elevation="0"><v-icon>mdi-filter</v-icon></v-btn>
                  <v-btn :disabled="!formvalidateFilterDate" @click="resetFilter()" style="
                      width: 30px !important;
                      min-width: 30px !important;
                      min-height: 40px !important;
                    " class="px-1 ml-1 grey lighten-3 text-CTM" elevation="0"><v-icon>mdi-restore</v-icon></v-btn>
                </div>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0">
      <v-card-title class="justify-space-between px-0">
        <v-chip small class="mx-5">{{ paginate.total }} Visitas del día</v-chip>
        <div class="d-flex flex-wrap gap-2 px-4 justify-space-center">
          <v-btn v-if="$can('read', 'permisos')" @click="getBlindingPermisionsAndOpenSlider()" elevation="0" small
            class="grey lighten-3 mr-1 text-subtitle-1 font-weight-bold py-4"><v-icon size="20" left
              color="light-blue accent-4">mdi-bell-check</v-icon><small>{{
      $vuetify.breakpoint.xs ? "PERMISOS" : "PERMISOS A ESTUDIANTES"
    }}</small></v-btn>
          <v-btn v-if="$can('create', 'visitasmasivas')" @click="openRegistrarVisitasMasivas()" elevation="0" small
            class="bgCTM white--text mr-1 mb-2 text-subtitle-1 font-weight-bold py-4"><v-icon size="20"
              left>mdi-expand-all</v-icon><small>{{
      $vuetify.breakpoint.xs ? "MASIVO" : "VISITAS MASIVAS"
    }}</small></v-btn>
          <v-btn v-if="$can('read', 'visitas') || $can('read', 'estudianteasistencia')
      " @click="getReporteVisitasExcel()" elevation="0" small
            class="green accent-4 white--text mr-1 text-subtitle-1 font-weight-bold py-4"><v-icon size="20"
              left>mdi-file-excel-box</v-icon><small>{{
      $vuetify.breakpoint.xs ? "REPORTE" : "DESCARGAR REPORTE"
    }}</small></v-btn>
          <v-btn v-if="$can('create', 'visitas')" @click="openPreviewRegistrarVisita()" elevation="0" small
            class="light-blue darken-4 white--text text-subtitle-1 font-weight-bold py-4"><v-icon size="20"
              left>mdi-home-plus</v-icon><small>{{
      $vuetify.breakpoint.xs ? "N. VISITA" : "REGISTRAR VISITA"
    }}</small></v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table :loading="loadingvisitas" :headers="headers" :items="listaVisitasHoy" height="480px" fixed-header
          :items-per-page="80" :options.sync="options" :server-items-length="paginate.total" :mobile-breakpoint="0"
          :footer-props="{ 
      'items-per-page-options': [80, 120, 200],
    }">
          <template v-slot:item.tipoVisita="{ item }">
            <v-tooltip top color="teal lighten-1" v-if="item.tipoVisita.text == 'ESTUDIANTES'">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar v-bind="attrs" v-on="on" size="40" class="amber accent-3">
                  <v-img :src="require('@/assets/estudiante.png')"
                    :lazy-src="require('@/assets/estudiante.png')"></v-img>
                </v-avatar>
              </template>
              <span>ESTUDIANTE</span>
            </v-tooltip>

            <v-tooltip top color="teal lighten-1" v-if="item.tipoVisita.text == 'COLABORADORES'">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar v-bind="attrs" v-on="on" size="40" class="cyan darken-1">
                  <v-img :src="require('@/assets/colaborador.png')"
                    :lazy-src="require('@/assets/colaborador.png')"></v-img>
                </v-avatar>
              </template>
              <span>COLABORADORES</span>
            </v-tooltip>

            <v-tooltip top color="teal lighten-1" v-if="item.tipoVisita.text == 'VISITAS'">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar v-bind="attrs" v-on="on" size="40" class="green accent-4">
                  <v-img max-width="30" :src="require('@/assets/visita.png')" :lazy-src="require('@/assets/visita.png')"
                    contain></v-img>
                </v-avatar>
              </template>
              <span>VISITA</span>
            </v-tooltip>

            <v-tooltip top color="teal lighten-1" v-if="item.tipoVisita.text == 'PERSONAL DE TERCEROS'">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar v-bind="attrs" v-on="on" size="40" class="deep-orange darken-1">
                  <v-img :src="require('@/assets/tercero.png')" :lazy-src="require('@/assets/tercero.png')"></v-img>
                </v-avatar>
              </template>
              <span>P. TERCERO</span>
            </v-tooltip>

            <v-tooltip top color="teal lighten-1" v-if="item.tipoVisita.text == 'PROVEEDORES'">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar v-bind="attrs" v-on="on" size="40" class="red darken-4">
                  <v-img :src="require('@/assets/proveedor.png')" :lazy-src="require('@/assets/proveedor.png')"></v-img>
                </v-avatar>
              </template>
              <span>PROVEEDOR</span>
            </v-tooltip>
          </template>
          <template v-slot:item.fecha="{ item }">
            <b>{{ formatFechaCustom(item.fecha, "DD/MMM") }}</b>
          </template>
          <template v-slot:item.dni="{ item }">
            <b>{{ item.dni || item.ruc }}</b>
          </template>

          <template v-slot:item.nombrecompleto="{ item }">
            <span v-if="item.dni && item.dni != ''" style="cursor: pointer" @click="openPreviewVisita(item)"
              class="d-block font-weight-bold blue--text text--darken-4">{{ item.nombrecompleto }}</span>
            <span v-if="item.entidad && item.entidad != ''" style="cursor: pointer" @click="openPreviewVisita(item)"
              class="d-block font-weight-bold blue--text text--darken-4">{{ item.entidad }}</span>
          </template>

          <template v-slot:item.areaVisita="{ item }">
            <small class="font-weight-bold">{{
      item.area ? item.area : item.areaColaborador
    }}</small>
            <small class="font-weight-bold" v-if="item.tipoVisita.text == 'ESTUDIANTES'">{{ item.carrera }}</small>
          </template>

          <template v-slot:item.fecha_salida="{ item }">
            <b v-if="item.fecha_salida">{{
      formatFechaCustom(item.fecha_salida, "DD/MMM")
    }}</b>
          </template>

          <template v-slot:item.hora_ingreso="{ item }">
            <v-btn v-if="isToday(item.fecha) &&
      !item.hora_ingreso &&
      $can('update', 'visitas')
      " @click="openMarcarIngreso(item)" small elevation="0" class="font-weight-bold" :class="[
      item.impedir_ingreso == 'SI'
        ? 'red darken-4 white--text'
        : 'green accent-3',
    ]">MARCAR INGRESO</v-btn>
            <span v-else>{{ item.hora_ingreso }}</span>
          </template>

          <template v-slot:item.sedes="{ item }">
            <small>{{ item.sedes }}</small>
          </template>

          <template v-slot:item.hora_salida="{ item }">
            <!--<v-btn v-if="isToday(item.fecha) || item.tipoVisita.value =='estudiante' && !item.hora_salida && $can('update', 'visitas') && item.hora_ingreso != null" @click="openMarcarSalida(item)" x-small elevation="0" class="yellow accent-4 font-weight-bold">MARCAR SALIDA</v-btn>-->
            <v-btn v-if="!item.hora_salida &&
      $can('update', 'visitas') &&
      item.hora_ingreso != null
      " @click="openMarcarSalida(item)" small elevation="0" class="yellow accent-4 font-weight-bold">
              <small v-if="item.permiso_salida && item.permiso_salida.estado != 'anulado'
      ">MARCAR SALIDA CON PERMISO</small>
              <span v-else>MARCAR SALIDA</span>
            </v-btn>
            <span v-else>{{ item.hora_salida }}</span>
          </template>

          <template v-slot:item.materialesIngresados="{ item }">
            <v-avatar size="25" v-if="item.materialesIngresados.length > 0" rounded
              class="green accent-4 white--text"><b>SI</b></v-avatar>
            <v-avatar size="25" v-else rounded class="blue-grey darken-4 white--text"><b>NO</b></v-avatar>
          </template>

          <!--
                    <template v-slot:item.reporte="{item}">
                        <v-btn icon tile elevation="0" class="bgCTM white--text" small><v-icon small>mdi-printer</v-icon></v-btn>
                    </template>
                    -->

          <template v-slot:item.usuario="{ item }">
            <v-chip small>
              <small class="font-weight-bold">{{
      formatUserName(item.usuario.nombres)
    }}</small></v-chip>
          </template>

          <template v-slot:item.usuario_salida="{ item }">
            <v-chip small v-if="item.usuario_salida">
              <small class="font-weight-bold">{{
                formatUserName(item.usuario_salida.nombres)
                }}</small></v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn v-if="item.fecha_salida == null && item.tipoVisita.text == 'ESTUDIANTES'"
              @click="onAddMaterials(item)" icon
              class="amber rounded-lg black--text"><v-icon>mdi-cellphone-link</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Filters from "@/modules/Shared/Filters";
import Push from "push.js";
import { socket } from "@/socket-cliente";
import { ref } from "vue";
import RegistrarMaterialesComponent from "../components/VisitasDiaComponents/RegitrarMaterialesComponent.vue"
export default {
  mixins: [Filters],
  props: {
    hidenFilters: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      push: null,
      formvalidateFilterDate: true,
      formvalidateFilter: true,
      dialogSalida: false,
      headers: [
        {
          text: "",
          value: "tipoVisita",
          sortable: false,
          width: "30px",
        },
        {
          text: "Documento",
          value: "dni",
          sortable: false,
          width: "50px",
        },
        {
          text: "Apellidos y Nombres / Razón Social",
          value: "nombrecompleto",
          sortable: false,
          width: "300px",
        },
        {
          text: "sede",
          value: "sedes",
          sortable: false,
          width: "20px",
        },

        {
          text: "Área / Jefatura / Carrera",
          value: "areaVisita",
          sortable: false,
          width: "200px",
        },
        {
          text: "F. de entrada",
          value: "fecha",
          sortable: false,
          width: "70px",
        },
        {
          text: "H. Entrada",
          value: "hora_ingreso",
          sortable: false,
          width: "100px",
        },

        {
          text: "F. Salida",
          value: "fecha_salida",
          sortable: false,
          width: "100px",
        },
        {
          text: "H. Salida",
          value: "hora_salida",
          sortable: false,
          width: "100px",
        },

        {
          text: "Ingresó Materiales",
          value: "materialesIngresados",
          sortable: false,
          width: "30px",
        },
        {
          text: "User Ingre.",
          value: "usuario",
          sortable: false,
          width: "30px",
        },
        {
          text: "User Salida",
          value: "usuario_salida",
          sortable: false,
          width: "30px",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
      ],
      options: {},
    };
  },
  watch: {
    options: {
      handler(option) {
        if (option) {
          this.option.page = option.page;
          this.option.itemsPerPage = option.itemsPerPage;
          this.getVisitasHoy(true);
        }
      },
    }, 
  },
  computed: {
    ...mapState("visitas", [
      "listaVisitasHoy",
      "dialogVisitaPreview",
      "filtroVisitas",
      "loadingvisitas",
      "paginate",
      "option",
    ]),
    ...mapState("visitas/registrarVisita", ["tipoVisita"]),
    // ...mapState('visitas/registrarSalida', ['loadingSalida', 'salida', 'visitaSalida']),
    ...mapState("visitas/utiles", ["sedes"]),
  },

  methods: {
    ...mapActions("visitas", [
      "getVisitasHoy",
      "resetFilter",
      "resetFilterText",
    ]),
    ...mapMutations("visitas", [
      "openPreviewVisita",
      "openPreviewRegistrarVisita",
      "openRegistrarVisitasMasivas",
      "openMarcarIngreso",
      "openMarcarSalida",
    ]),
    ...mapActions("visitas/registrarVisita", ["getApiTipoVisitas"]),
    ...mapActions("visitas/reporteVisita", [
      "getReporteVisitas",
      "getReporteVisitasExcel",
    ]),
    ...mapActions("permisos", ["getBlindingPermisionsAndOpenSlider"]),

    ...mapMutations("silderDerechaComponent", ['openSilder']),

    ...mapMutations("visitas/materialesIngresados", ['openEditMateriales', 'resetMateriales']),

    onAddMaterials(visita) {
      this.resetMateriales()
      let component = ref(RegistrarMaterialesComponent)
      this.openSilder({ open: true, width: 600, component: component })
      this.openEditMateriales(visita)

    }
  },

  mounted() {
    this.getVisitasHoy();
    this.getApiTipoVisitas();

    socket.on("LOAD_ALL_VISITAS", (data) => {
      this.getVisitasHoy(data);
    });
  },
};
</script>
