import utiles from "@/modules/Shared/utiles"
import EstudianteService from "../services/EstudianteService"
import ApiEstudianteService from "@/modules/Shared/services/ApiEstudianteService"
import store from "@/store"
import readXlsxFile from 'read-excel-file'
import swal from "sweetalert"


const estudianteSv = new EstudianteService()
const apiEstudiante = new ApiEstudianteService()
export default{
    modules:{
        utiles
    },
    namespaced: true,
    state:{
        listEstudiantes: [],
        estudianteMasivo:[],
        masivoComponent: "",
        estudianteComedor:{
            dni: "",
            nombre_completo: "",
            admision: "",
            carrera: "",
            estado: true,
            sede: "",
            fin_de_semana: false
        },

        estudianteComedorDefault:{
            dni: "",
            nombre_completo: "",
            admision: "",
            carrera: "",
            estado: true,
            sede: "",
            fin_de_semana: false
        },


        filterEstudiante:{
            dni: "",
            nombre_completo: "",
            admision: "",
            carrera: "",
            estado: "",
            sede: ""
        },

        filterEstudianteDefault:{
            dni: "",
            nombre_completo: "",
            admision: "",
            carrera: "",
            estado: "",
            sede: ""
        },

        estadoEstudiante:{
            newestado: false,
        },

        loadingRegister: false,
        loadingList: false
    },

    mutations:{
        setListEstudiantes(state, payload)
        {
            state.listEstudiantes = payload
        },

        setEstudiante(state, estudiante)
        {
            state.estudianteComedor = Object.assign({}, estudiante)
        },


        resetEstudiante(state)
        {
            state.estudianteComedor = Object.assign({}, state.estudianteComedorDefault)
        },


        resetFilterEstudiante(state)
        {
            state.filterEstudiante = Object.assign({}, state.filterEstudianteDefault)
        },


        setDataStudent(state, data){
            state.estudianteComedor.nombre_completo = data.result.nomalu;
            state.estudianteComedor.carrera = data.result.specialtyName;
            var yeradmin = data.result.period.substr(0, 4);
            var periodAdmin = data.result.period.substr(
              4,
              data.result.period.length - 1
            );

            state.estudianteComedor.admision = yeradmin + "-" + periodAdmin;
        },


        readExcelToMasivo(state, file)
        {
            readXlsxFile(file)
            .then((rows) => {

                
                
                for(var i=3; i < rows.length; i++)
                {
                    
                    if(rows[i][0] == '')
                    {
                        continue;
                    }

                    state.estudianteMasivo.push({
                        dni: rows[i][0],
                        nombre_completo: rows[i][1],
                        carrera: rows[i][2],
                        admision: rows[i][3],
                        sede: rows[i][4],
                        estado: rows[i][5] == 'ACTIVO' ? 1 : 0,
                        fin_de_semana: rows[i][6] == 'SI' ? 1 : 0,

                    })

                }
                console.log(state.estudianteMasivo)
              })
            .catch(error => {
                console.log(error)
                swal({
                    icon: "error",
                    title: "Ha ocurrido un error!"
                })
                state.masivoComponent = "SubirMasivoHospedaje"
            })
        },

        resetMasive(state)
        {
            state.estudianteMasivo = []
        }
    },

    actions:{

        async getApiData({state, commit, dispatch})
        {
            try{
                const data = await apiEstudiante.get(state.estudianteComedor.dni)
                commit('setDataStudent', data)
            }
            catch(error)
            {
                console.log(error)
            }
        },


        async getList({state, commit})
        {
            console.log(state.filterEstudiante)
            state.loadingList = true
            try
            {
                const data = await estudianteSv.getList(state.filterEstudiante)
                console.log(data)
                commit('setListEstudiantes', data)
                state.loadingList = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingList = false
            }
        },



        async add({state, commit, dispatch})
        {
            state.loadingRegister = true
            try
            {
                const data = await estudianteSv.add(state.estudianteComedor)
                dispatch('getList')
                state.loadingRegister = false
                commit('resetEstudiante')
                store.commit('silderDerechaComponent/closeSilder')
            }
            catch(error)
            {
                console.log(error)
                state.loadingRegister = false
            }
        },


        async addMasive({state, commit, dispatch})
        {
            state.loadingRegister = true
            try
            {
                const data = await estudianteSv.masive(state.estudianteMasivo)
                dispatch('getList')
                state.loadingRegister = false
                commit('resetMasive')
                store.commit('silderDerechaComponent/closeSilder')
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ha ocurrido un error",
                    icon: "error"
                })
                state.loadingRegister = false
            }
        },



        async update({state, commit, dispatch})
        { 
            state.loadingRegister = true
            try
            {
                const data = await estudianteSv.update(state.estudianteComedor)
                dispatch('getList')
                state.loadingRegister = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingRegister = false
            }
        },


        async cambiarEstado({state, commit, dispatch}, idestudiantecomedor)
        {

            const confirm = swal({
                title : "¿Desea cambiar el estado?",
                icon: "warning",
                dangerMode: true,
                buttons:['cancel', 'CONFIRMAR']
            })
            if(!confirm){ return }
            state.loadingRegister = true
            try
            {
                const data = await estudianteSv.cambiarEstado({idestudiantecomedor: idestudiantecomedor})
                dispatch('getList')
                
                state.loadingRegister = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingRegister = false
            }
        },

        async cambiarEstadoFinDeSemana({state, commit, dispatch}, idestudiantecomedor)
        {

            const confirm = swal({
                title : "¿Desea seleccionar fin de semana?",
                icon: "warning",
                dangerMode: true,
                buttons:['CANCELAR', 'CONFIRMAR']
            })
            if(!confirm){ return }
            state.loadingRegister = true
            try
            {
                const data = await estudianteSv.cambiarEstadofindesemana({idestudiantecomedor: idestudiantecomedor})
                dispatch('getList')
                
                state.loadingRegister = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingRegister = false
            }
        },

         async resetPassword({state, commit}, idestudiantecomedor)
        {
            
            try
            {
                const confirm = await swal({
                    title : "¿Desea resetear contraseña?",
                    icon: "warning",
                    dangerMode: true,
                    buttons:['CANCELAR', 'CONFIRMAR']
                })
                if(!confirm){ return}

                
                const data = await estudianteSv.resetPassword({idestudiantecomedor: idestudiantecomedor})
                swal({
                    title: "Se cambió la contraseña",
                    icon: "success"
                })
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ocurrió un grave error",
                    icon: "error"
                })
            }
        },

        async getReporteExcel({state, commit, dispatch})
        {
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false, 
                closeOnClickOutside: false, 
                closeOnEsc: false,
                allowOutsideClick: false 
              });
            try
            {
                const data = await estudianteSv.getReporte(state.filterEstudiante)
                swal({
                    title: "Reporte generado correctamente",
                    icon: "success",
                  });
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ha ocurrido un error, vuelve a intentar",
                    icon: "error",
                  });

            }
        }

    }
}