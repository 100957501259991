<template>
  <!--- CAMA SIMPLE  --->
  <v-card
    elevation="0"
    :width="horizontal? '100%' : '70px'"
    class="mx-1 my-1 d-flex blue-grey lighten-2"
    :height="horizontal?'80px':'190px'"
    :class="[isHorizontal]"
    v-if="bloquecardinal == cardinal"
    style="overflow: hidden; cursor: pointer;"
  >
    <v-card-actions v-if="!onlyRead" class="py-0 px-0 text-caption" :class="horizontal? 'btnsHorizontal': ''">
     <v-btn-toggle dense :class="horizontal?'horizontal-btn-group':''">
       <v-btn @click="deleteBloque(bloque.idbloque)" x-small color="red" class="rounded-0" :class="horizontal?'horizontal-btn':'vertical-btn'" ><v-icon color="white" x-small>mdi-close-thick</v-icon></v-btn>
       <v-btn @click="editBloque(bloque)" x-small color="amber" class="rounded-0" :class="horizontal?'horizontal-btn':'vertical-btn'" ><v-icon color="white" x-small>mdi-pencil</v-icon></v-btn>
     </v-btn-toggle>
  </v-card-actions> 


        <div
          class="d-flex justify-center flex-column pa-1 align-center"
          :class="colorfondoCardCama(cama.state)"
          style="height: 100%; width: 100%; position: relative"
          @click="handleCamaClick(cama, tipo)"
        >
          <chipEstadoCama :estado="cama.state" class="mb-1"  />
          <v-badge avatar bordered overlap bottom >
            <v-avatar size="30" rounded="sm" :color="colorfondoCama(cama.state)">
              <span class="font-weight-bold white--text">{{
                cama.indexcama
              }}</span>
            </v-avatar>
            <template v-slot:badge>
              <v-avatar :color="
              colorfondoCama(cama.state)
              ">
                <v-icon color="white--text">{{ cama.huesped ? 'mdi-account' : 'mdi-bed-empty' }} </v-icon>
              </v-avatar>
            </template>
          </v-badge>
        </div>


    <v-card-actions class="indigo justify-center text-caption pa-0"
    :class="horizontal ? 'texto-vertical rounded-0' : ''">
      <small class="white--text text-uppercase"> CAMA</small>
    </v-card-actions>
  </v-card>
  <!--- CAMA SIMPLE --->
</template>

<script>
import store from "@/store";
import chipEstadoCama from "../chipEstadoCama.vue";
import { mapActions, mapMutations } from 'vuex';

export default {
  components: {
    chipEstadoCama,
  },
  props: {
    bloquecardinal: {
      type: String,
      required: true,
    },

    cama: {
      type: Object,
      required: true,
    },

    horizontal: {
      type: Boolean,
      default: false,
    },

    cardinal: {
      type: String,
      required: true,
    },

    comentario: {
      type: String,
      default: ""
    },

    tipo: {
      type: String,
      required: true,
    },

    bloque:{
      type: Object,
      required: true
    },
     onlyRead:{
      type: Boolean,
      default: false
    }

  },

  computed: {
    isHorizontal() {
      return this.horizontal ? "flex-row " : "flex-column";
    },
  },

  methods:{
    ...mapActions('pabellones/creadorPabellon',['editBloque', 'deleteBloque']),
    //...mapMutations('hospedaje', ['selectedCama']),
    handleCamaClick(cama, tipo) {
      const { $ability } = this.$ability
      store.commit('hospedaje/selectedCama', { cama, tipo, $ability: this.$ability }, { root: true });
    },
    colorfondoCama(statecama) {
      switch (statecama) {
        case "disponible":
          return "blue-grey lighten-2";
        case "ocupada":
          return "blue darken-1";
        case "reporte":
          return "amber accent-4";
        case "inoperativa":
          return "red darken-4";
      }
    },

    colorfondoCardCama(statecama) {
      switch (statecama) {
        case "disponible":
          return "blue-grey lighten-5";
        case "ocupada":
          return "blue lighten-4";
        case "reporte":
          return "amber lighten-4";
        case "inoperativa":
          return "red lighten-5";
      }
    },
  }
};
</script>

<style scoped>
.rotateHorizontal{
    transform: rotate(-0.25turn);
}

.btnsHorizontal{
      -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
      width: 20px;
      height: 90px !important;
}

.horizontal-btn-group{
  top: 10px;
  left: -75px;
  background-color: transparent !important;
}

.vertical-btn{
  padding: 0 0px  !important; 
  margin: 0 1px 0px 0px!important;
}
.v-btn:not(.v-btn--round).v-size--x-small{
    width: 10px !important;

}
.horizontal-btn{
  padding: 0 21px  !important; 
}
</style>