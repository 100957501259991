import utiles from "@/modules/Shared/utiles";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import store from "@/store";

export default {
  namespaced: true,
  modules: {
    utiles,
  },
  state: {
    reporte: {
      prioridad: "",
      isInoperativa: false,
      titulo_reporte: "",
      detalle_reporte: "",
      evidencia_reporte: "",
      cama_id: -1,
      user_id: -1,
      fecha_reporte: "",
    },
    reporteDefault: {
      prioridad: "",
      isInoperativa: false,
      titulo_reporte: "",
      detalle_reporte: "",
      evidencia_reporte: "",
      user_id: -1,
      cama_id: -1,
      fecha_reporte: "",
    },

    fileDetails: {
      typeFilePreview: null,
      urlPreviewFile: null,
      errorFileUpload: null,
      fileType: null,
    },

    fileDetailsDefault: {
      typeFilePreview: null,
      urlPreviewFile: null,
      errorFileUpload: null,
      fileType: null,
    },

    reporteSeleccionado: {
      reporte: {},
      cama: {},
    },

    solucionReporte: {
      detalle_solucion: "",
      isSolucion: "SI",
      user_id: -1,
      reporte_id: -1,
      cama_id: -1,
      evidencia_solucion: "",
    },

    solucionReporteDefault: {
      detalle_solucion: "",
      isSolucion: "SI",
      user_id: -1,
      reporte_id: -1,
      cama_id: -1,
      evidencia_solucion: "",
    },

    listaReportes: [],

    filterReportes:{
      estado : ["no-solucionado"],
      title : "",
      pabellon: []
    },
    loadingReportes: false,
    componentReportes :"ListaTablaCamasReportes",
    listaDeAsuntosReportes:[
      "Baranda de cama desatornillada o malograda",
      "Falta colocar cortinas",
      "Barra de cortina está suelto",
      "Puerta de Locker malograda",
      "Cortar candado de locker",
      "Ventana necesita mantenimiento"
    ],
  },
  mutations: {
    resetReporte(state) {
      state.reporte = Object.assign({}, state.reporteDefault);
      state.fileDetails = Object.assign({}, state.fileDetailsDefault);
    },
    addEvidenciaSolucion(state, event) {
      if (
        event != null &&
        !(
          event.type == "image/jpeg" ||
          event.type == "image/png" ||
          event.type == "image/jpg" ||
          event.type == "application/pdf"
        )
      ) {
        swal({
          title: "ARCHIVO NO PERMITIDO",
          text: "Porfavor solo agregre archivos: png, jpg, pdf",
          icon: "error",
        });
        state.solucionReporte.evidencia_solucion = null;

        return;
      }
      state.solucionReporte.evidencia_solucion = event;
    },
    addEvidencia(state, event) {
      if (
        event != null &&
        !(
          event.type == "image/jpeg" ||
          event.type == "image/png" ||
          event.type == "image/jpg" ||
          event.type == "application/pdf"
        )
      ) {
        swal({
          title: "ARCHIVO NO PERMITIDO",
          text: "Porfavor solo agregre archivos: png, jpg, pdf",
          icon: "error",
        });
        state.reporte.evidencia_reporte = null;
        state.fileDetails.typeFilePreview = null;
        state.fileDetails.urlPreviewFile = null;
        state.fileDetails.errorFileUpload = true;
        return;
      }
      state.reporte.evidencia_reporte = event;
      state.fileDetails.fileType = event ? event.type : null;
      state.fileDetails.errorFileUpload = event ? false : true;
      state.fileDetails.typeFilePreview = event ? event.type : null;
      state.fileDetails.urlPreviewFile = event
        ? URL.createObjectURL(event)
        : null;
    },

    setListaReportes(state, payload) {
      state.listaReportes = payload;
    },

    setReporteSeleccionado(state, payload) {
      state.reporteSeleccionado.reporte = Object.assign({}, payload.reporte);
      state.reporteSeleccionado.cama = Object.assign({}, payload.cama);
      state.componentReportes = "VistaReporte"
    },

    setResetComponentReportes(state, value){
      //state.componentReportes = "ListaCamasReporte"
      state.componentReportes = "ListaTablaCamasReportes"
    }
  },
  actions: {
    getListaReportesAxios({ commit, state }, params) {
      state.loadingReportes = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = 
        utiles.state.apiUrl + "reportesallpabellones";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = {
        sede: params.sede, 
        estadoReporte: params.estadoReporte,
        title: params.title,
        idpabellon: params.idpabellon
      }; 
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((response) => {
          if (response.status == 200) {
            commit("setListaReportes", response.data.reportes);
            state.loadingReportes = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingReportes = false;
        });
    },
    setReporteCamaAxios({ commit, dispatch, state, rootState }, idpabellon) {
      commit("utiles/resetConfigAxios");
      state.reporte.cama_id = rootState.hospedaje.camaSeleccionada.cama.idcama;
      state.reporte.fecha_reporte = moment().format("YYYY-MM-DD HH:mm:ss");
      const datos = new FormData();
      for (let key in state.reporte) {
        datos.append(key, state.reporte[key]);
      }
      //Add user_id
      datos.append("user_id", store.state.user.iduser);

      utiles.state.configAxios.url = utiles.state.apiUrl + "reportecama";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = datos;
      utiles.state.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });

      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            swal({
              title: "Se guardó el reporte!",
              icon: "success",
            });
            rootState.hospedaje.dialogCama = false;

            store.dispatch("hospedaje/getAxiosPabellon", idpabellon);
            store.dispatch("hospedaje/getHosepdadosAxios", {
              idpabellon: idpabellon,
            });
          }
        })
        .catch((error) => {
          console.log(error);

          swal({
            title: error.response.data.message,
            icon: "error",
          });
        });
    },

    setResponderReporteAxios({ commit, dispatch, state }, payload) {
      commit("utiles/resetConfigAxios");
      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });
      utiles.state.configAxios.url = utiles.state.apiUrl + "responder-reporte";
      utiles.state.configAxios.method = "POST";

      let data = new FormData();
      for (let key in state.solucionReporte) {
        data.append(key, state.solucionReporte[key]);
      }

      data.append("user_id", store.state.user.iduser);
      data.append("reporte_id", payload.idreporte);
      data.append("cama_id", payload.idcama);

      utiles.state.configAxios.data = data;
      utiles.state.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      axios(utiles.state.configAxios)
        .then((resp) => {
          console.log(resp);
          if (resp.status == 200) {
            swal({
              title: "Guardado!",
              icon: "success",
            });
          }

          state.solucionReporte.detalle_solucion = "";
          commit("setReporteSeleccionado", {
            reporte: resp.data.reporte,
            cama: resp.data.cama,
          });
          dispatch("getListaReportesAxios", {
            sede: store.state.user.roles.sedes.split(','),
            estadoReporte: state.filterReportes.estado,
            title: state.filterReportes.title,
            idpabellon: state.filterReportes.pabellon
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     * REACTIVAR UN REPORTE
     */

    setReactivarReporte({ commit, dispatch, state }, payload) {
      swal({
        title: "¿Desea reactivar el reporte?",
        icon: "warning",
        dangerMode: true,
        buttons: ["cancelar", "confirmar"],
      }).then((confirmation) => {
        if (!confirmation) {
          return;
        }

        commit("utiles/resetConfigAxios");
        utiles.state.configAxios.url =
          utiles.state.apiUrl + "reactivar-reporte";
        utiles.state.configAxios.method = "POST";
        //iduser
        let data = {};
        data.user_id = store.state.user.iduser;
        data.cama_id = payload.idcama;
        data.reporte_id = payload.idreporte;
        //reporteid
        state.solucionReporte.reporte_id = payload.idreporte;
        state.solucionReporte.cama_id = payload.idcama;
        utiles.state.configAxios.data = data;
        utiles.state.configAxios.headers.Authorization =
          "Bearer " + store.state.user.token;
        console.log(data);
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });

        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              swal({
                title: "Se reactivo el reporte!",
                text: "Se cambio el estado de la cama",
                icon: "success",
              });
            }
            commit("setReporteSeleccionado", {
              reporte: resp.data.reporte,
              cama: resp.data.cama,
            });
            dispatch("getListaReportesAxios", {
              sede: store.state.user.roles.sedes.split(','),
              estadoReporte: state.filterReportes.estado,
              title: state.filterReportes.title,
              idpabellon: state.filterReportes.pabellon
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },

    /**
     * Solucionar reporte
     */

    setSolucionarReporte({ commit, dispatch, state }, payload) {
      swal({
        title: "¿Desea solucionar el reporte?",
        icon: "warning",
        dangerMode: true,
        buttons: ["cancelar", "confirmar"],
      }).then((confirmation) => {
        if (!confirmation) {
          return;
        }

        let data = {};

        data.reporte_id = payload.idreporte;
        data.cama_id = payload.idcama;

        commit("utiles/resetConfigAxios");
        utiles.state.configAxios.url =
          utiles.state.apiUrl + "solucionar-reporte";
        utiles.state.configAxios.method = "POST";
        utiles.state.configAxios.data = data;
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              swal({
                title: "Se solucionó el reporte!",
                icon: "success",
              });

              commit("setReporteSeleccionado", {
                reporte: resp.data.reporte,
                cama: resp.data.cama,
              });
              dispatch("getListaReportesAxios", {
                sede: store.state.user.roles.sedes.split(','),
                estadoReporte: state.filterReportes.estado,
                title: state.filterReportes.title,
                idpabellon: state.filterReportes.pabellon
              });
            }
          })
          .catch((error) => {
            console.log(error);
            swal({
              title: "Ha ocurrido un error!",
              icon: "error",
            });
          });
      });
    },
    

    deleteReporte({commit, dispatch, state}, payload){
      swal({
        title: "¿Desea eliminar el reporte?",
        icon: "warning",
        dangerMode: true,
        buttons: ["cancelar", "confirmar"],
      }).then((confirmation) => {
        if (!confirmation) {
          return;
        }
        commit("utiles/resetConfigAxios");
        utiles.state.configAxios.url = utiles.state.apiUrl + "eliminar-reporte";
        utiles.state.configAxios.method = "DELETE";
        utiles.state.configAxios.data = {idreporte: payload.idreporte, cama_id: payload.cama_id};
        utiles.state.configAxios.headers.Authorization =
          "Bearer " + store.state.user.token;
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
        axios(utiles.state.configAxios)
        .then(resp => {
          if(resp.status == 200){
            swal({
              title: "Se elimino el reporte",
              icon: "success"
            })

            dispatch("getListaReportesAxios", {
              sede: store.state.user.roles.sedes.split(','),
              estadoReporte: state.filterReportes.estado,
              title: state.filterReportes.title,
              idpabellon: state.filterReportes.pabellon
            });
          }
        })
        .catch(error => {
          console.error(error)
        })
      })
    },

    /**
     * DESCARGAR REPORTE EN EXCEL
     */

    getDownloadReportesCamasEXCEL({commit, state}){
      commit("utiles/resetConfigAxios");

      utiles.state.configAxios.url = utiles.state.apiUrl + "getexcelreportecamas";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = {
         estadoReporte: state.filterReportes.estado,
         titulo: state.filterReportes.title,
         idpabellones: state.filterReportes.pabellon,
         sede: store.state.user.roles.sedes.split(',')
      };
      utiles.state.configAxios.responseType = "blob";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se descarga el reporte",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });

      axios(utiles.state.configAxios)
        .then((response) => {
          // Convierte la respuesta en un objeto Blob
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          // Genera una URL temporal del archivo Blob
          const url = URL.createObjectURL(blob);
          // Crea un enlace y lo simula como si fuera un clic del usuario
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte.xlsx";
          a.click();
          state.loadingEXCELReporte = false;
          swal({
            title: "Reporte generado correctamente",
            icon: "success",
          });
        })
        .catch((error) => {
          console.error(error);
          // Si la respuesta es un objeto Blob, intenta obtener un mensaje de error a partir de la respuesta
          if (error.response && error.response.data instanceof Blob) {
            error.response.data.text().then((mensaje) => {
              console.error("Error en la respuesta:", mensaje);
            });
          }
          state.loadingEXCELReporte = false;
          swal({
            title: "Ha ocurrido un error",
            text: "Intenta actualizar la página",
            icon: "error",
          });
        });
    }
  },
};
