import utiles from '@/modules/Shared/utiles/index.js'
import MotivoAtencion from './MotivoAtencion.js'
import PacientePsicologia from './PacientePsicologia.js'
import AtencionPsicologica from './AtencionPsicologica.js'
import DialogosAlert from './DialogosAlert.js'
import Evaluaciones from './Evaluaciones.js'
import RespuestasEvaluaciones from './RespuestasEvaluaciones.js'

export default{
    namespaced:true,
    modules:{
        MotivoAtencion,
        PacientePsicologia,
        utiles,
        AtencionPsicologica,
        DialogosAlert,
        Evaluaciones,
        RespuestasEvaluaciones
    },
    state:{

    },
    mutations:{

    },
    actions:{

    }
}