import BloquesSeleccionadosService from "../services/BloquesSeleccionadosService"


const bloquesSeleccionadosSV = new BloquesSeleccionadosService

export default{
    namespaced:true,
    state:{
        listaBloquesSeleccionados: [],
        filters:{
            dni: "",
            desayuno: "",
            almuerzo: "",
            cena: "",
            nombre_completo: "",
            carrera: "",
            admision: "",
            rango_inicio: "",
            rango_fin: ""
        },  
        loadingLista: false
    },

    mutations:{
        setLista(state, payload)
        {
            state.listaBloquesSeleccionados = payload
        }
    },


    actions:{
        async getList({state, commit})
        {
            state.loadingLista = true
            try
            {
                const data = await bloquesSeleccionadosSV.getList(state.filters)
                commit('setLista', data)
                console.log(data)
                state.loadingLista = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingLista = false
            }
        },


        async getReporte({state, commit})
        { 
            swal({
            title: "Cargando...",
            text: "Por favor, espere un momento.",
            icon: "info",
            button: false, // Ocultar el botón de confirmación
            closeOnClickOutside: false, // Evitar que el usuario cierre la alerta haciendo clic afuera
            closeOnEsc: false, // Evitar que el usuario cierre la alerta presionando la tecla Esc
            allowOutsideClick: false // Evitar que el usuario cierre la alerta haciendo clic afuera
          });
            try
            {
                const data = await bloquesSeleccionadosSV.getReporte(state.filters)
                swal({
                    title: "Reporte generado correctamente",
                    icon: "success",
                  });
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ha ocurrido un error, vuelve a intentar",
                    icon: "error",
                  });
            }
        }
    }
}