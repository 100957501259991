 <template>
        <v-dialog v-model="dialogAlert.open" width="500" persistent>
        <v-card class="light-blue accent-4 py-8">
            <v-card-text class="d-flex align-center justify-center ">
                <v-avatar class="white" size="300">
                    <v-img :src="require('../../assets/psicologo-loading.png')" height="200" contain></v-img>
                </v-avatar>
            </v-card-text>
            <v-card-text>
                <div class="text-h4 white--text text-center font-weight-bold">cargando...</div>
            </v-card-text>
        </v-card>
    </v-dialog>

 </template>


<script>

import { mapState } from 'vuex'

export default{

    computed:{
        ...mapState('psicologia/DialogosAlert', ['dialogAlert'])
    }
}
</script>