<template>
    <div class="d-flex align-center" style="height: 100vh; ">
        <v-card elevation="0" width="100%" height="100%" rounded="0" v-if="!loadingAxiosData">
            <v-card-title class="justify-center blue-grey darken-4 white--text py-2 font-weight-bold text-subtitle-2">
                DNI VALIDADOS
            </v-card-title>
            <div class="text-center py-2 text-subtitle-2">
                revisa la información antes de registrar las visitas
            </div>
            <v-card-text >
                    <v-data-table fixed-header :height="dataExcel.length > 13 ? '550' : ''" dense :headers="headerstable" :items-per-page="1000" hide-default-footer :items="dataExcel">
                        <template v-slot:item.validate="{item}">
                            <v-avatar rounded size="25" :class="[ item.validate ? 'teal accent-4 white--text' : 'red darken-4 white--text']">
                                <v-icon color="white" small>{{ item.validate? 'mdi-check-decagram' : 'mdi-lightbulb-alert' }}</v-icon>
                            </v-avatar>
                        </template>
                        <template v-slot:item.impedir_ingreso="{item}">
                            <v-avatar v-if="item.impedir_ingreso == 'SI'" rounded size="25" class="red accent-4 white--text">SI</v-avatar>
                        </template>
                    </v-data-table>
            </v-card-text>

   
            <v-card-actions class="mx-2" >
                <v-btn @click="setApiVisitaMasivo()" elevation="0" class="mr-2 green accent-4 white--text" block>REGISTRAR VISITAS</v-btn>
            </v-card-actions>
        </v-card>

        <v-card elevation="0" class="d-flex align-center" style="background-color: #F3F5F8;" height="100%" width="100%" rounded="0" v-else>
            <v-card-text class="black--text text-center">
                <v-img :src="require('@/modules/Visitas/assets/rocket.gif')" :lazy-src="require('@/modules/Visitas/assets/rocket.gif')" width="350" class="mx-auto"></v-img>
                <h2>REGISTRANDO VISITAS</h2>
                <span>El proceso terminará pronto... </span>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data(){
        return{
            headerstable: [
                {
                    text: "TIPO VISITA",
                    value: "tipoVisita",
                    sortable: false,
                    width: "60px"
                },
                {
                    text: "FECHA DE INGRESO",
                    value: "fecha",
                    sortable: false,
                    width: "80px"
                },
                {
                    text: "",
                    value: "validate",
                    sortable: false,
                    width: "30px"
                },
                {
                    text: "DNI",
                    value: "dni",
                    sortable: false,
                    width: "50px"
                },
                {
                    text: "APELLIDOS Y NOMBRES",
                    value: "nombrecompleto",
                    sortable: false,
                    width: "300px"
                },
                {
                    text: "MOTIVO",
                    value: "motivo",
                    sortable: false,
                    width: "300px"
                },
                {
                    text: "IMPEDIR INGRESO",
                    value: "impedir_ingreso",
                    sortable: false,
                    width: "50px"
                },
                {
                    text: "OBSERVACIÓN",
                    value: "observacion",
                    sortable: false,
                    width: "300px"
                }
            ]
        }
    },

    computed:{
        ...mapState('visitas/visitasMasivas', ['dataExcel', 'loadingAxiosData'])
    },

    methods:{
        ...mapActions('visitas/visitasMasivas', ['setApiVisitaMasivo'])
    }
}
</script>