<template>
  <div style="width: 100%">
    <v-alert
      v-if="camaSeleccionada.cama.state == 'inoperativa'"
      text
      outlined
      icon="mdi-alert-circle"
      type="error"
      dense
    >
      <b class="text-uppercase">Esta cama esta inoperativa</b>
      <small class="d-block"
        >Levanta las observaciones antes de activar la cama</small
      >
    </v-alert>
    <div
      v-if="
        camaSeleccionada.cama.state == 'reporte' ||
        camaSeleccionada.cama.state == 'inoperativa'
      "

    >
      <span>a continuación se muestran los reportes registrados</span>
    </div>
    <v-card
      v-for="(reporte, index) in camaSeleccionada.cama.reporte.filter(r => r.estadoReporte === 'no-solucionado')"
      :key="index"
      class="black--text blue lighten-5 my-2"
      elevation="0"
      
    >
    
      <v-list-item>
        <v-list-item-icon class="mr-3 flex-column">
          <v-avatar size="35" color="indigo white--text">
            <h4>AD</h4>
          </v-avatar>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold text-subtitle-2">{{
            reporte.titulo_reporte
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            @click="selectedReporte(reporte)"
            icon
            class="indigo white--text"
            x-small
            ><v-icon small>mdi-chevron-right</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <div class="text-center black--text">
      Si deseas solucionarlos debes ir a
      <v-btn text small color="indigo" :to="{ name: 'reportesCamas'}">REPORTES</v-btn>
    </div>

    <v-dialog v-model="dialogReporte" max-width="500" scrollable>
      <v-card elevation="0" class="black--text">
        <div class="indigo darken-4 white--text py-2 px-4">
            Reporte Registrado
        </div>
        <v-divider></v-divider>
        <v-card-text class="black--text mt-2" style="max-height: 300px;">
          <h4 class="px-6 py-3">{{ reporteSeleccionado.titulo_reporte }}</h4>
          <div v-html="reporteSeleccionado.detalle_reporte"></div>

          <div v-if="reporteSeleccionado.evidencia">
            <div>Evidencia</div>
            <v-img
              v-if="TypeFileIndent(reporteSeleccionado.evidencia) != 'PDF'"
              :src="reporteSeleccionado.evidencia"
              aspect-ratio="1"
              contain
            ></v-img>
            <iframe
              v-else
              width="100%"
              :src="reporteSeleccionado.evidencia"
              frameborder="0"
              ref="iframe"
            ></iframe>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          class="pb-2 justify-space-between deep-purple lighten-5"
        >
          <v-chip small class="mr-2 pl-2"
            ><v-avatar left color="indigo" size="20"
              ><v-icon small color="white">mdi-calendar</v-icon></v-avatar
            >{{ formatFecha(reporteSeleccionado.fecha_reporte) }}</v-chip
          >
          <chipPrioridadReporte :prioridad="reporteSeleccionado.prioridad" />
          <v-chip small class="pl-2 font-weight-bold"
            ><v-avatar left color="bgCTM " size="20"
              ><v-icon small color="white">mdi-account</v-icon></v-avatar
            >{{ formatUserName(usuarioRegistrante) }}</v-chip
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Filters from "@/modules/Shared/Filters";
import chipPrioridadReporte from "./chipPrioridadReporte.vue";
export default {
  components: {
    chipPrioridadReporte,
  },
  mixins: [Filters],
  data: () => ({
    dialogReporte: false,
    reporteSeleccionado: {},
    usuarioRegistrante: "",
  }),
  computed: {
    ...mapState("hospedaje", ["camaSeleccionada"]),
  },
  methods: {
    selectedReporte(reporte) {
      this.dialogReporte = true;
      this.reporteSeleccionado = Object.assign({}, reporte);
      this.usuarioRegistrante = this.reporteSeleccionado.usuario.nombres;
      if (this.TypeFileIndent(this.reporteSeleccionado.evidencia) == "PDF") {
        this.loadPdf(this.reporteSeleccionado.evidencia);
      }
    },
    TypeFileIndent(url) {
      if (url == null) {
        return;
      }
      var spiturl = url.split(".");
      return spiturl[spiturl.length - 1];
    },

    loadPdf(urlPdf) {
      axios
        .get(urlPdf, { responseType: "arraybuffer" })
        .then((response) => {
          const fileData = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(fileData);
          this.$refs.iframe.src = fileURL;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
