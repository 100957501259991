<template>
    <div class="pa-5">
        <div class="font-weight-bold text-subtitle-2">MATERIALES INGRESADOS</div>
        <div class="mb-5">Registrar los materiales que ingreso el estudiante.</div>

        <div class="pa-3 rounded-lg blue lighten-5" v-if="visitaRef">
            <div class="font-weight-bold text-subtitle-2 text-center">
                {{ visitaRef.nombrecompleto }}
            </div>
            <div class="text-subtitle-2 text-center">DNI - {{ visitaRef.dni }}</div>

            <div v-if="visitaRef.tipoVisita.text == 'ESTUDIANTES'" class="text-center text-caption">{{ visitaRef.carrera
            + ' ' +
            visitaRef.admision }}
            </div>
        </div>

        <span>Materiales registrados</span>
        <v-data-table dense :headers="headers" hide-default-footer fixed-header mobile-breakpoint="0"
            :items="visitaRef.materialesIngresados">
            <template v-slot:item.actions="{ item }">
                <div>

                </div>
            </template>
        </v-data-table>


        <div>Registrar nuevos materiales</div>
        <v-form v-model="formValidateMaterial" class="my-5" :disabled="loadingMateriales">
            <div class="d-flex align-center">
                <v-text-field type="number" style="width: 10%" value="0" dense hide-details class="grey lighten-4 mr-1"
                    flat outlined v-model="material.cantidad" :rules="[rules.required]"></v-text-field>
                <v-text-field style="width: 50%" placeholder="Descripción" dense hide-details
                    class="grey lighten-4 mr-1" flat outlined v-model="material.descripcion"
                    :rules="[rules.required]"></v-text-field>
                <v-text-field style="width: 20%" placeholder="Marca" dense hide-details="auto"
                    class="grey lighten-4 mr-1" flat outlined v-model="material.marca"></v-text-field>
                <v-text-field style="width: 20%" placeholder="Serie" dense hide-details="auto"
                    class="grey lighten-4 mr-1" flat outlined v-model="material.codigo"></v-text-field>
                <v-btn :disabled="!formValidateMaterial" @click="setMaterial()" height="41" width="40px"
                    class="bgCTM px-0" style="min-width: 40px !important"><v-icon
                        color="white">mdi-plus</v-icon></v-btn>
            </div>
        </v-form>

        <div v-if="materialesIngresados.length > 0" class="mb-5">

            <v-data-table dense :headers="headers" hide-default-footer fixed-header height="200" mobile-breakpoint="0"
                :items="materialesIngresados">
                <template v-slot:item.cantidad="{ item }"><span>{{ item.cantidad }}</span></template>
                <template v-slot:item.descripcion="{ item }"><span>{{ item.descripcion }}</span></template>
                <template v-slot:item.marca="{ item }"><span>{{ item.marca }}</span></template>
                <template v-slot:item.codigo="{ item }"><span>{{ item.codigo }}</span></template>
                <template v-slot:item.actions="{ item }">
                    <v-btn @click="delMaterial(item)" x-small icon class="red">
                        <v-icon x-small color="white">mdi-close-thick</v-icon></v-btn>
                </template>
            </v-data-table>
        </div>

        <v-btn :loading="loadingMateriales" block class="bgCTM white--text" elevation="0"
            @click="saveMaterialesVisita()">
            GUARDAR DATOS </v-btn>

    </div>


</template>

<script>

import Filters from '@/modules/Shared/Filters';
import { mapState, mapActions, mapMutations } from 'vuex';
import visitasMasivas from '../../store/visitasMasivas';


export default {
    mixins: [Filters],
    data() {
        return {
            formValidateMaterial: true,
            headers: [
                {
                    text: "Cant.",
                    value: "cantidad",
                    sortable: false,
                    width: 30,
                },
                {
                    text: "Descripción.",
                    value: "descripcion",
                    sortable: false,
                    width: 150,
                },
                {
                    text: "Marca.",
                    value: "marca",
                    sortable: false,
                    width: 90,
                },
                {
                    text: "Serie.",
                    value: "codigo",
                    sortable: false,
                    width: 90,
                },
                {
                    text: "",
                    value: "actions",
                    sortable: false,
                    width: 40,
                },
            ],
        }
    },
    computed: {
        ...mapState('visitas/materialesIngresados', ['material', 'materialesIngresados', 'visitaRef', 'loadingMateriales'])
    },

    methods: {
        ...mapMutations('visitas/materialesIngresados', ['setMaterial', 'delMaterial']),
        ...mapActions('visitas/materialesIngresados', ['saveMaterialesVisita'])
    }

}
</script>