import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class PacienteService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );

    }

    async getPaciente(params)
    {
       const response = await this.axios.get('/pacientepsicologia', {params: params})
       return response.data;
    }  

    async findPaciente(params)
    {
       const response = await this.axios.get('/findpacientepsicologia', {params: params})
       return response.data;
    }


    async addPaciente(data)
    {
        const response = await this.axios.post('/pacientepsicologia', data)
        return response.data
    }
    async addPacienteMasive(data)
    {
        const response = await this.axios.post('/pacientepsicologia-masive', data)
        return response.data
    }
    async updatePaciente(data)
    {
        const response = await this.axios.put('/pacientepsicologia', data)
        return response.data
    }

    async cambioPacientePsicopedagogica(data)
    {
        const response = await this.axios.post('/cambio-condicion-psicopedagogica', data)
        return response.data
    }
}