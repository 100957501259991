import ApiEstudianteService from "@/modules/Shared/services/ApiEstudianteService";
import RegistroIndisciplinaService from "../services/RegistroIndisciplinaService";
import swal from "sweetalert";
import store from "@/store";
import LoadingComponent from "../components/alerts/loadingComponent.vue";
import SuccessComponent from "../components/alerts/successComponent.vue";
import ErrorComponent from "../components/alerts/errorComponent.vue";

import { ref } from "vue";
import router from "@/router";

const apiEstudiante = new ApiEstudianteService();
const RegistroIndisciplinaSV = new RegistroIndisciplinaService();
export default {
  namespaced: true,
  state: {
    casoIndisciplina: {
      fecha: "",
      fecha_emisionRD: "",
      dias_transcurridos: "",
      tipo_falta: "",
      sede: "",
      descripcion_corta: "",
      //detalles: "",
      involucrados: [
        {
          dni: "",
          nombre_completo: "",
          carrera: "",
          admision: "",
        },
      ],
      sancion: "",
      categoria: "",
      fecha_inicio: "",
      fecha_fin: "",
      acta: "",
      rd: "",
      observacion: "",
    },
    casoIndisciplinaDefault: {
      fecha: "",
      fecha_emisionRD: "",
      dias_transcurridos: "",
      tipo_falta: "",
      sede: "",
      descripcion_corta: "",
      //sdetalles: "",
      involucrados: [
        {
          dni: "",
          nombre_completo: "",
          carrera: "",
          admision: "",
        },
      ],
      sancion: "",
      categoria: "",
      fecha_inicio: "",
      fecha_fin: "",
      acta: "",
      rd: "",
      observacion: "",
    },
    listaCasosIndisciplina: [],
    selectedRegistroIndisciplina: null,
    filterCasosIndisciplina: {
      dni: "",
      sedes: [],
      tipoDeFalta: "",
      sancion: "",
      categoria: "",
      from: "",
      to: ""
    },
    filterCasosIndisciplinaDefault: {
      dni: "",
      sedes: [],
      tipoDeFalta: "",
      sancion: "",
      categoria: "",
      from: "",
      to: ""
    },
    involucrado: {
      dni: "",
      nombre_completo: "",
      carrera: "",
      admision: "",
    },

    archivoAdjunto: {
      tipo_documento: "",
      nombre_documento: "",
      file: "",
    },

    loadingSaveRegistro: false,
    loadingApiEstudiante: false,
    loadingListaRegistros: false,
    loadingShowRegistro: false,

    tipoDeFalta:[
      'Leve',
      'Moderado',
      'Grave',
      'Muy grave'
    ]
  },
  mutations: {
    addInvolucrado(state) {
      state.casoIndisciplina.involucrados.push(
        Object.assign({}, state.involucrado)
      );
    },
    removeEstudiante(state, index) {
      state.casoIndisciplina.involucrados.splice(index, 1);
    },
    setDataStudent(state, { data, index }) {
      state.casoIndisciplina.involucrados[index].nombre_completo =
        data.apellido_paterno + " " + data.apellido_materno + " " + data.nombres;
      state.casoIndisciplina.involucrados[index].carrera =
        data.carrera;

      state.casoIndisciplina.involucrados[index].admision =
        data.ano_admision + "-" + data.periodo_acedemico
    },

    setListaCasosIndisciplina(state, payload) {
      state.listaCasosIndisciplina = payload;
    },

    setRegistroCasos(state, payload)
    {
      state.casoIndisciplina = Object.assign({}, payload);
    },

    resetRegistroCasos(state) {
      state.casoIndisciplina = Object.assign({}, state.casoIndisciplinaDefault);
    },

    selectedRegistroIndisciplina(state, payload)
    {
      state.selectedRegistroIndisciplina = Object.assign({}, payload)
      console.log( state.selectedRegistroIndisciplina)
    },

    resetFilters(state)
    {
      state.filterCasosIndisciplina = Object.assign({}, state.filterCasosIndisciplinaDefault);
    }
 
  },
  actions: {
    async getApiData({ state, commit, dispatch }, index) {
      state.loadingApiEstudiante = true;
      try {
        const data = await apiEstudiante.get( 
          state.casoIndisciplina.involucrados[index].dni
        );
        commit("setDataStudent", { data, index });
        
        state.loadingApiEstudiante = false;
      } catch (error) {
        console.log(error);
        state.loadingApiEstudiante = false;
      }
    },

    async showRegistro({ state, commit, dispatch }, idregistro) {
      state.loadingShowRegistro = true;
      swal({
        title: "Cargando...",
        text: "Por favor, espere un momento.",
        icon: "info",
        button: false, 
        closeOnClickOutside: false, 
        closeOnEsc: false, 
        allowOutsideClick: false 
      });
      try {
        const data = await RegistroIndisciplinaSV.show({idregistro});
        commit("setRegistroCasos", data);
        state.loadingShowRegistro = false;
        swal.close()
      } catch (error) {
        console.log(error);
        state.loadingShowRegistro = false;
        swal({
          title: "No se encontró el caso de indisciplina",
          text: "Por favor, vuelva a intentar.",
          icon: "error",
          buttons: {
            confirm: {
              text: "Aceptar",
              value: true,
              visible: true,
              className: "btn-primary",
              closeModal: true,
            },
          },
        }).then((value) => {
          if (value) {
            router.push({name: 'listaDeCasosRegisrados'})
          }
        });
      }
    },
    async getListRegistros({ state, commit, dispatch }) {
      state.loadingListaRegistros = true;
      try {
        const data = await RegistroIndisciplinaSV.get(state.filterCasosIndisciplina);
        commit("setListaCasosIndisciplina", data);
        console.log(data)
        state.loadingListaRegistros = false;
      } catch (error) {
        console.log(error);
        state.loadingListaRegistros = false;
      }
    },

    async add({ state, commit, dispatch }) {
      state.loadingSaveRegistro = true;

      const componentLoading = ref(LoadingComponent);
      store.commit("disciplina/DialogosAlert/openDialogAlert", {
        open: true,
        component: componentLoading,
      });

      try {
        state.casoIndisciplina.user_id = store.state.user.iduser;
        const data = await RegistroIndisciplinaSV.add(state.casoIndisciplina);
        dispatch("getListRegistros");
        commit("resetRegistroCasos");
        console.log(data);
        state.loadingSaveRegistro = false;

        const componentSuccess = ref(SuccessComponent);
        store.commit("disciplina/DialogosAlert/openDialogAlert", {
          open: true,
          component: componentSuccess,
        });
      } catch (error) {
        console.log(error);
        state.loadingSaveRegistro = false;

        const componentError = ref(ErrorComponent);
        store.commit("disciplina/DialogosAlert/openDialogAlert", {
          open: true,
          component: componentError,
        });
      }
    },

    async update({ state, commit, dispatch }) {
      state.loadingSaveRegistro = true;

      const componentLoading = ref(LoadingComponent);
      store.commit("disciplina/DialogosAlert/openDialogAlert", {
        open: true,
        component: componentLoading,
      });

      try {
        state.casoIndisciplina.user_id = store.state.user.iduser;
        const data = await RegistroIndisciplinaSV.update(state.casoIndisciplina);
        dispatch("getListRegistros");
        commit("resetRegistroCasos");
        console.log(data);
        state.loadingSaveRegistro = false;

        const componentSuccess = ref(SuccessComponent);
        store.commit("disciplina/DialogosAlert/openDialogAlert", {
          open: true,
          component: componentSuccess,
        });
      } catch (error) {
        console.log(error);
        state.loadingSaveRegistro = false;

        const componentError = ref(ErrorComponent);
        store.commit("disciplina/DialogosAlert/openDialogAlert", {
          open: true,
          component: componentError,
        });
      }
    },


    async deleteRegistro({state, dispatch}, idregistro){

      const confirm = await swal({
        title: "Atención!",
        text: "¿Desea eliminar el registro?",
        icon: "warning",
        buttons: ['CANCELAR', 'ELIMINAR'],
        dangerMode: true
      })
      if(!confirm){return }
      try{
        swal({
          title: "Cargando...",
          text: "Por favor, espere un momento.",
          icon: "info",
          button: false,  
          closeOnClickOutside: false, 
          closeOnEsc: false, 
          allowOutsideClick: false 
        });
        const data = await RegistroIndisciplinaSV.delete(idregistro)
        dispatch('getListRegistros')
        swal({
          title: "Se elimino correctamente!",
          icon: "success"
      })
      }
      catch(error){
        console.log(error)
        swal({
          title: "Ha ocurrido un error",
          icon: "error"
      })
      }
    },


    async generarPDF({state, commit}, idregistro)
    {

      swal({
        title: "Cargando...",
        text: "Por favor, espere un momento.",
        icon: "info",
        button: false,  
        closeOnClickOutside: false, 
        closeOnEsc: false, 
        allowOutsideClick: false 
      });
      try
      {
        const data = await RegistroIndisciplinaSV.generarPDF({idregistro})
        swal({
          title: "Se generó el pdf",
          icon: "success"
      })
      }
      catch(error)
      {
        console.log(error)
        swal({
          title: "Ha ocurrido un error",
          icon: "error"
      })
      }
    },

    async generarExcel({state, commit})
    {

      swal({
        title: "Cargando...",
        text: "Por favor, espere un momento.",
        icon: "info",
        button: false,  
        closeOnClickOutside: false, 
        closeOnEsc: false, 
        allowOutsideClick: false 
      });
      try
      {
        const data = await RegistroIndisciplinaSV.getReporteExcel(state.filterCasosIndisciplina)
        swal({
          title: "Se generó el excel",
          icon: "success"
      })
      }
      catch(error)
      {
        console.log(error)
        swal({
          title: "Ha ocurrido un error",
          icon: "error"
      })
      }
    }


  },
};
