import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class CapacidadComedorService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }


    async get(params)
    {
        const response = await this.axios.get('capacidad-comedor', {params: params})
        return response.data
    }

    async add(data)
    {
        const response = await this.axios.post('capacidad-comedor', data)
        return response.data
    }


    async update(data)
    {
        const response = await this.axios.put('capacidad-comedor', data)
        return response.data
    }

}