import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class PacienteService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }

    async getPaciente(params, option)
    {
        console.log("PAGINACION", option)
       const response = await this.axios.get('/paciente?page=' + option.page , {params: params})
       console.log(response)
       return response.data;  
    } 

    async findPaciente(params)
    {
       const response = await this.axios.get('/findpaciente', {params: params})
       return response.data;
    }


    async addPaciente(data)
    {
        const response = await this.axios.post('/paciente', data)
        return response.data
    }

    async updatePaciente(data)
    {
        const response = await this.axios.put('/paciente', data)
        return response.data
    }


    //GENERAR REPORTE PDF
    async generatePDFReporteFichaPaciente(data) {

        const response = await this.axios.post('/generatepdfpaciente', {idpaciente: data.idpaciente}, {
            responseType: 'arraybuffer',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download =  'Ficha-Paciente-' + data.nombre_completo +'.pdf';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        return response.data;

    }

}