<template>
    <v-row class="py-2">
        <v-col cols="12" class="py-2 px-5 d-lg-flex align-center justify-space-between mt-2">
            <div>
                <div class="text-lg-h6 font-weight-bold">CONTROL DE VISITAS A SEDE {{ sede }}</div>
            </div>
            <div>
                <v-btn v-if="$can('create', 'configuravisitas')" :to="{name:'visitas-configuracion'}" elevation="0" small class="bgCTM white--text mr-2"><v-icon left>mdi-cog</v-icon>CONFIGURACIÓN</v-btn>
                <v-btn @click="filters = !filters" elevation="0" small class="bgCTM white--text "><v-icon left>{{filters?'mdi-eye-off':'mdi-eye'}}</v-icon>{{ filters? 'OCULTAR FILTROS' : 'VER FILTROS' }}</v-btn>
            </div>
        </v-col>
        <v-col cols="12" class="py-0">
            <visitas-del-dia :hidenFilters="filters"/>
        </v-col>

    </v-row>
</template>


<script>
import { mapActions, mapMutations } from 'vuex';
import VisitasDelDia from '../components/VisitasDelDía.vue'
import VisitasProgramada from '../components/VisitasProgramada.vue';
export default {
    data(){
        return{
            filters: true
        }
    },
    components:{
        VisitasDelDia,
        VisitasProgramada
    },
    methods:{
        ...mapActions("visitas/configuracion", [
      "getApiMotivos",
      "getApiAreasColaborador",
      "getApiAreasVisita",
      "getApiEmpresasTerceros",
    ]),
    ...mapActions("visitas/registrarVisita", [

      "getApiTipoVisitas",
    ]),
    ...mapMutations("visitas/registrarVisita", [
      "initialize",
    ]),
    },

    created(){
        if(!this.$can('read', 'visitas') && !this.$can('read', 'estudianteasistencia')){
            this.$router.push({name: 'dashboard'})
        }

        if(this.$vuetify.breakpoint.xs){
            this.filters = false;
        }
    },

    computed:{
        sede(){
            let sedes =  this.$store.state.user.roles.sedes.split(',');

            if(sedes.length > 1){
                return "ABQ - IRQ - FCHB - CAJAMARCA"
            }

            return sedes[0];
        }
    },

    mounted() {
    this.getApiMotivos();
    this.getApiAreasColaborador();
    this.getApiAreasVisita();
    this.getApiEmpresasTerceros();
    this.getApiTipoVisitas();
    this.initialize();
    //this.showTextField = true;
/*
    this.$nextTick(() => {
        this.$refs.FieldDNI.$el.querySelector('input').focus();
      });*/

  },
}
</script>r