<template>
  <div style="height: 100vh" class="d-flex align-center">
    <v-card :loading="loadingSalida" elevation="0" height="100%" width="100%" rounded="0">
      <v-card-title class="py-2 justify-center py-1"
        :class="[visitaSalida.permiso_salida && visitaSalida.permiso_salida.estado != 'anulado' ? 'cyan darken-4 white--text' : 'yellow accent-4']">
        <div class="text-center">
          <small v-if="visitaSalida.permiso_salida && visitaSalida.permiso_salida.estado != 'anulado'" class="d-block">
            El estudiante cuenta con permiso de salida</small>
          <div v-else class="text-subtitle-1 font-weight-bold">esta saliendo de la sede</div>
        </div>
      </v-card-title>
      <v-card-text class="py-2">
        <v-form v-model="validateForm">
          <div class="text-center mb-2">
            <h2 class="indigo--text text--accent-4 py-2">
              {{ visitaSalida.nombrecompleto || visitaSalida.entidad }}
            </h2>
            <h4>{{ visitaSalida.dni || visitaSalida.ruc }}</h4>
            <div class="d-block pa-2" v-if="visitaSalida.tipoVisita.value == 'estudiantes'">
              {{ visitaSalida.carrera || "" }} -
              {{ visitaSalida.admision || "" }}
            </div>
            <v-chip small class="mr-2 font-weight-bold"><span>{{ visitaSalida.dni || visitaSalida.ruc }}</span></v-chip>
            <v-chip small><v-avatar left size="20" class="bgCTM">
                <v-icon color="white" size="18">{{
                  visitaSalida.tipoVisita.icon
                  }}</v-icon></v-avatar><span class="font-weight-bold">{{
                    visitaSalida.tipoVisita.text
                }}</span></v-chip>
          </div>

          <div v-if="visitaSalida.tipoVisita.value == 'estudiantes'" class="mb-2">
            <span class="text-left black--text font-weight-bold">
              Tipo de salida:</span>
            <v-select v-model="visitaSalida.tipoSalida" dense hide-details="auto" class="grey lighten-4" flat outlined
              :items="tipoSalida" :rules="[rules.required]" label="Selecciona un tipo"></v-select>

          </div>

          <div v-if="visitaSalida.tipoVisita.value == 'estudiantes'">
            <v-alert border="top" colored-border type="info" elevation="0" dense
              v-if="visitaSalida.tipoSalida === 'salida_principal'" class="mb-0">
              <div class="text-caption font-weight-bold">SALIDA PRINCIPAL A SEDE</div>
              <div class="text-caption">
                Esta opción únicamente si se trata de la última salida de la sede con destino a su casa
              </div>
            </v-alert>
            <v-alert border="top" colored-border type="info" elevation="0" dense
              v-if="visitaSalida.tipoSalida === 'alimentacion'" class="mb-0">
              <div class="text-caption font-weight-bold">SALIDA POR ALIMENTACIÓN</div>
              <div class="text-caption">
                Esta opción exclusivamente para identificar salidas destinadas a almuerzos o cenas.
              </div>
            </v-alert>
            <v-alert border="top" colored-border type="info" elevation="0" dense
              v-if="visitaSalida.tipoSalida === 'asunto_personal'" class="mb-0">
              <div class="text-caption font-weight-bold">SALIDA POR ASUNTO PERSONAL</div>
              <div class="text-caption">
                Esta opción solo para identificar salidas distintas, como permisos o salidas por motivos médicos.
              </div>
            </v-alert>
            <v-alert border="top" colored-border type="info" elevation="0" dense
              v-if="visitaSalida.tipoSalida === 'permiso_de_salida'" class="mb-0">
              <div class="text-caption font-weight-bold">SALIDA POR PERMISO OTORGADO POR PSICÓLOGA</div>
              <div class="text-caption">
                Esta opción solo para identificar que el estudiante sale de sede por permiso otorgado.
              </div>
            </v-alert>
          </div>

          <div v-if="visitaSalida.personal && visitaSalida.personal.length > 0">
            <span class="text-left black--text font-weight-bold">PERSONAL QUE INGRESÓ</span>
            <v-data-table dense hide-default-footer fixed-header :height="visitaSalida.personal.length > 3 ? '150' : ''"
              :headers="headerPersonal" :items="visitaSalida.personal">
              <template v-slot:item.index="{ index }">{{ index + 1 }}</template>
            </v-data-table>
          </div>


          <div v-if="visitaSalida.marca_vehiculo" class="mb-2">
            <span class="text-left black--text font-weight-bold">VEHÍCULO INGRESADO:</span>
            <v-chip class="rounded d-block font-weight-bold"><span>{{ visitaSalida.marca_vehiculo + " " +
              visitaSalida.placa_vehiculo }}</span></v-chip>
          </div>

          <div v-if="
            visitaSalida.materialesIngresados &&
            visitaSalida.materialesIngresados.length > 0
          ">
            <span class="text-left black--text font-weight-bold">MATERIAL QUE INGRESÓ</span>
            <v-data-table dense hide-default-footer fixed-header
              :height="visitaSalida.materialesIngresados.length > 3 ? '150' : ''" :headers="headerMaterial"
              :items="visitaSalida.materialesIngresados">
            </v-data-table>
          </div>

          <div class="mb-2">
            <span class="font-weight-bold black--text">Observación o comentario de salida:</span>
            <v-textarea v-model="visitaSalida.comentario_salida" outlined dense hide-details height="50"></v-textarea>
          </div>

          <v-row>
            <v-col cols="12" class="py-2 black--text font-weight-bold">
              <small>Cargar Evidencia (opcional)</small>
              <v-file-input @change="addEvidenciaVisita" truncate-length="15" outlined class="grey lighten-4"
                hide-details="auto" dense accept="image/png, image/jpeg, image/jpg"
                name="evidencia_visita[]"></v-file-input>
            </v-col>
            <v-col cols="12" class="py-2 black--text font-weight-bold" v-if="visitaSalida.evidencia_visita.length > 0">
              <div class="d-flex justify-space-between"><small class="font-weight-bold">Imagenes cargadas</small>
                <v-btn x-small elevation="0" dark @click="quitarImagenes()"><v-icon left
                    x-small>mdi-delete</v-icon>Quitas
                  fotos</v-btn>
              </div>
              <div>
                <v-chip small class="ma-1" style="max-width: 150px" v-for="(img, i) in visitaSalida.evidencia_visita"
                  :key="i">
                  <v-icon left small>mdi-image</v-icon>
                  <span class="text-truncate">{{ img.name }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>


      <v-card-actions>
        <v-btn :loading="loadingSalida" @click="setApiMarcarSalida()" elevation="0" color="blue"
          class=" font-weight-bold" :disabled="!validateForm"
          :class="[visitaSalida.permiso_salida && visitaSalida.permiso_salida.estado != 'anulado' ? 'cyan darken-4 white--text' : 'yellow accent-4']"
          block>
          <span v-if="visitaSalida.permiso_salida && visitaSalida.permiso_salida.estado != 'anulado'">MARCAR SALIDA CON
            PERMISO</span>
          <span v-else>MARCAR SALIDA </span>
        </v-btn>
      </v-card-actions>
      <v-card-text class="black--text"
        v-if="visitaSalida.permiso_salida && visitaSalida.permiso_salida.estado != 'anulado'">
        <h3 class="mb-2">Detalle del permiso de salida:</h3>
        <div class=" d-flex justify-space-between flex-wrap">
          <div style="width: 100%;" class="mb-3" v-if="visitaSalida.permiso_salida.es_menor == 'SI'">
            <v-alert dense type="error"> El estudiante es menor de edad </v-alert>
          </div>
          <div style="width: 49%;" class="mb-3">
            <span>Fecha y Hora que debe salir:</span>
            <div class="font-weight-bold text-subtitle-2 rounded-sm pa-2 grey lighten-3">{{
              isNull(visitaSalida.permiso_salida.fecha_salida) + ' - ' + isNull(visitaSalida.permiso_salida.hora_salida)
              }}
            </div>
          </div>

          <div style="width: 49%;" class="mb-3">
            <span>Fecha y Hora que retorna:</span>
            <div class="font-weight-bold text-subtitle-2 rounded-sm pa-2 grey lighten-3">{{
              isNull(visitaSalida.permiso_salida.fecha_ingreso) + ' - ' +
              isNull(visitaSalida.permiso_salida.hora_ingreso) }}
            </div>
          </div>

          <div style="width: 100%;" class="mb-3">
            <span>Motivo de salida:</span>
            <div class="font-weight-bold text-subtitle-2 rounded-sm pa-2 grey lighten-3">{{
              visitaSalida.permiso_salida.motivo
              }}</div>
          </div>

          <div style="width: 100%;" class="mb-3">
            <span>Quien autoriza:</span>
            <div class="rounded-sm pa-2 grey lighten-3" style="line-height: 0.8rem;">
              <span class="font-weight-bold text-subtitle-2 d-block">{{ visitaSalida.permiso_salida.usuario.nombres + "
              " +
              visitaSalida.permiso_salida.usuario.apellidos }}</span>
              <small>{{ visitaSalida.permiso_salida.usuario.cargo }}</small>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins: [
    Filters
  ],
  data() {
    return {
      dialogSalida: false,
      validateForm: true,
      headerPersonal: [
        {
          text: "N°",
          value: "index",
          sortable: false,
          width: "30px",
        },
        {
          text: "Apellidos y Nombres",
          value: "nombrecompleto",
          sortable: false,
          width: "200px",
        },
      ],

      headerMaterial: [
        {
          text: "Cantidad",
          value: "cantidad",
          sortable: false,
          width: "30px",
        },
        {
          text: "Descripcion",
          value: "descripcion",
          sortable: false,
          width: "200px",
        },
        {
          text: "Marca",
          value: "marca",
          sortable: false,
          width: "80px",
        },
        {
          text: "Serie",
          value: "codigo",
          sortable: false,
          width: "80px",
        },
      ],
    };
  },

  computed: {
    ...mapState("visitas/registrarSalida", [
      "loadingSalida",
      "salida",
      "visitaSalida",
      "tipoSalida"
    ]),
  },

  methods: {
    ...mapActions('visitas/registrarSalida', ['setApiMarcarSalida']),
    ...mapMutations('visitas/registrarSalida', ['addEvidenciaVisita', 'quitarImagenes'])
  }
};
</script>
