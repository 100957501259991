<template>
  <v-dialog width="600" v-model="dialog" persistent>
    <v-card elevation="0" class="black--text font-weight-bold">
     <v-form v-model="validateForm">
      <v-card-title> Registrar nuevo Usuario </v-card-title>
      <v-card-subtitle class="pt-1 pb-3">
        Todos los datos son obligatorios
      </v-card-subtitle>
      <v-card-text class="black--text font-weight-bold">
        <v-row>
          <v-col cols="12" md="6">
            <label for="">Nombres</label>
            <v-text-field
              hide-details="auto"
              outlined
              dense
              placeholder="Nombre completo"
              :rules="[rules.required]"
              v-model="user.nombres"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label for="">Apellidos</label>
            <v-text-field
              hide-details="auto"
              outlined
              dense
              placeholder="Apellidos"
              :rules="[rules.required]"
              v-model="user.apellidos"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <label for="">Cargo</label>
            <v-text-field
              hide-details="auto"
              outlined
              dense
              placeholder="Jefe / Administrador / Asistente / Auxiliar"
              :rules="[rules.required]"
              v-model="user.cargo"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label for="">DNI</label>
            <v-text-field
              hide-details="auto"
              outlined
              dense
              placeholder="999999999"
              :rules="[rules.required]"
              v-model="user.dni"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <label for="">Email</label>
            <v-text-field
              type="email"
              hide-details="auto"
              outlined
              dense
              placeholder="email.cetemin.edu.pe"
              :rules="[rules.required, rules.email ]"
              v-model="user.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" v-if="user.iduser">
            <label for="">Cambiar contraseña</label>
            <v-text-field
              type="password"
              hide-details="auto"
              outlined
              dense
              placeholder="*********"
              v-model="user.password_confirmation"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" v-else>
            <label for="">Contraseña</label>
            <v-text-field
              type="password"
              hide-details="auto"
              outlined
              dense
              placeholder="*********"
              :rules="[rules.required]"
              v-model="user.password_confirmation"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title> Roles y Permisos </v-card-title>

      <v-card-text class="black--text font-weight-bold">
        <v-row>
          <v-col cols="12" md="6">
            <label for="">Selecciona un Rol</label>
            <v-select
              dense
              outlined
              hide-details="auto"
              placeholder="Roles disponibles"
              :items="listaDeRoles"
              item-value="idrole"
              item-text="name"
              :rules="[rules.required]"
              v-model="user.role_id"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-btn :disabled="!validateForm" @click="setRegisterUserAxios()" :loading="loading" block elevation="0" class="bgCTM white--text">GUARDAR USUARIO</v-btn>
            <v-btn block elevation="0" @click="setDialogRegister()" class="mt-2">CANCELAR</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins:[
    Filters
  ],
  data(){
    return{
        validateForm: true
    }
  },
  computed: {
    dialog: {
      get() {
        return this.dialogRegister;
      },

      set(val) {
        this.setDialogRegister();
      },
    },

    ...mapState("usuarios", ["dialogRegister", "user", "loading"]),
    ...mapState("usuarios/roles", ["listaDeRoles"])
  },

  methods: {
    ...mapMutations("usuarios", ["setDialogRegister"]),
    ...mapActions("usuarios", ['setRegisterUserAxios']),
    ...mapActions("usuarios/roles", ['getListRolesAxios'])
  },

  mounted(){
    this.getListRolesAxios()
  }
};
</script>
