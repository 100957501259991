<template>
    <v-row class="mt-2 pa-0" dense>
        <v-col cols="12" md="10" lg="8" class="mx-auto pa-1 d-flex align-center justify-center" >
            <div style="width: 100%;">
                <v-card elevation="0">
                    <v-card-text class="black--text py-12 white--text"
                        :style="`background-color:${examen.color_thema};`">
                        <div class="font-weight-bold text-lg-h4 text-subtitle-1 text-center"> {{ examen.titulo_examen }}
                        </div>
                    </v-card-text>

                    <v-card-text class="black--text text-center">
                        <div class="font-weight-bold text-subtitle-1">{{ estudianteEvaluado.nombre_completo }}</div>
                        <div class="text-subtitle-2">{{ estudianteEvaluado.carrera }} - {{ estudianteEvaluado.admision
                            }}
                        </div>
                    </v-card-text>
                    <v-card-text class="black--text" v-html="examen.descripcion"></v-card-text>
                </v-card>
                <v-stepper v-model="preguntaNumero" elevation="0" class="mt-5" v-if="strepperVisible">
                    <v-stepper-header class="justify-center pa-2" style="height: auto !important;">
                        <div class="d-flex align-center justify-center flex-wrap">
                            <v-avatar @click="navigation(index)" size="30" class="white--text ma-1 rounded-sm"
                                v-for="(item, index) in examen.items"
                                :style="preguntaNumero == index ? `background-color:${examen.color_thema};` : 'background-color: #CFD8DC'"
                                style="cursor: pointer;">
                                {{ index + 1 }}
                            </v-avatar>
                        </div>

                    </v-stepper-header>
                    <v-stepper-items class="my-0">


                        <v-stepper-content :step="index" v-for="(item, index) in examen.items" class="pa-0"
                            :key="`${index}-content`">
                            <v-card class="pa-2 pa-lg-8" elevation="0"
                                :style="`border-left: 8px solid ${examen.color_thema};`">
                                <v-form v-model="formvalidate[index]">
                                    <v-card-text class="black--text pa-0">
                                        <div class="pa-6" v-if="item.tipo_item == 'question'">

                                            <div class="font-weight-medium text-subtitle-1 text-lg-h6">
                                                {{ item.value }}
                                            </div>

                                            <div class="px-6 py-3">
                                                <v-text-field v-model="respuestaEvaluacion[index].value"
                                                    :rules="item.requerido ? [rules.required] : []" outlined
                                                    hide-details="auto" placeholder="Responde aqui"
                                                    v-if="item.tipo_pregunta == 'v-text-field'"></v-text-field>
                                                <v-textarea v-model="respuestaEvaluacion[index].value"
                                                    :rules="item.requerido ? [rules.required] : []" outlined
                                                    hide-details="auto" placeholder="Responde aqui"
                                                    v-if="item.tipo_pregunta == 'v-textarea'"></v-textarea>
                                                <v-checkbox v-model="respuestaEvaluacion[index].opcion_id"
                                                    v-if="item.tipo_pregunta == 'v-checkbox'" class="ma-1"
                                                    v-for="(opcion, i) in item.opciones" hide-details="auto"
                                                    :value="opcion.idopcion"
                                                    :rules="item.requerido ? [rules.checkboxRequired] : []"
                                                    :color="examen.color_thema" :label="opcion.value"></v-checkbox>
                                                <v-radio-group v-model="respuestaEvaluacion[index].opcion_id"
                                                    hide-details="auto"
                                                    :rules="item.requerido ? [rules.radioRequired] : []"
                                                    v-if="item.tipo_pregunta == 'v-radio'">
                                                    <v-radio :color="examen.color_thema" class="ma-1"
                                                        v-for="opcion in item.opciones" :label="opcion.value"
                                                        :value="opcion.idopcion" :key="opcion.idopcion"></v-radio>
                                                </v-radio-group>
                                                <v-select v-model="respuestaEvaluacion[index].opcion_id"
                                                    :rules="item.requerido ? [rules.required] : []" outlined
                                                    hide-details="auto" :items="item.opciones" item-text="value"
                                                    item-value="idopcion" placeholder="Responde aqui"
                                                    v-if="item.tipo_pregunta == 'v-select'"></v-select>
                                            </div>

                                        </div>

                                        <div class="pa-6" v-if="item.tipo_item == 'imagen'">
                                            <div v-html="item.value"></div>
                                        </div>

                                        <div class="pa-6" v-if="item.tipo_item == 'video'">
                                            <div v-html="item.value"></div>
                                            <div class="text-subtitle-2"> {{ item.descripcion }}</div>

                                        </div>

                                        <div class="font-weight-bold text-h6" v-if="item.tipo_item == 'seccion'">
                                            <div v-html="item.value" class="pa-6 white--text"
                                                :style="`background-color:${examen.color_thema};`"></div>
                                            <div class="pa-6" v-if="item.descripcion">
                                                {{ item.descripcion }}
                                            </div>
                                        </div>

                                        <div class="pa-6" v-if="item.tipo_item == 'texto'">
                                            <div v-html="item.value"></div>
                                        </div>

                                    </v-card-text>
                                    <v-card-actions class="justify-space-between">
                                        <v-chip v-if="item.requerido" label small
                                            class="text-caption">Obligatorio</v-chip>
                                        <v-btn v-if="index + 1 < examen.items.length" :disabled="!formvalidate[index]"
                                            :color="examen.color_thema" class="white--text" elevation="0"
                                            @click="preguntaNumero = preguntaNumero + 1">
                                            Continue
                                        </v-btn>
                                        <v-btn v-else @click="sendRespuestasEvaluacion()"
                                            :disabled="!formvalidate[index]" :color="examen.color_thema"
                                            class="white--text px-5" elevation="0">
                                            ENVIAR EVALUACIÓN
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-stepper-content>

                    </v-stepper-items>

                </v-stepper>


            </div>
        </v-col>
    </v-row>

</template>


<script>
import Filters from '@/modules/Shared/Filters'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    mixins: [Filters],
    data() {
        return {
            formvalidate: {},
            preguntaNumero: 0,
            strepperVisible: false
        }
    },
    computed: {
        ...mapState('psicologia/Evaluaciones', ['examen']),
        ...mapState('psicologia/RespuestasEvaluaciones', ['respuestaEvaluacion', 'estudianteEvaluado']),

    },
    methods: {
        ...mapActions('psicologia/Evaluaciones', ['showEvaluacion']),
        ...mapMutations('psicologia/Evaluaciones', ['resetExamen']),
        ...mapMutations('psicologia/RespuestasEvaluaciones', ['sendRespuestas', 'inicializarRespuestas']),
        ...mapActions('psicologia/RespuestasEvaluaciones', ['sendRespuestasEvaluacion']),
        navigation(index) {
            if (this.formvalidate[index]) {
                this.preguntaNumero = index
            } else {
                return null
            }
        }
    },
    watch: {
        'examen.items': {
            handler(val) {
                if (val.length > 0) {
                    this.inicializarRespuestas(this.examen.items)
                    this.strepperVisible = true
                }
            }
        }
    },
    mounted() {
        this.resetExamen()
        this.showEvaluacion(this.$route.params.key)



    }
}
</script>

<style>
iframe[src*="youtube.com"] {
    max-width: 60%;
    height: auto;
    aspect-ratio: 16/9;
}

/* Media query para dispositivos móviles */
@media only screen and (max-width: 767px) {
    iframe[src*="youtube.com"] {
        max-width: 100%;
        /* Se ajusta el ancho máximo al 100% para dispositivos móviles */
    }
}

/* Media query para tablets y dispositivos similares */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    iframe[src*="youtube.com"] {
        max-width: 70%;
        /* Se ajusta el ancho máximo al 70% para tablets */
    }
}

/* Media query para pantallas de escritorio */
@media only screen and (min-width: 1024px) {
    iframe[src*="youtube.com"] {
        max-width: 60%;
        /* Se mantiene el ancho máximo al 60% para pantallas de escritorio */
    }
}
</style>