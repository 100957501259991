<template>
  <div>
    <v-card elevation="0">
      <v-card-title class="py-1">
        <small>Filtros</small>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="d-flex mb-2">
              <v-select
                v-model="filterReportes.estado"
                multiple
                outlined
                hide-details="auto"
                dense
                label="Filtrar por Estado"
                :items="['solucionado', 'no-solucionado']"
                @change="bundleBtnReporteClick()"
                small-chips
                class="mr-2"
                style="width: 50%"
              >
              </v-select>
              <v-text-field
                outlined
                hide-details="auto"
                dense
                label="Buscar por asunto"
                @change="bundleBtnReporteClick()"
                v-model="filterReportes.title"
                style="width: 50%"
              >
              </v-text-field>
            </div>

            <div class="d-flex">
              <v-select
                v-model="filterReportes.pabellon"
                outlined
                hide-details="auto"
                dense
                label="Filtrar por pabellón"
                :item-text="(item) => item.nombre + '(' + item.piso + ')'"
                item-value="idpabellon"
                :items="listaPabellones"
                @change="bundleBtnReporteClick()"
                small-chips
                style="width: 80%"
                class="mr-2"
                multiple
              >
              </v-select>

              <div class="pa-2 d-flex justify-center" style="width: 20%">
                <v-btn
                  class="green accent-4 white--text rounded-lg"
                  icon
                  elevation="0"
                  @click="getDownloadReportesCamasEXCEL()"
                  ><v-icon>mdi-microsoft-excel</v-icon></v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-simple-table class="mt-3" v-if="!loadingReportes">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="max-width: 5px; width: 5px">N°</th>
            <th style="width: 50px">Cama</th>
            <th style="width: 300px">Reporte</th>
            <th style="width: 80px">Fecha Reporte</th>
            <th style="width: 80px">Estado</th>
            <th style="width: 80px">Usuario</th>
            <th style="width: 80px"></th>
          </tr>
        </thead>
        <tbody v-if="listaReportes.length == 0 && !loadingReportes">
          <tr>
            <td colspan="7" class="text-center pa-5">
              <span class="font-weight-bold d-block"
                >No hay datos que mostrar</span
              >
              <small class="d-block">revise los filtros</small>
            </td>
          </tr>
        </tbody>
        <tbody v-for="(pabellon, index) in listaReportes" :key="index">
          <tr class="blue lighten-5">
            <td colspan="7" class="font-weight-bold">
              <v-avatar size="30" rounded color="bgCTM" class="mr-2"
                ><v-icon size="20" color="white">mdi-home</v-icon></v-avatar
              >
              Pabellón {{ pabellon.nombre }} - {{ pabellon.piso }}
            </td>
          </tr>
          <template v-for="(cama, a) in pabellon.camas_con_reporte">
            <tr>
              <td
                :rowspan="cama.reportes.length + 1"
                class="font-weight-bold text-center"
              >
                {{ a + 1 }}
              </td>
              <td :rowspan="cama.reportes.length + 1">
                <v-badge avatar bordered overlap bottom>
                  <v-avatar
                    size="35"
                    rounded="sm"
                    :color="colorAvatarCama(cama.state)"
                  >
                    <span class="font-weight-bold white--text">{{
                      cama.indexcama
                    }}</span>
                  </v-avatar>
                  <template v-slot:badge>
                    <v-avatar :color="colorAvatarCama(cama.state)">
                      <v-icon color="white--text"
                        >{{ cama.huesped ? "mdi-account" : "mdi-bed-empty" }}
                      </v-icon>
                    </v-avatar>
                  </template>
                </v-badge>
              </td>
            </tr>
            <template v-for="reporte in cama.reportes">
              <tr>
                <td class="font-weight-bold d-flex align-center">
                  <v-icon small color="grey" class="mr-2"
                    >mdi-bullhorn-variant</v-icon
                  >
                  <small class="text-uppercase">
                    {{ reporte.titulo_reporte }}</small
                  >
                </td>
                <td class="text-uppercase font-weight-bold text-caption">
                  {{ formatFechaCustom(reporte.fecha_reporte, "DD/MM/YY") }}
                </td>
                <td>
                  <v-chip small class="pl-2 font-weight-bold">
                    <v-avatar
                      left
                      :class="
                        reporte.estadoReporte == 'solucionado'
                          ? 'green accent-3'
                          : 'red accent-4'
                      "
                    >
                      <v-icon small color="white">{{
                        reporte.estadoReporte == "solucionado"
                          ? "mdi-check"
                          : "mdi-close"
                      }}</v-icon>
                    </v-avatar>
                    <small class="text-uppercase">{{
                      reporte.estadoReporte
                    }}</small>
                  </v-chip>
                </td>
                <td>
                  <v-chip small class="pl-2 font-weight-bold"
                    ><v-avatar left class="bgCTM"
                      ><v-icon small color="white"
                        >mdi-account</v-icon
                      ></v-avatar
                    >
                    <small>{{ formatUserName(reporte.users.nombres) }}</small>
                  </v-chip>
                </td>
                <td>
                  <v-btn v-if="$can('delete', 'reportes')" @click="deleteReporte({idreporte: reporte.idreporte, cama_id: reporte.cama_id})" icon class="grey darken-2 white--text mr-1" small
                    ><v-icon small>mdi-delete-outline</v-icon></v-btn
                  >

                  <v-btn
                    @click="
                      setReporteSeleccionado({
                        reporte: reporte,
                        cama: cama,
                      })
                    "
                    icon
                    class="bgCTM white--text"
                    small
                    ><v-icon small>mdi-chevron-right</v-icon></v-btn
                  >
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <div v-else class="mt-3">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </div>
  </div> 
</template>

<script>
import store from "@/store";

import { mapActions, mapMutations, mapState } from "vuex";
import Filters from "@/modules/Shared/Filters";
export default {
  mixins: [Filters],
  data() {
    return {
      panel: [0],
    };
  },
  computed: {
    ...mapState("hospedaje/reporteCama", [
      "listaReportes",
      "loadingReportes",
      "filterReportes",
    ]),
    ...mapState("pabellones", ["listaPabellones"]),
  },

  methods: {
    ...mapActions("pabellones", ["getAxiosPabellones"]),
    ...mapActions("hospedaje/reporteCama", ["getListaReportesAxios", "deleteReporte", "getDownloadReportesCamasEXCEL"]),
    ...mapMutations("hospedaje/reporteCama", ["setReporteSeleccionado"]),
    colorAvatarCama(estado) {
      switch (estado) {
        case "inoperativa":
          return "red darken-4";
        case "reporte":
          return "amber darken-4";
        case "disponible":
          return "blue darken-4";
        case "ocupada":
          return "blue darken-4";
      }
    },
    bundleBtnReporteClick() {
      this.getListaReportesAxios({
        sede: store.state.user.roles.sedes.split(","),
        estadoReporte: this.filterReportes.estado,
        title: this.filterReportes.title,
        idpabellon: this.filterReportes.pabellon,
      });
    },
  },

  watch: {
    listaPabellones: {
      handler() {
        this.filterReportes.pabellon = [];
        this.listaPabellones.map((pabellon) => {
          this.filterReportes.pabellon.push(pabellon.idpabellon);
        });
      },
    },
  },

  mounted() {
    this.getAxiosPabellones();

    //Seleccionar todos los options del select PABELLONES

    this.getListaReportesAxios({
      sede: store.state.user.roles.sedes.split(","),
      estadoReporte: this.filterReportes.estado,
      title: this.filterReportes.title,
      idpabellon: this.filterReportes.pabellon,
    });
  },
};
</script>
