<template>
  <div>
    <v-dialog width="400" v-model="dialog">
      <v-card>
        <v-card-title> Desocupar camas masivo </v-card-title>
        <v-card-subtitle class="text-caption py-2">
          Aquí podrá desocupar las camas masivamente, seleccione entre desocupar
          todas o ingresar un rango personalizado
        </v-card-subtitle>
        <v-card-text class="font-weight-bold black--text">
          <v-form v-model="validateForm">
            <div>
              <label for="">Desocupar:</label>
              <v-select
                v-model="desocuparMasivo.tipo"
                outlined
                dense
                hide-details="auto"
                placeholder="Seleccione Todas/Personalizado"
                :items="['Todas', 'Personalizado']"
                :rules="[rules.required]"
              ></v-select>
            </div>

            <div v-if="desocuparMasivo.tipo == 'Personalizado'" class="mt-2">
                <span>Selecciona las camas a desocupar</span>
                <v-item-group multiple v-if="listaCamasDesocupadas && !loadingCamasDesocupadas"  class="d-flex flex-wrap justify-center" v-model="desocuparMasivo.rangocamas">
                    <div v-for="(numcama, i) in listaCamasDesocupadas" :key="i">
                    <v-item v-slot="{ active, toggle }" :value="numcama.idcama">
                        <v-avatar size="30" @click="toggle" rounded class="font-weight-bold ma-1" 
                        style="cursor: pointer;"
                        :class="active? 'indigo white--text': 'blue-grey lighten-3'">
                        {{ numcama.indexcama }}
                        </v-avatar>
                    </v-item>
                    </div>
                </v-item-group>
                <div v-if="loadingCamasDesocupadas" class="d-flex flex-wrap justify-center">
                    <v-skeleton-loader class="ma-1" type="button" width="30" v-for="(b, i) in [1,2,3,4,5,6,7,8,9,10]" :key="i"></v-skeleton-loader>
                </div>
            </div>

            

            <v-btn @click="setDesocuparCamas(idpabellon)" :disabled="!validateForm" elevation="0" class="red white--text my-2" block
              ><v-icon left color="white">mdi-run</v-icon>DESOCUPAR</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins:[ Filters ],
  data() {
    return {
      validateForm: true,
      idpabellon: -1
    };
  },
  watch:{
    dialogDesocupar:{
        handler(val){
            if(!val){ this.resetDesocuparMasivo() }
        }
    }
  },
  computed: {
    ...mapState("hospedaje/desocuparMasivos", ["dialogDesocupar", "desocuparMasivo", "listaCamasDesocupadas", "loadingCamasDesocupadas"]),
    dialog: {
      get() {
        return this.dialogDesocupar;
      },
      set(val) {
        this.setDialogDesocupar();
      },
    },
  },

  methods: {
    ...mapMutations("hospedaje/desocuparMasivos", ["setDialogDesocupar", "resetDesocuparMasivo"]),
    ...mapActions("hospedaje/desocuparMasivos", ["getListaCamasDesocupadasAxios", "setDesocuparCamas"])
  },

  mounted(){
    if(this.$route.params.id){
        this.idpabellon = this.$route.params.id
        this.getListaCamasDesocupadasAxios({ 
            idpabellon: this.$route.params.id
        });

    }
  }
};
</script>
