import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class MedicamentoService
{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );

        
    }


    async get()
    {
       const response = await this.axios.get('/presuncion')
       return response.data;
    }


    async add(data)
    {
        const response = await this.axios.post('/presuncion', data)
        return response.data
    }

    async update(data)
    {
        const response = await this.axios.put('/presuncion', data)
        return response.data
    }

    async delete(idpresuncion)
    {
        return await this.axios.delete('/presuncion?idpresuncion='+idpresuncion)
    }
}
