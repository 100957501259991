<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <v-card elevation="0" class="indigo darken-3">
                    <v-card-text class="pa-12 d-flex justify-space-between align-center">
                        <div>
                            <h1 class="mr-5 white--text py-3">Hospedaje</h1>
                            <span class="white--text">Dashboard de pabellones, hospedaje y reportes de camas.</span>
                        </div>

                        <div>
                            <v-btn :to="{ name: 'hospedaje' }" elevation="0"
                                class="indigo darken-1 white--text mr-2"><v-icon></v-icon>PABELLONES</v-btn>
                            <v-btn :to="{ name: 'hospedados-reporte' }" elevation="0"
                                class="indigo darken-1 white--text"><v-icon></v-icon>HOSPEDADOS</v-btn>
                        </div>

                    </v-card-text>
                    <v-card-actions class="indigo darken-1 d-flex justify-center">
                        <div v-if="$route.meta.tipo == 'pabellon'">
                            <v-btn :to="{ name: 'hospedaje-principal' }" text elevation="0"
                                class="indigo darken-1 white--text mr-2"><v-icon></v-icon>PABELLONES DISPONIBLES</v-btn>
                            <v-btn :to="{ name: 'reporteGeneralPabellones' }" text elevation="0"
                                class="indigo darken-1 white--text mr-2"><v-icon></v-icon>REPORTE GENERAL</v-btn>
                            <v-btn :to="{ name: 'crear-pabellon' }" v-if="$can('create', 'pabellones')" elevation="0"
                                class="indigo darken-3 white--text px-5"><v-icon></v-icon>CREAR NUEVO PABELLÓN</v-btn>
                        </div>

                        <div v-if="$route.meta.tipo == 'hospedado'">
                            <v-btn :to="{ name: 'hospedados-reporte' }" text elevation="0"
                                class="indigo darken-1 white--text mr-2"><v-icon></v-icon>LISTA TOTAL DE HOSPEDADOS</v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" class="px-1">
                <router-view name="hospedajePabellones"></router-view>

            </v-col>


        </v-row>
    </v-container>
</template>

<script>


export default {
    components: {


    }
}
</script>