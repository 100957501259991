<template>
  <div>

    
    <v-card elevation="0" class="px-2 pb-2">
      <v-card-subtitle class="black--text font-weight-bold"
        >REGISTRAR NUEVO PABELLÓN</v-card-subtitle
      >

      <div class="pa-3">
        <label for="">Ubicación de la Salida / Baño</label>
        <v-select outlined :items="ubicacionBaño" hide-details="auto" dense v-model="dataPabellon.selectdUbicacionBaño"></v-select>
      </div>

      <!--Onerectangle-->
      <OneRectangle v-if="dataPabellon.selectedTemplate == 'Onerectangle'" :ubicacionBano="dataPabellon.selectdUbicacionBaño">
        <template v-slot:espacioCamasA>
             <ListaBloques v-for="(bloque, index) in listaBloques " :key="index" :bloque="bloque" :cardinal="'A'"/>
            <btn-add-cama :cardinal="'A'" :height="'190px'" class="ma-1"/>
        </template>
      </OneRectangle>
       <!--Onerectangle-->


       <!--AyBrectangle--> 
      <AyBrectangle  v-if="dataPabellon.selectedTemplate == 'AyBrectangle'" :ubicacionBano="dataPabellon.selectdUbicacionBaño">
        <template v-slot:espaciocamasA>
          <ListaBloques v-for="(cama, index) in listaBloques " :key="index" :bloque="cama" :cardinal="'A'" />
          <btn-add-cama :cardinal="'A'" :height="'150px'" class="ma-1"/>
        </template>
        <template v-slot:espaciocamasB>
           <ListaBloques v-for="(cama, index) in listaBloques " :key="index" :bloque="cama" :cardinal="'B'"  />
           <btn-add-cama :cardinal="'B'" :height="'150px'" class="ma-1"/>
        </template>
      </AyBrectangle>
       <!--AyBrectangle-->



       <!--AByCrectangle--> 
      <AByCrectangle  v-if="dataPabellon.selectedTemplate == 'AByCrectangle'" :ubicacionBano="dataPabellon.selectdUbicacionBaño">
        <template v-slot:espacioCamaIzquierdaA>
            <btn-add-cama :cardinal="'A'" :height="'35px'" :vertical="true" class="ma-1"/>
            <ListaBloques v-for="(cama, index) in listaBloques " :key="index" :bloque="cama" :cardinal="'A'" :horizontal="true"  />  
        </template>
        <template v-slot:espacioCamaArribaB>
            <ListaBloques v-for="(cama, index) in listaBloques " :key="index" :bloque="cama" :cardinal="'B'" />
              <btn-add-cama :cardinal="'B'" :height="'150px'" class="ma-1"/>
        </template>
        <template v-slot:espacioCamaAbajoC>
              <ListaBloques v-for="(cama, index) in listaBloques " :key="index" :bloque="cama" :cardinal="'C'" />
              <btn-add-cama :cardinal="'C'" :height="'150px'" class="ma-1" /> 
        </template>
      </AByCrectangle>
       <!--AByCrectangle-->
    </v-card>



    <DialogFormAddCama />
   
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import BtnAddCama from './creador/btnAddCama.vue';
import ListaBloques from './read/ListaBloques.vue'


import DialogFormAddCama from './creador/DialogFormAddCama.vue'

import OneRectangle from './creador/OneRectangleTemplate.vue'
import AyBrectangle from './creador/AyBrectangleTemplate.vue'
import AByCrectangle from './creador/AByCrectangleTemplate.vue'
export default {
  name: "CreadorPabellon",
  components:{
    ListaBloques,
    BtnAddCama,
    OneRectangle,
    AyBrectangle,
    AByCrectangle,
    DialogFormAddCama
  },
  data: () => ({
    openDialog: false,
  }),

  computed:{
    ...mapState('pabellones',["ubicacionBaño"]),
    ...mapState('pabellones/creadorPabellon', ['listaBloques', 'dataPabellon'])
  },


 
};
</script>

<style scoped>
.pabellonAyBrectangle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}


.pabellonABCrectangle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-height: 100%;
}

.texto-vertical {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

</style>
