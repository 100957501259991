import Module from './Module.vue';
import CrearPabellonView from './views/CrearPabellonView.vue'
import PabellonesView from './views/PabellonesView.vue';
const moduleRoute = {
    path: "/pabellones",
    component: Module,
    children: [
      {
        path: "/",
        component: PabellonesView,
        name: 'pabellones',
        meta: {
          requiresAuth: true,
          title: "Pabellones",
        },
      },
      {
        path: "crear-nuevo",
        component: CrearPabellonView,
        name: 'crear-pabellon',
        meta: {
          requiresAuth: true,
          title: "Crear pabellon",
        },
      }
      
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };