import utiles from "@/modules/Shared/utiles";
import silderDerechaComponent from "@/modules/Shared/utiles/silderDerechaComponent";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";

export default {
  namespaced: true,
  modules: {
    utiles,
  },
  state: {
    visitaRef: null,
    material: {
      cantidad: 0,
      descripcion: "",
      marca: "",
      codigo: "",
    },

    materialDefault: {
      cantidad: 0,
      descripcion: "",
      marca: "",
      codigo: "",
    },
    materialesIngresados: [],
    loadingMateriales: false,
  },
  mutations: {
    setMaterial(state) {
      state.materialesIngresados.push(state.material);
      state.material = Object.assign({}, state.materialDefault);
    },

    delMaterial(state, material) {
      let index = state.materialesIngresados.indexOf(material);

      swal({
        title: "¿Desea eliminar el registro?",
        icon: "warning",
        buttons: ["cancel", "CONFIRMAR"],
        dangerMode: true,
      }).then((confirma) => {
        if (!confirma) {
          return;
        }

        state.materialesIngresados.splice(index, 1);
      });
    },

    openEditMateriales(state, visita) {
      state.visitaRef = Object.assign({}, visita);
    },

    resetMateriales(state) {
      state.material = Object.assign({}, state.materialDefault);
      state.materialesIngresados = [];
    },
  },
  actions: {
    saveMaterialesVisita({ state, commit, dispatch }) {
      commit("utiles/resetConfigAxios");
      let data = {};
      state.loadingMateriales = true;
      data.idvisita = state.visitaRef.idvisita;
      data.materialesIngresados = state.materialesIngresados;

      utiles.state.configAxios.url =
        utiles.state.apiUrl + "materialespostvisita";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      utiles.state.configAxios.data = data;

      axios(utiles.state.configAxios)
        .then((response) => {
          if (response.status == 200) {
            store.dispatch("visitas/getVisitasHoy");
          }
          state.loadingMateriales = false;
          store.commit("silderDerechaComponent/closeSilder");
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: "Ha ocurrido un error",
            icon: "error",
          });
          state.loadingMateriales = false;
        });
    },
  },
};
