import Module from './Module.vue';
import ComedorPrincipalView from './views/ComedorPrincipalView.vue'
const moduleRoute = {
    path: "/comedor",
    component: Module,
    children: [
      {
        path: "",
        redirect: "ingreso-diario",
        component: ComedorPrincipalView,
        name: 'comedor',
        meta: {
          requiresAuth: true,
          title: "Comedor",
        },
      },
      {
        path: "estudiantes-admitidos",
        component: ComedorPrincipalView,
        children:[
          { 
            path: "",
            components: {
              comedorView: () => import('@/modules/Comedor/components/EstudiantesAdmitidosComponent.vue'),
            },
            name: "comedor-estudiantes",
            meta: {
              requiresAuth: true,
              title: "Comedor - estudiantes",
              seccion: "estudiantes"
            },
          }
        ],

      },
      {
        path: "cronograma-ingreso",
        component: ComedorPrincipalView,
        children:[
          { 
            path: "",
            components: {
              comedorView: () => import('@/modules/Comedor/components/CronogramaIngresoComponent.vue'),
            },
            name: "cronograma-ingreso",
            meta: {
              requiresAuth: true,
              title: "Comedor - Cronograma Ingreso",
              seccion: "estudiantes"
            },
          }
        ],

      },
      {
        path: "configuracion-general",
        component: ComedorPrincipalView,
        children:[
          { 
            path: "",
            components: {
              comedorView: () => import('@/modules/Comedor/components/ConfiguracionGeneralComponent.vue'),
            },
            name: "configuracion-general",
            meta: {
              requiresAuth: true,
              title: "Comedor - Configuración general",
              seccion: "estudiantes"
            },
          }
        ],

      },
      {
        path: "ingreso-diario",
        component: ComedorPrincipalView,
        children:[
          { 
            path: "",
            components: {
              comedorView: () => import('@/modules/Comedor/components/IngresoDiarioComponent.vue'),
            },
            name: "ingreso-diario",
            meta: {
              requiresAuth: true,
              title: "Comedor - Ingreso diario",
              seccion: "estudiantes"
            },
          }
        ],

      },
      
      {
        path: "menu-semanal",
        component: ComedorPrincipalView,
        children:[
          { 
            path: "",
            components: {
              comedorView: () => import('@/modules/Comedor/components/MenuSemanalComponent.vue'),
            },
            name: "menu-semanal",
            meta: {
              requiresAuth: true,
              title: "Comedor - Ingreso diario",
              seccion: "estudiantes"
            },
          }
        ],

      },
      {
        path: "verificacion-ingreso",
        component:  () => import('@/modules/Comedor/views/VerificacionIngresoView.vue'),
            name: "verificacion-ingreso",
            meta: {
              title: "Comedor - Configuración general",
              seccion: "estudiantes"
            },
      },
      {
        path: "verificacion-ingreso-extemporaneo",
        component:  () => import('@/modules/Comedor/views/VerificacionIngresoExtemporaneoView.vue'),
            name: "verificacion-ingreso-extemporaneo",
            meta: {
              title: "Comedor - Configuración general",
              seccion: "estudiantes"
            },
      },
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  }; 