<template>
  <v-app-bar color="white" elevate-on-scroll dense rounded="lg">
    <v-app-bar-nav-icon
      @click.stop="nav.drawer = !nav.drawer"
    ></v-app-bar-nav-icon>

    <router-link :to="{ name: 'dashboard' }" style="text-decoration: none">
      <v-img :src="require('@/assets/logo-03.svg')" max-width="100"></v-img>
    </router-link>

    <v-spacer></v-spacer>
    <v-img
      v-if="$vuetify.breakpoint.mdAndUp"
      max-width="70"
      contain
      class="mr-3"
      :src="require('@/assets/LOGO-TIC.svg')"
    ></v-img>

    <v-chip class="font-weight-bold mr-2 d-none d-lg-inline-block">
      <v-avatar size="28" color="grey darken-4" left
        ><v-icon class="white--text" small>mdi-calendar-month</v-icon></v-avatar
      >
      <span class="text-subtitle-1 font-weight-bold">{{ fecha }}</span>
    </v-chip>

    <v-chip class="font-weight-bold mr-2 d-none d-lg-inline-block">
      <v-avatar size="28" color="grey darken-4" left
        ><v-icon class="white--text" small
          >mdi-clock-time-three-outline</v-icon
        ></v-avatar
      >
      <span class="text-subtitle-1 font-weight-bold">{{ hora }}</span>
    </v-chip>

    <v-chip
      v-if="sedes.length == 1"
      class="font-weight-bold mr-2 d-none d-lg-inline-block"
      style="cursor: pointer"
    >
      <v-avatar size="28" color="light-blue darken-4" left
        ><v-icon class="white--text" small>mdi-map-marker</v-icon></v-avatar
      >
      <span class="text-subtitle-1 font-weight-bold">{{ sedes[0] }}</span>
    </v-chip>

    <div class="d-flex align-center rounded-xl grey lighten-2 pa-1 pl-1">
      <v-badge
        bordered
        bottom
        color="green accent-4"
        dot
        offset-x="10"
        offset-y="10"
      >
        <v-avatar left size="28" color="grey darken-4 mr-2"
          ><v-icon color="white">mdi-account</v-icon></v-avatar
        >
      </v-badge>
      <div>
        <div
          class="font-weight-bold"
          style="font-size: 12px; line-height: 1.2rem"
        >
          {{ firtsName(user.nombres) + " " + firtsName(user.apellidos) }}
        </div>
      </div>

      <v-menu offset-y max-width="250" min-width="250">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            right
            size="28"
            color="grey darken-4 ml-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="white">mdi-dots-vertical</v-icon>
          </v-avatar>
        </template>
        <v-card elevation="0" class="deep-purple darken-4 pa-0 rounded-0">
          <v-card-text class="text-center py-8">
            <v-avatar left size="70" color="white mb-3" rounded
              ><v-icon size="60" color="deep-purple darken-4"
                >mdi-account</v-icon
              ></v-avatar
            >
            <div
              class="font-weight-bold text-subtitle-1 white--text text-capitalize"
              style="line-height: 1.2rem"
            >
              {{ user.nombres }}
            </div>
            <div
              class="font-weight-bold text-subtitle-1 white--text text-capitalize mb-2"
              style="line-height: 1.2rem"
            >
              {{ user.apellidos }}
            </div>
            <v-chip
              small
              class="deep-purple--text text--darken-4 text-caption font-weight-bold mb-4"
              >{{ user.cargo }}</v-chip
            >
            <div class="deep-purple--text text--lighten-4 text-caption">
              {{ user.email }}
            </div>
          </v-card-text>
        </v-card>
        <v-list class="py-0">
          <v-list-item dense @click="setDialogChangePass()">
            <v-list-item-title
              ><v-avatar class="indigo mr-2" size="25"
                ><v-icon color="white" small>mdi-lock-reset</v-icon></v-avatar
              >
              CAMBIAR CONTRASEÑA</v-list-item-title
            >
          </v-list-item>
          <v-list-item dense @click="logout()">
            <v-list-item-title
              ><v-avatar class="indigo mr-2" size="25"
                ><v-icon color="white" small
                  >mdi-logout-variant</v-icon
                ></v-avatar
              >
              CERRAR SESIÓN</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <DialogChangePass />
    </div>

    <v-dialog v-model="selectedSedeDialog" width="500" persistent>
      <v-card>
        <v-card-title
          class="blue-grey darken-4 text-subtitle-1 white--text justify-space-between"
        >
          <span>CAMBIAR DE SEDE:</span>
          <v-icon
            class="white--text font-weight-bold"
            style="cursor: pointer"
            @click="selectedSedeDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="font-weight-bold black--text py-4">
          <label for="">Seleccionar sede:</label>
          <v-select
            flat
            outlined
            dense
            hide-details="auto"
            placeholder="SEDES"
            :items="sedes"
          ></v-select>
          <v-btn block class="bgCTM white--text mt-2">CAMBIAR SEDE</v-btn>
          <small>Al cambiar de sede, se reiniciará la sesión</small>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import DialogChangePass from "./DialogChangePass.vue";
import moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  mixins: [Filters],
  components: {
    DialogChangePass,
  },
  data() {
    return {
      fecha: "",
      hora: "",
      selectedSedeDialog: false,
    };
  },
  name: "NavbarUp",
  props: {},
  computed: {
    isNotMovil() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg;
    },
    ...mapState(["user", "nav"]),
    ...mapState("visitas/utiles", ["sedes"]),
    sedes() {
      let sedes = this.$store.state.user.roles.sedes.split(",");
      return sedes;
    },
  },

  methods: {
    ...mapActions("login", ["logout"]),
    ...mapMutations("login/restorepass", ["setDialogChangePass"]),

    fechayhora() {
      return this.dateCurrent() + "    " + this.timeCurrent();
    },
  },

  mounted() {
    setInterval(() => {
      const fecha = moment().format("DD MMMM YYYY");
      this.fecha = fecha;

      const hora = moment().format("hh:mm:ssa");
      this.hora = hora;
    }, 100);
  },
};
</script>
