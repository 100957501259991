<template>
  <router-link :to="{name: 'comedor'}" style="text-decoration: none;">
    <a href="#" class="cardDash wallet card-dasboard">
    <div class="overlay"></div>
    <div class="circle">
      <img style="z-index: 10000;" width="80px" :src="require('@/modules/Visitas/assets/comedor-men.svg')"/>
    </div>
    <p class="mb-0">COMEDOR</p>
    <span>Control de ingreso al comedor</span>
  </a>
  </router-link>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({}),

  computed: {
    ...mapState("pabellones", ["listaPabellones"]),
  },
  methods: {
    ...mapActions("pabellones", ["getAxiosPabellones"]),
  },

  mounted() {
    this.getAxiosPabellones();
  },
};
</script>
