import ConsolidadoGeneralService from "../services/ConsolidadoGeneralService"
import swal from "sweetalert"
import store from "@/store"
const consolidadoSv = new ConsolidadoGeneralService
export default{
    namespaced:true,
    state:{
        consolidadoGeneral:[],
        totalHuespedes: [],
        loadingConsolidado: false,
        loadingConsolidadoHuespedes: false,
        filtersConsolidado: {
            sede: ""
        },
        filtersHuespedes:{
            dninombre: "",
            carrera: "",
            admision: "",
            nombre_pabellon: [],
            sede: [],
            tipohuesped:"",

        }
    },
    mutations:{
        setConsolidado(state, payload)
        {
            state.consolidadoGeneral = payload
        },

        setTotalHuespedes(state, payload)
        {
            state.totalHuespedes = payload
        }
    },
    actions:{

        async getConsolidado({state, commit})
        {
            state.loadingConsolidado = true
            try
            {
                const data = await consolidadoSv.getConsolidadoGeneral(state.filtersConsolidado)
                commit('setConsolidado', data)
                console.log(data)
                state.loadingConsolidado = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingConsolidado = false
            }
        },


        async getReporteTotalHuespedes({state, commit})
        {
            state.loadingConsolidadoHuespedes = true
            try
            {
                const data = await consolidadoSv.getReporteTotalHuespedes(state.filtersHuespedes)
                commit('setTotalHuespedes', data)
                console.log(data)
                state.loadingConsolidadoHuespedes = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingConsolidadoHuespedes = false
            }
        }, 


        async getConsolidadoPDF({state, commit})
        {
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false, 
                closeOnClickOutside: false, 
                closeOnEsc: false, 
                allowOutsideClick: false 
              });
            try
            {
                const data = await consolidadoSv.generarPDF(state.filtersConsolidado)
                swal({
                    title: "Se generó el PDF",
                    icon: "success"
                })
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ocurrio un grave error",
                    icon: "error"
                })
            }
        },

        async getReporteExcel({state, commit})
        {
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false, 
                closeOnClickOutside: false, 
                closeOnEsc: false, 
                allowOutsideClick: false 
              });
            try
            {
                const data = await consolidadoSv.getReporteExcel(state.filtersConsolidado)
                swal({
                    title: "Se generó el EXCEL",
                    icon: "success"
                })
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ocurrio un grave error",
                    icon: "error"
                })
            }
        },

        //getReporteExcelHuespedes


        async getReporteExcelHuespedes({state, commit})
        {
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false, 
                closeOnClickOutside: false, 
                closeOnEsc: false, 
                allowOutsideClick: false 
              });
            try
            {
                const data = await consolidadoSv.getReporteExcelHuespedes(state.filtersHuespedes)
                swal({
                    title: "Se generó el EXCEL",
                    icon: "success"
                })
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ocurrio un grave error",
                    icon: "error"
                })
            }
        },
    }
}