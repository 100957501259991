<template>
      <v-row class="pa-0 mt-1">
        <v-col
          cols="3"
          class="d-flex flex-column justify-center align-center pa-2"
        >
          <div class="d-flex flex-column justify-center align-center">
            <v-avatar color="indigo" size="30">
              <v-icon color="white" small>{{
                camaSeleccionada.tipo == "cama"
                  ? "mdi-bed-queen-outline"
                  : "mdi-bunk-bed-outline"
              }}</v-icon>
            </v-avatar>
            <v-chip
              color="white"
              class="black--text text-uppercase font-weight-bold text-truncate"
              small
            >
              {{ camaSeleccionada.tipo }} /

              <small v-if="camaSeleccionada.cama.is_camaarriba" class="font-weight-bold">
                ARRIBA
              </small>
              <small v-if="camaSeleccionada.cama.is_camaabajo" class="font-weight-bold">
                ABAJO
              </small>
            </v-chip>
          </div>
        </v-col>
        <v-col
          cols="3"
          class="d-flex flex-column justify-center align-center pa-2"
        >
          <div class="d-flex flex-column justify-center align-center">
            <v-avatar color="indigo lighten-4" rounded="lg" size="30">
              <span class="indigo--text text-center font-weight-bold text-h6">
                {{ camaSeleccionada.cama.indexcama }}
              </span>
            </v-avatar>
            <v-chip
              color="white"
              class="black--text text-uppercase font-weight-bold text-truncate"
              small
            >
              ID de cama
            </v-chip>
          </div>
        </v-col>
        <v-col
          cols="3"
          class="d-flex flex-column justify-center align-center pa-2"
        >
          <chipEstadoCama :estado="camaSeleccionada.cama.state" :block="true" :callback="camaSeleccionada.cama.state == 'reporte' ? verReporteCama: () => false"  />
        </v-col>

        <v-col
          cols="3"
          class="d-flex flex-column justify-center align-center pa-2"
           @click="handleReportarCamaClick()"
        >

            <v-avatar rounded="lg" color="amber darken-3" size="30" style="cursor:pointer;">
              <v-icon color="white" size="25">mdi-alert-rhombus</v-icon>
            </v-avatar>
            <v-chip
              color="white"
              class="black--text text-uppercase font-weight-bold text-truncate"
              small
              style="cursor:pointer;"
            >
              REPORTAR
            </v-chip>
        </v-col>

        <v-col
          cols="12"
          v-if="camaSeleccionada.cama.comentario"
          class="black--text blue-grey lighten-4 font-weight-bold text-caption rounded-lg pa-2"
        >
          <span>{{ camaSeleccionada.cama.comentario }} </span>
        </v-col>
      </v-row>
</template>


<script>
import { mapMutations } from 'vuex';
import chipEstadoCama from "../chipEstadoCama.vue";

export default {
    components:{
        chipEstadoCama
    },
    props:{
        camaSeleccionada:{
            type: Object,
            required: true
        }
    },

    methods:{
      ...mapMutations('hospedaje',['reportarProblemaCama', 'verReporteCama']),
      handleReportarCamaClick(){
        this.reportarProblemaCama({$ability: this.$ability})
      }
    }
}
</script>