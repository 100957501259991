<template>
  <div>
    <v-card elevation="0">
      <v-card-text class="black--text py-2">
        <v-card-subtitle class="black--text font-weight-bold px-2"
          >Ingrese los siguientes datos:</v-card-subtitle
        >
        <div class="my-2">
          <label for="">NOMBRE DEL PABELLÓN:</label>
          <v-text-field
            hide-details="auto"
            outlined
            dense
            outlinet
            placeholder="identificador de pabellón"
            class="font-weight-bold"
            v-model="dataPabellon.nombre"
          ></v-text-field>
        </div>

        <div class="d-flex">
          <div class="my-2 mr-3" style="width: 50%">
            <label for="">SEDE:</label>
            <v-select
              hide-details="auto"
              outlined
              dense
              outlinet
              :items="sedes"
              placeholder="nombre de la sede"
              class="font-weight-bold"
              v-model="dataPabellon.sede"
            ></v-select>
          </div>

          <div class="my-2 mr-3" style="width: 50%">
            <label for="">GENERO:</label>
            <v-select
              hide-details="auto"
              outlined
              dense
              outlinet
              :items="genero"
              placeholder="Aptos para mujeres / hombres"
              class="font-weight-bold"
              v-model="dataPabellon.genero"
              multiple
            ></v-select>
          </div>

          <div class="my-2" style="width: 50%">
            <label for="">PERSONAL A HOSPEDARSE:</label>
            <v-select
              hide-details="auto"
              outlined
              dense
              outlinet
              :items="tipoPersonal"
              placeholder="alumnos / colaboradores"
              class="font-weight-bold"
              v-model="dataPabellon.personal_hop"
            ></v-select>
          </div>
        </div>

        <div class="d-flex">
          <div class="my-2 mr-2" style="width: 50%">
            <label for="">Capacidad total de camas:</label>
            <v-text-field
              hide-details="auto"
              outlined
              dense
              outlinet
              placeholder="0 - n camas"
              class="font-weight-bold"
              type="number"
              v-model="dataPabellon.capacidad_total"
            ></v-text-field>
          </div>

          <div class="my-2" style="width: 50%">
            <label for="">Piso:</label>
            <v-select
              hide-details="auto"
              outlined
              dense
              outlinet
              :items="pisos"
              placeholder="alumnos / colaboradores"
              class="font-weight-bold"
              v-model="dataPabellon.piso"
            ></v-select>
          </div>
        </div>

        <div class="mb-3">
          <label for="">DESCRIPCIÓN: </label>
          <v-textarea
            hide-details="auto"
            outlined
            dense
            rows="3"
            class="font-weight-bold"
            v-model="dataPabellon.descripcion"
          ></v-textarea>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("pabellones", ["sedes", "tipoPersonal", "genero", "pisos"]),
    ...mapState("pabellones/creadorPabellon", ["dataPabellon"])
  },
};
</script>
