<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-card-title class="flex-row align-start justify-space-between">
                <div>
                    <h4>Vista previa de los huespedes</h4>
                    <small class="text--secondary font-weight-regular">Revisa la información antes de dar el siguiente paso.</small>
                </div>

                <div>
                    <v-btn small @click="resetAllMasivo()" elevation="0" class="bgCTM white--text mr-2"><v-icon left>mdi-reload</v-icon>Volver a cargar</v-btn>
                    <v-btn small @click="HospedarMasivaos(idpabellon)"  elevation="0" class="green accent-4 white--text"><v-icon left>mdi-database-arrow-up-outline</v-icon>CARGAR DATOS</v-btn>
                </div>
            </v-card-title>

            <v-card-text>
                <scroller widthScroll="2030px" />
                <v-data-table class="table-reference" :headers="headers" :items="dataExcel" mobile-breakpoint="0" :items-per-page="200">
                    <template v-slot:item.validate="{item}">
                            <div class="ma-1 d-flex flex-column justify-center align-center pa-1 rounded-lg" style="width: 50px;">
                                <v-avatar size="25" rounded :class="item.validate != null? 'green accent-4' : 'red darken-3'">
                                    <v-icon small color="white">mdi-shield-check</v-icon>
                                </v-avatar>
                                 <small class="mt-0">{{item.validate != null ? item.validate : 'noInden'}}</small>
                            </div>
                    </template>

                    <template v-slot:item.tipo="{item}">
                        <v-avatar size="30" rounded :class="item.tipo == 'estudiante' ? 'amber black--text' : 'teal accent-4 black--text' ">
                            <small class="font-weight-bold">{{ item.tipo == 'estudiante' ? 'EST' : 'COL'}}</small>
                        </v-avatar>
                    </template>
                    <template v-slot:item.indexcama="{item}">
                        <v-avatar v-if="item.indexcama != null" rounded color="indigo" size="30">
                            <h4 class="font-weight-bold white--text">{{item.indexcama}}</h4>
                        </v-avatar>    
                        <v-avatar v-else rounded color="red darken-3" size="30">
                           <v-icon color="white">mdi-close</v-icon>
                        </v-avatar>  
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import scroller from '@/modules/Shared/components/Scroller.vue'
export default {
    components:{
        scroller
    },
    data(){
        return{
            idpabellon: -1,
            headers: [
                {
                    text: "N°",
                    value: "index",
                    width: 20,
                    sortable: false
                },
                {
                    text: "DNI",
                    value: "dni",
                    width: 30,
                    sortable: false
                },
                {
                    text: "Validación",
                    value: "validate",
                    width: 50,
                    sortable: false
                },

                {
                    text: "Apellidos y Nombres",
                    value: "nombrecompleto",
                    width: 300,
                    sortable: false
                },
                {
                    text: "Carrera",
                    value: "carrera",
                    width: 200,
                    sortable: false
                },
                {
                    text: "Admision",
                    value: "admision",
                    width: 40,
                    sortable: false
                },
                {
                    text: "Tipo",
                    value: "tipo",
                    width: 40,
                    sortable: false
                },
                {
                    text: "Jefatura",
                    value: "jefatura",
                    width: 80,
                    sortable: false
                },
                {
                    text: "Cargo",
                    value: "cargo",
                    width: 100,
                    sortable: false
                },
                {
                    text: "Cama",
                    value: "indexcama",
                    width: 40,
                    sortable: false
                },
                {
                    text: "Fecha ingreso",
                    value: "fecha_ingreso",
                    width: 180,
                    sortable: false
                },
                {
                    text: "Fecha Salida",
                    value: "fecha_salida",
                    width: 180,
                    sortable: false
                },
                {
                    text: "Días semana",
                    value: "dias_semana",
                    width: 40,
                    sortable: false
                },
                {
                    text: "Almohada",
                    value: "almohada",
                    width: 20,
                    sortable: false
                },
                {
                    text: "Funda almohada",
                    value: "funda_almohada",
                    width: 20,
                    sortable: false
                },
                {
                    text: "Sabana",
                    value: "sabana",
                    width: 20,
                    sortable: false
                },
                {
                    text: "Frazada",
                    value: "frazada",
                    width: 20,
                    sortable: false
                },
                {
                    text: "Locker",
                    value: "locker",
                    width: 20,
                    sortable: false
                }
                
            ]
        }
    },

    computed:{
        ...mapState('hospedaje/hospedarMasivos',['dataExcel'])
    },

    methods:{
        ...mapMutations("hospedaje/hospedarMasivos",["resetAllMasivo"]),
        ...mapActions("hospedaje/hospedarMasivos",['HospedarMasivaos'])
    },

    mounted(){
        if(this.$route.params.id){
            this.idpabellon = this.$route.params.id
        }
    }
}
</script>