<template>
    <div>
        <v-card class="mt-2 bgCTMHover rounded-b-0" elevation="0">
            <v-card-text class="white--text pa-4 pb-0 pa-md-8">
                <div class="text-h6 text-lg-h4 font-weight-bold">Comedor</div>
                <div class="text-subtitle-2 font-weight-light">Gestión de ingreso al comedor</div>
            </v-card-text>

            <v-card-text class="pa-0" v-if="$vuetify.breakpoint.mdAndDown">
                <MenuSuperiorComponent></MenuSuperiorComponent>
            </v-card-text>
        </v-card>
        <div class="px-0 mt-0 d-flex" style="position: relative;">

            <v-navigation-drawer v-if="$vuetify.breakpoint.lgAndUp" permanent :mobile-breakpoint="0"
                 class="bgCTMHover" style="height: 86vh;">

                <v-list nav>
                    <v-list-item v-if="$can('read', 'comedor-estudiantes')" :to="{ name: 'comedor-estudiantes' }" link style="height: 40px;" class="px-0">
                        <v-list-item-icon class="ma-1 mr-2">
                            <v-avatar color="white darken-1" class="mr-3 rounded-sm" size="35" left>
                                <v-icon class="text-CTM">mdi-account-group</v-icon>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-title class="white--text text-subtitle-2">Estudiantes admitidos</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="$can('read', 'comedor-bloques-seleccionados')" :to="{ name: 'cronograma-ingreso' }" link style="height: 40px;" class="px-0">
                        <v-list-item-icon class="ma-1 mr-2">
                            <v-avatar color="white darken-1" class="mr-3 rounded-sm" size="35" left>
                                <v-icon class="text-CTM">mdi-calendar-cursor-outline</v-icon>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-title class="white--text text-subtitle-2">Bloques seleccionados</v-list-item-title>
                    </v-list-item>


                    <v-list-item v-if="$can('read', 'comedor-ingreso-diario')" :to="{ name: 'ingreso-diario' }" link style="height: 40px;" class="px-0">
                        <v-list-item-icon class="ma-1 mr-2">
                            <v-avatar color="white darken-1" class="mr-3 rounded-sm" size="35" left>
                                <v-icon class="text-CTM">mdi-motion-sensor</v-icon>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-title class="white--text text-subtitle-2">Ingreso diario</v-list-item-title>
                    </v-list-item>


                    <v-list-item v-if="$can('read', 'comedor.configuracion')" :to="{ name: 'configuracion-general' }" link style="height: 40px;" class="px-0">
                        <v-list-item-icon class="ma-1 mr-2">
                            <v-avatar color="white darken-1" class="mr-3 rounded-sm" size="35" left>
                                <v-icon class="text-CTM">mdi-cog</v-icon>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-title class="white--text text-subtitle-2">Configuración general</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="$vuetify.breakpoint.mdAndUp && $can('read', 'comedor.configuracion')"  :to="{ name: 'menu-semanal' }" link
                        style="height: 40px;" class="px-0">
                        <v-list-item-icon class="ma-1 mr-2">
                            <v-avatar color="white darken-1" class="mr-3 rounded-sm" size="35" left>
                                <v-icon class="text-CTM">mdi-food</v-icon>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-title class="white--text text-subtitle-2">Menu de la semana</v-list-item-title>
                    </v-list-item>



                    <v-list-item v-if="$vuetify.breakpoint.mdAndUp && $can('read', 'comedor.verificador-ingreso')" :to="{ name: 'verificacion-ingreso' }" link
                        style="height: 40px;" class="px-0">
                        <v-list-item-icon class="ma-1 mr-2">
                            <v-avatar color="white darken-1" class="mr-3 rounded-sm" size="35" left>
                                <v-icon class="text-CTM">mdi-qrcode-scan</v-icon>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-title class="white--text text-subtitle-2">Verificación de
                            ingreso</v-list-item-title>
                    </v-list-item>




                </v-list>

            </v-navigation-drawer>

            <div class="blue-grey lighten-4 pl-0 pl-lg-2 mt-1"
                :style="$vuetify.breakpoint.mdAndDown ? 'width: 100%;' : 'width: 95%'">
                <router-view name="comedorView"></router-view>
            </div>

        </div>
    </div>

</template>


<script>
import MenuSuperiorComponent from '../components/menuSuperiorComponent.vue';


export default {
    components: {
        MenuSuperiorComponent
    },
    data() {
        return {
            drawer: false,
            isHovered: false
        }
    },
    methods: {
        onMouseEnter() {
            this.isHovered = true;
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.drawer = true;
            }
        },
        onMouseLeave() {
            this.isHovered = false;
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.drawer = false;
            }
        },
    },
    mounted() {
        if (!this.$can('read', 'comedor-ingreso-diario')) {
            this.$router.push('dashboard')
        }


        this.filtersHidden = this.$vuetify.breakpoint.mdAndDown


    }
}
</script>