import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";

export default {
  namespaced: true,
  modules: {
    utiles,
  },
  state: {
    dialogDesocupar: false,
    desocuparMasivo: {
      tipo: "",
      rangocamas: [],
    },
    desocuparMasivoDefault: {
      tipo: "",
      rangocamas: [],
    },

    listaCamasDesocupadas: [],
    loadingCamasDesocupadas: false,

    
  },

  mutations: {
    setDialogDesocupar(state) {
      state.dialogDesocupar = !state.dialogDesocupar;
    },

    resetDesocuparMasivo(state) {
      state.desocuparMasivo = Object.assign({}, state.desocuparMasivoDefault);
    },

    setListaCamasDesocupadas(state, payload) {
      state.listaCamasDesocupadas = payload;
    },
  },

  actions: {
    getListaCamasDesocupadasAxios({ commit, state }, payload) {
      state.loadingCamasDesocupadas = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "camasocupadas";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = { idpabellon: payload.idpabellon };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            commit("setListaCamasDesocupadas", resp.data.camas);
          }

          state.loadingCamasDesocupadas = false;
        })
        .catch((error) => {
          console.log(error);
          state.loadingCamasDesocupadas = false;
        });
    },

    setDesocuparCamas({ commit, dispatch, state }, idpabellon) {
      swal({
        title: "¿Desea desocupar las camas?",
        icon: "warning",
        dangerMode: true,
        buttons: ["cancelar", "confirmar"],
      }).then((confirma) => {
        if (!confirma) {
          return;
        }

        commit("utiles/resetConfigAxios");
        utiles.state.configAxios.url =
          utiles.state.apiUrl + "desocuparcamasmasivo";
        utiles.state.configAxios.method = "POST";
        utiles.state.configAxios.data = {
          idpabellon: idpabellon,
          tipo: state.desocuparMasivo.tipo,
          rangocamas: state.desocuparMasivo.rangocamas,
        };
        utiles.state.configAxios.headers.Authorization =
          "Bearer " + store.state.user.token;

        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });

        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              store.dispatch("hospedaje/getAxiosPabellon", idpabellon);
              store.dispatch("hospedaje/getHosepdadosAxios", {
                idpabellon: idpabellon,
              });

              swal({
                title: "Se desocuparon las camas",
                icon: "success",
              });

              commit("resetDesocuparMasivo");
              commit("setDialogDesocupar");
            }
          })
          .catch((error) => {
            console.log(error);

            swal({
              title: "Ocurrió un error",
              icon: "error",
            });
          });
      });
    },
  },
};
