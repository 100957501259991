import CapacidadComedorService from "../services/CapacidadComedorSevice";

const capacidadSV = new CapacidadComedorService();

export default {
  namespaced: true,
  state: {
    capacidad: {
      sede: "",
      capacidad_sillas: 0,
    },
    capacidadDefault: {
      sede: "",
      capacidad_sillas: 0,
    },
    listacapacidad: [],
    filter: {
      sede: "",
    },
    loadingAdd: false,
    loadingList: false,

    dialogCapacidad: {
      open: false,
    },
  },

  mutations: {
    setListaCapacidad(state, payload) {
      state.listacapacidad = payload;
    },

    setCapacidad(state, capacidad) {
      state.dialogCapacidad.open = true;
      state.capacidad = Object.assign({}, capacidad);
    },

    resetCapacidad(state) {
      state.capacidad = Object.assign({}, state.capacidadDefault);
    },
  },

  actions: {
    async get({ state, commit }) {
      state.loadingList = true;
      try {
        const data = await capacidadSV.get(state.filter);
        commit("setListaCapacidad", data);
        state.loadingList = false;
      } catch (error) {
        state.loadingList = false;
        console.log(error);
      }
    },

    async add({ state, commit, dispatch }) {
      state.loadingAdd = true;
      try {
        const data = await capacidadSV.add(state.capacidad);
        dispatch("get");
        state.loadingAdd = false;
        commit('resetCapacidad')
        state.dialogCapacidad.open = false
      } catch (error) {
        console.log(error);
        state.loadingAdd = false;
      }
    },

    async update({ state, commit, dispatch }) {
      state.loadingAdd = true;
      try {
        const data = await capacidadSV.update(state.capacidad);
        dispatch("get");
        state.loadingAdd = false;
        commit('resetCapacidad')
        state.dialogCapacidad.open= false
      } catch (error) {
        console.log(error);
        state.loadingAdd = false;
      }
    },
  },
};
