import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class MedicamentoService
{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );

        
    }


    async getMedicamento(params)
    {
       const response = await this.axios.get('/medicamento', {params: params})
       console.log(response)
       return response.data;
    }


    async addMedicamento(data)
    {
        const response = await this.axios.post('/medicamento', data)
        return response.data
    }

    async updateMedicamento(data)
    {
        const response = await this.axios.put('/medicamento', data)
        return response.data
    }

    async deleteMedicamento(idmedicamento)
    {
        return await this.axios.delete('/medicamento?idmedicamento='+idmedicamento)
    }


    async getReporte(data)
    {
        const response = await this.axios.post('/medicamentoreporte', data, {
            responseType: 'blob',
        })
        const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); 
          // Genera una URL temporal del archivo Blob
          const url = URL.createObjectURL(blob);
          // Crea un enlace y lo simula como si fuera un clic del usuario
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte-medicamentos.xlsx";
          a.click();
    }
}
