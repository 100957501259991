<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h4>Lista de Roles y permisos </h4>
            </v-col>
            <v-col cols="12" md="4">
                <form-roles />
            </v-col>
            <v-col cols="12" md="8">
                <lista-roles />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ListaRoles from '../components/Roles/ListaRoles.vue'
import FormRoles from '../components/Roles/FormRoles.vue'
export default {
    components:{
        ListaRoles,
        FormRoles
    },
    created(){
        if(!this.$can('create', 'usuarios')){
            this.$router.push({name: 'dashboard'})
        }
    }
}
</script>