<template>
  <router-link :to="{name: 'visitas-home'}" style="text-decoration: none;">
  <a class="cardDash human-resources card-dasboard">
      <div class="overlay"></div>
      <div class="circle">
        <img style="z-index: 10000;" width="80px" :src="require('@/modules/Visitas/assets/segurity-men.svg')"/>
      </div>
      <p class="mb-0">VISITAS</p>
      <span>Ingreso y salida de personas</span>
    </a>
  </router-link>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({}),

  computed: {
    ...mapState("pabellones", ["listaPabellones"]),
  },
  methods: {
    ...mapActions("pabellones", ["getAxiosPabellones"]),
  },

  mounted() {
    this.getAxiosPabellones();
  },
};
</script>

