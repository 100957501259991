import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class BloquesHorarioService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }


    async getList(params){
        const response = await this.axios.get('bloque-horario-comedor', {params: params})
        return response.data

    }


    async add(data){
        const response = await this.axios.post('bloque-horario-comedor', data)
        return response.data
        
    }

    async update(data){
        const response = await this.axios.put('bloque-horario-comedor', data)
        return response.data
        
    }


}