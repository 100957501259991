<template>
  <v-container class="px-1">
    <div class="d-flex justify-space-between mb-3">
        <b>PABELLONES REGISTRADOS</b>
        <v-btn v-if="$can('create', 'pabellones')" :to="{ name: 'crear-pabellon'}" small elevation="0" color="bgCTM white--text">CREAR NUEVO PABELLÓN</v-btn>
    </div>

    <lista-pabellones />
  </v-container>
</template>

<script>
import ListaPabellones from "../components/ListaPabellones.vue";
export default {
  components: {
    ListaPabellones,
  },
  created(){
    if(!this.$can('read', 'pabellones')){
      this.$router.push({name: "dashboarad"})
    }
  }
};
</script>
