<template>
    <v-row dense class="pa-5">
        <v-col cols="12">
            <div class="font-weight-bold text-subtitle-1">
                Cargar permisos de forma masiva
            </div>
        </v-col>
        <v-col cols="12">
            <div class="teal darken-3 rounded-lg pa-3">
                <label class="font-weight-bold white--text">Cargar plantilla masiva</label>
                <v-file-input label="haga clic para cargar" @click:clear="resetDataMasivo()" @change="readExcel" outlined dense hide-details="auto" class="white"></v-file-input>
            </div>
        </v-col>
        <v-col cols="12">
            <v-btn :loading="loadingPermisosMasivos" @click="savePermisosMasivos()" class="green accent-3 white--text"
                v-if="permisosMasivos.length > 0" elevation="0"><v-icon left>mdi-database-arrow-up</v-icon>CARGAR PERMISOS</v-btn>
            <v-btn text @click="descargarArchivo()" class="blue--text"><v-icon>mdi-microsoft-excel</v-icon>DESCARGAR PLANTILLA</v-btn>
        </v-col>
        <v-col cols="12">
            <v-simple-table fixed-header height="600px">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th style="min-width: 150px;">DNI</th>
                            <th style="min-width: 300px;">APELLIDOS Y NOMBRES</th>
                            <th style="min-width: 100px;">ADMISIÓN</th>
                            <th style="min-width: 200px;">CARRERA</th>
                            <th style="min-width: 100px;">SEDE</th>
                            <th style="min-width: 300px;">MOTIVO</th>
                            <th style="min-width: 100px;">MENOR DE EDAD</th>
                            <th style="min-width: 150px;">FECHA DE SALIDA</th>
                            <th style="min-width: 150px;">HORA DE SALIDA</th>
                            <th style="min-width: 150px;">FECHA DE RETORNO</th>
                            <th style="min-width: 150px;">HORA DE RETORNO</th>
                            <th style="min-width: 300px;">LUGAR A DONDE SE DIRIGE</th>
                            <th style="min-width: 100px;">SALIDA POR SUSPENSIÓN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="perm in permisosMasivos">
                            <td>{{ perm['dni'] }}</td>
                            <td>{{ perm['nombrecompleto'] }}</td>
                            <td>{{ perm['admision'] }}</td>
                            <td>{{ perm['carrera'] }}</td>
                            <td>{{ perm['sede'] }}</td>
                            <td>{{ perm['motivo'] }}</td>
                            <td>{{ perm['es_menor'] }}</td>
                            <td>{{ perm['fecha_salida'] }}</td>
                            <td>{{ perm['hora_salida'] }}</td>
                            <td>{{ perm['fecha_ingreso'] }}</td>
                            <td>{{ perm['hora_ingreso'] }}</td>
                            <td>{{ perm['lugar_dirige'] }}</td>
                            <td>{{ perm['suspension'] }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>

</template>


<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
    name: "permisosMasivos",
    computed: {
        ...mapState('permisos', ['permisosMasivos', 'loadingPermisosMasivos'])
    },

    methods: {
        ...mapMutations('permisos', ['readExcel', 'resetDataMasivo']),
        ...mapActions('permisos', ['savePermisosMasivos']),
        descargarArchivo() {
            const url = `${process.env.BASE_URL}assets/PLANTILLA-MASIVA-PERMISOS.xlsx`;
            const link = document.createElement("a");
            link.href = url;
            link.download = "PLANTILLA-MASIVA-PERMISOS.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

    }
}
</script>