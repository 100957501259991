<template>
    <div>
        <v-card elevation="0">
            <v-card-title class="justify-space-between">
                <span class="text-subtitle-1">Visitas programadas</span>
                <v-btn elevation="0" small class="bgCTM white--text">PROGRAMAR</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers"></v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>


<script>
export default {
    data(){
        return{
            headers:[
                { 
                    text: "avatar",
                    value: "avatar"
                },
            ]
        }
    }
}
</script>