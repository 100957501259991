import utiles from "@/modules/Shared/utiles";
import { socket } from "@/socket-cliente";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";

export default{
    namespaced: true,
    modules:{
        utiles
    },
    state: {
        visitaSalida: {
            tipoVisita: {value: "visitas"},
            dni: "",
            nombrecompleto: "",
            admision: "",
            carrera: "",
            ruc: "",
            entidad: "",
            area: "",
            areaColaborador: "",
            empresaservicios: "",
            motivo: "",
            fecha: "",
            hora_ingreso: "",
            hora_salida: "",
            observacion: "",
            materialesIngresados: [],
            personal: [],
            sedes: "",
            usuario_id: "",
            usuario_salida_id: "",
            evidencia_visita: [],
            comentario_salida: "",
            idpermisosalida: -1,
            tipoSalida: ""
          },
      
          loadingSalida: false,

          tipoSalida:[
            {
              text: "SALIDA PRINCIPAL DE SEDE",
              value: "salida_principal"
            },
            {
              text: "ALIMENTACIÓN",
              value: "alimentacion"
            },
            {
              text: "ASUNTO PERSONAL",
              value: "asunto_personal"
            },
            {
              text: "PERMISO DE SALIDA",
              value: "permiso_de_salida"
            },
          ]
    },
    mutations:{
      setVisitaSalida(state, visita){
            visita.evidencia_visita = visita.evidencia_visita == null ? [] : visita.evidencia_visita;
            state.visitaSalida = Object.assign(state.visitaSalida, visita)

            state.visitaSalida.idpermisosalida = visita.permiso_salida ? visita.permiso_salida.idpermisosalida : null;
            console.log(state.visitaSalida)
        },

      clearFormSalida(state){
        state.visitaSalida.evidencia_visita = []
        state.visitaSalida.comentario_salida = ""
      },

        addEvidenciaVisita(state, event) {
          if (
            event != null &&
            !(
              event.type == "image/jpeg" ||
              event.type == "image/png" ||
              event.type == "image/jpg" ||
              event.type == "application/pdf"
            )
          ) {
            swal({
              title: "ARCHIVO NO PERMITIDO",
              text: "Porfavor solo agregre archivos: png, jpg, pdf",
              icon: "error",
            });
    
            return;
          }
          if (event != null) {
            state.visitaSalida.evidencia_visita.push(event);
          }
        },

        quitarImagenes(state){
          state.visitaSalida.evidencia_visita = []
        }
    },
    actions:{
        //MARCAR SALIDA
    setApiMarcarSalida({commit, dispatch, state}){
        state.loadingSalida = true
        commit('utiles/resetConfigAxios');
        utiles.state.configAxios.url = utiles.state.apiUrl + 'visitas-salida'
        utiles.state.configAxios.method = 'post'
        utiles.state.configAxios.headers = {
          "Content-Type": "multipart/form-data",
        };
        state.visitaSalida.fecha_salida = moment().format('YYYY-MM-DD')
        state.visitaSalida.hora_salida = moment().format('HH:mm:ss')
        state.visitaSalida.usuario_salida_id = store.state.user.iduser;

        const datos = new FormData();
        for (let key in state.visitaSalida) {
          if (
            key == "tipoVisita" 
          ) {
            datos.append(key, JSON.stringify(state.visitaSalida[key]));
            continue;
          }
          if (key === "evidencia_visita") {
            const evidencias = state.visitaSalida[key];
            if (!evidencias) {
              continue;
            }
            for (let i = 0; i < evidencias.length; i++) {
              datos.append("evidencia_visita[]", evidencias[i]);
            }
            continue;
          }
          datos.append(key, state.visitaSalida[key]);
        }
        utiles.state.configAxios.data = datos

        utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
        axios(utiles.state.configAxios)
        .then(resp=>{
          if(resp.status == 200){
            store.dispatch('visitas/getVisitasHoy');
            state.loadingSalida = false
            store.commit('visitas/closeSlider')
            socket.emit('marcarSalida', false);
          }
          })
          .catch(error => {
            console.log(error)
            swal({
              title: "Error!",
              text: "Ocurrio un error al guardar la visita",
              icon: "error",
            })
            state.loadingSalida = false
          })
      }
    }
}