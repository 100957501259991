import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";
import readXlsxFile from 'read-excel-file'
//import moment from "moment";
import router from "@/router";
import moment from 'moment-timezone';


export default{
    namespaced: true,
    modules:{
      utiles,
    },
    state:{
      visita: {
        tipoVisita: {value: "visitas"},
        dni: "",
        nombrecompleto: "",
        admision: "",
        carrera: "",
        ruc: "",
        entidad: "",
        area: "",
        areaColaborador: "",
        empresaservicios: "",
        motivo: "",
        fecha: "",
        hora_ingreso: "",
        hora_salida: "",
        observacion: "",
        materialesIngresados: [],
        personal: [],
        sedes: "",
        usuario_id: "",
        imagen: null,
        evidencia_visita: [],
        programar_visita: false,
        hora_aproximada : "",
        validate: null,
        impedir_ingreso: ""
      },
      visitaDefault: {
        tipoVisita: {value: "visitas"},
        dni: "",
        nombrecompleto: "",
        admision: "",
        carrera: "",
        ruc: "",
        entidad: "",
        area: "",
        areaColaborador: "",
        empresaservicios: "",
        motivo: "",
        fecha: "",
        hora_ingreso: "",
        hora_salida: "",
        observacion: "",
        materialesIngresados: [],
        personal: [],
        sedes: "",
        usuario_id: "",
        imagen: null,
        evidencia_visita: [],
        programar_visita: false,
        hora_aproximada : "",
        validate:null,
        impedir_ingreso: ""
      },
        dataExcel: [],

        loadingProcessData: false,
        loadingAxiosData: false,
        totalRows: 0,
        rowsResueltos: 0,

        informeFinal: []

    },
    mutations:{
      resetVistaMasiva(state){
        state.visita = Object.assign({}, state.visitaDefault)
        state.dataExcel = []
        state.masivoComponent = "SubirMasivoHospedaje"
        state.totalRows = 0
        state.rowsResueltos = 0
        state.loadingProcessData = false
        state.informeFinal= []
      },

      setInformeFinal(state, payload){
        state.informeFinal = payload
      }
    },
    actions:{

      setApiVisitaMasivo({commit, dispatch, state}){
        commit('utiles/resetConfigAxios');
        utiles.state.configAxios.url = utiles.state.apiUrl + 'visita-masiva'
        utiles.state.configAxios.method = 'POST'
        utiles.state.configAxios.data = {dataMasivo : state.dataExcel, usuario_id: store.state.user.iduser}
        utiles.state.configAxios.headers.Authorization = 'Bearer ' + store.state.user.token


        
      
        swal({
          title: "¿Deseas cargar la información?",
          icon: "warning",
          buttons: ['cancel', 'CONFIRMAR'],
          dangerMode: true
        })
        .then(confirmar => {
          if(!confirmar){ return }

          state.loadingAxiosData = true
          axios(utiles.state.configAxios)
          .then(resp => {
            console.log(resp)
            if(resp.status == 200){
              store.dispatch('visitas/getVisitasHoy')
              commit('setInformeFinal', resp.data.informe)
              state.loadingAxiosData = false
              store.state.visitas.componentSlider = "resumenCarga"
            }
          })
          .catch(error => {
            console.log(error)
            state.loadingAxiosData = false
            swal({
              title: "Error al cargar la información.",
              text: "Intentelo de nuevo mas tarde.",
              icon: "error",
            })
          })

        })

        
      },
      readDataFileExcel({ commit, dispatch, state }, file) {
        readXlsxFile(file)
        .then((rows) => {
            dispatch('dataConvertJson', rows)
          })
        .catch(error => {
            console.log(error)
            swal({
                title: "Ha ocurrido un error!"
            })
            state.masivoComponent = "SubirMasivoHospedaje"
        })
    },
      async dataConvertJson({ commit, dispatch, state }, data) {
        state.dataExcel = []
        let excelData = data

        console.log(data)
         /**
         * Validar si es el archivo plantilla correcto
         */
         if(excelData[0][0] != 'Cetemin000*'){
          swal({
            title: "Has insertado otro archivo que no es la plantilla",
            icon: "error"
          })

          return
      }

        state.loadingProcessData = true
        state.totalRows = excelData.length - 1
        for(var i= 5; i <= excelData.length - 1; i++){
            state.visita = Object.assign({}, state.visitaDefault)
            state.visita.index = i - 2

            state.visita.tipoVisita = excelData[i][0]

            //SALTAR BUCLE SI NO HAY DNI

            if(data[i][1] == '' || !data[i][1])
            {
              continue
            }

            let cadenadni = data[i][1].toString() 
            let dnisinespacios = cadenadni.replace(/\s/g, "");
            state.visita.dni = dnisinespacios

          

            //Validacion de dni SIGE Y RENIEC
            var response = null
            if(!response &&  excelData[i][0] == 'VISITAS'){
                response = await dispatch('getDNIReniec')
            }

            if(!response &&  excelData[i][0] == 'ESTUDIANTES'){
              response = await dispatch('getDNISige')
            }

            //si no encuentra datos devuelve un objeto vacio
            if(!response){ response = {}}
            state.visita.nombrecompleto = response.nombrecompleto || 'NO VALIDADO POR RENIEC'


            state.visita.carrera = response.carrera || ''
            state.visita.admision = response.admision || ''
            state.visita.observacion = excelData[i][2]
            state.visita.impedir_ingreso = excelData[i][3]
            state.visita.fecha = excelData[i][4]
            state.visita.hora_aproximada = excelData[i][5]
            state.visita.motivo = excelData[i][6]
            state.visita.sedes = excelData[i][7]

            state.dataExcel.push(state.visita)

            console.log(state.dataExcel)
            state.rowsResueltos = ((i + 1) / excelData.length) * 100;

        }

        state.loadingProcessData = true

        store.state.visitas.componentSlider = "resultadoCargaPlantilla"

       
      },
        async getDNISige({commit, dispatch, state}){
            commit("utiles/resetConfigAxios");
          utiles.state.configAxios.url =
            "https://sige-stage-mw.cetemin.edu.pe/fix/student/search/" +
            state.visita.dni;
          utiles.state.configAxios.headers.Authorization =
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXRlbWluIiwiYXVkIjoid2ViIiwiaWF0IjoxNjYyMDQ0MTM1LCJleHAiOjE2NjMzNDAxMzUsImFrIjoiNWNlNTc2ZDliN2MzZjMxNjYifQ.468NjhnVKfbd_tlgfdiJbLrwXC6jcZo42QP-tAJHgBE";
          
            const response = await axios(utiles.state.configAxios)
            .then((res) => {
            if (res.status == 200) {
                const obj = {};
                obj.nombrecompleto = res.data.result.nomalu;
                obj.carrera = res.data.result.specialtyName;
                var yeradmin = res.data.result.period.substr(0, 4);
                var periodAdmin = res.data.result.period.substr(
                4,
                res.data.result.period.length - 1
                );
                obj.admision = yeradmin + "-" + periodAdmin;
                state.visita.validate = "sige"
                return obj;
                }
            })
            .catch((error) => {
                console.log(error)
    
                return null
            });
    
            return response;
        },
    
        async getDNIReniec({commit, state}){
            commit("utiles/resetConfigAxios");
            utiles.state.configAxios.url = utiles.state.apiUrl+ 'dniconsulta';
            utiles.state.configAxios.method = "POST";
            utiles.state.configAxios.data = { dni : state.visita.dni}
  
           const response = await axios(utiles.state.configAxios)
            .then(resp =>{
                const obj = {}
              if(resp.status == 200){
                obj.nombrecompleto = resp.data.data.data.ap_paterno + " " + resp.data.data.data.ap_materno + " " + resp.data.data.data.nombres;
                state.visita.validate = "reniec"
                return obj;
              }
            })
            .catch(error => {
              console.log(error)
              return {}
            })
    
            return response;
        },
    }
}