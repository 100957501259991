import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class CategoriaDisciplinaService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }

    async get(params)
    {
       const response = await this.axios.get('/categoria-disciplina', {params: params})
       return response.data;
    }  

    async add(data)
    {
        const response = await this.axios.post('/categoria-disciplina', data)
        return response.data
    }

    async update(data)
    {
        const response = await this.axios.put('/categoria-disciplina', data)
        return response.data
    }

    async delete(idcategoria)
    {
        const response = await this.axios.delete('/categoria-disciplina?idcategoria=' + idcategoria)
        return response.data
    }


    

}