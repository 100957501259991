import Module from "./Module.vue";
import DashboardView from "./views/DashboardView.vue";
import ConfiguracionView from "./views/ConfiguracionView.vue";


const moduleRoute = {
  path: "/visitas",
  component: Module,
  children: [
    {
      path: "/",
      component: DashboardView,
      name: "visitas-home",
      meta: {
        requiresAuth: true,
        title: "Visitas",
      },
    },
    {
      path: "configuracion",
      component: ConfiguracionView,
      name: "visitas-configuracion",
      meta: {
        requiresAuth: true,
        title: "Configuracion de Visitas",
      },
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
