<template>
  <div class="d-flex align-center " style="height: 100vh">
    <v-card elevation="0" width="100%" class="transparent">
      <v-card-text class="text-center black--text">
        <v-img :src="require('@/modules/Visitas/assets/excel.gif')" :lazy-src="require('@/modules/Visitas/assets/excel.gif')" width="250" class="mx-auto"></v-img>
        <h2>{{ loadingProcessData ? 'SE ESTÁ CARGANDO LA PLANTILLA' : 'SUBIR PLANTILLA' }}</h2>
        <span>{{ loadingProcessData? 'espere que cargue completamente' : 'carga el archivo excel y el proceso iniciará ' }}</span>

        <v-file-input
          v-if="!loadingProcessData"
          placeholder="cargar archivo .xlxs"
          truncate-length="15"
          hide-details
          outlined
          dense
          class="ma-4 mx-auto blue-grey lighten-4 black--text font-weight-bold rounded-lg pa-2"
          style="width: 400px"
          @change="readDataFileExcel"
        ></v-file-input>

        <v-row v-else class="ma-4">
          <v-col cols="8" class="mx-auto">
            <v-progress-linear
              v-model="rowsResueltos"
              height="30"
              class="rounded-lg"
            >
              <strong>{{ Math.ceil(rowsResueltos) }}%</strong>
            </v-progress-linear>
          </v-col>
        </v-row>

        <v-btn text small color="blue darken-4" @click="descargarArchivo()"
          >DESCARGAR PLANTILLA</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("visitas/visitasMasivas", [
      "loadingProcessData",
      "rowsResueltos",
    ]),
  },
  methods: {
    descargarArchivo() {
      const url = `${process.env.BASE_URL}assets/visitas-masivo.xlsx`;
      const link = document.createElement("a");
      link.href = url;
      link.download = "visitas-masivo.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    ...mapActions("visitas/visitasMasivas", ["readDataFileExcel"]),
  },
};
</script>
