import { render, staticRenderFns } from "./RestorePass.vue?vue&type=template&id=75d85635&scoped=true&"
import script from "./RestorePass.vue?vue&type=script&lang=js&"
export * from "./RestorePass.vue?vue&type=script&lang=js&"
import style0 from "./RestorePass.vue?vue&type=style&index=0&id=75d85635&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75d85635",
  null
  
)

export default component.exports