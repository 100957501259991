<template>
  <v-card elevation="0">
    <v-card-text class="black--text pa-8">
      <v-img :src="require('@/assets/logo-03.svg')" class="mx-auto mb-2" max-width="200"></v-img>
      <div class="text-h5 font-weight-medium text-center">Bienvenido 👋🏻</div>
      <span>Please sign-in to your account and start the adventure</span>
      <v-alert dense v-if="errorLogin.type" :type="errorLogin.type" class="mt-2">
        <small>{{errorLogin.message}}</small>
      </v-alert>
      <v-form class="mt-3" v-model="validateForm">
        <div class="py-3">
          <v-text-field
            type="email"
            outlined
            dense
            hide-details="auto"
            label="Email"
            v-model="loginFileds.email"
            :rules="[rules.required, rules.email]"
          ></v-text-field>
        </div>

        <div class="py-3">
          <v-text-field
            type="password"
            outlined
            dense
            hide-details="auto"
            label="Contraseña"
            v-model="loginFileds.password"
            :rules="[rules.required]"
            @keypress.enter="loginUser()"
          ></v-text-field>
        </div>

        <span
          ><a @click="switchComponent('FormRestorePass')"
            >Recuperar contraseña?</a
          ></span
        >

        <div class="py-3">
          <v-btn
            :disabled="!validateForm"
            @click="loginUser()"
            block
            elevation="0"
            class="bgCTM white--text"
           
          >
            Iniciar Sesión
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  mixins: [Filters],
  name: "FormLogin",

  data() {
    return {
      validateForm: true,
    };
  },
  computed: {
    ...mapState("login", ["loginFileds", "errorLogin"]),
  },

  methods: {
    ...mapMutations("login", ["switchComponent"]),
    ...mapActions("login", ["loginUser"]),
  },
};
</script>
