<template>
<div >
    <v-row style="height: 100vh; " class="bg-residencias">
        <v-col cols="11" lg="5" class="d-flex justify-center align-center mx-auto mx-lg-none"
        :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl? 'white' : ''">
                <div>
                  <component v-bind:is="componentComponent"></component>
                  <v-img width="120" contain class="mx-auto" :src="require('@/assets/LOGO-TIC.svg')"></v-img>
                </div>
                
        </v-col>

        <v-col cols="9" lg="7" class="bg-residencias d-none d-lg-block">

        </v-col>
    </v-row>
</div>
</template>

<script>
import { mapState } from 'vuex'

import FormLogin from "../components/FormLogin.vue"
import FormRestorePass from "../components/FormRestorePass.vue"
export default {
    name: "Login",
    components:{
        FormLogin,
        FormRestorePass
    },

    data(){
        return{}
    },
    computed:{
       ...mapState('login', ['componentComponent'])
    },
   

}
</script>

<style scoped>
.bg-residencias {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: change-background 30s infinite;
}

@keyframes change-background {
  0% {
    background-image: url('~@/assets/residencias.jpg');
  }
  20.33% {
    background-image: url('~@/assets/CAMAS.jpg');
  }
  30.55% {
    background-image: url('~@/assets/ABQ.jpg');
  }
  50.66% {
    background-image: url('~@/assets/IRQ.jpg');
  }

  60.77% {
    background-image: url('~@/assets/COMEDOR.jpg');
  }
  80.88% {
    background-image: url('~@/assets/FCHB.jpg');
  }
  90.99% {
    background-image: url('~@/assets/AREQUIPA-HOSPEDAJE.jpg');
  }
  100% {
     background-image: url('~@/assets/residencias.jpg');
  }
}
</style>