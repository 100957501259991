<template>
    <div class="d-flex align-center">
        <v-card elevation="0" height="100%" width="100%">
            <v-card-title class="blue white--text">RESUMEN DE CARGA MASIVA</v-card-title>
            <v-card-text class="text-center black--text pa-4">
                    <h2 >Se registró {{ informeFinal.registradas }}/{{ informeFinal.cargas_totales }} visita(as) en total</h2>
                    <div>
                        <span>Informe Final</span>

                        <div class="mt-3 text-left font-weight-bold">{{ informeFinal.registradas }} VISITA(AS) REGISTRADAS</div>
                        <div :style="informeFinal.detalle_success.length > 10?'height: 300px; ':''" style="overflow-x: auto;">
                            <div class="rounded-lg blue-grey lighten-4 my-2 pa-2 black--text font-weight-bold text-left" v-for="(info, i) in informeFinal.detalle_success" :key="i">
                               <v-avatar size="25" class="green accent-3 white--text"><span>{{ i + 1 }}</span></v-avatar> {{  info }}
                            </div>
                        </div>

                        <div class="mt-4 text-left font-weight-bold">{{ informeFinal.no_registradas }} VISITA(AS) NO REGISTRADAS </div>
                        <div :style="informeFinal.no_registradas.length > 10?'height: 300px; ':''" style="overflow-x: auto;">
                            <div class="rounded-lg blue-grey lighten-4 my-2 pa-2 black--text font-weight-bold text-left" v-for="(info, i) in informeFinal.detalle_error" :key="i">
                               <v-avatar size="25" class="red darken-4 white--text"><span>{{ i + 1 }}</span></v-avatar> {{  info }}
                            </div>
                        </div>
                    </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('visitas/visitasMasivas',['informeFinal'])
    }
}
</script>