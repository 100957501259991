import { ref } from "vue"
import store from "@/store"
import AtencionPsicologicaService from "../services/AtencionPsicologicaService"
import PacientePsicologia from "./PacientePsicologia"

import DialogoSuccessComponent from "../components/DialogsConfirm/DialogoSuccessComponent.vue"
import DialogoLoadingComponent from "../components/DialogsConfirm/DialogoLoadingComponent.vue"
import swal from "sweetalert"
import PacienteService from "../services/PacientePsicologiaService"



const serviceAP = new AtencionPsicologicaService
const ppservice = new PacienteService
export default{
    namespaced:true,
    state:{
        seletedPaciente: null,
        filterAtenciondiaria: {
            area_atencion: "",
            nivel_alerta: "",
            tipo_atencion: "",
            motivo: "",
            nombreDni: "",
            sede: "",
            from: "",
            to: ""
        },
        filterAtenciondiariaDefault: {
            area_atencion: "",
            nivel_alerta: "",
            tipo_atencion: "",
            motivo: "",
            nombreDni: "",
            sede: "",
            from: "",
            to: ""
        },
        atencionPsicologica:{
            area_atencion: "psicopedagogica",
            tipo_atencion: "Acompañamiento",
            nivel_alerta: "",
            fecha: "",
            sede: "",
            motivo: "",
            descripcion:"",
            condicion_psico: "",
            paciente_id: "",
            num_sesion_total: 1,
            num_sesion_actual: 1
        },
        atencionPsicologicaDefault:{
            area_atencion: "psicopedagogica",
            tipo_atencion: "Acompañamiento",
            nivel_alerta: "",
            fecha: "",
            sede: "",
            motivo: "",
            descripcion:"",
            condicion_psico: "",
            paciente_id: "",
            num_sesion_total: 1,
            num_sesion_actual: 1
        },

        cambioCondicion:{
            motivo: "",
            new_condicion_psicopedagogica: ""
        },

        cambioCondicionDefault:{
            motivo: "",
            new_condicion_psicopedagogica: ""
        },

        loadingSaveAtencion: false,
        loadingListAtenciones: false,
        listaAtenciones: []
    },
    mutations:{
        seleccionarPaciente(state, payload){
            state.seletedPaciente = Object.assign({}, payload)
        },

        resetSeleccionarPaciente(state)
        {
            state.seletedPaciente = null
        },

        setAtencionPsicologica(state, payload)
        {
            state.atencionPsicologica = Object.assign({}, payload)
        },

        setAtencionesPsicolgicas(state, payload)
        {
            state.listaAtenciones = payload
        },

        setAtencionPsicologicaEditar(state, payload)
        {
            store.commit('silderDerechaComponent/closeSilder')
            state.atencionPsicologica = Object.assign({}, payload)
            state.atencionPsicologica.nivel_alerta = parseInt(payload.nivel_alerta)
            store.commit('psicologia/PacientePsicologia/setPacientePsico', payload.paciente_psicologia)
        },

        resetFormAntecionPsicologica(state)
        {
            state.atencionPsicologica = Object.assign({}, state.atencionPsicologicaDefault)
            PacientePsicologia.state.pacientePsicologia = Object.assign({}, PacientePsicologia.state.pacientePsicologiaDefault)
        },

        resetFilterAtencionesDiarias(state)
        {
            state.filterAtenciondiaria = Object.assign({}, state.filterAtenciondiariaDefault)
        },

        resetCambioAtencion(state)
        {
            state.cambioCondicion = Object.assign({}, state.cambioCondicionDefault)
        }

    },
    actions:{


        async getAllAtenciones({state, commit, dispatch})
        {
            state.loadingListAtenciones = true
            try
            {
                const data = await  serviceAP.getAll(state.filterAtencionPsicologica)
                commit('setAtencionesPsicolgicas', data)
                state.loadingListAtenciones = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingListAtenciones = false

            }
        },


        async getAtencionesDiarias({state, commit})
        {
            state.loadingListAtenciones = true
            try
            {
                const data = await  serviceAP.getAllDays(state.filterAtenciondiaria)
                console.log(state.filterAtenciondiaria)
                commit('setAtencionesPsicolgicas', data)
                state.loadingListAtenciones = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingListAtenciones = false
            }
        },


        async showAtencionPsicologica({state, commit}, id_atencion_psicologica)
        {

            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false, 
                closeOnClickOutside: false, 
                closeOnEsc: false, 
                allowOutsideClick: false 
              });
            try {
                const data = await serviceAP.show({id_atencion_psicologica: id_atencion_psicologica})
                commit('setAtencionPsicologicaEditar', data)
               
                swal.close()
            }
            catch (error) {
                console.log(error)
                swal({
                    title: "No se encontró la atención medica",
                    text: "Por favor, vuelva a intentar.",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "Aceptar",
                        value: true,
                        visible: true,
                        className: "btn-primary",
                        closeModal: true,
                      },
                    },
                  }).then((value) => {
                    if (value) {
                      router.push({name: 'historiasPacientesPsicologia'})
                    }
                  });
            }
        },

        async guardarAtencion({state, commit, dispatch})
        {
            state.loadingSaveAtencion = true
            try
            { 
                const componentLoading = ref(DialogoLoadingComponent)
                store.commit('psicologia/DialogosAlert/openDialogAlert', {open: true, component:componentLoading })


                state.atencionPsicologica['paciente_id'] = PacientePsicologia.state.pacientePsicologia.idpaciente;
                const data = await  serviceAP.add({atencion_psicologica: state.atencionPsicologica})
                dispatch('getAllAtenciones')
                state.loadingSaveAtencion = false

                const component = ref(DialogoSuccessComponent)
                store.commit('psicologia/DialogosAlert/openDialogAlert', {open: true, component:component })

                commit('resetFormAntecionPsicologica')
            }
            catch(error)
            {
                console.log(error)
                state.loadingSaveAtencion = false
            }
        },


        async updateAtencion({state, commit, dispatch})
        {
            state.loadingSaveAtencion = true
            try
            {
                const componentLoading = ref(DialogoLoadingComponent)
                store.commit('psicologia/DialogosAlert/openDialogAlert', {open: true, component:componentLoading })


                const data = await  serviceAP.update(state.atencionPsicologica)
                dispatch('getAllAtenciones')
                state.loadingSaveAtencion = false

                const component = ref(DialogoSuccessComponent)
                store.commit('psicologia/DialogosAlert/openDialogAlert', {open: true, component:component })

                commit('resetFormAntecionPsicologica')
                
            }
            catch(error)
            {
                console.log(error)
                state.loadingSaveAtencion = false
            }
        },


        async deleteAtencion({state, commit, dispatch}, id_atencion_psicologica){
            
            try{
                const confirm = await swal({
                    title: "Atención",
                    text: "¿Deseas eliminar la atención?",
                    icon: "warning",
                    buttons: ['CANCELAR', 'ELIMINAR'],
                    dangerMode: true
                })

                if(confirm){
                    swal({
                        title: "Cargando...",
                        text: "Por favor, espere un momento.",
                        icon: "info",
                        button: false, 
                        closeOnClickOutside: false, 
                        closeOnEsc: false, 
                        allowOutsideClick: false 
                      });
                    const data = await serviceAP.delete(id_atencion_psicologica)
                    dispatch('getAtencionesDiarias')
                    swal({
                        title: "Correcto!",
                        text: "Se eliminó correctamente",
                        icon: "success"
                    })
                }
            }
            catch(error){
                console.log(error)
                swal({
                    title: "Error!",
                    text: "Ha ocurrido un error",
                    icon: "error"
                })
            }
        },


        async generatePDF({state}, id_atencion_psicologica)
        {
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false,  
                closeOnClickOutside: false, 
                closeOnEsc: false, 
                allowOutsideClick: false 
              });
            try
            {
                const data = await  serviceAP.generarPDF({id_atencion_psicologica: id_atencion_psicologica})
                swal({
                    title: "Se generó el pdf",
                    icon: "success"
                })
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ha ocurrido un error",
                    icon: "error"
                })
            }
        },


        async generateExcel({state})
        {
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false,  
                closeOnClickOutside: false, 
                closeOnEsc: false, 
                allowOutsideClick: false 
              });
            try
            {
                const data = await  serviceAP.getReporteExcel(state.filterAtenciondiaria)
                swal({
                    title: "Se generó el excel",
                    icon: "success"
                })
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ha ocurrido un error",
                    icon: "error"
                })
            }
        },

        async cambioCondicionPsicopedagogica({state, commit, dispatch})
        {
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false,  
                closeOnClickOutside: false, 
                closeOnEsc: false, 
                allowOutsideClick: false 
              });
            try
            {
                const data = await ppservice.cambioPacientePsicopedagogica({
                    idpaciente: state.seletedPaciente.idpaciente, 
                    new_condicion_psicopedagogica : state.cambioCondicion.new_condicion_psicopedagogica,
                    motivo_cambio : state.cambioCondicion.motivo
                }) 

                swal({
                    title: "Se cambio la condicion del paciente",
                    icon: "success"
                })

                store.dispatch('psicologia/PacientePsicologia/getAllPacientesePsico')
                commit('seleccionarPaciente', data)
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ha ocurrido un error",
                    icon: "error"
                })
            }
        }
    }
}