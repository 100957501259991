export default{
    namespaced:true,
    state: {
        silderderecha:{
            open: false,
            width: 500,
            component: null,
            props: null
        },
        silderderechaDefault:{
            open: false,
            width: 500,
            component: null,
            props: null
        }
    },

    mutations: {
        openSilder(state, payload = silderderechaDefault){
            state.silderderecha = Object.assign({}, payload)
            console.log(state.silderderecha)
        },
        closeSilder(state){
            state.silderderecha.open = false
        }
    }
}