<template>
    <v-container>
        <v-row class="px-0">
            <v-col cols="12" md="10" class="mx-auto px-0">
                <div class="d-flex justify-space-between">
                    <h4>Usuarios Registrados</h4>
                    <v-btn @click="setDialogRegister()" small elevation="0" class="bgCTM white--text">REGISTRAR USUARIO</v-btn>
                </div>
                <ListaUsuarios  class="mt-3"/>
                <DialogFormRegisterUserVue/>
            </v-col>
        </v-row> 
    </v-container>
</template>


<script>
import { mapMutations } from 'vuex'
import DialogFormRegisterUserVue from '../components/DialogFormRegisterUser.vue'
import ListaUsuarios from '../components/ListaUsuarios.vue'
export default {
    components:{
        ListaUsuarios,
        DialogFormRegisterUserVue
    },

    methods:{
        ...mapMutations('usuarios', ['setDialogRegister'])
    },

    created(){
        if(!this.$can('create', 'usuarios')){
            this.$router.push({name: 'dashboard'})
        }
    }
}
</script>