<template>
    <div>


        <v-dialog v-model="onDialogConfirm" width="500" >
            <v-card class="pa-9 teal accent-3">
                <v-card-text class="teal--text text--darken-4">
                    <div class="rounded-circle pa-3 white d-flex align-center justify-center mx-auto"
                        style="width: 160px; height: 160px;">
                        <v-img :src="require('../assets/avion-de-papel.png')" width="150"></v-img>
                    </div>
                    <div class="text-h6 font-weight-black text-center pa-2 text-uppercase">Se envió el permiso correctamente!</div>
                    <div class="text-center text-subtitle-1 font-weight-medium">Nuevo permiso agregado</div>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="loadingSavePermiso" width="500" persistent>
            <v-card class="pa-9 blue lighten-2">
                <v-card-text class="white--text">
                    <div class="rounded-circle pa-3 white d-flex align-center justify-center mx-auto"
                        style="width: 160px; height: 160px;">
                        <v-img :src="require('../assets/cohete.png')" width="150"></v-img>
                    </div>
                    <div class="text-h6 font-weight-black text-center pa-2 text-uppercase">Se esta enviando el permiso</div>
                    <div class="text-center text-subtitle-1 font-weight-medium">Porfavor espere un momento</div>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="loadingPeticion" width="500" persistent>
            <v-card class="pa-9 cyan darken-1">
                <v-card-text class="white--text">
                    <div class="rounded-circle pa-3 white d-flex align-center justify-center mx-auto"
                        style="width: 160px; height: 160px;">
                        <v-img :src="require('../assets/esperar.png')" width="150"></v-img>
                    </div>
                    <div class="text-h6 font-weight-black text-center pa-2 text-uppercase">Cargando la petición</div>
                    <div class="text-center text-subtitle-1 font-weight-medium">Porfavor espere un momento</div>
                </v-card-text>
            </v-card>
        </v-dialog>



        <v-dialog v-model="permisoExistente" width="600" persistent>
            <v-card class="pa-9 yellow lighten-4">
                <v-card-text v-if="permisoExistenteResponse">
                    <div class="rounded-circle pa-3 white d-flex align-center justify-center mx-auto"
                        style="width: 160px; height: 160px;">
                        <v-img :src="require('../assets/megaphone.png')" width="150"></v-img>
                    </div>
                    <div class="text-h6 font-weight-black text-center pa-2 text-uppercase">Ya existe un permiso de salida
                        activo para el estudiante</div>
                    <div class="text-center text-subtitle-1 font-weight-medium">Probablemente el agente no registro su
                        salida o retorno a sede</div>
                    <v-divider></v-divider>
                    <div class="yellow lighten-5 pa-2 rounded-lg" >
                        <div class="text-subtitle-2 font-weight-medium text-center pa-2">{{ permisoExistenteResponse.dni + '- ' + permisoExistenteResponse.nombrecompleto }}</div>
                        <v-simple-table class="yellow lighten-5" dense>
                            <tbody>
                                <tr>
                                    <td>Motivo</td>
                                    <td>{{ permisoExistenteResponse.motivo }}</td>
                                </tr>
                                <tr>
                                    <td>Fecha salida</td>
                                    <td>{{ formatFecha(permisoExistenteResponse.fecha_salida + ' ' +
                                        permisoExistenteResponse.hora_salida) }}</td>
                                </tr>
                                <tr>
                                    <td>Fecha ingreso</td>
                                    <td>{{ formatFecha(permisoExistenteResponse.fecha_ingreso + ' ' +
                                        permisoExistenteResponse.hora_ingreso) }}</td>
                                </tr>
                                <tr>
                                    <td>Estado</td>
                                    <td>{{ permisoExistenteResponse.estado }}</td>
                                </tr>
                                <tr>
                                    <td>Autorizado por:</td>
                                    <td>{{ permisoExistenteResponse.users.nombres + ' ' +
                                        permisoExistenteResponse.users.apellidos }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-center" v-if="permisoExistenteResponse">
                    <v-btn @click="closeDialogPermisoPersistente()" large elevation="0" class="blue-grey lighten-5 blue-grey--text text--darken-4 px-6">CERRAR
                        AVISO</v-btn>
                    <v-btn
                        v-if="permisoExistenteResponse.estado == 'enviado' && fechaRetornoVencida(permisoExistenteResponse.fecha_ingreso + ' ' + permisoExistenteResponse.hora_ingreso) == 'no-vencida'"
                        @click="setAnularPermiso(permisoExistenteResponse.idpermisosalida)" large elevation="0"
                        class="blue-grey darken-4 white--text px-6"><v-icon left>mdi-stop</v-icon>ANULAR PERMISO</v-btn>

                    <v-btn
                    v-if="(permisoExistenteResponse.estado == 'enviado' || permisoExistenteResponse.estado == 'iniciado') && fechaRetornoVencida(permisoExistenteResponse.fecha_ingreso + ' ' + permisoExistenteResponse.hora_ingreso) == 'vencida'"
                        @click="setFinalizarPermiso(permisoExistenteResponse.idpermisosalida)" large elevation="0"
                        class="blue-grey darken-4 white--text px-6"><v-icon left>mdi-stop</v-icon>FINALIZAR PERMISO</v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="confirmClosePermisoExist" width="500">
            <v-card  class="teal accent-2 pa-9">
                <v-card-text>   
                    <div class="rounded-circle pa-3 white d-flex align-center justify-center mx-auto"
                        style="width: 160px; height: 160px;">
                        <v-img :src="require('../assets/confirmar.png')" width="150"></v-img>
                    </div>
                    <div class="text-h6 font-weight-black text-center pa-2 text-uppercase">PERMISO TERMINADO CORRECTAMENTE!</div>
                    <div class="text-center text-subtitle-1 font-weight-medium">Se anulo o finalizó el permiso, presione continuar.</div>

                    <v-btn @click="closeConfirmDialogAnulPermiso()" large elevation="0" block class="white lighten-5 blue-grey--text text--darken-4 px-6 mt-2">CONTINUAR</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import Filters from '@/modules/Shared/Filters';
import { mapState, mapActions, mapMutations } from 'vuex';


export default {
    mixins: [Filters],
    data(){
        return{
            test: true
        }
    },
    computed: {
        ...mapState('permisos', ['permisoExistente', 'permisoExistenteResponse', 'loadingSavePermiso', 'confirmClosePermisoExist', 'confirmacionCreatePermiso', 'loadingPeticion']),

        onDialogConfirm:{
            get(){
                return this.confirmacionCreatePermiso
            },
            set(){
                this.closeDialogConfirmCreatePermiso()
            }
        },
    },

    methods: {
        ...mapActions('permisos', ["setFinalizarPermiso", "setAnularPermiso"]),
        ...mapMutations('permisos', ['closeConfirmDialogAnulPermiso', 'closeDialogPermisoPersistente', 'closeDialogConfirmCreatePermiso']),
        
        fechaRetornoVencida(fecha_retorno) {
            const fechaActual = moment();
            const fechaIngresada = moment(fecha_retorno);
            if (fecha_retorno) {

                if (fechaIngresada.isAfter(fechaActual)) {
                    // Fecha de retorno aun no vence
                    return 'no-vencida'

                } else if (fechaIngresada.isSameOrBefore(fechaActual)) {
                    // fecha de retorno vencida
                    return 'vencida'
                }
            }
        }
    }
}
</script>