<template>
    <v-container>
        <v-card elevation="0">
        <v-card-text class="black--text">
            <div class="text-h5 font-weight-bold">Se cargaron los permisos</div>
            <div class="text-subtitle-2">Revisar los resultados</div>
        </v-card-text>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>PERMISOS REGISTRADOS</th>
                            <th>NO REGISTRADOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div v-for="reg in resultadoPermisosMasivos.permisos_registrados" class="pa-2">
                                    <div class="font-weight-bold text-subtitle-2 green--text">{{ reg.dni + ' - ' + reg.nombrecompleto}}</div>
                                    <div>{{ reg.message }}</div>
                                </div>
                                <div v-if="resultadoPermisosMasivos.permisos_registrados.length == 0">
                                    <div>No hay permisos registrados</div>
                                </div>
                            </td>
                            <td>
                                <div v-for="reg in resultadoPermisosMasivos.permisos_no_registrados" class="pa-2">
                                    <div class="font-weight-bold text-subtitle-2 red--text">{{ reg.dni + ' - ' + reg.nombrecompleto}}</div>
                                    <div>{{ reg.message }}</div>
                                </div>
                                <div v-if="resultadoPermisosMasivos.permisos_no_registrados.length == 0">
                                    <div>No hay permisos</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';


export default{
    
    computed:{
        ...mapState('permisos', ['resultadoPermisosMasivos'])
    }
}
</script>