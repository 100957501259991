<template>
  <v-form style="width: 100%" v-model="validateForm">
    <div class="font-weight-bold text-center black--text mb-3">
      <h4>REPORTAR PROBLEMA CON LA CAMA</h4>
    </div>

    <v-row class="black--text font-weight-bold">
      <v-col cols="12" v-if="reporte.isInoperativa">
        <v-alert
      icon="mdi-shield-lock-outline"
      dense
      text
      type="warning"
    >
        <span>Antes debe reubicar al hospedado en otra cama</span>
    </v-alert>
      </v-col>
      <v-col cols="12" md="6">
        <div>
          <label for="">Prioridad</label>
          <v-select
            outlined
            hide-details="auto"
            placeholder="Seleccione una prioridad"
            dense
            :items="['BAJA', 'MEDIA', 'ALTA', 'CRÍTICA']"
            v-model="reporte.prioridad"
            :rules="[rules.required]"
          ></v-select>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div>
          <label for="">¿La cama estará inoperativa?:</label>
          <v-radio-group row dense hide-details="auto" class="mt-1" v-model="reporte.isInoperativa" >
            <v-radio label="SI" :value="true"></v-radio>
            <v-radio label="NO" :value="false"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="12">
        <div>
          <label for="">Asunto:</label>
          <!--
          <v-text-field dense hide-details="auto" outlined placeholder="Titulo del reporte" v-model="reporte.titulo_reporte" :rules="[rules.required]"></v-text-field>
          -->
          <v-select dense outlined :items="listaDeAsuntosReportes" hide-details="auto" placeholder="Selecciona" v-model="reporte.titulo_reporte" :rules="[rules.required]"></v-select>
        </div> 
      </v-col>
      <v-col cols="12">
        <div>
          <label for="">Detalle del reporte: (Opcional)</label>
          <Editor
            api-key="1r2zuk2tv5ekuxqpas860aoxogwsyzonb7k871tbr4lmeogz"
            :init="{
              plugins: 'lists link paste',
              toolbar:
                'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ',
            }"
            v-model="reporte.detalle_reporte"
            :rules="[rules.required]"
          ></Editor>
        </div>
      </v-col>
      <v-col cols="6">
        <div>
          <label for="">Evidencia:</label>
          <v-file-input
            hide-details="auto"
            dense
            outlined
            truncate-length="15"
            placeholder="JPG, PNG, JPEG, PDF"
            @change="addEvidencia"
            type="file"
            accept="image/*,.pdf"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0" color="blue-grey lighten-4">
            <v-card-text class="d-flex justify-center align-center">
                <h5 class="font-weight-bold" v-if="!fileDetails.urlPreviewFile">FILE PREVIEW</h5>
                <v-img  
                v-if="fileDetails.urlPreviewFile && fileDetails.fileType != 'application/pdf'"
                :src="fileDetails.urlPreviewFile" 
                aspect-ratio="1.4"
                contain></v-img>
                <iframe
                height="380px"
                width="100%"
                v-if="fileDetails.urlPreviewFile && fileDetails.fileType == 'application/pdf'"
                :src="fileDetails.urlPreviewFile"
                frameborder="0"
              ></iframe>
             
            </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" >
        <v-btn :disabled="!validateForm" @click="setReporteCamaAxios(idpabellon)" color="indigo white--text" elevation="0" block>REPORTAR</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { mapActions, mapMutations, mapState } from 'vuex';
import Filters from "@/modules/Shared/Filters"
export default {
  mixins:[
    Filters
  ],
  components: {
    Editor,
  },
  data () {
    return{
      idpabellon: 0,
      validateForm : true
    }
  },
  watch:{
    'reporte.detalle_reporte' :(newval) => {
       if(newval.length > 30){
            
         }
    } 
  },
  computed:{
    ...mapState('hospedaje/reporteCama',['reporte', 'fileDetails', 'listaDeAsuntosReportes']),
    ...mapState('hospedaje', ['camaSeleccionada'])
  },

  methods:{
    ...mapMutations('hospedaje/reporteCama', ['addEvidencia']),
    ...mapActions('hospedaje/reporteCama', ['setReporteCamaAxios'])
  },
  mounted(){
    if(this.$route.params.id){
      this.idpabellon = this.$route.params.id
    }
  }
};
</script>
