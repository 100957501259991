<template>
  <v-container style="height: 80vh" class="d-flex justify-center align-center">
    <v-card elevation="0" height="500" width="100%">
      <v-card-text
        class="text-center black--text d-flex justify-center align-center"
      >
        <div>
          <v-img
            class="mx-auto"
            :src="require('@/assets/cerebro.gif')"
            contain
            width="200"
          ></v-img>
          <h2 class="pa-2">CARGANDO INFORMACIÓN...</h2>
          <span>No cierre, ni actualice la página</span>
          <v-progress-linear
            v-model="rowsResueltos"
            height="40"
            class="mt-5 rounded-xl mx-auto"
            style="width: 500px"
          >
            <strong>{{ Math.ceil(rowsResueltos) }}%</strong>
          </v-progress-linear>
          <span>Validando DNI con sige / reniec</span>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      knowledge: 33,
    };
  },

  computed:{
    ...mapState('hospedaje/hospedarMasivos',['rowsResueltos'])
  }
};
</script>
