<template>
  <div>
    <v-list-item v-if="pabellon.nombre">
      <v-list-item-icon>
        <v-avatar size="35" rounded="lg" color="bgCTM"
          ><v-icon size="20" color="white">mdi-home</v-icon></v-avatar
        >
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="line-height: 1rem !important">
          <span class="font-weight-bold text-uppercase">{{
            pabellon.nombre + " (" + pabellon.piso + ")"
          }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <span>{{ pabellon.sede }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action class="flex-row justify-space-between">

        <v-avatar
        class="flex-column mr-2 grey darken-4 white--text"
        size="50"
        rounded
        >
          <h2 class="font-weight-bold">{{ pabellon.totalReportes }}</h2>
          <small class="d-block">REPORT</small>
        </v-avatar>

        <v-avatar
          class="flex-column mr-2"
          size="50"
          :class="pabellon.genero == 'mujeres'? 'pink darken-1' : 'indigo darken-1'"
          rounded
        >
            <v-icon size="35" class="white--text">{{pabellon.genero == 'mujeres'? 'mdi-human-female':'mdi-human-male'}}</v-icon>
        </v-avatar>
        
        <v-avatar
          class="flex-column mr-2"
          size="50"
          color="amber darken-3 white--text"
          rounded
        >
          <h2 class="font-weight-bold">{{ pabellon.capacidad_total }}</h2>
          <small class="d-block">TOTAL</small>
        </v-avatar>

        <v-avatar
          class="flex-column mr-2"
          size="50"
          color="blue darken-1 white--text"
          rounded
        >
          <h2 class="font-weight-bold">{{ pabellon.ocupadas }}</h2>
          <small class="d-block">OCUP.</small>
        </v-avatar>

        <v-avatar
          class="flex-column mr-2"
          size="50"
          color="green accent-4 white--text"
          rounded
        >
          <h2 class="font-weight-bold">{{ pabellon.disponibles }}</h2>
          <small class="d-block">DISP.</small>
        </v-avatar>
      </v-list-item-action>
    </v-list-item>
    <v-row v-else>
      <v-col cols="10">
        <v-skeleton-loader
          type="list-item-avatar-two-line"
          max-width="50%"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="2" class="d-flex">
        <v-skeleton-loader
          type="avatar"
          class="mr-2 rounded-lg"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="avatar"
          class="mr-2"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="avatar"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    pabellon: {
      type: Object,
      required: true,
    },
  },
};
</script>
