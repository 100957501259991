<template>
  <div>
    <div class="d-flex justify-space-between black--text">
      <div class="text-h6">Lista de Hospedados</div>
    </div>

    <v-row>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-space-between black--text ml-auto"
      >
        <v-text-field
          @keypress.enter="
            getHosepdadosAxios({
              idpabellon: idpabellon,
              nombreDni: filter.nombreDni,
            })
          "
          v-model="filter.nombreDni"
          dense
          outlined
          label="Buscar por nombre / dni"
          hide-details="auto"
          class="mr-2"
        >
          <template v-slot:append>
            <v-btn
              :loading="loadingFilter"
              @click="
                getHosepdadosAxios({
                  idpabellon: idpabellon,
                  nombreDni: filter.nombreDni,
                })
              "
              icon
              small
              class="indigo white--text"
            >
              <v-icon>mdi-magnify</v-icon></v-btn
            >
          </template>
        </v-text-field>
        <v-btn
          @click="getReporteHospedados(idpabellon)"
          class="red darken-4 white--text rounded-lg mr-2"
          icon
          elevation="0"
          ><v-icon>mdi-file-pdf-box</v-icon></v-btn
        >
        <v-btn
          @click="getReporteHospedadosExcel(idpabellon)"
          class="green accent-4 white--text rounded-lg"
          icon
          elevation="0"
          ><v-icon>mdi-microsoft-excel</v-icon></v-btn
        >
      </v-col>

      <v-col cols="12" md="6" class="d-flex justify-end">
        <!--
        <v-chip class="blue white--text mr-2" label small
          ><v-icon small class="white--text" left>mdi-swap-horizontal</v-icon>
          Cambiar de cama</v-chip
        >
        <v-chip class="red white--text mr-2" label small
          ><v-icon small class="white--text" left>mdi-run</v-icon>Desocupar
          cama</v-chip
        >
        <v-chip class="amber darken-3 white--text" label small
          ><v-icon small class="white--text" left>mdi-pencil</v-icon>Editar
          datos</v-chip
        >-->
        <v-btn :to="{name :'hospedarMasivo', params: {id: idpabellon }}" class="blue white--text mr-2"  elevation="0"><v-icon small left color="white">mdi-expand-all</v-icon>Hospedaje masivo</v-btn>
        <v-btn @click="setDialogDesocupar()" class="red white--text"  elevation="0"><v-icon small left color="white">mdi-run-fast</v-icon>Desocupar camas</v-btn>
        <DialogDesocuparMasivo />
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="lista"
      :loading="loadingLista"
      :items-per-page="60"
      disable-pagination
      fixed-header
      height="500px"
    >
      <template v-slot:item.index="{ index }">
        <b> {{ index + 1 }}</b>
      </template>
      <template v-slot:item.nombrecompleto="{ item }">
        <div class="font-weight-bold text-capitalize">
          {{ item.nombrecompleto }}
        </div>
        <small v-if="item.tipo == 'estudiante'">{{ item.carrera }}</small>
        <small v-if="item.tipo == 'colaborador'">{{
          item.jefatura + " -  " + item.cargo
        }}</small>
      </template>

      <template v-slot:item.tipo="{ item }">
        <v-chip
          class="text-uppercase font-weight-bold"
          :class="
            item.tipo == 'estudiante' ? 'lime accent-2' : 'blue lighten-4'
          "
          small
          >{{ reduceText(item.tipo, 3) }}</v-chip
        >
      </template>

      <template v-slot:item.fecha_ingreso="{ item }">
        {{ formatFechaSimple(item.fecha_ingreso) }}
      </template>

      <template v-slot:item.fecha_salida="{ item }">
        {{ formatFechaSimple(item.fecha_salida) }}
      </template>

      <template v-slot:item.indexcama="{ item }">
        <v-avatar rounded class="blue darken-1 white--text" size="30">
          <h4>{{ item.indexcama }}</h4>
        </v-avatar>
      </template>

      <template v-slot:item.usuario="{ item }">
        <v-chip small class="font-weight-bold">
          <v-avatar class="bgCTM white--text" size="20" left>
            <v-icon color="white" small>mdi-account</v-icon>
          </v-avatar>
         <small> {{ formatUserName(item.usuario.nombres) }} </small>
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex">
          <v-btn
            v-if="$can('update', 'hospedaje')"
            @click="
              cambiarCamaHospedado({ idpabellon: idpabellon, hospedado: item })
            "
            small
            class="blue white--text mr-2"
            icon
            elevation="0"
            ><v-icon>mdi-swap-horizontal</v-icon></v-btn
          >
          <v-btn
            v-if="$can('update', 'hospedaje')"
            small
            @click="
              setDesalojoHuespedAxios({
                idpabellon: idpabellon,
                idhuesped: item.idhuesped,
              })
            "
            class="red white--text mr-2"
            icon
            elevation="0 "
            ><v-icon>mdi-run</v-icon></v-btn
          >
          <v-btn
            v-if="$can('update', 'hospedaje')"
            small
            @click="editHospedado(item)"
            class="amber darken-3 white--text"
            icon
            elevation="0 "
            ><v-icon small>mdi-pencil</v-icon></v-btn
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Filters from "@/modules/Shared/Filters";
import DialogDesocuparMasivo from './HospedajeDesocuparMasivos/DialogDesocuparMasivo.vue';

export default {
  mixins: [Filters],
  components:{
    DialogDesocuparMasivo
  },
  props: {
    lista: {
      type: Array,
    },
  },
  data: () => ({
    headers: [
      {
        text: "N°",
        value: "index",
        width: "10px",
        sortable: false,
      },
      {
        text: "DNI",
        value: "dni",
        width: "20px",
        sortable: false,
      },
      {
        text: "Apellidos y Nombres",
        value: "nombrecompleto",
        width: "220px",
        sortable: false,
      },
      {
        text: "admision",
        value: "admision",
        width: "60px",
        sortable: false,
      },
      {
        text: "tipo",
        value: "tipo",
        width: "60px",
        sortable: false,
      },
      {
        text: "N° cama",
        value: "indexcama",
        width: "60px",
        sortable: false,
      },
      {
        text: "Fecha Ingreso",
        value: "fecha_ingreso",
        width: "80px",
        sortable: false,
      },
      {
        text: "Fecha Salida",
        value: "fecha_salida",
        width: "80px",
        sortable: false,
      },

      {
        text: "Hospedador",
        value: "usuario",
        width: "80px",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        width: "60px",
        sortable: false,
      },
    ],
    idpabellon: "",
  }),

  computed: {
    ...mapState("hospedaje", ["loadingLista", "loadingFilter", "filter"]),
  },
  methods: {
    ...mapActions("hospedaje", [
      "getHosepdadosAxios",
      "setDesalojoHuespedAxios",
    ]),
    ...mapActions("hospedaje/reportesHospedados", ["getReporteHospedados", "getReporteHospedadosExcel"]),
    ...mapMutations("hospedaje", ["editHospedado", "cambiarCamaHospedado"]),
    ...mapMutations('hospedaje/desocuparMasivos', ['setDialogDesocupar'])
  },
  mounted() {
    if (this.$route.params.id) {
      this.idpabellon = this.$route.params.id;
    }
  },
};
</script>
