<template>
  <div class="d-flex rounded-lg blue-grey lighten-4" style="width:100%; position: relative;">
    <div class="d-flex pa-5 plano lineasDiagonales justify-center align-center" style="width: 40px;" >
            {{ ubicacionBano == 'bañoderecha' ? 'SALIDA' : 'BAÑOS' }}
    </div>
    <div class="pabellonAyBrectangle" style="width: 90%;">
      <div class="d-flex"  >
        <slot name="espaciocamasA"></slot>
      </div>
      <div class="pa-3 blue-grey lighten-2 white--text d-block text-center" style="width: 66vw;">
        PASADIZO CENTRO
      </div>
      <div class="d-flex">
        <slot name="espaciocamasB"></slot>
      </div>
    </div>
    <div class="d-flex pa-5 plano lineasDiagonales justify-center align-center" style="width: 40px;" >
            {{ ubicacionBano == 'bañoderecha' ? 'BAÑOS' : 'SALIDA' }}
    </div>
  </div>
</template>

<script>
export default {
    props:{
        ubicacionBano:{
            type: String,
            required: true
        }
    }
}
</script>

<style>
.pabellonAyBrectangle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

.texto-vertical {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.plano {
  margin: 5px;
  border: dotted 5px #78909c;
  border-radius: 5px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: center;
  font-weight: bold;
  color: #78909c;
  font-size: 20px;
}

.lineasDiagonales {
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0) 25px,
    rgba(0, 0, 0, 0) 40px,
    #b0bec5 40px,
    #b0bec5 60px
  )
}



</style>
