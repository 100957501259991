<template>
  <v-container>
    <v-card elevation="0">
      <v-card-text>
        <descripcion-pabellon :pabellon="pabellon" />
        <v-alert v-if="pabellon && pabellon.descripcion" text dense color="teal" icon="mdi-information" class="mb-2" border="left">
         {{pabellon.descripcion }}
        </v-alert>
        <ReadPabellon :dataPabellon="pabellon" /> 
        
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="mt-5">
      <v-card-text>
        <lista-hospedados :lista="listaHuespedes" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ReadPabellon from "@/modules/Pabellones/components/ReadPabellon.vue";
import DescripcionPabellon from "../components/DescripcionPabellon.vue";
import ListaHospedados from "../components/ListaHospedados.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  components: {
    ReadPabellon,
    DescripcionPabellon,
    ListaHospedados,
  },

  computed: {
    ...mapState("hospedaje", ["pabellon", "listaHuespedes"]),
  },

  methods: {
    ...mapActions("hospedaje", ["getAxiosPabellon", "getHosepdadosAxios"]),
  },
  created(){
        if(!this.$can('read', 'hospedaje')){
            this.$router.push({name: 'dashboard'})
        }
    },

  mounted() {
    if (this.$route.params.id) {
      this.getAxiosPabellon(this.$route.params.id);
      this.getHosepdadosAxios({idpabellon:this.$route.params.id});
    }else{
      this.$router.push({name: 'dashboard'})
    }

  },
};
</script>
