<template>
  <v-data-table :headers="headers" :loading="loading" :items="listaPabellones" mobile-breakpoint="0">
    <template v-slot:item.actions="{ item }">
      <div class="d-flex justify-space-around">
        <v-btn v-if="$can('read', 'hospedaje')" :to="{ name: 'pabellonView', params: { id: item.idpabellon } }" small icon
          class="blue-grey darken-1 mr-2">
          <v-icon color="white">mdi-chevron-right</v-icon>
        </v-btn>
        <!--<v-btn icon small class="amber darken-4 mr-2"> <v-icon small color="white">mdi-pencil</v-icon></v-btn>-->
        <v-btn @click="destroyPabellonAxios(item.idpabellon)" v-if="$can('delete', 'pabellones')" icon small
          class="red darken-4"> <v-icon small color="white">mdi-delete</v-icon></v-btn>
      </div>
    </template>
    <template v-slot:item.nombre="{ item }">
      <span class="font-weight-bold text-caption">{{ item.nombre + " - (" + item.piso + ")" }}</span>
    </template>

    <template v-slot:item.capacidad="{ item }">

      <v-avatar class="flex-column mr-2" size="40" color="amber darken-4 white--text" rounded>
        <h3 class="font-weight-bold">{{ item.capacidad_total }}</h3>
        <small class="d-block">TOTAL</small>
      </v-avatar>
    </template>

    <template v-slot:item.ocupadas="{ item }">
      <v-avatar class="flex-column mr-2" size="40" color="blue darken-1 white--text" rounded>
        <h3 class="font-weight-bold">{{ item.ocupadas }}</h3>
        <small class="d-block">OCUP.</small>
      </v-avatar>
    </template>

    <template v-slot:item.disponibles="{ item }">
      <v-avatar class="flex-column mr-2" size="40" color="green accent-4 white--text" rounded>
        <h3 class="font-weight-bold">{{ item.disponibles }}</h3>
        <small class="d-block">DISP.</small>
      </v-avatar>
    </template>

    <template v-slot:item.genero="{ item }">
      <div class="d-flex justify-center">
        <div v-if="item.genero === 'ambos'">

          <v-avatar rounded size="30" 
            class="pink darken-1 mx-auto mr-2">
            <v-icon size="20" class="white--text">
              mdi-human-female</v-icon>
          </v-avatar>

          <v-avatar rounded size="30" 
            class="indigo darken-1 mx-auto">
            <v-icon size="20" class="white--text">mdi-human-male</v-icon>
          </v-avatar>

        </div>
        <v-avatar v-else rounded size="30" class="mx-auto"
          :class="item.genero == 'mujeres' ? 'pink darken-1' : 'indigo darken-1'">
          <v-icon size="20" class="white--text">{{ item.genero == 'mujeres' ? 'mdi-human-female' : 'mdi-human-male' }}</v-icon>
        </v-avatar>
      </div>
    </template>

    <template v-slot:item.personal_hop="{ item }">
      <div class="d-flex justify-center">
        <v-chip small class="text-uppercase font-weight-bold">{{ item.personal_hop }}</v-chip>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import store from '@/store';
import { mapActions, mapState } from 'vuex';

export default {
  name: "ListaPabellones",
  data() {
    return {
      headers: [
        {
          text: "",
          value: "actions",
          width: 100,
          sortable: false

        },
        {
          text: "NOMBRE DE PABELLÓN",
          value: "nombre",
          width: 150,
          sortable: false
        },
        {
          text: "SEDE.",
          value: "sede",
          width: 40,
          sortable: false
        },
        {
          text: "CAPAC.",
          value: "capacidad",
          width: 40,
          sortable: false
        },
        {
          text: "OCUP.",
          value: "ocupadas",
          width: 40,
          sortable: false
        },
        {
          text: "DISP.",
          value: "disponibles",
          width: 40,
          sortable: false
        },
        {
          text: "GENERO",
          value: "genero",
          width: 60,
          sortable: false
        },
        {
          text: "PERSONAL",
          value: "personal_hop",
          width: 120,
          sortable: false
        },
        {
          text: "DESCRIPCIÓN",
          value: "descripcion",
          width: 350,
          sortable: false
        },
      ],

    }
  },

  computed: {
    ...mapState('pabellones', ['listaPabellones', 'loading'])
  },

  methods: {
    ...mapActions('pabellones', ['getAxiosPabellones', 'destroyPabellonAxios'])
  },

  mounted() {
    this.getAxiosPabellones();
  }
};
</script>
