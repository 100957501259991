import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import configuracion from "./configuracion";
import { socket } from "@/socket-cliente";
export default {
  namespaced: true,
  modules: {
    utiles,
  },
  state: {
    alert: {
      state: "",
      message: "",
    },
    alertDefault: {
      state: "",
      message: "",
    },
    loading: false,
    loadingRuc: false,
    tipoVisita: [],
    visita: {
      tipoVisita: null,
      tipodocumento: "DNI",
      dni: "",
      nombrecompleto: "",
      admision: "",
      carrera: "",
      ruc: "",
      entidad: "",
      area: "",
      areaColaborador: "",
      empresaservicios: "",
      motivo: "",
      fecha: "",
      hora_ingreso: "",
      hora_salida: "",
      observacion: "",
      materialesIngresados: [],
      personal: [],
      sedes: "",
      usuario_id: "",
      imagen: null,
      evidencia_visita: [],
      programar_visita: false,
      hora_aproximada: "",
      impedir_ingreso: "NO",
      marca_vehiculo: "",
      placa_vehiculo: "",
      tipoIngreso: "",
    },

    visitaDefault: {
      tipodocumento: "DNI",
      dni: "",
      nombrecompleto: "",
      admision: "",
      carrera: "",
      ruc: "",
      entidad: "",
      area: "",
      areaColaborador: "",
      empresaservicios: "",
      motivo: "",
      fecha: "",
      hora_ingreso: "",
      hora_salida: "",
      observacion: "",
      materialesIngresados: [],
      personal: [],
      sedes: "",
      usuario_id: "",
      imagen: null,
      evidencia_visita: [],
      programar_visita: false,
      hora_aproximada: "",
      impedir_ingreso: "NO",
      marca_vehiculo: "",
      placa_vehiculo: "",
      tipoIngreso: "",
    },

    material: {
      cantidad: 0,
      descripcion: "",
      marca: "",
      codigo: "",
    },

    materialDefault: {
      cantidad: 0,
      descripcion: "",
      marca: "",
      codigo: "",
    },

    personal: {
      dni: "",
      nombrecompleto: "",
    },

    personalDefault: {
      dni: "",
      nombrecompleto: "",
    },

    loadingForm: false,

    isMutationCalled: false,

    llamada: 0,

    tipoIngreso: [
      {
        text: "INGRESO PRINCIPAL A SEDE",
        value: "ingreso_principal",
      },
      {
        text: "OTRO INGRESO",
        value: "otro_ingreso",
      },
    ],
  },

  mutations: {
    initialize(state) {
      state.llamada = state.llamada + 1;
      state.visita.tipoVisita = state.tipoVisita.filter(
        (tipo) => tipo.value == "visitas"
      )[0];
      console.log("llamada " + state.llamada);
    },

    getDateCurrent(state) {
      state.visita.fecha = moment().format("YYYY-MM-DD");
      state.visita.hora_ingreso = moment().format("HH:mm:ss");
    },

    resetAlert(state) {
      state.alert = Object.assign({}, state.alertDefault);
    },

    cambioDePerfil(state) {
      state.visita.nombrecompleto = "";
      state.visita.dni = "";
      state.visita.ruc = "";
      state.visita.entidad = "";
      state.visita.carrera = "";
      state.visita.admision = "";
      state.visita.materialesIngresados = [];
      state.visita.personal = [];
      state.personal.nombrecompleto = "";
      state.visita.observacion = "";
      state.visita.areaColaborador = "";
      state.visita.area = "";
      state.visita.motivo = "";
      state.visita.empresaservicios = "";
      state.visita.evidencia_visita = [];
      state.visita.programar_visita = false;
      state.visita.hora_aproximada = "";
      state.visita.impedir_ingreso = "NO";
      state.visita.marca_vehiculo = "";
      state.visita.placa_vehiculo = "";
      state.visita.tipodocumento = "DNI";

      state.alert = Object.assign({}, state.alertDefault);
    },

    resetDataVisita(state) {
      state.visita = Object.assign({}, state.visitaDefault);
    },

    setMaterial(state) {
      state.visita.materialesIngresados.push(state.material);
      state.material = Object.assign({}, state.materialDefault);
    },

    delMaterial(state, material) {
      let index = state.visita.materialesIngresados.indexOf(material);

      swal({
        title: "¿Desea eliminar el registro?",
        icon: "warning",
        buttons: ["cancel", "CONFIRMAR"],
        dangerMode: true,
      }).then((confirma) => {
        if (!confirma) {
          return;
        }

        state.visita.materialesIngresados.splice(index, 1);
      });
    },

    setPersonal(state) {
      state.visita.personal.push(state.personal);
      state.personal = Object.assign({}, state.personalDefault);
    },

    delPersonal(state, persona) {
      let index = state.visita.personal.indexOf(persona);
      swal({
        title: "¿Desea eliminar este registro?",
        icon: "warning",
        dangerMode: true,
        buttons: ["cancel", "CONFIRMAR"],
      }).then((confirma) => {
        if (!confirma) {
          return;
        }
        state.visita.personal.splice(index, 1);
      });
    },

    setTipoVisitas(state, payload) {
      state.tipoVisita = payload;
      //state.visita.tipoVisita = state.tipoVisita.filter(tipo => tipo.value == 'visitas')[0];
    },

    setListaVisitas(state, payload) {
      state.listavisitas = payload;
    },

    addEvidenciaVisita(state, event) {
      if (
        event != null &&
        !(
          event.type == "image/jpeg" ||
          event.type == "image/png" ||
          event.type == "image/jpg" ||
          event.type == "application/pdf"
        )
      ) {
        swal({
          title: "ARCHIVO NO PERMITIDO",
          text: "Porfavor solo agregre archivos: png, jpg, pdf",
          icon: "error",
        });

        state.visita.imagen = null;
        return;
      }
      if (event != null) {
        state.visita.imagen = null;
        state.visita.evidencia_visita.push(event);
      }
    },

    quitarTodasImages(state) {
      state.visita.evidencia_visita = [];
    },
  },

  actions: {
    getApiTipoVisitas({ commit, state }) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "tipovisita";
      utiles.state.configAxios.method = "get";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      axios(utiles.state.configAxios).then((resp) => {
        if (resp.status == 200) {
          commit("setTipoVisitas", resp.data.data);

          if (!state.isMutationCalled) {
            state.isMutationCalled = true;
            commit("initialize");
          }
          /*setTimeout( () => {
            commit('initialize')
          }, 200)*/
        }
      });
    },

    /**
     * BUSCAR EN API RUC SUNAT
     */

    getApiSunatAxios({ commit, state }) {
      commit("resetAlert");
      state.loadingRuc = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "rucconsulta";
      utiles.state.configAxios.data = { ruc: state.visita.ruc };
      utiles.state.configAxios.method = "POST";
      axios(utiles.state.configAxios)
        .then((resp) => {
          console.log(resp);
          if (resp.status == 200 && resp.data.data.data.ruc) {
            if (state.visita.tipoVisita.value == "proveedores") {
              state.visita.entidad = resp.data.data.data.nombre_razon_social;
              state.alert.state = "success";
              state.alert.message =
                "Validación SUNAT! - completa los datos que faltan";
            }
            state.loadingRuc = false;
            return;
          }

          state.alert.state = "no-found";
          state.alert.message = "No se encontró RUC";
          state.loadingRuc = false;
        })
        .catch((error) => {
          console.log(error);
          state.alert.state = "error";
          state.alert.message =
            "No se encontró RUC - verifica el número de RUC";
          state.loadingRuc = false;
        });
    },

    /**
     * BUSCAR EN API RENIEC
     */
    getApiReniecAxios({ commit, state }, tipoVisita = "") {
      commit("resetAlert");
      state.loading = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "dniconsulta";
      utiles.state.configAxios.data = {
        dni:
          state.visita.tipoVisita.value != "proveedores"
            ? state.visita.dni
            : state.personal.dni,
      };
      utiles.state.configAxios.method = "POST";
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200 && resp.data.data.data.dni) {
            state.alert.state = "success";
            state.alert.message =
              "Validación Reniec! - completa los datos que faltan";

            if (state.visita.tipoVisita.value != "proveedores") {
              state.visita.nombrecompleto =
                resp.data.data.data.ap_paterno +
                " " +
                resp.data.data.data.ap_materno +
                " " +
                resp.data.data.data.nombres;
            } else {
              state.personal.nombrecompleto =
                resp.data.data.data.ap_paterno +
                " " +
                resp.data.data.data.ap_materno +
                " " +
                resp.data.data.data.nombres;
            }
            state.loading = false;
            return;
          }
          state.alert.state = "no-found";
          state.alert.message = "No se encontró DNI";
          state.loading = false;
          state.visita.nombrecompleto = "";
          state.personal.nombrecompleto = "";
        })
        .catch((error) => {
          console.log(error);
          state.alert.state = "error";
          state.alert.message =
            "No se encontró DNI - Ingresa verifica el número de DNI";
          state.loading = false;
          state.visita.nombrecompleto = "";
          state.personal.nombrecompleto = "";
        });
    },

    /**
     * BUSCAR EN API CARNET DE EXTRANJERIA
     */
    getApiCEAxios({ commit, state }) {
      commit("resetAlert");
      state.loading = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "ceconsulta";
      utiles.state.configAxios.data = {
        ce:
          state.visita.tipoVisita.value != "proveedores"
            ? state.visita.dni
            : state.personal.dni,
      };
      utiles.state.configAxios.method = "POST";
      axios(utiles.state.configAxios)
        .then((resp) => {
          console.log(resp);
          if (resp.status == 200 && resp.data.data.data.nombres) {
            state.alert.state = "success";
            state.alert.message =
              "Validación Migraciones! - completa los datos que faltan";

            if (state.visita.tipoVisita.value != "proveedores") {
              state.visita.nombrecompleto =
                resp.data.data.data.ap_paterno +
                " " +
                resp.data.data.data.ap_materno +
                " " +
                resp.data.data.data.nombres;
            } else {
              state.personal.nombrecompleto =
                resp.data.data.data.ap_paterno +
                " " +
                resp.data.data.data.ap_materno +
                " " +
                resp.data.data.data.nombres;
            }
            state.loading = false;
            return;
          }
          state.alert.state = "no-found";
          state.alert.message = "No se encontró CE";
          state.loading = false;
          state.visita.nombrecompleto = "";
          state.personal.nombrecompleto = "";
        })
        .catch((error) => {
          console.log(error);
          state.alert.state = "error";
          state.alert.message =
            "No se encontró CE - Ingresa verifica el número de CE";
          state.loading = false;
          state.visita.nombrecompleto = "";
          state.personal.nombrecompleto = "";
        });
    },

    /***
     * OBTENER DATA DE LOS ESTUDIANTES DESDE API DEL SIGE
     */

    getDataEstudiantesApi({ commit, dispatch, state }) {
      state.loading = true;
      commit("utiles/resetConfigAxios");
      /*utiles.state.configAxios.url =
        "https://sige-stage-mw.cetemin.edu.pe/fix/student/search/" +
        state.visita.dni;
      utiles.state.configAxios.headers.Authorization =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXRlbWluIiwiYXVkIjoid2ViIiwiaWF0IjoxNjYyMDQ0MTM1LCJleHAiOjE2NjMzNDAxMzUsImFrIjoiNWNlNTc2ZDliN2MzZjMxNjYifQ.468NjhnVKfbd_tlgfdiJbLrwXC6jcZo42QP-tAJHgBE";
*/
      utiles.state.configAxios.url =
        "https://api-estudiantes.cetemin.edu.pe/api/get-api-for-dni?dni=" +
        state.visita.dni;
      utiles.state.configAxios.headers.Authorization =
        "Bearer 1|FHwV5zZDcRuaGoUmWUuwWTmrdDgh4b05X6Cep7Kq5a64d070";

      axios(utiles.state.configAxios)
        .then((res) => {
          if (res.status == 200) {
            state.alert.state = "success";
            state.alert.message =
              "Estudiante encontrado! - completa los datos que faltan";
            state.visita.nombrecompleto = res.data.apellido_paterno + " " + res.data.apellido_materno + " " + res.data.nombres;
            state.visita.carrera = res.data.carrera;
            /*var yeradmin = res.data.result.period.substr(0, 4);
            var periodAdmin = res.data.result.period.substr(
              4,
              res.data.result.period.length - 1
            );*/
            

            state.visita.admision = res.data.ano_admision + "-" + res.data.periodo_acedemico;
            state.loading = false;
          }
        })
        .catch((error) => {
          //dispatch('getApiReniecAxios')
          state.loading = false;
          state.alert.state = "error";
          state.alert.message = "No se encontro al estudiante!";
          state.visita.nombrecompleto = "";
        });
    },

    /***
     * OBTENER DATA DE LOS COLABORADORES DESDE API DEL ctm-colaboradores.cetemin.edu.pe/api/v1/
     */

    getDataColaboradorApi({ commit, dispatch, state }) {
      state.loading = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        "https://ctm-colaboradores.cetemin.edu.pe/api/v1/colaborador/" +
        state.visita.dni;
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + "3|G7fWXbLjroLuJdQCaa7IP1DJewgnCHNLSb4tIs9a";

      axios(utiles.state.configAxios)
        .then((resp) => {
          console.log(resp);
          if (resp.status === 200) {
            state.loading = false;

            state.visita.nombrecompleto = resp.data.nombrecompleto;
            let areaSelected = configuracion.state.areascolaborador.find(
              (el) => {
                if (
                  el.nombrearea === resp.data.area ||
                  resp.data.area === "ADM. Y FINANZAS"
                ) {
                  return true;
                }
              }
            );
            state.visita.areaColaborador = areaSelected.nombrearea;

            state.alert.state = "success";
            state.alert.message = "Colaborador encontrado!";
          }
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
          state.visita.nombrecompleto = "";
          state.visita.areaColaborador = -1;
          state.alert.state = "error";
          state.alert.message = "No se encontro al colaborador!";
        });
    },

    //GUARDAR VISITA

    setApiVisita({ commit, dispatch, state }) {
      console.log(state.visita);
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "visitas";
      utiles.state.configAxios.method = "POST";

      utiles.state.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      state.visita.usuario_id = store.state.user.iduser;
      state.visita.sedes = store.state.user.roles.sedes;

      const datos = new FormData();
      for (let key in state.visita) {
        if (
          key == "tipoVisita" ||
          key == "materialesIngresados" ||
          key == "personal"
        ) {
          datos.append(key, JSON.stringify(state.visita[key]));
          continue;
        }

        if (key === "evidencia_visita") {
          const evidencias = state.visita[key];
          if (!evidencias) {
            continue;
          }
          for (let i = 0; i < evidencias.length; i++) {
            datos.append("evidencia_visita[]", evidencias[i]);
          }
          continue;
        }

        datos.append(key, state.visita[key]);
      }
      utiles.state.configAxios.data = datos;
      swal({
        title: "cargando....",
        text: "espere mientras se procesa la solicitud.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

      axios(utiles.state.configAxios)
        .then((resp) => {
          console.log(resp);
          if (resp.status == 205) {
            swal({
              title: `Este visitante con número de doc ${
                state.visita.dni || state.visita.ruc
              } aún no ha registrado su salida de hoy`,
              text: state.visita.nombrecompleto,
              icon: "warning",
            });
          }
          if (resp.status == 200) {
            store.dispatch("visitas/getVisitasHoy");
            store.state.visitas.dialogVisitaPreview.dialog = false;

            socket.emit("addVisita", false);

            commit("cambioDePerfil");

            swal({
              title: "Exito!",
              text: "Visita guardada con exito",
              icon: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          store.state.visitas.dialogVisitaPreview.dialog = false;
          swal({
            title: "Error!",
            text: "Ocurrio un error al guardar la visita",
            icon: "error",
          });
        });
    },
  },
};
