<template>
<v-dialog width="550" v-model="dialog">
    <v-card class="overflow-hidden">
      <v-card-actions class="py-0 mb-1">
          <informacion-cama :camaSeleccionada="camaSeleccionada" />
      </v-card-actions>
    <v-card-text class="pa-4 d-flex justify-center align-center">
       <!-- <form-asingacion v-if="!camaSeleccionada.cama.huesped || isEditable" />
        <view-data-hospedado v-else :hospedado="camaSeleccionada.cama.huesped" />
        <cambiar-cama-asignado v-if="isCambiarCama" />-->
        <component v-bind:is="componentDialog" :hospedado="camaSeleccionada.cama.huesped"></component>
    </v-card-text>
    <v-overlay opacity="1" color="white" absolute :value="loadingoverlay">
      <h2 class="font-weight-bold black--text">Cargando...</h2>
    </v-overlay>
  </v-card>
</v-dialog>
</template> 

<script>
import { mapMutations, mapState } from 'vuex';
import FormAsingacion from "./DialogoHospedarCama/FormAsignacion.vue"
import ViewDataHospedado from "./DialogoHospedarCama/ViewDataHospedado.vue"
import InformacionCama from "./DialogoHospedarCama/InformacionCama.vue"
import CambiarCamaAsignado from "./DialogoHospedarCama/CambiarCamaAsignado.vue"
import FormReporteCama from "./DialogoHospedarCama/FormReporteCama.vue"
import ViewReporte from "./DialogoHospedarCama/ViewReporte.vue"
import CheckAnimate from '@/modules/Shared/components/CheckAnimate.vue'
import CamaVaciaNoRol from './DialogoHospedarCama/CamaVaciaNoRol.vue'
export default {
  components: {
    FormAsingacion,
    InformacionCama,
    CheckAnimate,
    ViewDataHospedado,
    CambiarCamaAsignado,
    FormReporteCama,
    ViewReporte,
    CamaVaciaNoRol
  },

  computed:{
    ...mapState('hospedaje', ['camaSeleccionada', 'dialogCama', 'loadingoverlay', 'componentDialog', 'isEditable', 'isCambiarCama']),

    dialog:{
      get(){
        return this.dialogCama
      },

      set(val){
        this.openDialogCama(val)
      }
    }

  },
  methods:{
    ...mapMutations('hospedaje', ['openDialogCama', 'selectedCama', 'editHospedado'])
  },

  mounted(){
    console.log(this.camaSeleccionada)
  }
};
</script>
