import store from "@/store"
import swal from "sweetalert";
import utiles from "@/modules/Shared/utiles";
import axios from "axios";
export default{
    modules:{
        utiles
    },
    namespaced: true,
    state:{
        secciones:[
            {
                text: "Gestion de Pabellones",
                value: "pabellones",
                permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Gestion de Hospedaje",
                value: "hospedaje",
                permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Reportes de camas",
                value: "reportes",
                permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Gestion de usuarios",
                value: "usuarios",
                permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Gestion de visitas",
                value: "visitas",
                permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
               text: "Visitas configuracion",
                value: "configuravisitas",
                permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Visitas masivas",
                 value: "visitasmasivas",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Solicitud de permisos",
                 value: "permisos",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Ingreso y Salida Estudiantes",
                 value: "estudianteasistencia",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Tópico",
                 value: "topico",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Tópico-Medicamentos",
                 value: "topico.medicamentos",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Psicologia",
                 value: "psicologia",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Psicologia-Evaluaciones",
                 value: "psicologia.evaluaciones",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Disciplina",
                 value: "disciplina",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Comedor Estudiantes",
                 value: "comedor-estudiantes",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Comedor Bloques seleccionados",
                 value: "comedor-bloques-seleccionados",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Comedor Ingreso diario",
                 value: "comedor-ingreso-diario",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Comedor-Configuracion",
                 value: "comedor.configuracion",
                 permisos: [ 'create','read', 'update', 'delete' ]
            },
            {
                text: "Comedor General",
                 value: "comedor",
                 permisos: [ 'create','read', 'update', 'delete' ]
            } ,
            {
                text: "Comedor Verificacion de ingreso",
                 value: "comedor.verificador-ingreso",
                 permisos: [ 'create','read', 'update', 'delete' ]
            } 
        ],

        rol:{
            name: "",
            sedes : [],
            permisos: []
        },

        rolDefault:{
            nombreRol: "",
            sedes : [],
            permisos: []
        },

        permiso:{
            name: "",
            suject:"",
            actions: []
        },

        permisoDefault:{
            name: "",
            suject:"",
            actions: []
        },


        permisos :[],

        permisosEliminados: [],

        listaDeRoles: [],

        editIndex: -1,

        loadingRoles: false

    },

    mutations:{
        getPermisosDeSeccion(state){
           state.permisos =  state.secciones.filter( seccion => state.permiso.suject == seccion.value)[0].permisos
        },

        addPermiso(state){
            let condicion = state.rol.permisos.find(
                (elem) => elem.suject == state.permiso.suject
              );

              if (condicion) {
                swal({
                  title: "Ya se ha registrado este rol",
                  icon: "warning",
                });
        
                return;
              }

            state.permiso.name = state.secciones.filter( seccion => state.permiso.suject == seccion.value)[0].text
            state.rol.permisos.push(Object.assign({}, state.permiso))

            //Reset seleccion de seccion
            store.commit('usuarios/roles/resetObjetRol')
        },

        resetObjetRol(state){
            state.permiso = Object.assign({}, state.permisoDefault)
            
        },


        setEditRol(state, payload){
            state.rol.name = payload.name
            state.rol.sedes = payload.sedes
            state.rol.permisos = payload.permisos
            state.rol.idrole = payload.idrole
            state.editIndex = payload.idrole
        },

        deletePermisoList(state, payload){
            swal({
                title: "¿Desea eliminar permiso?",
                icon: "warning",
                dangerMode: true,
                buttons: ['cancelar', 'eliminar']
            })
            .then(comfirmation => {
                if(!comfirmation){ return}
                let index = state.rol.permisos.indexOf(payload)
                if(index != -1){
                    state.rol.permisos.splice(index, 1)
                    state.permisosEliminados.push(Object.assign({}, payload))
                }
            })
        }

        
    },


    actions:{

        getListRolesAxios({commit, state}){
            state.loadingRoles =true
            commit('utiles/resetConfigAxios')
            utiles.state.configAxios.url = utiles.state.apiUrl + 'rol'
            utiles.state.configAxios.method = 'GET'
            utiles.state.configAxios.headers.Authorization =
            "Bearer " + store.state.user.token;
            axios(utiles.state.configAxios)
            .then(response => {
                if(response.status == 200){
                    state.listaDeRoles = response.data.roles
                    console.log(response.data.roles)
                    state.loadingRoles =false

                }   
            })
            .catch(error =>{
                console.log(error)
                state.loadingRoles =false
            })

        },

        setRolAxios({commit,dispatch, state}){
            swal({
                title: "Cargando...",
                text: "Por favor, espera mientras se realiza la petición.",
                icon: "info",
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
                dangerMode: true,
              });
            commit('utiles/resetConfigAxios')

            /**
             * Parsear data antes de enviar
             */
            let data = Object.assign({}, state.rol)
            let dataparse = {}
            dataparse.name = data.name
            dataparse.sedes = data.sedes.join(',')
            dataparse.permisos  = []
            dataparse.idrole = data.idrole
            
            data.permisos.forEach(permiso => {
                let newpermiso = Object.assign({}, permiso)
                newpermiso.actions = permiso.actions.join(',')
                dataparse.permisos.push(newpermiso)
            });

            

            utiles.state.configAxios.method = 'POST' 

            if(state.editIndex > -1){
                dataparse.permisosEliminados = state.permisosEliminados
                utiles.state.configAxios.method = 'PUT' 
            }
            console.log(utiles.state.configAxios.method)
            utiles.state.configAxios.url = utiles.state.apiUrl + 'rol'
            utiles.state.configAxios.data = dataparse
            utiles.state.configAxios.headers.Authorization =
            "Bearer " + store.state.user.token;

            console.log(state.editIndex)
             
            axios(utiles.state.configAxios)
            .then(response => {
                console.log(response)
                if(response.status == 200){
                    dispatch('getListRolesAxios')
                    swal({
                        title: state.editIndex == -1 ? "Se agrego nuevo rol!" : "Se actualizó el rol!",
                        icon: "success",
                        });

                  state.rol = Object.assign({}, state.rolDefault)
                  state.editIndex = -1
                }
            })
            .catch(error =>{
                console.log(error)
                if(error.response.status == 400){
                    swal({
                        title: "Ya existe un rol con el mismo nombre",
                        icon: "warning",
                      });
                }else{
                    swal({
                        title: "Ha ocurrido un error",
                        icon: "error",
                      });
                }
                
            })
        },


        destroyRolAxios({commit, dispatch, state}, idrole){
            commit('utiles/resetConfigAxios')

            swal({
                title: "¿Desea eliminar el rol?",
                icon: "warning",
                dangerMode: true,
                buttons: ['cancelar', 'ELIMINAR']
            })
            .then(confirmacion => {
                if(!confirmacion){ return }
                swal({
                    title: "Cargando...",
                    text: "Por favor, espera mientras se realiza la petición.",
                    icon: "info",
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    dangerMode: true,
                  });
                utiles.state.configAxios.url = utiles.state.apiUrl + 'rol'
                utiles.state.configAxios.method = 'DELETE'
                utiles.state.configAxios.data = { idrole: idrole}
                utiles.state.configAxios.headers.Authorization =
                "Bearer " + store.state.user.token;
                axios(utiles.state.configAxios)
                .then(response => {
                    if(response.status == 200){
                        dispatch('getListRolesAxios')
                        swal({
                            title: "Se eliminó con exito!",
                            icon: "success",
                          });
                    }
                })
                .catch(error =>{
                    console.log(error)
                    swal({
                        title: "Ocurrió un error!",
                        icon: "error",
                      });
                })

            })
        }
    }
}