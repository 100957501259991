import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";
import readXlsxFile from 'read-excel-file'
//import moment from "moment";
import router from "@/router";
import moment from 'moment-timezone';

export default {
  namespaced: true,
  modules: {
    utiles,
  },
  state: {
    masivoComponent: "SubirMasivoHospedaje",
    hospedaje: {
      dni: "",
      tipo: "estudiante",
      nombrecompleto: "",
      edad: 0,
      carrera: "",
      admision: "",
      jefatura: "",
      cargo: "",
      indexcama: "",
      fecha_ingreso: "",
      fecha_salida: "",
      dias_semana: 5,
      almohada: false,
      frazada: false,
      sabana: false,
      funda_almohada: false,
      locker: false,
      validate: null
    },
    hospedajeDefault: {
      dni: "",
      tipo: "estudiante",
      nombrecompleto: "",
      edad: 0,
      carrera: "",
      admision: "",
      jefatura: "",
      cargo: "",
      indexcama: "",
      fecha_ingreso: "",
      fecha_salida: "",
      dias_semana: 5,
      almohada: false,
      frazada: false,
      sabana: false,
      funda_almohada: false,
      locker: false,
      validate: null
    },

    fileExcel: {
      file: null,
    },
    
    dataExcel : [],
    totalRows: 0,
    rowsResueltos: 0,
    estadoFinal: null
  },

  mutations: {
    setHospedaje(state, payload) {
      state.hospedaje = Object.assign({}, payload);
    },

    resetHospedaje(state) {
      state.hospedaje = Object.assign({}, state.hospedajeDefault);
    },


    resetAllMasivo(state){
      state.listaHospedajes = []
      state.hospedaje = Object.assign({}, state.hospedajeDefault);
      state.masivoComponent = "SubirMasivoHospedaje"
      state.dataExcel = []
      state.fileExcel.file = null
      state.rowsResueltos = 0
      state.estadoFinal = null
    },

    uploadFileExcel(state, event) {
      if (
        event != null &&
        !(
          event.type == "application/vnd.ms-excel" ||
          event.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        swal({
          title: "ARCHIVO NO PERMITIDO",
          text: "Porfavor solo agregre archivos: Excel",
          icon: "error",
        });
        state.fileExcel.file = null;
        swal({
          title: "Solo se permiten documentos excel",
          icon: "warning",
        });
        return;
      }
      state.fileExcel.file = event;
    },
    


  },
  actions: {
    readDataFileExcel({ commit, dispatch, state }) {
        readXlsxFile(state.fileExcel.file)
        .then((rows) => {
            dispatch('dataConvertJson', rows)
            

          })
        .catch(error => {
            console.log(error)
            swal({
                title: "Ha ocurrido un error!"
            })
            state.masivoComponent = "SubirMasivoHospedaje"
        })

    },

    async dataConvertJson({state, dispatch}, data){
        state.dataExcel = []
        let excelData = data

        console.log(data)

        /**
         * Validar si es el archivo plantilla correcto
         */
        if(excelData[0][0] != 'Cetemin000*'){
            swal({
              title: "Has insertado otro archivo que no es la plantilla",
              icon: "error"
            })

            return 
        }

        state.masivoComponent = "LoadingDataHospedaje"
        state.totalRows = excelData.length - 1
        for(var i= 3; i <= excelData.length - 1; i++){
            state.hospedaje = Object.assign({}, state.hospedajeDefault)
            state.hospedaje.index = i - 2
            state.hospedaje.dni = excelData[i][0]
            var response = {}

            //Validacion de dni SIGE Y RENIEC
            response = await dispatch('getDNISige')
            if(!response &&  excelData[i][4] == 'colaborador'){
                response = await dispatch('getDNIReniec')
            }
            //si no encuentra datos devuelve un objeto vacio
            if(!response){ response = {}}
            let nombre = excelData[i][1] == null ? 'NO SE ENCONTRÓ DATOS DEL ESTUD.' : excelData[i][1]
            state.hospedaje.nombrecompleto = response.nombrecompleto || nombre
            state.hospedaje.carrera = response.carrera || excelData[i][2]
            state.hospedaje.admision = response.admision || excelData[i][3]
            state.hospedaje.tipo = excelData[i][4] || "estudiante"
            if(state.hospedaje.tipo == 'colaborador'){
                state.hospedaje.jefatura = excelData[i][5] != null ? excelData[i][5] : "no especifica"
                state.hospedaje.cargo = excelData[i][6] != null ? excelData[i][6] : "no especifica"
            }else{
                state.hospedaje.jefatura = excelData[i][5]
                state.hospedaje.cargo = excelData[i][6]
            }
            
            state.hospedaje.indexcama = excelData[i][7]

            state.hospedaje.fecha_ingreso = excelData[i][8] ? moment(excelData[i][8], "DD/MM/YYYY HH:mm:ss").tz("America/Lima").format("YYYY-MM-DD HH:mm:ss") : moment().tz("America/Lima").format("YYYY-MM-DD HH:mm:ss")
            state.hospedaje.fecha_salida = excelData[i][9] ? moment(excelData[i][9], "DD/MM/YYYY HH:mm:ss").tz("America/Lima").format("YYYY-MM-DD HH:mm:ss") : null
            state.hospedaje.dias_semana = excelData[i][10] || 5
            state.hospedaje.almohada = excelData[i][11] == 'si' ? 1 : 0
            state.hospedaje.funda_almohada = excelData[i][12] == 'si' ? 1 : 0
            state.hospedaje.frazada = excelData[i][13] == 'si' ? 1 : 0
            state.hospedaje.sabana = excelData[i][14] == 'si' ? 1 : 0
            state.hospedaje.locker = excelData[i][15] == 'si' ? 1 : 0
            state.dataExcel.push(state.hospedaje)
            state.rowsResueltos = ((i + 1) / excelData.length) * 100;
        }

        state.masivoComponent = "TablaResultadosHospedaje"

        console.log(state.hospedaje)
    },

    async getDNISige({commit, dispatch, state}){
        commit("utiles/resetConfigAxios");
        /*
      utiles.state.configAxios.url =
        "https://sige-stage-mw.cetemin.edu.pe/fix/student/search/" +
        state.hospedaje.dni;
      utiles.state.configAxios.headers.Authorization =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXRlbWluIiwiYXVkIjoid2ViIiwiaWF0IjoxNjYyMDQ0MTM1LCJleHAiOjE2NjMzNDAxMzUsImFrIjoiNWNlNTc2ZDliN2MzZjMxNjYifQ.468NjhnVKfbd_tlgfdiJbLrwXC6jcZo42QP-tAJHgBE";
        */
        utiles.state.configAxios.url = 
        "https://api-estudiantes.cetemin.edu.pe/api/get-api-for-dni?dni=" +
        state.hospedaje.dni;
        utiles.state.configAxios.method = 'GET';
        utiles.state.configAxios.headers.Authorization =
        "Bearer 1|FHwV5zZDcRuaGoUmWUuwWTmrdDgh4b05X6Cep7Kq5a64d070";

        const response = await axios(utiles.state.configAxios)
        .then((res) => {
        if (res.status == 200) {
            const obj = {};
            obj.nombrecompleto = res.data.nombres + " " + res.data.apellido_paterno + " " + res.data.apellido_materno;
            obj.carrera = res.data.carrera;
            /*
            var yeradmin = res.data.result.period.substr(0, 4);
            var periodAdmin = res.data.result.period.substr(
            4,
            res.data.result.period.length - 1
            );*/
            obj.admision = res.data.ano_admision + "-" + res.data.periodo_acedemico;
            state.hospedaje.validate = "sige"
            return obj;
            }
        })
        .catch((error) => {
            console.log(error)

            return null
        });

        return response;
    },

    async getDNIReniec({commit, state}){
        commit("utiles/resetConfigAxios");
        let token = "d674134c-526d-4c04-bdc7-47eb94ab5358"
        utiles.state.configAxios.url = utiles.state.apiUrl+ 'dniconsulta';
        utiles.state.configAxios.method = "POST";
        utiles.state.configAxios.data = { dni : state.hospedaje.dni}

       const response = await axios(utiles.state.configAxios)
        .then(resp =>{
            const obj = {}
          if(resp.status == 200){
            obj.nombrecompleto = resp.data.data.data.ap_paterno + " " + resp.data.data.data.ap_materno + " " + resp.data.data.data.nombres;
            state.hospedaje.validate = "reniec"
            return obj;
          }
        })
        .catch(error => {
          console.log(error)
          return {}
        })

        return response;
    },

    async validarEstadoHospedado({commit, state}, estadoHuesped){
      commit('utiles/resetConfigAxios')
      utiles.state.configAxios.url = utiles.state.apiUrl + 'gethuesped'
      utiles.state.configAxios.method = 'POST'
      utiles.state.configAxios.data = { dni: state.hospedaje.dni, estadoHuesped: estadoHuesped }
      utiles.state.configAxios.headers.Authorization = "Bearer " + store.state.user.token;

      const response = await axios(utiles.state.configAxios)
      .then(resp=>{
        if(resp.status==200){
          const obj = {}
          if(resp.data && resp.data.huesped.estado_huesped == "hospedado"){
             obj.estado = "hospedado"
          }

          return obj
        }
      })
      .catch(error => {
        console.log(error)
        return {}
      })

      return response
    },

    /**
     * hospedar lista 
     */
    HospedarMasivaos({commit, state}, idpabellon){
      commit('utiles/resetConfigAxios')
      utiles.state.configAxios.url = utiles.state.apiUrl + 'hospedarmasivos'
      utiles.state.configAxios.method = 'POST'
      utiles.state.configAxios.data = { listaHospedajes : state.dataExcel, user_id: store.state.user.iduser, idpabellon: idpabellon}
      utiles.state.configAxios.headers.Authorization = "Bearer " + store.state.user.token;

      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });
      axios(utiles.state.configAxios)
      .then(resp => {
        if(resp.status == 200){
          swal({
            title: "Se cargó la información",
            icon: "success"
          })
          state.estadoFinal = resp.data.estado_final
          state.masivoComponent = "InformeFinalHospedaje"
          //router.push({name: 'pabellonView', params: {id: idpabellon}})
        }
      })
      .catch(error => {
        console.log(error)
        swal({
          title: "Ha ocurrido un error",
          icon: "error"
        })
      })
    }

  },
};
