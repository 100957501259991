import swal from "sweetalert"
import BloquesHorarioService from "../services/BloquesHorarioService"


const bloqueshorarioSV = new BloquesHorarioService

export default{
    namespaced: true,
    state:{
        bloqueshorario:{
            sede: "",
            tipo_comida: "",
            bloques: [
                {
                    bloque_name: "BLOQUE 1",
                    hora_inicio: "",
                    hora_fin: ""    
                },

                {
                    bloque_name: "BLOQUE 2",
                    hora_inicio: "",
                    hora_fin: ""    
                },

                {
                    bloque_name: "BLOQUE 3",
                    hora_inicio: "",
                    hora_fin: ""    
                },
                {
                    bloque_name: "BLOQUE 4",
                    hora_inicio: "",
                    hora_fin:""    
                }
            ]
        },
        bloqueshorarioDefault:{
            sede: "",
            tipo_comida: "",
            bloques: [
                {
                    bloque_name: "BLOQUE 1",
                    hora_inicio: "",
                    hora_fin: ""    
                },

                {
                    bloque_name: "BLOQUE 2",
                    hora_inicio: "",
                    hora_fin: ""    
                },

                {
                    bloque_name: "BLOQUE 3",
                    hora_inicio: "",
                    hora_fin: ""    
                },
                {
                    bloque_name: "BLOQUE 4",
                    hora_inicio: "",
                    hora_fin:""    
                }
            ]
        },

        listBloqueshorario: [],
        filterBloqueSeleccionado:{
            sede: ""
        },
        loadingAdd: false,
        loadingList : false,
        dialogAdd:{
            open: false
        }
    },

    mutations:{
        setListBloques(state, payload)
        {
            state.listBloqueshorario = payload
        },


        setBloque(state, payload)
        {
            const {sede, tipo_comida, bloques } = payload
    
            state.bloqueshorario.sede = sede
            state.bloqueshorario.tipo_comida = tipo_comida
            state.bloqueshorario.bloques = JSON.parse(JSON.stringify(bloques))
        },

        resetBloque(state)
        {
            state.bloqueshorario = null
            state.bloqueshorario =  Object.assign({}, state.bloqueshorarioDefault)
            state.bloqueshorario.bloques = []
            state.bloqueshorario.bloques = JSON.parse(JSON.stringify(state.bloqueshorarioDefault.bloques)) 
        }
    },

    actions:{

        async getListBloques({state, commit})
        {
            state.loadingList = true
            try
            {
                const data = await bloqueshorarioSV.getList(state.filterBloqueSeleccionado)
                commit('setListBloques', data)
                console.log("BLOQUES!", data)
                state.loadingList = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingList = false
            }
        },

        async add({state, commit, dispatch})
        {
            state.loadingAdd = true
            try
            {
                const data = await bloqueshorarioSV.add(state.bloqueshorario)
                dispatch('getListBloques')
                commit('resetBloque')
                state.loadingAdd = false
                state.dialogAdd.open = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingAdd = false

                if(error.response.status === 502){

                    swal({
                        title: "Ya existe un bloque para esa sede y comida",
                        icon: "error"
                    })
                }
            }
        },


        async update({state, commit, dispatch})
        {
            state.loadingAdd = true
            try
            {
                const data = await bloqueshorarioSV.update(state.bloqueshorario)
                dispatch('getListBloques')
                commit('resetBloque')
                state.loadingAdd = false
                state.dialogAdd.open = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingAdd = false
            }
        },
    }
}