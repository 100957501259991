import utiles from "@/modules/Shared/utiles";
import { socket } from "@/socket-cliente";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";

export default {
  namespaced: true,
  modules: {
    utiles,
  },
  state: {
    visitaIngreso: {
      tipoVisita: { value: "visitas" },
      dni: "",
      nombrecompleto: "",
      admision: "",
      carrera: "",
      ruc: "",
      entidad: "",
      areaForm: "",
      area: "",
      areaColaborador: "",
      empresaservicios: "",
      motivo: "",
      fecha: "",
      hora_ingreso: "",
      hora_salida: "",
      observacion: "",
      materialesIngresados: [],
      nuevosmaterialesIngresados: [],
      materialIngresadoCombinado: [],
      personal: [],
      nuevopersonal: [],
      personalCombinado: [],
      sedes: "",
      usuario_id: "",
      usuario_salida_id: "",
      imagen: null,
      evidencia_visita: [],
      programar_visita: false,
      hora_aproximada: "",
    },
    visitaIngresoDefault: {
      tipoVisita: { value: "visitas" },
      dni: "",
      nombrecompleto: "",
      admision: "",
      carrera: "",
      ruc: "",
      entidad: "",
      areaForm: "",
      area: "",
      areaColaborador: "",
      empresaservicios: "",
      motivo: "",
      fecha: "",
      hora_ingreso: "",
      hora_salida: "",
      observacion: "",
      materialesIngresados: [],
      personal: [],
      sedes: "",
      usuario_id: "",
      usuario_salida_id: "",
      imagen: null,
      evidencia_visita: [],
      programar_visita: false,
      hora_aproximada: "",
    },
    personal: {
      dni: "",
      nombrecompleto: "",
    },
    personalDefault: {
      dni: "",
      nombrecompleto: "",
    },
    material: {
      cantidad: 0,
      descripcion: "",
      marca: "",
      codigo: "",
    },

    materialDefault: {
      cantidad: 0,
      descripcion: "",
      marca: "",
      codigo: "",
    },
    alert: {
      state: "",
      message: "",
    },
    alertDefault: {
      state: "",
      message: "",
    },

    loading: false,
    loadingIngreso: false,
  },

  mutations: {
    setVisitaIngreso(state, payload) {
      payload.evidencia_visita =
        payload.evidencia_visita == null ? [] : payload.evidencia_visita;
      //ADD NEW FIELDS
      state.visitaIngreso = Object.assign(state.visitaIngreso, payload);
      state.visitaIngreso.personalCombinado = Object.assign(
        [],
        state.visitaIngreso.personal
      );
      state.visitaIngreso.materialIngresadoCombinado = Object.assign(
        [],
        state.visitaIngreso.materialesIngresados
      );
    },
    setPersonal(state) {
      state.visitaIngreso.nuevopersonal.push(state.personal);

      state.visitaIngreso.personalCombinado.push(state.personal);

      state.personal = Object.assign({}, state.personalDefault);
    },

    delPersonal(state, persona) {
      let index = state.visitaIngreso.nuevopersonal.indexOf(persona);
      swal({
        title: "¿Desea eliminar este registro?",
        icon: "warning",
        dangerMode: true,
        buttons: ["cancel", "CONFIRMAR"],
      }).then((confirma) => {
        if (!confirma) {
          return;
        }
        state.visitaIngreso.nuevopersonal.splice(index, 1);
        state.visitaIngreso.personalCombinado = [
          ...state.visitaIngreso.personal,
          ...state.visitaIngreso.nuevopersonal,
        ];
      });
    },
    setMaterial(state) {
      state.visitaIngreso.nuevosmaterialesIngresados.push(state.material);
      state.visitaIngreso.materialIngresadoCombinado.push(state.material);
      state.material = Object.assign({}, state.materialDefault);
    },

    delMaterial(state, material) {
      let index =
        state.visitaIngreso.nuevosmaterialesIngresados.indexOf(material);

      swal({
        title: "¿Desea eliminar el registro?",
        icon: "warning",
        buttons: ["cancel", "CONFIRMAR"],
        dangerMode: true,
      }).then((confirma) => {
        if (!confirma) {
          return;
        }

        state.visitaIngreso.nuevosmaterialesIngresados.splice(index, 1);
        state.visitaIngreso.materialIngresadoCombinado = [
          ...state.visitaIngreso.materialesIngresados,
          ...state.visitaIngreso.nuevosmaterialesIngresados,
        ];
      });
    },

    resetAlert(state) {
      state.alert = Object.assign({}, state.alertDefault);
    },

    addEvidenciaVisita(state, event) {
      if (
        event != null &&
        !(
          event.type == "image/jpeg" ||
          event.type == "image/png" ||
          event.type == "image/jpg" ||
          event.type == "application/pdf"
        )
      ) {
        swal({
          title: "ARCHIVO NO PERMITIDO",
          text: "Porfavor solo agregre archivos: png, jpg, pdf",
          icon: "error",
        });

        state.visitaIngreso.imagen = null;
        return;
      }
      if (event != null) {
        state.visitaIngreso.imagen = null;
        state.visitaIngreso.evidencia_visita.push(event);
      }
    },

    resetMarcarIngreso(state) {
      state.visitaIngreso.observacion = "";
      state.visitaIngreso.personal = [];
      state.visitaIngreso.materialesIngresados = [];

      state.personal = Object.assign({}, state.personalDefault);
      state.material = Object.assign({}, state.materialDefault);
    },

   
  },

  actions: {
    setApiMarcarIngreso({ commit, dispatch, state }) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "visitas-ingreso";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };

      //Importante enviar la hora de ingreso
      state.visitaIngreso.hora_ingreso = moment().format("HH:mm:ss");
      state.visitaIngreso.usuario_id = store.state.user.iduser;
      const datos = new FormData();
      for (let key in state.visitaIngreso) {
        if (
          key == "tipoVisita" ||
          key == "nuevosmaterialesIngresados" ||
          key == "nuevopersonal"
        ) {
          datos.append(key, JSON.stringify(state.visitaIngreso[key]));
          continue;
        }
        if (key === "evidencia_visita") {
          const evidencias = state.visitaIngreso[key];
          if (!evidencias) {
            continue;
          }
          for (let i = 0; i < evidencias.length; i++) {
            datos.append("evidencia_visita[]", evidencias[i]);
          }
          continue;
        }
        datos.append(key, state.visitaIngreso[key]);
      }
      utiles.state.configAxios.data = datos;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;


      state.loadingIngreso = true;

      axios(utiles.state.configAxios)
        .then((resp) => {
          console.log(resp);
          if (resp.status == 200) {
            swal({
              title: "Marcar ingreso",
              text: "Se marco el ingreso de la visita",
              icon: "success",
            });

            store.dispatch("visitas/getVisitasHoy");

            store.state.visitas.dialogVisitaPreview.dialog = false;
            state.loadingIngreso = false;

            socket.emit('marcarIngreso', false);
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingIngreso = false;
          swal({
            title: "Error al marcar ingreso",
            text: "Porfavor revise la conexion a internet e intente nuevamente",
            icon: "error",
          });
        });
    },

    getApiReniecAxios({ commit, state }, tipoVisita = "") {
      commit("resetAlert");
      state.loading = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = "https://api.apirest.pe/api/getDniPremium";
      utiles.state.configAxios.method = "POST";

      utiles.state.configAxios.data = {
        dni:
          tipoVisita == "proveedores" ? state.personal.dni : state.visita.dni,
      };

      utiles.state.configAxios.headers.Authorization =
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjhhYjYyMWVkNDBmMmVhMzlmMDg3M2Y2ZGQzOWIwZjQzMGFmZGE1ODBkY2JiYjk4ZDYyM2ZmOGM2NzJiNzQxN2JiZTZjNDQwMDc0MjZhYzZkIn0.eyJhdWQiOiIxIiwianRpIjoiOGFiNjIxZWQ0MGYyZWEzOWYwODczZjZkZDM5YjBmNDMwYWZkYTU4MGRjYmJiOThkNjIzZmY4YzY3MmI3NDE3YmJlNmM0NDAwNzQyNmFjNmQiLCJpYXQiOjE1NjY0MjMxODYsIm5iZiI6MTU2NjQyMzE4NiwiZXhwIjoxODgyMDQyMzg2LCJzdWIiOiIxNTUiLCJzY29wZXMiOlsiKiJdfQ.gMN_mZ7o3n_ODVX0h5kIU-xmN6HETZU98zXE2AZHywtb0Q7A35DfssNcr34wIN_TaPWHhXWn5MmYnTEhk20pGQb-1_ebGr2uZRdyiHlniY7rky7ZWlvsYDfp8p1gxh5gfEZbgTjQCZl0gJP6bh1yURzJSm3hcSYLgjR7oz6uYokV8FFSHIhrxLbrWBGapNVdqKRBELJ1clSNDRKH2CT2criscOtkXQEbZSY68CP4eTIoVk0C4-ghx1TlxSp4wMOV6_YgRc8JGMXSEpX3r9gZ1AcER3BTyfzRz0JlpXiE_F0C0NtQH8dFf1uYQv1d8S3wYKjBWAmn4QMGdsIphHcswfEpM9_o8gwBIdEzYm3vv5NJNAp79OGq9kHT7gtwiEV9cqdi5fgZ-nVvXmUN7wwi1ef87OKgP8j3K6AePB7R5W9ElqLwHy0O2j8urz992ZHFynMwLVd02L_ElIM9xeBDjblB-awg-RN9Krw29uXgYcsUdyOkmrmLON2Lg4-5De7-5g2cR-vyKiLCzRILvJBs-BmcrVo-UHkk99bIL3wUT6C9akMZ7crqRzbYBil5-Cln6WS6_9pMww0in6YhRm_jv-QY23xH5WZVWE3RwKCAE-zu4EeRWP4dnL2RbhH_SJO19EZVUSVj1frf9T7u6rGQUYegK7AxyApDGkqgSpFHB6Y";
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200 && resp.data.result.DNI) {
            state.alert.state = "success";
            state.alert.message =
              "Validación Reniec! - completa los datos que faltan";
            state.personal.nombrecompleto = resp.data.result.NombreCompleto;
            state.loading = false;
            return;
          }
          state.alert.state = "no-found";
          state.alert.message = "No se encontró DNI";
          state.loading = false;
          state.visitaIngreso.nombrecompleto = "";
          state.personal.nombrecompleto = "";
        })
        .catch((error) => {
          console.log(error);
          state.alert.state = "error";
          state.alert.message =
            "No se encontró DNI - Ingresa verifica el número de DNI";
          state.loading = false;
          state.visitaIngreso.nombrecompleto = "";
          state.personal.nombrecompleto = "";
        });
    },
  },
};
