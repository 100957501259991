<template>
  <div style="height: 100vh" class="d-flex align-center">
    <v-card :loading="loadingIngreso" elevation="0" height="100%" width="100%" rounded="0">
      <v-card-title
        class="justify-center text-subtitle-2 font-weight-bold text-uppercase py-1 mb-2 indigo darken-1 white--text"
      >
        Registrar Ingreso
      </v-card-title>
      <v-card-text>
        <div class="black--text text-center">
          <div class="mb-2">Está ingresando:</div>
          <h2 class="py-2" v-if="visitaIngreso.nombrecompleto">
            {{ visitaIngreso.nombrecompleto}}
          </h2>
          <h2 class="py-2" v-if="visitaIngreso.entidad ">
            {{ visitaIngreso.entidad  }}
          </h2>
          <div
            class="d-block pa-2"
            v-if="visitaIngreso.tipoVisita.value == 'estudiantes'"
          >
            {{ visitaIngreso.carrera || "" }} -
            {{ visitaIngreso.admision || "" }}
          </div>
          <v-chip class="mr-2 font-weight-bold"
            ><span>{{ visitaIngreso.dni || visitaIngreso.ruc }}</span></v-chip
          >
          <v-chip
            ><v-avatar left size="25" class="bgCTM">
              <v-icon color="white" size="20">{{
                visitaIngreso.tipoVisita.icon
              }}</v-icon></v-avatar
            ><span class="font-weight-bold">{{
              visitaIngreso.tipoVisita.text
            }}</span></v-chip
          >
        </div>

        <div class="pa-5 rounded-lg red lighten-4 mt-4 text-center" v-if="visitaIngreso.impedir_ingreso == 'SI'">
          <h3 class="red--text text--darken-4">NO PUEDE INGRESAR A LA SEDE</h3>
          <span class="red--text text-left">
            <b>Motivo: </b> {{ visitaIngreso.observacion }}
          </span><br>
        </div>

        <v-form v-model="formValidate" v-if="visitaIngreso.impedir_ingreso == 'NO'">
        <v-row class="mt-1">
          <v-col
            cols="12"
            md="6"
            class="black--text"
            v-if="visitaIngreso.tipoVisita.value != 'estudiantes'"
          >
            <label for="">Área a ingresar</label>
            <div
              v-if="visitaIngreso.area"
              class="rounded-lg blue-grey lighten-4 font-weight-bold pa-2"
            >
              {{ visitaIngreso.area }}
            </div>
            <div v-if="visitaIngreso.area == null || visitaIngreso.area == ''">
                <v-select
                  v-model="visitaIngreso.areaForm"
                  dense
                  hide-details="auto"
                  class="grey lighten-4"
                  flat
                  outlined
                  :items="areasvisitas"
                  item-text="nombrearea"
                  item-value="nombrearea"
                  placeholder="Seleccione una área"
                  :rules="[rules.required]"
                ></v-select>
              </div>

            <div
              v-if="visitaIngreso.areaColaborador"
              class="rounded-lg blue-grey lighten-4 font-weight-bold pa-2"
            >
              {{ visitaIngreso.areaColaborador }}
            </div>



          </v-col>
          <v-col
            cols="12"
            md="6"
            class="black--text"
            v-if="visitaIngreso.tipoVisita.value != 'estudiantes' && visitaIngreso.tipoVisita.value != 'colaboradores'
            && visitaIngreso.tipoVisita.value != 'terceros'"
          >
            <label for="">Motivo</label>
            <div class="rounded-lg blue-grey lighten-4 font-weight-bold pa-2">
              {{ visitaIngreso.motivo }}
            </div>
          </v-col>

          <v-col
            cols="12"
            class="py-0 black--text font-weight-bold"
            v-if="visitaIngreso.tipoVisita.value == 'proveedores'"
          >
            <v-btn
              small
              elevation="0"
              class="blue-grey lighten-4"
              @click="personaladd = !personaladd"
              >Agregar personal</v-btn
            >
            <div>
              <small v-if="visitaIngreso.personal.length > 0 || personaladd"
                >PERSONAL</small
              >
              <v-form v-model="formValidatePersonal" v-if="personaladd">
                <div class="d-flex align-center">
                  <v-text-field
                    v-model="personal.dni"
                    dense
                    hide-details
                    class="grey lighten-4 mr-1"
                    flat
                    outlined
                    style="width: 10%"
                    placeholder="Insertar DNI"
                    @keypress.enter="getApiReniecAxios('proveedores')"
                    :rules="[rules.required]"
                    clearable
                  >
                    <template v-slot:append>
                      <v-btn icon :loading="loading">
                        <v-icon @click="getApiReniecAxios('proveedores')"
                          >mdi-magnify</v-icon
                        >
                      </v-btn>
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-model="personal.nombrecompleto"
                    dense
                    hide-details
                    class="grey lighten-4 mr-1"
                    flat
                    outlined
                    style="width: 60%"
                    :placeholder="
                      alert.state == 'no-found' ? alert.message : ''
                    "
                    :rules="[rules.required]"
                    clearable
                  >
                    <template v-slot:append>
                      <v-avatar
                        size="25"
                        :class="
                          alert.state == 'success' ? 'green accent-3' : 'grey'
                        "
                      >
                        <v-icon color="white" small>{{
                          alert.state == "success"
                            ? "mdi-shield-check"
                            : "mdi-alert-circle"
                        }}</v-icon>
                      </v-avatar>
                    </template>
                  </v-text-field>

                  <v-btn
                    @click="setPersonal()"
                    :disabled="!formValidatePersonal"
                    elevation="0"
                    height="41"
                    width="40px"
                    class="bgCTM px-0"
                    style="min-width: 40px !important"
                    ><v-icon color="white">mdi-plus</v-icon></v-btn
                  >
                </div>
              </v-form>
            </div>

            <v-data-table
              v-if="visitaIngreso.personalCombinado.length > 0"
              :headers="headerPersonal"
              :items="visitaIngreso.personalCombinado"
              dense
              hide-default-footer
              fixed-header
              height="150"
              mobile-breakpoint="0"
              no-data-text="no se registró personal"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn v-if="!item.idpersona" @click="delPersonal(item)" x-small icon class="red">
                  <v-icon x-small color="white">mdi-close-thick</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>

          <v-col
            cols="12"
            class="py-0 black--text font-weight-bold mt-2"
            v-if="
              visitaIngreso.tipoVisita.value != 'colaboradores' ||
              visitaIngreso.areaColaborador == 'DOCENTES'
            "
          >
            <v-btn
              small
              elevation="0"
              class="blue-grey lighten-4"
              @click="materialadd = !materialadd"
              >Agregar material</v-btn
            >
            <div>
              <small
                v-if="materialadd || visitaIngreso.materialesIngresados > 0"
                >MATERIALES A INGRESAR</small
              >
              <v-form v-model="formValidateMaterial" v-if="materialadd">
                <div class="d-flex align-center">
                  <v-text-field
                    type="number"
                    style="width: 10%"
                    value="0"
                    dense
                    hide-details
                    class="grey lighten-4 mr-1"
                    flat
                    outlined
                    v-model="material.cantidad"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    style="width: 50%"
                    placeholder="Descripción"
                    dense
                    hide-details
                    class="grey lighten-4 mr-1"
                    flat
                    outlined
                    v-model="material.descripcion"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    style="width: 20%"
                    placeholder="Marca"
                    dense
                    hide-details="auto"
                    class="grey lighten-4 mr-1"
                    flat
                    outlined
                    v-model="material.marca"
                  ></v-text-field>
                  <v-text-field
                    style="width: 20%"
                    placeholder="Serie"
                    dense
                    hide-details="auto"
                    class="grey lighten-4 mr-1"
                    flat
                    outlined
                    v-model="material.codigo"
                  ></v-text-field>
                  <v-btn
                    :disabled="!formValidateMaterial"
                    @click="setMaterial()"
                    height="41"
                    width="40px"
                    class="bgCTM px-0"
                    style="min-width: 40px !important"
                    ><v-icon color="white">mdi-plus</v-icon></v-btn
                  >
                </div>
              </v-form>
            </div>

            <v-data-table
              v-if="visitaIngreso.materialIngresadoCombinado.length > 0"
              dense
              :headers="headers"
              hide-default-footer
              fixed-header
              height="100"
              mobile-breakpoint="0"
              :items="visitaIngreso.materialIngresadoCombinado"
              no-data-text="No se registró material"
            >
              <template v-slot:item.cantidad="{ item }"
                ><span>{{ item.cantidad }}</span></template
              >
              <template v-slot:item.descripcion="{ item }"
                ><span>{{ item.descripcion }}</span></template
              >
              <template v-slot:item.marca="{ item }"
                ><span>{{ item.marca }}</span></template
              >
              <template v-slot:item.codigo="{ item }"
                ><span>{{ item.codigo }}</span></template
              >
              <template v-slot:item.actions="{ item }">
                <v-btn v-if="!item.idmaterialingresado" @click="delMaterial(item)" x-small icon class="red">
                  <v-icon x-small color="white">mdi-close-thick</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" class="black--text font-weight-bold">
            <label for="">Observacion / comentarios</label>
            <v-textarea
              hide-details
              outlined
              dense
              class="grey lighten-4"
              height="50"
              v-model="visitaIngreso.observacion"
            ></v-textarea>
          </v-col>
          <v-col cols="6" class="py-1 black--text font-weight-bold">
              <small>Cargar Evidencia (opcional)</small>
              <v-file-input
                @change="addEvidenciaVisita"
                :value="visitaIngreso.imagen"
                truncate-length="15"
                outlined
                class="grey lighten-4"
                hide-details="auto"
                dense
                accept="image/png, image/jpeg, image/jpg"
                name="evidencia_visita[]"
              ></v-file-input>
            </v-col>
          <v-col cols="12">
            <v-btn :loading="loadingIngreso" :disabled="!formValidate" @click="setApiMarcarIngreso()" block elevation="0" class="bgCTM white--text"
              >MARCAR INGRESO</v-btn
            >
          </v-col>
        </v-row>
       </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  mixins: [Filters],
  data() {
    return {
      formValidatePersonal: true,
      formValidateMaterial: true,
      formValidate: true,
      personaladd: false,
      materialadd: false,
      headerPersonal: [
        {
          text: "DNI",
          value: "dni",
          sortable: false,
          width: 40,
        },
        {
          text: "APELLIDOS Y NOMBRES",
          value: "nombrecompleto",
          sortable: false,
          width: 250,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: 40,
        },
      ],
      headers: [
        {
          text: "Cant.",
          value: "cantidad",
          sortable: false,
          width: 30,
        },
        {
          text: "Descripción.",
          value: "descripcion",
          sortable: false,
          width: 150,
        },
        {
          text: "Marca.",
          value: "marca",
          sortable: false,
          width: 90,
        },
        {
          text: "Serie.",
          value: "codigo",
          sortable: false,
          width: 90,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: 40,
        },
      ],
    };
  },
  computed: {
    ...mapState('visitas/configuracion',['areasvisitas']),
    ...mapState("visitas/registrarIngreso", [
      "visitaIngreso",
      "personal",
      "loading",
      "material",
      "alert",
      "materialesIngresados",
      "loadingIngreso"
    ]),

  },

  methods: {
    ...mapMutations("visitas/registrarIngreso", ["setPersonal", "delPersonal", "delMaterial", 'setMaterial', 'delMaterial', 'addEvidenciaVisita']),
    ...mapActions("visitas/registrarIngreso", ["getApiReniecAxios", "setApiMarcarIngreso"]),
  },
};
</script>
