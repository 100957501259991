<template>
  <div>
    <div class="d-flex justify-space-between">
      <h4>REPORTE </h4>
      <v-btn class="bgCTM white--text" small @click="setResetComponentReportes()">
        <v-icon left>mdi-arrow-left-thick</v-icon>
        volver a lista</v-btn>
    </div>
    <v-card elevation="0" class="mt-2 rounded-lg">
      <v-card-actions
        class="mb-0 white--text indigo align-center py-1 justify-space-between"
        v-if="reporteSeleccionado.cama.huesped"
      >
        <small class="text-left">Cama ocupada por: </small>
        <div class="mt-1 text-center">
          <v-chip x-small class="white font-weight-bold ma-1"
            ><v-icon x-small left>mdi-account</v-icon>
            {{ reporteSeleccionado.cama.huesped.nombrecompleto }}</v-chip
          >
          <v-chip
            x-small
            class="white font-weight-bold ma-1"
            v-if="reporteSeleccionado.cama.huesped.admision"
            ><v-icon x-small left>mdi-school</v-icon>
            {{ reporteSeleccionado.cama.huesped.admision }}</v-chip
          >
          <v-chip
            x-small
            class="white font-weight-bold ma-1"
            v-if="reporteSeleccionado.cama.huesped.carrera"
            ><v-icon x-small left>mdi-briefcase</v-icon>
            {{ reporteSeleccionado.cama.huesped.carrera }}</v-chip
          >
          <v-chip
            x-small
            class="white font-weight-bold ma-1"
            v-if="reporteSeleccionado.cama.huesped.cargo"
            ><v-icon x-small left>mdi-briefcase</v-icon>
            {{ reporteSeleccionado.cama.huesped.cargo }}</v-chip
          >
        </div>
      </v-card-actions>

      <v-card-text
        v-if="!reporteSeleccionado.cama.indexcama"
        class="text-center"
      >
        Selecciona un reporte para ver el detalle
      </v-card-text>
      <v-card-text
        class="black--text py-1"
        v-if="reporteSeleccionado.cama.indexcama"
      >
        <div class="d-flex pa-2">
          <div class="pa-2 mr-2 d-flex justify-center align-center">
            <v-badge avatar bordered overlap bottom>
              <v-avatar
                size="45"
                rounded="sm"
                :color="colorAvatarCama(reporteSeleccionado.cama.state)"
              >
                <h2 class="font-weight-bold white--text">
                  {{ reporteSeleccionado.cama.indexcama }}
                </h2>
              </v-avatar>
              <template v-slot:badge>
                <v-avatar
                  :color="colorAvatarCama(reporteSeleccionado.cama.state)"
                >
                  <v-icon color="white--text"
                    >{{
                      reporteSeleccionado.cama.huesped
                        ? "mdi-account"
                        : "mdi-bed-empty"
                    }}
                  </v-icon>
                </v-avatar>
              </template>
            </v-badge>
          </div>
          <div class="pa-2" style="width: 100%">
            <div class="text-titulo font-weight-bold">
              {{ reporteSeleccionado.reporte.titulo_reporte }}
            </div>
            <div class="d-flex justify-space-between" style="width: 100%">
              <span class="mr-2">{{ formatFecha(reporteSeleccionado.reporte.fecha_reporte) }}</span>
             
              <v-chip small class="font-weight-bold pl-2" v-if="reporteSeleccionado.reporte.users">
                <v-avatar class="bgCTM" left><v-icon color="white" small>mdi-account</v-icon></v-avatar>
                {{formatUserName(reporteSeleccionado.reporte.users.nombres)}}</v-chip>
            </div>
          </div>
        </div>

        <!---------------------------------------------------------->
        <!--------------CUERPO DE REPORTE------------>
         <v-btn
          @click="dialogEvidencia = !dialogEvidencia"
          color="teal"
          dark
          v-if="reporteSeleccionado.reporte.evidencia && $vuetify.breakpoint.sm"
          elevation="0"
          x-small
          class="mx-auto py-1"
        >
          <v-icon left>mdi-image</v-icon> EVIDENCIA DE REPORTE
        </v-btn>
        <v-row class="pa-0">
          <v-col cols="12" :md="reporteSeleccionado.reporte.evidencia? 8 : 12">
            <div
              class="black--text"
              v-html="reporteSeleccionado.reporte.detalle_reporte"
            ></div>
          </v-col>
          <v-col cols="12" md="4" v-if="reporteSeleccionado.reporte.evidencia" class="d-flex flex-column justify-center">
            <h5>Evidencia de Reporte:</h5>
              <v-img :src="reporteSeleccionado.reporte.evidencia" contain aspect-ratio="1"></v-img>
              <v-btn
                @click="dialogEvidencia = !dialogEvidencia"
                color="bgCTM white--text"
                dark
                v-if="reporteSeleccionado.reporte.evidencia"
                elevation="0"
                x-small
                class="mx-auto py-1"
              >
                <v-icon left>mdi-fullscreen</v-icon> AMPLIAR
        </v-btn>
          </v-col>
        </v-row> 
      </v-card-text>
      <!--------------CUERPO DE REPORTE------------>
      <!---------------------------------------------------------->

      <!---------------------------------------------------------->
      <!--------------SOLUCIONAR REPORTE------------>

      <v-btn v-if="$can('update', 'reportes') &&
          reporteSeleccionado.cama.indexcama &&
          reporteSeleccionado.reporte.estadoReporte == 'no-solucionado'"
      @click="setSolucionarReporte({
                  idreporte: reporteSeleccionado.reporte.idreporte,
                  idcama: reporteSeleccionado.cama.idcama,
                })"    
      small class="green accent-3 white--text mx-3" elevation="0"><v-icon left>mdi-check-decagram</v-icon> SOLUCIONAR REPORTE</v-btn>

      <!--------------SOLUCIONAR REPORTE------------>
      <!---------------------------------------------------------->



      <!---------------------------------------------------------->
      <!--------------RESPONDER REPORTE------------>

      <v-expansion-panels
      flat
        v-if="
          $can('update', 'reportes') &&
          reporteSeleccionado.cama.indexcama &&
          reporteSeleccionado.reporte.estadoReporte == 'no-solucionado'
        "
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold">
            <div>
              <v-avatar color="bgCTM mr-2" size="30"
                ><v-icon color="white" small
                  >mdi-comment-text-multiple-outline</v-icon
                ></v-avatar
              >
              AGREGAR COMENTARIO
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Editor
              style="width: 100%"
              api-key="1r2zuk2tv5ekuxqpas860aoxogwsyzonb7k871tbr4lmeogz"
              :init="{
                plugins: 'lists link paste',
                toolbar:
                  'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ',
              }"
              :rules="[rules.required]"
              v-model="solucionReporte.detalle_solucion"
            ></Editor>

            <div class="d-flex full-width" style="width: 100%">

              <div class="pa-4" style="width: 50%">
                <v-file-input
                  type="file"
                  accept="image/*,.pdf"
                  @change="addEvidenciaSolucion"
                  outlined
                  hide-details="auto"
                  placeholder="Adjuntar evidencia"
                  dense
                ></v-file-input>
              </div>
            </div>

            <v-btn
              :disabled="solucionReporte.detalle_solucion.length < 30"
              @click="
                setResponderReporteAxios({
                  idreporte: reporteSeleccionado.reporte.idreporte,
                  idcama: reporteSeleccionado.cama.idcama,
                })
              "
              block
              class="bgCTM white--text my-4"
              elevation="0"
              > COMENTAR
            </v-btn>
            <v-alert
              v-if="solucionReporte.isSolucion == 'SI'"
              class="blue lighten-5 blue--text darken-4--text font-weight-bold"
              dense
            >
              <template v-slot:prepend>
                <v-icon left color="blue darken-4">mdi-information</v-icon>
              </template>
              Recuerda que debes solucionar todos los reportes de esta cama,
              para que el estado cambie
            </v-alert>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!--------------RESPONDER REPORTE------------>
      <!---------------------------------------------------------->

      <v-card-actions
        class="green accent-3 white--text"
        v-if="
          $can('update', 'reportes') &&
          reporteSeleccionado.cama.indexcama &&
          reporteSeleccionado.reporte.estadoReporte == 'solucionado'
        "
      >
        <small class="mx-auto"
          >Este reporte está solucionado, si desea reactivar el reporte haga
          <v-btn
            @click="
              setReactivarReporte({
                idreporte: reporteSeleccionado.reporte.idreporte,
                idcama: reporteSeleccionado.cama.idcama,
              })
            "
            color="indigo white--text ml-1"
            x-small
            elevation="0"
            >click aquí</v-btn
          >
        </small>
      </v-card-actions>

      <v-dialog
        v-model="dialogEvidencia"
        width="500"
        v-if="reporteSeleccionado.reporte.evidencia"
      >
        <v-card elevation="0" class="grey darken-3">
          <v-card-actions class="grey darken-4 white--text"
            >Evidencia de reporte:</v-card-actions
          >
          <v-card-text>
            <v-img :src="reporteSeleccionado.reporte.evidencia" contain></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>

    <v-subheader class="pa-4 grey--text">RESPUESTAS</v-subheader>
    <v-card
      elevation="11"
      class="mt-3"
      v-for="(solucion, index) in reporteSeleccionado.reporte.solucion_reporte"
      :key="index"
    >
      <v-card-subtitle
        class="d-flex justify-space-between black--text font-weight-bold text-caption indigo lighten-5 py-2"
      >
        <div>
          {{
            formatFechaCustom(solucion.created_at, "DD MMM YYYY [ - ] hh:mma")
          }}
        </div>

        <v-btn
          @click="dialogEvidenciaSolucion = !dialogEvidenciaSolucion"
          x-small
          color="indigo white--text"
          v-if="solucion.evidencia_solucion"
          elevation="0"
          ><v-icon left>mdi-image</v-icon> Evidencia adjuntada</v-btn
        >
      </v-card-subtitle>

      <v-card-text
        v-html="solucion.detalle_solucion"
        class="black--text py-2 pb-0"
      ></v-card-text>

      <v-card-actions class="pt-0 justify-space-between">
        <v-chip small class="pl-2" v-if="solucion.isSolucion == 'SI'">
          <v-avatar size="30" color="green accent-4" left
            ><v-icon small color="white">mdi-check</v-icon></v-avatar
          >
          <small class="font-weight-bold">SOLUCIÓN</small>
        </v-chip>
        <v-chip small class="pl-2">
          <v-avatar size="30" color="indigo" left
            ><v-icon small color="white">mdi-account</v-icon></v-avatar
          >
          <small class="font-weight-bold"
            >{{ formatUserName(solucion.user.nombres) }}
          </small>
        </v-chip>
      </v-card-actions>
      <v-dialog
        v-model="dialogEvidenciaSolucion"
        width="500"
        v-if="solucion.evidencia_solucion"
      >
        <v-card elevation="0" class="grey darken-3">
          <v-card-actions class="grey darken-4 white--text"
            >Evidencia de reporte:</v-card-actions
          >
          <v-card-text>
            <v-img :src="solucion.evidencia_solucion" contain></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import Editor from "@tinymce/tinymce-vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  mixins: [Filters],
  components: { 
    Editor,
  },
  data: () => ({
    dialogEvidencia: false,
    dialogEvidenciaSolucion: false,
  }),
  computed: {
    ...mapState("hospedaje/reporteCama", [
      "reporteSeleccionado",
      "solucionReporte",
    ]),
  },

  methods: {
    ...mapActions("hospedaje/reporteCama", [
      "setResponderReporteAxios",
      "setReactivarReporte",
      "setSolucionarReporte",
    ]),
    ...mapMutations("hospedaje/reporteCama", ["addEvidenciaSolucion", "setResetComponentReportes"]),
    colorAvatarCama(estado) {
      switch (estado) {
        case "inoperativa":
          return "red darken-4";
        case "reporte":
          return "amber darken-4";
        case "disponible":
          return "blue darken-4";
        case "ocupada":
          return "blue darken-4";
      }
    },
  },
};
</script>
