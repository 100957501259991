import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";
export default {
  namespaced: true,
  modules: {
    utiles,
  },
  state: {
    restorepass: {
      email: "",
    },

    updatepass:{
      password: "",
      password_confirmation: ""
    },

    changepass:{
      currentpassword: "",
      newpassword: "",
      email: ""
    },

    dialogChangepass: false,

    componentRestorepass : "ErrorValidateToken",
    loadingValidate: false
  },

  mutations:{
    setDialogChangePass(state){
      state.dialogChangepass = !state.dialogChangepass;
    }
  },

  actions: {
    sendEmailRestorePass({ commit, state }) {
      commit("utiles/resetConfigAxios");
      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });
      utiles.state.configAxios.url = utiles.state.apiUrl + "sendtokenrestore";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = state.restorepass;
       axios(utiles.state.configAxios)
       .then(resp => {
         if(resp.status == 200){
          swal({
            title: "Revisa tu bandeja de entrada",
            text: "Acabamos de enviarte un correo con el link para restablecer tu contraseña",
            icon: "success"
          })
         }
       })
       .catch(error => {
          console.log(error)
         
            if(error.response.status == 400){

              swal({
                title: "El email ingresado no está registrado",
                icon: "warning"
              })

            }else{

              swal({
                title: "Ocurrio un error",
                icon: "error"
              })
            }
          
       })
    },

    validateToken({commit, dispatch, state}, payload){
      state.loadingValidate = true
      commit('utiles/resetConfigAxios')
      utiles.state.configAxios.url = utiles.state.apiUrl + "valitokenpass";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = { token: payload.token, email: payload.email};
      axios(utiles.state.configAxios)
      .then(resp =>{
        if(resp.status == 200){
            state.componentRestorepass = "FormUpdatePass"
            state.loadingValidate = false
        }
      })
      .catch(error => {
        console.log(error)
            state.componentRestorepass = "ErrorValidateToken"
            state.loadingValidate = false
      })
    },


    setUpdatePasswordAxios({commit, state}, email){
      commit('utiles/resetConfigAxios')
      console.log(email)
      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });
      state.updatepass.email = email
      utiles.state.configAxios.url = utiles.state.apiUrl + "updatepasstoken";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = state.updatepass

      axios(utiles.state.configAxios)
      .then(response => {
        if(response.status == 200){
            swal({
              title: "Se cambio la contraseña correctamente!",
              icon: "success"
            })
        }
      })
      .catch(error => {
        console.log(error)
        if(error.response.status == 400){
          swal({
            title: "No se encontró el usuario!",
            text: "Si tienes problemas comunicate con soporte técnico",
            icon: "error"
          })

          return
        }

        swal({
          title: "Ha ocurrido un error!",
          icon: "error"
        })
      })
    },


    updatePassword({commit, state}){
      commit('utiles/resetConfigAxios')
      state.changepass.email = store.state.user.email;
      utiles.state.configAxios.url = utiles.state.apiUrl + "userpass";
      utiles.state.configAxios.method = "PUT";
      utiles.state.configAxios.data = state.changepass
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;


        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });

      axios(utiles.state.configAxios)
      .then(resp => {
        if(resp.status == 200){
          swal({
            title: "La cambió la contraseña!",
            icon: "success"
          })
        }
      })
      .catch(error => {
        console.log(error)
        if(error.response.status == 400){
          swal({
            title: "La contraseña actual es incorrecta!",
            icon: "warning"
          })

          return
        }

        swal({
          title: "Ha ocurrido un error!",
          icon: "error"
        })
      })

    }

  },
};
