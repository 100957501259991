<template>
    <v-dialog width="500" v-model="dialogAlert.open" persistent>
        <v-card class="red darken-1">
            <v-card-text class="d-flex align-center justify-center py-4">
                <v-avatar class="red lighten-4" size="250">
                    <v-img :src="require('../../assets/advertencia.png')" height="250" contain></v-img>
                </v-avatar>
            </v-card-text>
            <v-card-text>
                <div class="text-h4 teal--text text--lighten-5 text-center font-weight-bold">Ha ocurrido un error!</div>
            </v-card-text>
            <v-card-actions class="justify-center pb-6">
                <v-btn @click="onReload()" x-large class="red lighten-4 red--text px-5" elevation="0">
                    <v-icon left size="20">mdi-reload</v-icon>
                    Actualizar página
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { mapState } from 'vuex';


export default {


    computed: {
        ...mapState('disciplina/DialogosAlert', ['dialogAlert'])
    },


    methods: {
        onReload() {
            window.location.reload();

        }
    }
}
</script>