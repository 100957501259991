<template>
    <div class="d-flex  rounded-lg blue-grey lighten-4">
        <div class="d-flex pa-5 plano lineasDiagonales justify-center align-center" style="width: 2%;" >
            {{ ubicacionBano == 'bañoderecha' ? 'SALIDA' : 'BAÑOS' }}
        </div>

        <div class="pabellonOnerectangle">
            <div class="d-flex">
                <slot name="espacioCamasA"></slot>
            </div>

            <div class="pa-3 blue-grey lighten-2 white--text text-center d-block" style="width: 66vw;">
                  PASADIZO INFERIOR
            </div>
        </div>

        <div class="d-flex pa-5 plano lineasDiagonales justify-center align-center" style="width: 2%;" >
            {{ ubicacionBano == 'bañoderecha' ? 'BAÑOS' : 'SALIDA' }}
        </div>

      </div>
</template>


<script>
export default {
    props:{
        ubicacionBano:{
            type: String,
            required: true
        }
    }
}
</script>



<style>
.pabellonOnerectangle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}



.texto-vertical {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

.plano{
  margin: 5px;
  border: dotted 5px #78909C;
  border-radius: 5px;
  writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-align: center;
    font-weight: bold;
    color: #78909C;
    font-size: 20px;
  
}

.lineasDiagonales{
  background: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 25px,
      rgba(0, 0, 0, 0) 40px,
      #B0BEC5 40px,
      #B0BEC5 60px
    );
}
</style>