<template>
    <v-dialog width="500" v-model="dialogAlert.open" persistent>
        <v-card class="blue">
            <v-card-text class="d-flex align-center justify-center py-4">
                <v-avatar class="blue lighten-4" size="250">
                    <v-img :src="require('../../assets/logrado.png')" height="250" contain></v-img>
                </v-avatar>
            </v-card-text>
            <v-card-text>
                <div class="text-h4 teal--text text--lighten-5 text-center font-weight-bold">Caso registrado!</div>
            </v-card-text>
            <v-card-actions class="justify-center pb-6">
                <v-btn @click="onReload()" x-large class="blue lighten-4 blue--text px-5" elevation="0">
                    <v-icon left size="20">mdi-plus-thick</v-icon>
                    NUEVO REGISTRO
                </v-btn>
                <v-btn :to="{ name: 'listaDeCasosRegisrados' }" x-large
                    class="blue lighten-4 blue--text px-8" elevation="0">
                    <v-icon left size="25">mdi-clipboard-list-outline</v-icon>
                    IR A LISTA 
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { mapState } from 'vuex';


export default {


    computed: {
        ...mapState('disciplina/DialogosAlert', ['dialogAlert'])
    },


    methods: {
        onReload() {
            window.location.reload();

        }
    }
}
</script>