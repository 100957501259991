import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { abilitiesPlugin } from '@casl/vue';



Vue.config.productionTip = false
Vue.use(abilitiesPlugin, store.getters.ability, {
  useGlobalProperties: true
})
//Importar Modulos
import LoginModule from './modules/Login'
import PabellonesModule from './modules/Pabellones'
import HospedajeModule from './modules/Hospedaje'
import DashboardModule from './modules/Dashboard'
import UsuariosModule from './modules/Usuarios'
import VisitasModule from './modules/Visitas'
import PermisosModule from './modules/Permisos'
import TopicoModule from './modules/Topico'
import PsicologiaModule from './modules/Psicologia'
import DisciplinaModule from './modules/Disciplina'
import ComedorModule from './modules/Comedor'

//EndImportarModulos

import { registerModules } from "./register-modules";



registerModules({
  login: LoginModule,
  pabellones: PabellonesModule,
  hospedaje: HospedajeModule,
  dashboard: DashboardModule,
  usuarios: UsuariosModule,
  visitas: VisitasModule,
  permisos: PermisosModule,
  topico: TopicoModule,
  psicologia: PsicologiaModule,
  disciplina: DisciplinaModule,
  comedor: ComedorModule
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
