<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>HOSPEDAJE MASIVO</v-card-title>
      <v-card-subtitle>
        A continuación, los pasos que debes seguir para hospedar de manera
        masiva.
      </v-card-subtitle>
      <v-card-text>
        <v-list-item>
          <v-list-item-icon
            ><v-avatar class="bgCTM white--text font-weight-bold" size="30"
              >1</v-avatar
            ></v-list-item-icon
          >
          <v-list-item-content class="black--text">
            <span class="font-weight-bold"
              >Descarga la plantilla en excel
              <v-btn color="blue" text small @click="descargarArchivo()">
                <v-icon left>mdi-file-download</v-icon> plantilla de
                hospedaje</v-btn
              ></span
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon
            ><v-avatar class="bgCTM white--text font-weight-bold" size="30"
              >2</v-avatar
            ></v-list-item-icon
          >
          <v-list-item-content class="black--text">
            <span class="font-weight-bold"
              >Completa la información de la planilla tomando en cuenta las
              siguientes reglas:</span
            >
          </v-list-item-content>
        </v-list-item>
        <div class="pa-3">

          <v-list-item dense>
            <v-list-item-title class="black--text font-weight-bold">
                CONSIDERACIONES:
            </v-list-item-title>
          </v-list-item>  
          <v-list>
            <v-list-item dense v-for="(regla, index) in reglas" :key="index">
              <v-list-item-icon>
                <v-avatar class="green accent-3 white--text" size="20" left
                  ><v-icon small>mdi-check</v-icon></v-avatar
                >
              </v-list-item-icon>
              <v-list-item-content>
                <span>{{ regla }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <v-list-item>
          <v-list-item-icon
            ><v-avatar class="bgCTM white--text font-weight-bold" size="30"
              >3</v-avatar
            ></v-list-item-icon
          >
          <v-list-item-content class="black--text font-weight-bold">
            <span class="font-weight-bold"
              >Sube la plantilla y presiona SIGUIENTE
            </span>
            <div class="pa-3 bgCTM text-center rounded-lg d-flex justify-center my-2">
                <v-file-input v-model="fileExcel.file" :rules="[rules.required]" @change="uploadFileExcel" dark style="width: 200px;" truncate-length="15" dense outlined hide-details="auto" placeholder="subir excel"></v-file-input>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-card-actions class="justify-end px-4">
            <v-btn @click="readDataFileExcel" :disabled="!fileExcel.file" class="bgCTM white--text" elevation="0"><v-icon left>mdi-arrow-right-thick</v-icon> SIGUIENTE</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  mixins:[Filters],
  data() {
    return {
      reglas: [
        "Rellene todos las celdas obligatorias.",
        "Si una cama esta como inoperativa no se podrá hospedar.",
        "Si una cama ya esta ocupada, no se reasignará al nuevo huesped",
        "Para los estudiantes, se validará con el sige de no encontrar al estudiante se obtendrá los datos de la plantilla",
        "Si es un colaborador, se validará el dni con reniec y se completará la información con los datos brindados en la plantilla",
        "El sistema solo permite el archivo excel de la plantilla, si sube otro excel con los mismos campos no se aceptará"
      ],
    };
  },

  computed:{
    ...mapState('hospedaje/hospedarMasivos',['fileExcel'])
  },

  methods:{
    ...mapMutations('hospedaje/hospedarMasivos', ['uploadFileExcel']),

    ...mapActions('hospedaje/hospedarMasivos', ['readDataFileExcel']),
    descargarArchivo() {
      const url = `${process.env.BASE_URL}assets/hospedaje-masivo.xlsx`; 
        const link = document.createElement("a"); 
        link.href = url; 
        link.download = "hospedaje-masivo.xlsx"; 
        document.body.appendChild(link); 
        link.click(); 
        document.body.removeChild(link); 
    }
  }
};
</script>
