<template>

    <v-container fluid>
        <v-row class="mt-1">
            <v-col cols="12" class="pa-0">
                <v-card class="blue accent-4 background-psicologia" elevation="0">
                    <v-card-text class="white--text d-flex justify-space-between align-center py-13">
                        <div class="px-4">
                            <div class="font-weight-bold text-h4">Psicología</div>
                            <div class="text-subtitle-1">Gestión de atenciones psicológicas</div>
                        </div>
                        <div>
                            <v-btn v-if="$can('read', 'psicologia')" :to="{name: 'registroDiarioPsicologia'}" text color="white" class="mr-2"><v-icon left class="white rounded-circle blue--text pa-4"
                                    size="20">mdi-sofa-single-outline</v-icon>Atenciones</v-btn>
                            <v-btn v-if="$can('read', 'psicologia.evaluaciones')" :to="{name: 'listaEvaluaciones'}" text color="white"><v-icon left class="white rounded-circle blue--text pa-4"
                                    size="20">mdi-book-open-variant-outline</v-icon>Evaluaciones</v-btn>
                        </div>
                    </v-card-text>
                    <v-card-actions class="blue darken-4 d-flex justify-center">
                            <div v-if="$route.meta.seccion == 'atenciones' && $can('read', 'psicologia')">
                                <v-btn :to="{name: 'registroDiarioPsicologia'}" text color="white" class="mr-2">Registro diario</v-btn>
                                <v-btn  :to="{name: 'historiasPacientesPsicologia'}" text color="white">Historias</v-btn>
                            </div>
                            <div v-if="$route.meta.seccion == 'evaluaciones' && $can('read', 'psicologia.evaluaciones')">
                                <v-btn :to="{name: 'listaEvaluaciones'}"   text color="white" class="mr-2">Lista de evaluaciones</v-btn>
                                <v-btn :to="{name: 'resultadoDeEvaluaciones'}" text class="mr-2" color="white">Resultados de evaluaciones</v-btn>
                                <v-btn v-if="$can('create', 'psicologia.evaluaciones')" @click="onGenerateEvaluacion()" class="blue accent-4 white--text" elevation="0">CREAR NUEVO EXAMEN</v-btn>
                            </div>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" class="pa-0">
                <router-view name="psicologiaView"></router-view>
            </v-col>
        </v-row>
    </v-container>

</template>


<script>
import { mapActions, mapMutations } from 'vuex';


export default{

    methods:{
        ...mapMutations('psicologia/Evaluaciones',['resetExamen']),
        ...mapActions('psicologia/Evaluaciones',['generateEvaluacion']),

        onGenerateEvaluacion()
        {
            this.resetExamen() 
            this.generateEvaluacion()
        }
    },
    mounted()
    {
        console.log(this.$route)
    }
}
</script>


<style>
.background-psicologia{
    background-image: url('../assets/paz-mental.svg'); 
    clip-path: url("../assets/paz-mental.svg");
    background-position: 50% 30%;
    background-size: 40%;
}
</style> 