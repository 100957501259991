<template>
  <div class="d-flex" style="height: 100vh">
    <v-card elevation="0" width="100%" class="transparent">
      <v-card-text class="text-left black--text">
        <v-btn @click="openListPermisos()" elevation="0" small class="bgCTM white--text "><v-icon left color="white">mdi-arrow-left</v-icon>REGRESAR</v-btn>
        <div class="font-weight-bold text-subtitle-2 text-center"> DETALLE DE PERMISO DE SALIDA A ESTUDIANTE:</div>

        <div class="pa-2">

            <v-simple-table>
                <tbody>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width: 30%;">CREADO:</td>
                        <td style="width: 25%;">{{ formatFechaCustom(permiso.created_at, 'DD/MM/YYYY') }}</td>
                        <td class="grey lighten-4 font-weight-bold" style="width: 20%;">SEDE:</td>
                        <td style="width: 25%;">{{ permiso.sede }}</td>
                    </tr>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width: 30%;">ESTADO:</td>
                        <td colspan="3">{{ permiso.estado == 'enviado'? 'SIN USAR' : permiso.estado }}</td>
                        
                    </tr>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width: 30%;">DNI:</td>
                        <td style="width: 25%;">{{ permiso.dni }}</td>
                        <td class="grey lighten-4 font-weight-bold" style="width: 20%;">ADMISIÓN:</td>
                        <td style="width: 25%;">{{ permiso.admision }}</td>
                    </tr>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width:30%;">ESTUDIANTE:</td>
                        <td colspan="3">
                            <div class="py-2">{{ permiso.nombrecompleto }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width:30%;">CARRERA:</td>
                        <td colspan="3">
                            <div class="py-2">{{ permiso.carrera }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width:30%;">MOTIVO:
                        </td>
                        <td colspan="3">{{ permiso.motivo }}</td>
                    </tr>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width:30%;">LUGAR QUE SE DIRIGE:
                        </td>
                        <td colspan="3">{{ permiso.lugar_dirige }}</td>
                    </tr>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width:30%;">AUTORIZADO POR:
                        </td>
                        <td colspan="3">
                            <div >
                                {{ permiso.users.nombres + ' ' + permiso.users.apellidos }}
                            </div>
                            <div class="font-weight-bold">{{ permiso.users.cargo }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width: 30%;">FECHA SALIDA:</td>
                        <td style="width: 25%;">{{ formatFechaCustom(permiso.fecha_salida, 'DD/MM/YYYY')  }}</td>
                        <td class="grey lighten-4 font-weight-bold" style="width: 20%;">HORA SALIDA:</td>
                        <td style="width: 25%;">{{ formatFechaCustom(permiso.fecha_salida + ' ' + permiso.hora_salida, 'hh:mm a')  }}</td>
                    </tr>
                    <tr>
                        <td class="grey lighten-4 font-weight-bold" style="width: 30%;">FECHA RETORNO</td>
                        <td style="width: 25%;">{{ formatFechaCustom(permiso.fecha_ingreso, 'DD/MM/YYYY')  }}</td>
                        <td class="grey lighten-4 font-weight-bold" style="width: 20%;">HORA RETORNO:
                        </td>
                        <td style="width: 25%;">{{ formatFechaCustom(permiso.fecha_ingreso + ' ' + permiso.hora_ingreso, 'hh:mm a')  }}</td>
                    </tr>

            </tbody>
            </v-simple-table>




        </div>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
    mixins:[Filters],
    data(){
        return{
            evidencia: false,
        }
    },
    computed:{
        ...mapState('permisos', ['permiso'])
    },
    methods:{
        ...mapMutations('permisos', ['openListPermisos']),
        ...mapActions('visitas', ['getListarPermisosToSede']),
        isNull(value){

            return !value? '' : value;
        }
    },

    
}
</script>