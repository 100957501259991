<template>
    <v-navigation-drawer
      stateless
      v-model="silderderecha.open"
      right 
      temporary
      :width="silderderecha.width"
      app
    >
      <v-btn small icon class="bgCTM white--text mt-2 ml-2 mb-0 d-lg-none rounded"  elevation="0"  @click="silderderecha.open = !silderderecha.open"><v-icon>mdi-close</v-icon></v-btn>
      <component :is="silderderecha.component" v-bind="silderderecha.props"/>
      <v-avatar
        style="cursor: pointer"
        size="40"
        rounded
        class="bgCTM close-flotante d-none d-lg-inline-block"
        @click="silderderecha.open = !silderderecha.open"
      >
        <v-icon color="white">mdi-close-thick</v-icon>
      </v-avatar>
    </v-navigation-drawer>
  </template>


<script>
import { mapState } from 'vuex';


export default{
    computed:{
        ...mapState('silderDerechaComponent', ['silderderecha'])
    }
}

</script>

<style scoped>
.close-flotante {
  position: absolute;
  margin: 10px;
  top: 0;
  left: -50px;
  z-index: 1000;
}

.v-navigation-drawer {
  overflow: visible !important;
}
</style>