<template>
  <div class="black--text" style="width: 100%">
    <v-alert
        v-if="camaSeleccionada.cama.state == 'reporte' "
      text
      outlined
      color="deep-orange"
      icon="mdi-alert"
      dense
    >
      <div class="font-weight-bold text-subtitle-2 d-flex justify-space-between">
        <span>HAY UNO O MÁS REPORTES PARA ESTA CAMA</span>
        <v-btn @click="verReporteCama()" icon small class="ml-auto deep-orange white--text px-1"><v-icon>mdi-chevron-right</v-icon></v-btn>
      </div>   
    </v-alert>

    <div class="text-caption indigo white--text px-3 rounded-pill">
      DATOS DEL HOSPEDADO
    </div>
    <div class="py-1 px-3">
      <v-list-item>
        <v-list-item-icon>
          <v-avatar color="indigo"
            ><v-icon color="white">mdi-account-check</v-icon></v-avatar
          ></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold" style="white-space: pre-wrap;">{{
            hospedado.nombrecompleto
          }}</v-list-item-title>
          <v-list-item-subtitle v-if="hospedado.tipo == 'estudiante'" style="white-space: pre-wrap;">{{
            hospedado.carrera
          }}</v-list-item-subtitle>
          <v-list-item-subtitle  v-if="hospedado.tipo == 'colaborador'" style="white-space: pre-wrap;" class="black--text">
            <div class="d-block">{{hospedado.jefatura}}</div>
            <div class="d-block text-caption">{{hospedado.cargo}}</div>
          </v-list-item-subtitle>
          <div class="d-block">
            DNI:{{ hospedado.dni }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </div>

    <div class="text-caption font-weight-bold py-1 px-3">
      DÍAS DE LA SEMANA QUE PERMANECERÁ HOSPEDADO
    </div>
    <v-rating
      length="7"
      v-model="hospedado.dias_semana"
      readonly
      dense
      color="primary"
      class="d-flex justify-center mb-3"
    >
      <template v-slot:item="props">
        <template v-if="props.index === 0">
          <v-avatar
            @click="props.click"
            size="40"
            :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
            class="mr-2"
          >
            <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
              L
            </h3>
          </v-avatar>
        </template>
        <template v-if="props.index === 1">
          <v-avatar
            @click="props.click"
            size="40"
            :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
            class="mr-2"
          >
            <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
              M
            </h3>
          </v-avatar>
        </template>
        <template v-if="props.index === 2">
          <v-avatar
            @click="props.click"
            size="40"
            :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
            class="mr-2"
          >
            <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
              Mc
            </h3>
          </v-avatar>
        </template>
        <template v-if="props.index === 3">
          <v-avatar
            @click="props.click"
            size="40"
            :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
            class="mr-2"
          >
            <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
              J
            </h3>
          </v-avatar>
        </template>
        <template v-if="props.index === 4">
          <v-avatar
            @click="props.click"
            size="40"
            :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
            class="mr-2"
          >
            <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
              V
            </h3>
          </v-avatar>
        </template>
        <template v-if="props.index === 5">
          <v-avatar
            @click="props.click"
            size="40"
            :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
            class="mr-2"
          >
            <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
              S
            </h3>
          </v-avatar>
        </template>
        <template v-if="props.index === 6">
          <v-avatar
            @click="props.click"
            size="40"
            :color="props.isFilled ? 'indigo' : 'blue-grey lighten-4'"
            class="mr-2"
          >
            <h3 :class="props.isFilled ? 'white--text' : 'blue-grey--text'">
              D
            </h3>
          </v-avatar>
        </template>
      </template>
    </v-rating>

    <div class="d-flex justify-space-around mt-3">
      <v-chip label large color="cyan lighten-5">
        <v-avatar  color="teal darken-4">
          <v-icon small color="white">mdi-calendar</v-icon>
        </v-avatar>
        <div class="d-flex flex-column px-3 teal--text text--darken-4">
          <div class="font-weight-bold" style="line-height: 1">
            {{ formatFecha(hospedado.fecha_ingreso) }}
          </div>
          <div class="text-caption">Fecha de ingreso</div>
        </div>
      </v-chip>

      <v-chip label large color="teal lighten-5">
         <v-avatar  color="teal darken-4">
          <v-icon small color="white">mdi-calendar</v-icon>
        </v-avatar>
        <div class="d-flex flex-column px-3 cyan--text text--darken-4">
          <div class="font-weight-bold" style="line-height: 1">
            {{ formatFecha(hospedado.fecha_salida) }}
          </div>
          <div class="text-caption">Fecha de salida</div>
        </div>
      </v-chip>
    </div>

    <div class="text-caption indigo white--text px-3 my-3 rounded-pill">
      IMPLEMENTOS ENTREGADOS
    </div>
    <div class="d-flex flex-wrap">
      <div class="pa-2">
        <v-chip
          class="font-weight-bold black--text"
          :disabled="!hospedado.almohada"
        >
          <v-avatar left color="light-green accent-4"
            ><v-icon class="white--text">mdi-check</v-icon></v-avatar
          >
          Almohada
        </v-chip>
      </div>

      <div class="pa-2">
        <v-chip
          class="font-weight-bold black--text"
          :disabled="!hospedado.funda_almohada"
        >
          <v-avatar left color="light-green accent-4"
            ><v-icon class="white--text">mdi-check</v-icon></v-avatar
          >
          Funda de Almohada
        </v-chip>
      </div>

      <div class="pa-2">
        <v-chip
          class="font-weight-bold black--text"
          :disabled="!hospedado.frazada"
        >
          <v-avatar left color="light-green accent-4"
            ><v-icon class="white--text">mdi-check</v-icon></v-avatar
          >
          Frazada
        </v-chip>
      </div>

      <div class="pa-2">
        <v-chip
          class="font-weight-bold black--text"
          :disabled="!hospedado.sabana"
        >
          <v-avatar left color="light-green accent-4"
            ><v-icon class="white--text">mdi-check</v-icon></v-avatar
          >
          Sabana
        </v-chip>
      </div>

      <div class="pa-2">
        <v-chip
          class="font-weight-bold black--text"
          :disabled="!hospedado.locker"
        >
          <v-avatar left color="light-green accent-4"
            ><v-icon class="white--text">mdi-check</v-icon></v-avatar
          >
          Locker
        </v-chip>
      </div>
    </div>

    <!--BOTONES DE ACTION-->
    <div class="d-flex justify-end">
      <v-btn v-if="$can('update', 'hospedaje')" @click="cambiarCamaHospedado({idpabellon: idpabellon, hospedado: hospedado})" class="blue white--text mr-2" icon elevation="0 "
        ><v-icon>mdi-swap-horizontal</v-icon></v-btn
      >
      <v-btn v-if="$can('update', 'hospedaje')" @click="setDesalojoHuespedAxios({idpabellon: idpabellon, idhuesped: hospedado.idhuesped})" class="red white--text mr-2" icon elevation="0 "
        ><v-icon>mdi-run</v-icon></v-btn
      >
      <v-btn v-if="$can('update', 'hospedaje')" @click="editHospedado(hospedado)" class="amber darken-3 white--text" icon elevation="0 "
        ><v-icon small>mdi-pencil</v-icon></v-btn
      >
    </div>
    <!--BOTONES DE ACTION-->
  </div>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  mixins: [Filters],
  props: {
    hospedado: {
      type: Object,
      required: true,
    },
  },
  data:() => ({
    idpabellon : 0
  }),
  computed:{
    ...mapState('hospedaje', ['camaSeleccionada'])
  },
  methods:{
    ...mapActions('hospedaje', ['setDesalojoHuespedAxios']),
    ...mapMutations('hospedaje', ['editHospedado', 'cambiarCamaHospedado', 'verReporteCama'])
  },

  mounted(){
    if(this.$route.params.id){
      this.idpabellon = this.$route.params.id
    }
  }
};
</script>
