import Module from './Module.vue';
import PermisosView from './views/PermisosView.vue'
const moduleRoute = {
    path: "/permisos",
    component: Module,
    children: [
      {
        path: "/",
        component: PermisosView,
        name: 'permisos',
        meta: {
          requiresAuth: true,
          title: "Permisos",
        },
      }
    ]
};
  
export default router => {
    router.addRoute(moduleRoute);
  };