import store from "@/store"
import PacientePsicologiaService from '../services/PacientePsicologiaService'
import ApiEstudianteService from "@/modules/Shared/services/ApiEstudianteService";
import readXlsxFile from 'read-excel-file'


const ppservice = new PacientePsicologiaService
const apiEstudiante = new ApiEstudianteService

export default{
    namespaced: true,
    state:{
        pacientePsicologia:{
            dni: "",
            nombre_completo: "",
            fecha_nacimiento: "",
            direccion: "",
            telefono: "",
            carrera: "",
            admision: "",
            nombre_contacto: "",
            celular_contacto: "",
            sede: ""
        },
        pacientePsicologiaDefault:{
            dni: "",
            nombre_completo: "",
            fecha_nacimiento: "",
            direccion: "",
            telefono: "",
            carrera: "",
            admision: "",
            nombre_contacto: "",
            celular_contacto: "",
            sede: ""
        },
        findPaciente:{
            dni: ""
        },

        filterPaciente:{
            nombreDni: "",
            carrera:"",
            admision: "",
            sede: ""
        },
        filterPacienteDefault:{
            nombreDni: "",
            carrera:"",
            admision: "",
            sede: ""
        },

        listaPacientes: [],

        pacientesMasivo:[],

        loadingSavePaciente: false,
        loadingListPacientes: false,
        loadingFindPaciente:false,

        notfound: false,
        loadingPacienteMasivo:false
    },
    mutations:{
        setPacientePsico(state, payload){
            state.pacientePsicologia = Object.assign({}, payload)
        },

        setListPacientesPsico(state, payload){
            state.listaPacientes = payload
        },

        resetPaciente(state)
        {
            state.pacientePsicologia = Object.assign({}, state.pacientePsicologiaDefault)
        },

        setDataStudent(state, data){
            state.pacientePsicologia.nombre_completo = data.result.nomalu;
            state.pacientePsicologia.carrera = data.result.specialtyName;
            var yeradmin = data.result.period.substr(0, 4);
            var periodAdmin = data.result.period.substr(
              4,
              data.result.period.length - 1
            );

            state.pacientePsicologia.admision = yeradmin + "-" + periodAdmin;
        },

        readExcelToMasivo(state, file)
        {
            readXlsxFile(file)
            .then((rows) => {

                
                
                for(var i=2; i < rows.length; i++)
                {
                    
                    if(rows[i][0] == '')
                    {
                        continue;
                    }
                    console.log(rows)
                    state.pacientesMasivo.push({
                        dni: rows[i][0],
                        nombre_completo: rows[i][1],
                        sexo: rows[i][2],
                        fecha_nacimiento: rows[i][3],
                        carrera: rows[i][4],
                        admision: rows[i][5],
                        sede: rows[i][6],
                        celular: rows[i][7],
                        direccion: rows[i][8],
                        nombre_contacto: rows[i][9],
                        celular_contacto: rows[i][10]
                    })
                    

                }
                console.log(state.pacientesMasivo)
              })
            .catch(error => {
                console.log(error)
                swal({
                    title: "Ha ocurrido un error!"
                })
            })
        },

        resetPacientesMasivos(state)
        {
            state.pacientesMasivo = []
        }

    },
    actions:{
        async getAllPacientesePsico({state, commit, dispatch}){
            state.loadingListPacientes = true
            try
            {
               
                const data = await ppservice.getPaciente(state.filterPaciente) 
                commit('setListPacientesPsico', data)
                state.loadingListPacientes = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingListPacientes = false

            }
        },


        async getApiData({state, commit, dispatch})
        {
            try{
                const data = await apiEstudiante.get(state.pacientePsicologia.dni)
                commit('setDataStudent', data)
            }
            catch(error)
            {
                console.log(error)
            }
        },

        async finPacientePsico({state, commit})
        {   
            state.loadingFindPaciente = true
            commit('resetPaciente')
            try
            {
                const data = await ppservice.findPaciente({dni: state.findPaciente.dni }) 
                commit('setPacientePsico', data)
                state.loadingFindPaciente = false
                state.notfound = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingFindPaciente = false
                state.notfound = true

            }
        },

        async savePacientePsico({state, commit, dispatch}){
            state.loadingSavePaciente = true
            try
            {
                const data = await ppservice.addPaciente(state.pacientePsicologia) 
                dispatch('getAllPacientesePsico')
                state.loadingSavePaciente = false
                store.commit('silderDerechaComponent/closeSilder')
                store.commit('psicologia/AtencionPsicologica/seleccionarPaciente', data)
                commit('setPacientePsico', data)
                state.notfound = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingSavePaciente = false

            }
        },

        async addPacienteMasive({state, commit, dispatch})
        {
            state.loadingPacienteMasivo = true
            try
            {
                const data = await ppservice.addPacienteMasive({pacientes: state.pacientesMasivo})
                dispatch('getAllPacientesePsico')
                commit('resetPacientesMasivos')
                store.commit('silderDerechaComponent/closeSilder')
                state.loadingPacienteMasivo = false
                swal({
                    title: "GUARDADO EXITOSAMENTE",
                    icon: "success"
                })
            }
            catch(error)
            {
                console.log(error)
                state.loadingPacienteMasivo = false
                swal({
                    title: "Error al guardar",
                    icon: "error"
                })
            }
        }
       
    }
}