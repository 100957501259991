<template>
  <v-navigation-drawer
    stateless
    v-model="dialogVisitaPreview.dialog"
    right 
    temporary
    :width="dialogVisitaPreview.width"
    app
  >
    <v-btn small icon class="bgCTM white--text mt-2 ml-2 mb-0 d-lg-none rounded"  elevation="0"  @click="dialogVisitaPreview.dialog = !dialogVisitaPreview.dialog"><v-icon>mdi-close</v-icon></v-btn>
    <component :is="componentSlider" />
    <v-avatar
      style="cursor: pointer"
      size="40"
      rounded
      class="bgCTM close-flotante d-none d-lg-inline-block"
      @click="dialogVisitaPreview.dialog = !dialogVisitaPreview.dialog"
    >
      <v-icon color="white">mdi-close-thick</v-icon>
    </v-avatar>
  </v-navigation-drawer>
</template>

<script>
import FormRegistrarVisita from "./VisitasDiaComponents/FormRegistrarVisita.vue";
import VisitaPreview from "./VisitasDiaComponents/VisitaPreview.vue";
import MarcarIngreso from './VisitasDiaComponents/MarcarIngreso.vue'
import MarcarSalida from './VisitasDiaComponents/MarcarSalida.vue';
import cargarPlantilla from './CargasMasivasComponent/cargarPlantilla.vue'
import resultadoCargaPlantilla from './CargasMasivasComponent/resultadoCargaPlantilla.vue';
import resumenCarga from './CargasMasivasComponent/resumenCarga.vue';
import nuevoPermiso from '@/modules/Permisos/components/formularios/nuevo-permiso.vue'
import permisosMasivos from '@/modules/Permisos/components/formularios/permisos-masivos.vue'
import ManejarPermisosAgente from './Permisos/ManejarPermisosAgente.vue';
import ViewPermisoAgente from './Permisos/ViewPermisoAgente.vue';
import InsertMedicamento from '@/modules/Topico/components/formularios/InsertMedicamento.vue'
import ViewPermiso from "@/modules/Permisos/components/ViewPermiso.vue";
import FormPacienteRegister from "@/modules/Topico/components/paciente/FormPacienteRegister.vue";
import ResultadoPermisosMasivos from "@/modules/Permisos/components/formularios/resultadoPermisosMasivos.vue";

import { mapMutations, mapState } from "vuex";
export default {
  components: {
    VisitaPreview,
    FormRegistrarVisita,
    MarcarIngreso,
    MarcarSalida,
    cargarPlantilla,
    resultadoCargaPlantilla,
    resumenCarga,
    nuevoPermiso,
    ManejarPermisosAgente,
    ViewPermisoAgente,
    InsertMedicamento,
    ViewPermiso,
    FormPacienteRegister,
    permisosMasivos,
    ResultadoPermisosMasivos
  },
  computed: {
    ...mapState("visitas", ["dialogVisitaPreview", "componentSlider"]),
  },
};
</script>

<style scoped>
.close-flotante {
  position: absolute;
  margin: 10px;
  top: 0;
  left: -50px;
  z-index: 1000;
}

.v-navigation-drawer {
  overflow: visible !important;
}
</style>
