import utiles from "@/modules/Shared/utiles";
import estudiantes from "./estudiantes";
import HorarioComedor from "./HorarioComedor";
import bloqueshorario from "./bloqueshorario";
import capacidadcomedor from "./capacidadcomedor";
import bloquesSeleccionados from "./bloquesSeleccionados";
import verificarIngresoComedor from "./verificarIngresoComedor";
import menuSemanal from "./menuSemanal";

export default {
  modules: {
    utiles,
    estudiantes,
    HorarioComedor,
    bloqueshorario,
    capacidadcomedor,
    bloquesSeleccionados,
    verificarIngresoComedor,
    menuSemanal
    
  },
  namespaced: true,
  state: {},
};
