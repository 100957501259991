<template>
  <v-app>
    <BackgroundGradient />
    <NavigationDrawer v-if="getterLogginIn" />
    
    <v-main class="blue-grey lighten-4">
      <v-container fluid>
        <navbar-up v-if="getterLogginIn" />
        <router-view />
        <SliderDerecha v-if="getterLogginIn" />
        <SilderDerechaComponent v-if="getterLogginIn"></SilderDerechaComponent>
      </v-container>
    <v-footer>
      <v-col cols="12" class="d-md-flex justify-space-between align-center">
        <small class="d-block mb-0"
          >OPERATIC - <b> CETEMIN {{ yearCurrent }}</b></small
        >
       
        <div v-if="getterLogginIn" style="font-size: 14px; line-height: 1" class="font-weight-bold">Developed by Daniel Arellano - TIC </div>
      </v-col>
    </v-footer>
    </v-main>


    <v-overlay
      opacity="1"
      color="white"
      :value="overlayGeneral"
      z-index="100"
      class="d-flex align-center justify-space-center"
    >
      <v-img
        width="90"
        :src="require('@/assets/cetemin300x300.png')"
        class="mx-auto animate__animated animate__wobble"
      ></v-img>
      <h3
        class="font-weight-bold black--text pa-2 animate__animated animate__flipInX animate__delay-2s"
      >
        OPERATIC
      </h3>
    </v-overlay>



  </v-app>
</template>

<script>

import SliderDerecha from "./modules/Visitas/components/SliderDerecha.vue";

import BackgroundGradient from "./components/BackgroundGradient.vue";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import NavbarUp from "./components/NavbarUp.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import store from "./store";
import moment from "moment";
import { socket } from "./socket-cliente";
import SilderDerechaComponent from "./modules/Shared/components/SilderDerechaComponent.vue";

export default {
  name: "App",
  components: {
    NavigationDrawer,
    NavbarUp,
    BackgroundGradient,
    SliderDerecha,
    SilderDerechaComponent
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapGetters(["getterLogginIn"]),
    ...mapState(["overlayGeneral"]),
    yearCurrent() {
      return moment().format("YYYY");
    },
  },
  methods:{
    ...mapActions('login', ['logout']),
    ...mapActions('visitas/configuracion',['getApiMotivos', 'getApiAreasColaborador', 'getApiAreasVisita', 'getApiEmpresasTerceros'])
  },
  created() {
    if (store.getters.getterLogginIn) {
      store.commit("login/setUser", {
        user: store.state.user,
        token: store.state.user.token,
      });
    }

    let iduser = this.$store.state.user.iduser;
    
    socket.on('LOGOUT_AT_LOCKET', (iduser)=> {
       if(iduser === this.$store.state.user.iduser){
          this.logout();
       }
    })
  },

  mounted(){
    // CARGAR LISTA DE CONFIGURACIONES
    socket.on('LOAD_AREAS_COLABORADORES', (load)=> {
        this.getApiAreasColaborador(load);
    })

    socket.on('LOAD_AREAS_VISITA', (load)=> {
      this.getApiAreasVisita(load)
    }) 


    socket.on('LOAD_MOTIVOS', (load)=> {
      this.getApiMotivos(load)
    })

    socket.on('LOAD_TERCEROS', (load)=> {
      this.getApiEmpresasTerceros(load)
    })

  }
};
</script>

<style>
:root {
  --animate-delay: 0.1s;
}
.bgCTM {
  background-color: #00192a !important;
}
.text-CTM{
  color: #00192a !important;
}
.bgCTMHover {
  background-color: #112f43 !important;
}
.bg {
  background-color: rgb(236, 236, 236);
}

.swal-title,
.swal-text,
.swal-button {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>
