import store from "@/store";
import AtencionMedicaService from "../service/atencionMedicaService";
import PacienteService from "../service/pacienteService";
import visitas from "@/modules/Visitas/store/index";

const AtencionMedicaSV = new AtencionMedicaService();

const PacienteSV = new PacienteService();

export default {
  namespaced: true,
  state: {
    pacintesAtendidos: [],
    listAtenciones: [],
    listAtencionesDiarias: [],

    paginate: {
      current_page: 1,
      from: 1,
      last_page: 0,
      per_page: 20,
      to: 2,
      total: 0,
    },

    option: {
        page: 1,
        itemsPerPage: 20,
      },

    filterpaciente: {
      dni: "",
      sedes: "",
      orderByCreate: true,
      nombreDni: "",
      admision: "",
    },
    filteratenciones: {
      sede: "",
      idpaciente: "",
      from: "",
      to: "",
      presuncion_diagnostica: "",
      nivel_riesgo: "",
      paciente_name: "",
      tipo_atencion: "",
      tipo_paciente: "",
      admision: "",
      carrera: "",
    },
    filteratencionesDefault: {
      sede: "",
      idpaciente: "",
      from: "",
      to: "",
      presuncion_diagnostica: "",
      nivel_riesgo: "",
      paciente_name: "",
      tipo_atencion: "",
      tipo_paciente: "",
      admision: "",
      carrera: "",
    },

    selectedPaciente: null,
    selectedAtencion: null,
    loading: false,
    loadingAtenciones: false,
    loadinAtencionesDiarias: false,
    previewAtencionMedica: false,
    loadingGenaratePDF: false,
    loadingPrintPDF: false,
    loadingReporteExcel: false,
  },
  mutations: {
    setPaginate(state, response) {
      state.paginate.current_page = response.current_page;
      state.paginate.from = response.from;
      state.paginate.last_page = response.last_page;
      state.paginate.per_page = response.per_page;
      state.paginate.to = response.to;
      state.paginate.total = response.total;
    },
    setListaPacientesAtendidos(state, payload) {
      state.pacintesAtendidos = payload;
    },

    setListaAtencionesPorPaciente(state, payload) {
      state.listAtenciones = [];
      state.listAtenciones = payload;
    },

    setListaAtencionesDiarias(state, payload) {
      state.listAtencionesDiarias = [];
      state.listAtencionesDiarias = payload;
    },

    selectPaciente(state, paciente) {
      state.selectedPaciente = Object.assign({}, paciente);
      state.listAtenciones = [];
    },

    selectAtencion(state, atencion) {
      state.selectedAtencion = null;
      state.selectedAtencion = Object.assign({}, atencion);
    },

    resetSelectedPaciente(state) {
      state.selectedPaciente = null;
      state.listAtenciones = [];
      state.filteratenciones = Object.assign({}, state.filteratencionesDefault);
    },

    resetFilter(state) {
      state.filteratenciones = Object.assign({}, state.filteratencionesDefault);
    },

    switchPreviewAtencionMedica(state) {
      state.previewAtencionMedica = !state.previewAtencionMedica;
    },
  },
  actions: {
    async getListPacientesAtenciones({ commit, state }) {
      state.loading = true;
      try {
        state.filterpaciente.sedes = store.state.user.roles.sedes;
        const resp = await PacienteSV.getPaciente(state.filterpaciente, state.option);
        commit('setPaginate', resp)
        commit("setListaPacientesAtendidos", resp.data);
        state.loading = false;
      } catch (error) {
        state.loading = false;
        console.log(error);
      }
    },

    async getListAtencionesPorPaciente({ commit, state }) {
      state.loadingAtenciones = true;
      try {
        state.filteratenciones.idpaciente = state.selectedPaciente.idpaciente;
        const data = await AtencionMedicaSV.all(state.filteratenciones);
        commit("setListaAtencionesPorPaciente", data);
        state.loadingAtenciones = false;
      } catch (error) {
        state.loadingAtenciones = false;
        console.log(error);
      }
    },

    async getListAtencionesDiarias({ commit, state }) {
      state.loadinAtencionesDiarias = true;
      try {
        //state.filteratenciones.sede = store.state.user.roles.sedes
        const data = await AtencionMedicaSV.getDay(state.filteratenciones);
        console.log(state.filteratenciones);
        commit("setListaAtencionesDiarias", data);
        state.loadinAtencionesDiarias = false;
      } catch (error) {
        state.loadinAtencionesDiarias = false;
        console.log(error);
      }
    },

    //GENERAR REPORTES

    async generatePDFAtencionMedica({ commit, state }, id_atencion_medica) {
      state.loadingGenaratePDF = true;
      try {
        //state.filteratenciones.sede = store.state.user.roles.sedes
        const data = await AtencionMedicaSV.generatePDFAtencionMedica({
          id_atencion_medica: id_atencion_medica,
        });
        state.loadingGenaratePDF = false;
      } catch (error) {
        state.loadingGenaratePDF = false;
        console.log(error);
      }
    },

    async printReporteAtencionMedica({ commit, state }, id_atencion_medica) {
      state.loadingPrintPDF = true;
      try {
        //state.filteratenciones.sede = store.state.user.roles.sedes
        const data = await AtencionMedicaSV.printReporteAtencionMedica({
          id_atencion_medica: id_atencion_medica,
        });
        state.loadingPrintPDF = false;
      } catch (error) {
        state.loadingPrintPDF = false;
        console.log(error);
      }
    },

    async getExcelAtencionesMedicas({ commit, state }) {
      state.loadingReporteExcel = true;
      swal({
        title: "Cargando...",
        text: "Por favor, espere un momento.",
        icon: "info",
        button: false, // Ocultar el botón de confirmación
        closeOnClickOutside: false, // Evitar que el usuario cierre la alerta haciendo clic afuera
        closeOnEsc: false, // Evitar que el usuario cierre la alerta presionando la tecla Esc
        allowOutsideClick: false, // Evitar que el usuario cierre la alerta haciendo clic afuera
      });
      try {
        const data = await AtencionMedicaSV.getReporte(state.filteratenciones);
        swal({
          title: "Reporte generado correctamente",
          icon: "success",
        });
        state.loadingReporteExcel = false;
      } catch (error) {
        console.log(error);
        state.loadingReporteExcel = false;
        swal({
          title: "Ha ocurrido un error, vuelve a intentar",
          icon: "error",
        });
      }
    },
  },
};
