<template>
    <v-card
            @click="OpenDialogCardinal(cardinal)"
            class="d-flex justify-center align-center py-1 green accent-4 white--text"
            :class="!vertical? 'flex-column':''"
            elevation="0"
            style="cursor: pointer"
            min-width="30px"
            :width="vertical? '100%' : '40px'"
            :max-width="vertical? '100%' : '40px'"
            :height="height"
          >
            <v-icon color="white">mdi-plus-circle-outline</v-icon>
            <small
              class="font-weight-bold text-caption"
              :class="!vertical? 'texto-vertical' : ''"
              style="font-size: 10px !important"
              >AGREGAR ({{cardinal}})</small
            >
   </v-card>
</template>


<script>
import { mapMutations, mapState } from 'vuex';

export default {
    props:{
        cardinal: {
            type: String,
            required:true
        },
        height: {
            type: String,
            required: true
        },
        vertical:{
            type: Boolean,
            default: false
        }
    },
    methods:{
    ...mapMutations('pabellones/creadorPabellon', ['OpenDialogCardinal'])

  }
}
</script>


<style>
.texto-vertical {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}
</style>