import Module from './Module.vue';
import disciplinaView from './views/disciplinaView.vue'

const moduleRoute = {
    path: "/disciplina",
    component: Module,
    children: [
      {
        path: "/",
        redirect: "listadecasos",
        component: disciplinaView,
        name: 'comiteDisciplinaView',
        meta: {
          requiresAuth: true,
          title: "Comite de disciplina",
        },
      },
     {
        path: "registrodecasos",
        component: disciplinaView,
        children:[
          {
            path: "",
            components: {
              disciplinaView: () => import('@/modules/Disciplina/components/FormReporteCasosIndisciplina.vue'),
            },
            name: "registrarNuevoCaso",
            meta: {
              requiresAuth: true,
              title: "Disciplina - Registrar nuevo caso",
            },
          }
        ],
 
      },
      {
        path: "editar-registro/:idregistro",
        component: disciplinaView,
        children:[
          {
            path: "",
            components: {
              disciplinaView: () => import('@/modules/Disciplina/components/FormReporteCasosIndisciplina.vue'),
            },
            name: "editarRegistroCaso",
            meta: {
              requiresAuth: true,
              title: "Disciplina - Editar registro",
            },
          }
        ],

      },
      {
        path: "listadecasos",
        component: disciplinaView,
        children:[
          {
            path: "",
            components: {
              disciplinaView: () => import('@/modules/Disciplina/components/ListaReportesCasos.vue'),
            },
            name: "listaDeCasosRegisrados",
            meta: {
              requiresAuth: true,
              title: "Disciplina - Lista de registros",
            },
          }
        ],

      },




      
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };