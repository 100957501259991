import MetricaMedicamentosService from "../service/metricaMedicamentosService"

const MetricaMedicamentoSv = new MetricaMedicamentosService;
export default{
    namespaced: true,
    state:{
        medicamentosMayorDemanda : [],
        medicamentosStockMinimo: [],
        medicamentosPorTipoPaciente: [],
        atencionesMensuales: [],
        filterMedicamentoMD:{
            sede: "",
            fecha: new Date().toISOString().substr(0, 7),
            nombre: ""
        },
        filterMedicamentoSM:{
            sede: "",
            lote: "",
            nombre: ""
        },
        filterMedicamentoPT:{
            sede: "",
            fecha: new Date().toISOString().substr(0, 7),
        },
        filterAntencionesMensuales:{
            sede: ""
        },
        loadingMedicamentoMD:false,
        loadingMedicamentoSM: false,
        loadingMedicamentosPT:false,
        loadingAtencionesMensuales: false
    },
    mutations:{
        setMedicamentosMD(state, payload)
        {
            state.medicamentosMayorDemanda = payload
        },

        setMedicamentosSM(state, payload)
        {
            state.medicamentosStockMinimo = payload
        },
        setMedicamentosPT(state, payload)
        {
            state.medicamentosPorTipoPaciente = payload
        },
        setAtencionesMensuales(state, payload)
        {
            state.atencionesMensuales = payload
        }
    },
    actions:{
        async getMedicamentoMayorDemanda({state, commit})
        {
            state.loadingMedicamentoMD = true
            try
            {
                const data = await MetricaMedicamentoSv.getMayorDemanda(state.filterMedicamentoMD)
                state.loadingMedicamentoMD = false
                commit('setMedicamentosMD', data)
            }
            catch(error)
            {
                state.loadingMedicamentoMD = false
                console.log(error)
            }
        },

        async getMedicamentoStockMinimo({state, commit})
        {
            state.loadingMedicamentoSM = true
            try
            {
                const data = await MetricaMedicamentoSv.getStockminimo(state.filterMedicamentoSM)
                state.loadingMedicamentoSM = false
                commit('setMedicamentosSM', data)
            }
            catch(error)
            {
                state.loadingMedicamentoSM = false
                console.log(error)
            }
        },

        async getMedicamentosPorTipoPaciente({state, commit})
        {
            state.loadingMedicamentosPT = true
            try
            {
                const data = await MetricaMedicamentoSv.getMedicamentosPorTipoPaciente(state.filterMedicamentoPT)
                state.loadingMedicamentosPT = false
                console.log(data)
                commit('setMedicamentosPT', data)
            }
            catch(error)
            {
                state.loadingMedicamentosPT = false
                console.log(error)
            }
        },

        async getAtencionesMensuales({state, commit})
        {
            state.loadingAtencionesMensuales = true
            try
            {
                const data = await MetricaMedicamentoSv.getAtencionesMensuales(state.filterAntencionesMensuales)
                state.loadingAtencionesMensuales = false
                commit('setAtencionesMensuales', data)
            }
            catch(error)
            {
                state.loadingAtencionesMensuales = false
                console.log(error)
            }
        }
    }
}
