<template>
    <div class="d-flex" style="height: 100vh">
        <v-card elevation="0" width="100%" class="transparent">
            <v-card-text class="text-left black--text">
                <div class="font-weight-bold text-subtitle-2 text-center"> DETALLE DE PERMISO DE SALIDA A ESTUDIANTE:
                </div>

                <div class="pa-2">

                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width: 30%;">CREADO:</td>
                                <td style="width: 25%;">{{ formatFechaCustom(permiso.created_at, 'DD/MM/YYYY') }}</td>
                                <td class="grey lighten-4 font-weight-bold" style="width: 20%;">SEDE:</td>
                                <td style="width: 25%;">{{ permiso.sede }}</td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width: 30%;">ESTADO:</td>
                                <td colspan="3">{{ permiso.estado == 'enviado' ? 'SIN USAR' : permiso.estado }}</td>

                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width: 30%;">DNI:</td>
                                <td style="width: 25%;">{{ permiso.dni }}</td>
                                <td class="grey lighten-4 font-weight-bold" style="width: 20%;">ADMISIÓN:</td>
                                <td style="width: 25%;">{{ permiso.admision }}</td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width:30%;">ESTUDIANTE:</td>
                                <td colspan="3">
                                    <div class="py-2">{{ permiso.nombrecompleto }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width:30%;">CARRERA:</td>
                                <td colspan="3">
                                    <div class="py-2">{{ permiso.carrera }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width:30%;">MOTIVO:
                                </td>
                                <td colspan="3">{{ permiso.motivo }}</td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width:30%;">LUGAR QUE SE DIRIGE:
                                </td>
                                <td colspan="3">{{ permiso.lugar_dirige }}</td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width:30%;">AUTORIZADO POR:
                                </td>
                                <td colspan="3">
                                    <div>
                                        {{ permiso.users.nombres + ' ' + permiso.users.apellidos }}
                                    </div>
                                    <div class="font-weight-bold">{{ permiso.users.cargo }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width: 30%;">FECHA SALIDA:</td>
                                <td style="width: 25%;">{{ formatFechaCustom(permiso.fecha_salida, 'DD/MM/YYYY') }}</td>
                                <td class="grey lighten-4 font-weight-bold" style="width: 20%;">HORA SALIDA:</td>
                                <td style="width: 25%;">{{ formatFechaCustom(permiso.fecha_salida + ' ' +
                                    permiso.hora_salida, 'hh:mm a') }}</td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width: 30%;">FECHA RETORNO</td>
                                <td style="width: 25%;">{{ formatFechaCustom(permiso.fecha_ingreso, 'DD/MM/YYYY') }}
                                </td>
                                <td class="grey lighten-4 font-weight-bold" style="width: 20%;">HORA RETORNO:
                                </td>
                                <td style="width: 25%;">{{ formatFechaCustom(permiso.fecha_ingreso + ' ' +
                                    permiso.hora_ingreso, 'hh:mm a') }}</td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width:30%;">EVIDENCIA DE SALIDA:
                                </td>
                                <td colspan="3">
                                    <v-btn v-if="permiso.documento != null" @click="evidencia = !evidencia" small
                                        elevation="0" class="blue white--text"><v-icon left small>{{ !evidencia ?
                                    'mdi-eye' : 'mdi-eye-off' }}</v-icon>{{ evidencia ? 'ocultar evidencia' :
                                    'ver evidencia' }}</v-btn>
                                    <iframe v-if="evidencia" :src="permiso.documento" width="100%" class="mt-2"
                                        height="500" frameborder="0"></iframe>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey lighten-4 font-weight-bold" style="width:30%;">EVIDENCIA DE INGRESO:
                                </td>
                                <td colspan="3">
                                    <v-btn v-if="permiso.evidencia_ingreso != null"
                                        @click="evidenciaIngreso = !evidenciaIngreso" small elevation="0"
                                        class="blue white--text"><v-icon left small>{{ !evidencia ? 'mdi-eye' :
                                    'mdi-eye-off' }}</v-icon>
                                        {{ evidencia ? 'ocultar evidencia' : 'ver evidencia' }}</v-btn>
                                    <iframe v-if="evidenciaIngreso" :src="permiso.evidencia_ingreso" width="100%"
                                        class="mt-2" height="500" frameborder="0"></iframe>
                                </td>
                            </tr>
                            <tr v-if="permiso.visita_id">
                                <td colspan="4" style="width:30%; border: 2px solid #ffffff !important;"
                                    class="amber lighten-2 font-weight-bold text-center">
                                    FECHA Y HORA DE SALIDA DEL ESTUDIANTE<v-icon>mdi-arrow-right-thick</v-icon>
                                </td>
                            </tr>
                            <tr v-if="permiso.visita_id">
                                <td class="amber lighten-2 font-weight-bold"
                                    style="width: 30%; border: 2px solid #ffffff !important;">FECHA</td>
                                <td style="width: 25%; border: 2px solid #ffffff !important;" class="amber lighten-4">{{
                                    formatFechaCustom(permiso.salida_sede, 'DD/MM/YYYY') }}</td>
                                <td class="amber lighten-2 font-weight-bold"
                                    style="width: 20%; border: 2px solid #ffffff !important;">HORA:
                                </td>
                                <td style="width: 25%; border: 2px solid #ffffff !important;" class="amber lighten-4">{{
                                    formatFechaCustom(permiso.salida_sede, 'hh:mm a') }}</td>
                            </tr>
                            <tr v-if="permiso.visita_id">
                                <td class="amber lighten-2 font-weight-bold"
                                    style="width:30%; border: 2px solid #ffffff !important;">COMENTARIO DE SALIDA
                                    (AGENTE):
                                </td>
                                <td colspan="3" class="amber lighten-4" style="border: 2px solid #ffffff !important;">
                                    {{ permiso.visitas.comentario_salida }}
                                </td>
                            </tr>
                            <tr v-if="permiso.visita_id">
                                <td colspan="4" class="teal accent-3 font-weight-bold text-center"
                                    style="width:30%; border: 2px solid #ffffff !important;">
                                    <v-icon>mdi-arrow-left-thick</v-icon>FECHA Y HORA DE RETORNO DEL ESTUDIANTE
                                </td>
                            </tr>
                            <tr v-if="permiso.visita_id">
                                <td class="teal accent-3 font-weight-bold"
                                    style="width: 30%; border: 2px solid #ffffff !important;">FECHA</td>
                                <td style="width: 25%; border: 2px solid #ffffff !important;" class="teal lighten-5">{{
                                    formatFechaCustom(permiso.retorno_sede, 'DD/MM/YYYY') }}</td>
                                <td class="teal accent-3 font-weight-bold"
                                    style="width: 20%; border: 2px solid #ffffff !important;">HORA:
                                </td>
                                <td style="width: 25%; border: 2px solid #ffffff !important;" class="teal lighten-5">{{
                                    formatFechaCustom(permiso.retorno_sede, 'hh:mm a') }}</td>
                            </tr>

                            <tr v-if="permiso.extemporaneo">
                                <td colspan="4">
                                    <div class="font-weight-bold text-subtitle-2 ">El permiso es extemporáneo</div>
                                </td>
                            </tr>

                            <tr v-if="permiso.extemporaneo">
                                <td colspan="4">
                                    <div class="text-subtitle-1 text-center">
                                        Este permiso es extemporaneo (se registró despúes que el estudiante salió
                                        de sede) lo que significa que no podrá
                                        controlar la salida o ingreso del estudiante a sede.
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </v-simple-table>




                </div>
            </v-card-text>
        </v-card>
    </div>
</template>


<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
    mixins: [Filters],
    data() {
        return {
            evidencia: false,
            evidenciaIngreso: false
        }
    },
    computed: {
        ...mapState('permisos', ['permiso'])
    },
    methods: {

    },

    mounted() {
        this.evidencia = false
        this.evidenciaIngreso = false
    }


}
</script>