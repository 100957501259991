<template>
  <v-navigation-drawer app 
  :permanent="nav.drawer && !$vuetify.breakpoint.xs"
  v-model="nav.drawer"
  color="bgCTM" width="55">
    <div 
      class="d-flex flex-column align-center justify-center"
      style="height: 100vh"
    >
      <router-link  :to="{ name: 'dashboard'}" style="text-decoration: none;" >
      <v-card width="68px" elevation="0" :color="namepathcurrent == 'dashboard' ? 'bgCTMHover' : 'transparent'">
        <v-card-text class="py-2 px-1 text-center">
          <v-icon class="white--text" size="25">mdi-home</v-icon>
          <div class="title-menu font-weight-bold white--text">INICIO</div>
        </v-card-text>
      </v-card>
      </router-link>


     <router-link :to="{name: 'usuarios'}" style="text-decoration: none;" v-if="$can('read', 'usuarios')">
     <v-card width="68px" elevation="0" :color="namepathcurrent == 'usuarios' ? 'bgCTMHover' : 'transparent'" >
        <v-card-text class="py-2 px-1 text-center">
          <v-icon class="white--text" size="25">mdi-account-group</v-icon>
          <div class="title-menu font-weight-bold white--text">USUARIOS</div>
        </v-card-text>
      </v-card>
      </router-link>

      <router-link  :to="{name: 'rolesdeusuario'}" style="text-decoration: none;" v-if="$can('create', 'usuarios')">
      <v-card width="68px" elevation="0" :color="namepathcurrent == 'rolesdeusuario' ? 'bgCTMHover' : 'transparent'" >
        <v-card-text class="py-2 px-1 text-center">
          <v-icon class="white--text" size="25">mdi-account-tie</v-icon>
          <div class="title-menu font-weight-bold white--text">ROLES</div>
        </v-card-text>
      </v-card>
      </router-link>

    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: "Navigation-Drawer",
  data:()=>({
    namepathcurrent : ""
  }),
  watch: {
  '$route' (to, from) {
    if(to.path != from.path) { // <- you may need to change this according to your needs!
      this.namepathcurrent = to.name
    }
  }
},
  computed:{
    isMovil(){
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.md
    },
    ...mapState(['nav']),
  },

  created(){
    if(this.isMovil){
      this.nav.drawer = false;
      console.log("is movil")
    }
  }

 
}
</script>
<style scoped>
.title-menu {
  font-size: 10px;
}
</style>
