<template>
    <v-card elevation="0">
        <v-card-text class="text-center black--text">
            <v-img v-if="medicamento.tipo == 'medicamento'" :src="require('../../assets/farmaco.png')" width="200" class="mx-auto"></v-img>
            <v-img v-else :src="require('../../assets/insumos.png')" width="150" class="mx-auto"></v-img>

            <div class="text-h6 font-weight-bold text-uppercase">{{ medicamento.idmedicamento ? `Editar datos del ${medicamento.tipo}`: `Insertar nuevo ${medicamento.tipo}` }}</div>
        </v-card-text>
        <v-card-text class="black--text font-weight-bold px-5">
            <v-form v-model="validateform">
                <v-row>
                    <v-col cols="12" class="py-1 d-flex justify-center">
                        <v-radio-group
                            v-model="medicamento.tipo"
                            row
                            hide-details="auto"
                            class="ma-0 black--text"
                            >
                            <v-radio
                                label="Medicamento"
                                value="medicamento"
                            ></v-radio>
                            <v-radio
                                label="Insumo y/o material"
                                value="insumo"
                            ></v-radio>
                            </v-radio-group>
                    </v-col>
                    <v-col cols="12" class="py-1">
                        <div class="font-weight-light">Campos requeridos <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></div>
                    </v-col>
                    <v-col cols="12" :md="medicamento.tipo == 'medicamento'? '6' : '12'">
                        <div>
                            <label>Nombre del producto <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></label>
                            <v-text-field :rules="[rules.required]" v-model="medicamento.name_producto" dense hide-details="auto" outlined></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" v-if="medicamento.tipo == 'medicamento'">
                        <div>
                            <label>Principio activo <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></label>
                            <v-text-field :rules="[rules.required]" v-model="medicamento.principio_activo" dense hide-details="auto" outlined></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" v-if="medicamento.tipo == 'medicamento'">
                        <div>
                            <label>Descripción <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></label>
                            <v-text-field  dense hide-details="auto" v-model="medicamento.descripcion" outlined></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div>
                            <label>Fecha de vencimiento <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></label>
                            <v-text-field :rules="[rules.required]" v-model="medicamento.fecha_vencimiento" type="date" dense hide-details="auto" outlined></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" v-if="medicamento.tipo == 'medicamento'">
                        <div>
                            <label>Presentación <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></label>
                            <v-select :rules="[rules.required]" v-model="medicamento.presentacion" dense hide-details="auto" outlined :items="presentacion"></v-select>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" v-if="!medicamento.idmedicamento">
                        <div>
                            <label>Cantidad inicial <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></label>
                            <v-text-field :rules="[rules.required, rules.onlyNumber]" v-model="medicamento.cantidad_inicial" type="number" dense hide-details="auto" outlined></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" v-else>
                        <div>
                            <label>Stock actual <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></label>
                            <v-text-field :rules="[rules.required, rules.onlyNumber]" v-model="medicamento.stock_actual" type="number" dense hide-details="auto" outlined></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div>
                            <label>Lote <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></label>
                            <v-text-field :rules="[rules.required]" v-model="medicamento.lote" dense hide-details="auto" outlined></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div>
                            <label>Sede <span class="red--text font-weight-bold" style="font-size: 20px;">*</span></label>
                            <v-select :rules="[rules.required]" v-model="medicamento.sede" dense hide-details="auto" outlined :items="sedes"></v-select>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-btn v-if="medicamento.idmedicamento" :loading="loading" @click="updateMedicamento()" :disabled="!validateform" block elevation="0" class="bgCTM white--text">GUARDAR</v-btn>
                        <v-btn v-else :loading="loading" @click="addMedicamento()" :disabled="!validateform" block elevation="0" class="bgCTM white--text">GUARDAR</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>


<script>
import Filters from '@/modules/Shared/Filters/index.js'
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    mixins:[Filters],
    data() {
        return {
            validateform: true,
        }
    },

    computed:{
        ...mapState('topico/Medicamentos', ['medicamento', 'loading']),
        ...mapState('topico/utiles', ['sedes']),
        ...mapState('topico', ['presentacion'])
    },

    methods:{
        ...mapActions('topico/Medicamentos', ['addMedicamento', 'updateMedicamento'])
    }
}
</script>