import utiles from "@/modules/Shared/utiles";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";

import roles from "./roles";
import { socket } from "@/socket-cliente";
export default {
  modules: {
    utiles,
    roles
  },
  namespaced: true,

  state: {
    dialogRegister: false,

    user: {
      nombres: "",
      apellidos: "",
      dni: "",
      cargo: "",
      emai: "",
      password: "",
      password_confirmation: "",
      role_id: "",
    },

    userDefault: {
      nombres: "",
      apellidos: "",
      dni: "",
      cargo: "",
      password: "",
      emai: "",
      password_confirmation: "",
      role_id: "",
    },

    filtrosBusqueda:{
      nombredni: "",
      email: ""
    },

    listaUsers: [],
    loading: false,
    loadingList: false,
    overlayCambioSede: false
  },

  mutations: {
    setDialogRegister(state) {
      state.dialogRegister = !state.dialogRegister;
      store.commit("usuarios/resetUser");
    },

    setListUsuarios(state, payload) {
      state.listaUsers = payload;
    },

    resetUser(state) {
      state.user = Object.assign({}, state.userDefault);
    },

    setDialogEdit(state, payload) {
      state.dialogRegister = !state.dialogRegister;
      state.user = Object.assign({}, payload);
      state.user.role_id = payload.roles.idrole
    },
  },

  actions: {
    getListaDeUsuariosAxios({ commit, state }) {
      commit("utiles/resetConfigAxios");
      state.loadingList = true;
      utiles.state.configAxios.url = utiles.state.apiUrl + "user";
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.params = state.filtrosBusqueda
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((response) => {
          if (response.status == 200) {
            commit("setListUsuarios", response.data.users);
            state.loadingList = false;
            console.log(response)
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingList = false;
        });
    },
    setRegisterUserAxios({ commit, dispatch, state }) {
      commit("utiles/resetConfigAxios");
      state.loading = true;

      state.user.password = state.user.password_confirmation;

      utiles.state.configAxios.url = utiles.state.apiUrl + "user";
      if (!state.user.iduser) {
        utiles.state.configAxios.method = "POST";
      } else {
        utiles.state.configAxios.method = "PUT";
      }

      utiles.state.configAxios.data = state.user;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      swal({
        title: "Cargando...",
        text: "Por favor, espera mientras se realiza la petición.",
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      });

      axios(utiles.state.configAxios)
        .then((response) => {
          if (response.status == 200) {
            state.loading = false;
            swal({
              title: state.user.iduser
                ? "Usuario Actualizado"
                : "Usuario Registrado",
              icon: "success",
            });
            state.dialogRegister = false;
            dispatch("getListaDeUsuariosAxios");
          }
        })

        .catch((error) => {
          console.log(error);
          state.loading = false;
          swal({
            title: "Ha ocurrido un error!",
            icon: "error",
          });
        });
    },

    setChangeIsActiveAxios({ commit, dispatch, state }, user) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "is-active";
      utiles.state.configAxios.method = "PUT";
      utiles.state.configAxios.data = { iduser: user.iduser };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: user.isActive
          ? "¿Desea bloquear el usuario?"
          : "¿Desea desbloquear al usuario?",
        icon: "warning",
        dangerMode: true,
        buttons: ["CANCELAR", "CONFIRMAR"],
      }).then((confimation) => {
        if (!confimation) {
          return false;
        }

        swal({
            title: "Cargando...",
            text: "Por favor, espera mientras se realiza la petición.",
            icon: "info",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            dangerMode: true,
          });

        axios(utiles.state.configAxios)
          .then((response) => {
            if (response.status == 200) {
                dispatch('getListaDeUsuariosAxios')
                swal({
                    title: response.data.user.isActive? "SE DESBLOQUEO USUARIO" :  "SE BLOQUEO EL USUARIO",
                    icon: "success"
                })
                if(!response.data.user.isActive){
                   console.log(response.data)
                   socket.emit('bloquearUsuario', response.data.user.iduser)
                }
            }
          })
          .catch((error) => {
            console.log(error);
            swal({
                title: "Ha ocurrido un error",
                icon: "error"
            })
          });
      });
    },


    /**
     * CAMBIAR SEDE DE USUARIO LOGEADO
     */


    setChangeSedeLogin({state, commit, dispatch}){
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "is-active";
      utiles.state.configAxios.method = "PUT";
      utiles.state.configAxios.data = { iduser: user.iduser };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      state.overlayCambioSede = true
        axios(utiles.state.configAxios)
        .then(resp => {
          state.overlayCambioSede = false

        })
        .catch(error => {
          console.log(error)
          state.overlayCambioSede = false 
          swal({
            title: "No se logró cambiar la sede",
            icon: "error",
            text: "Por favor vuelve a intentar"
          })

        })
    }



  },
};
