import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class AtencionPsicologicaService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }

    async getAll(params)
    {
       const response = await this.axios.get('/atencionpsicologica', {params: params})
       return response.data;
    }  


    async getAllDays(params)
    {
       const response = await this.axios.get('/atencionespsicologicasdiarias', {params: params})
       return response.data;
    }  

    async show(params)
    {
        const response = await this.axios.get('/showatencionpsicologica', {params: params})
        return response.data
    }

    async add(data)
    {
        const response = await this.axios.post('/atencionpsicologica', data)
        return response.data
    }

    async update(data)
    {
        const response = await this.axios.put('/atencionpsicologica', data)
        return response.data
    }

    async delete(id_atencion_psicologica)
    {
        const response = await this.axios.delete('/atencionpsicologica?id_atencion_psicologica=' + id_atencion_psicologica)
        return response.data
    }


    async generarPDF(data)
    {
        const response = await this.axios.post('/generar-pdf', data, {
            responseType: 'arraybuffer',
        })
        
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download = 'Reporte_Atencion_psicologica.pdf';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        return response.data;
    }

    async getReporteExcel(data)
    {
        const response = await this.axios.post('generar-excel', data, {
            responseType: 'blob',
        })
        const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); 
          // Genera una URL temporal del archivo Blob
          const url = URL.createObjectURL(blob);
          // Crea un enlace y lo simula como si fuera un clic del usuario
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte-atencion-psicologia.xlsx";
          a.click();
    }



}