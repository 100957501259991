import utiles from "@/modules/Shared/utiles";
import visitas from "@/modules/Visitas/store/index";
import { socket } from "@/socket-cliente";
import store from "@/store";
import axios from "axios";
import readXlsxFile from "read-excel-file";
import swal from "sweetalert";

function validateFormat(event) {
  if (
    event != null &&
    !(
      event.type == "image/jpeg" ||
      event.type == "image/png" ||
      event.type == "image/jpg" ||
      event.type == "application/pdf"
    )
  ) {
    swal({
      title: "ARCHIVO NO PERMITIDO",
      text: "Porfavor solo agregre archivos: png, jpg, pdf",
      icon: "error",
    });

    return true;
  }
}

export default {
  namespaced: true,
  modules: {
    utiles,
  },
  state: {
    lista_permisos: [],
    lista_permisos_agente: [],
    permiso: {
      idpermisosalida: -1,
      extemporaneo: 0,
      dni: "",
      nombrecompleto: "",
      carrera: "",
      admision: "",
      suspension: "NO",
      motivo: "",
      lugar_dirige: "",
      fecha_salida: "",
      hora_salida: "",
      fecha_ingreso: "",
      hora_ingreso: "",
      documento: null,
      usuario_id: "",
      sede: "",
      visita_id: -1,
      es_menor: "NO"
    },

    permisoDefault: {
      idpermisosalida: -1,
      extemporaneo: 0,
      dni: "",
      nombrecompleto: "",
      carrera: "",
      suspension: "NO",
      admision: "",
      motivo: "",
      lugar_dirige: "",
      fecha_salida: "",
      hora_salida: "",
      fecha_ingreso: "",
      hora_ingreso: "",
      documento: null,
      usuario_id: "",
      sede: "",
      visita_id: -1,
      es_menor: "NO"
    },
    loading: false,
    alert: {
      type: "",
      message: "",
    },

    studentNotSede: false,

    filtersPermisos: {
      nombreDni: "",
      sedes: [],
      fecha_inicio: "",
      fecha_fin: "",
    },

    filtersPermisosDefault: {
      nombreDni: "",
      sedes: [],
      fecha_inicio: "",
      fecha_fin: "",
    },
    paginate: {
      current_page: 1,
      from: 1,
      last_page: 0,
      per_page: 2,
      to: 2,
      total: 0,
    },
    option: {
      page: 1,
      itemsPerPage: 80,
    },
    dialgoEvidenciaSalidaPermisos: {
      urlPDF: null,
      idpermisosalida: -1,
      openDialog: false,
    },
    dialgoEvidenciaIngresoPermisos: {
      urlPDF: null,
      idpermisosalida: -1,
      openDialog: false,
    },

    evidenciaSalida: {
      file: null,
      idpermisosalida: -1,
    },
    evidenciaSalidaDefault: {
      file: null,
      idpermisosalida: -1,
    },
    evidenciaIngreso: {
      file: null,
      idpermisosalida: -1,
    },
    evidenciaIngresoDefault: {
      file: null,
      idpermisosalida: -1,
    },

    permisosMasivos: [],
    loadingPermisosMasivos: false,
    resultadoPermisosMasivos: {},

    loadingPermisoSede: false,
    loadingSavePermiso: false,
    loadingBusquedaEstudiante: false,
    permisoExistente: false,
    permisoExistenteResponse: null,
    confirmClosePermisoExist: false,
    confirmacionCreatePermiso: false,
    loadingPeticion: false,
  },
  mutations: {
    openPermisoMasivo() {
      visitas.state.componentSlider = "permisosMasivos";
      visitas.state.dialogVisitaPreview.dialog = true;
      visitas.state.dialogVisitaPreview.width = 950;
    },

    openNuevoPermiso() {
      (visitas.state.componentSlider = "nuevoPermiso"),
        (visitas.state.dialogVisitaPreview.dialog = true);
      visitas.state.dialogVisitaPreview.width = 680;
    },

    openEditPermiso(state, permiso) {
      visitas.state.componentSlider = "nuevoPermiso";
      visitas.state.dialogVisitaPreview.dialog = true;
      visitas.state.dialogVisitaPreview.width = 680;
      state.permiso = Object.assign({}, permiso);
    },

    openListPermisos() {
      visitas.state.componentSlider = "ManejarPermisosAgente";
      visitas.state.dialogVisitaPreview.dialog = true;
      visitas.state.dialogVisitaPreview.width = 680;
    },

    openPreviewPermisoAgente(state, permiso) {
      visitas.state.componentSlider = "ViewPermisoAgente";
      visitas.state.dialogVisitaPreview.dialog = true;
      visitas.state.dialogVisitaPreview.width = 680;
      state.permiso = Object.assign({}, permiso);
    },

    openPreviewPermiso(state, permiso) {
      visitas.state.componentSlider = "ViewPermiso";
      visitas.state.dialogVisitaPreview.dialog = true;
      visitas.state.dialogVisitaPreview.width = 680;
      state.permiso = Object.assign({}, permiso);
    },

    onChangeDocumento(state, event) {
      if (
        event != null &&
        !(
          event.type == "image/jpeg" ||
          event.type == "image/png" ||
          event.type == "image/jpg" ||
          event.type == "application/pdf"
        )
      ) {
        swal({
          title: "ARCHIVO NO PERMITIDO",
          text: "Porfavor solo agregre archivos: png, jpg, pdf",
          icon: "error",
        });

        state.permiso.documento = null;
        return;
      }
      if (event != null) {
        state.permiso.documento = event;
        console.log(event);
      }
    },

    onChangeDocumentoIngreso(state, event) {
      if (event == null) {
        state.evidenciaIngreso.file = null;
      }

      if (validateFormat(event)) {
        state.evidenciaIngreso.file = event;
        return;
      }
    },

    onChangeDocumentoSalida(state, event) {
      if (event == null) {
        state.evidenciaSalida.file = null;
      }

      if (validateFormat(event)) {
        state.evidenciaSalida.file = event;
        return;
      }
    },

    setPermiso(state, payload) {
      state.lista_permisos = payload;
    },

    setPermisoAgente(state, payload) {
      state.lista_permisos_agente = payload;
    },

    resetPermiso(state) {
      console.log(sede);

      state.permiso = Object.assign({}, state.permisoDefault);
      state.permiso.documento = null;

      var sede = store.state.user.roles.sedes.split(",");

      if (sede.length == 1) {
        state.permiso.sede = sede[0];
      }
    },

    resetFilters(state) {
      state.filtersPermisos = Object.assign({}, state.filtersPermisosDefault);
    },

    setPaginate(state, payload) {
      state.paginate = Object.assign({}, payload);
    },
    closeConfirmDialogAnulPermiso(state) {
      state.confirmClosePermisoExist = !state.confirmClosePermisoExist;
    },

    closeDialogPermisoPersistente(state) {
      state.permisoExistente = !state.permisoExistente;
    },

    closeDialogConfirmCreatePermiso(state) {
      state.confirmacionCreatePermiso = !state.confirmacionCreatePermiso;
    },

    resetAllDialogs(state) {
      state.loadingSavePermiso = false;
      state.permisoExistente = false;
      state.confirmClosePermisoExist = false;
      state.confirmacionCreatePermiso = false;
      state.loadingPeticion = false;
    },

    resetEvidenciaSalida(state) {
      state.evidenciaSalida = Object.assign({}, state.evidenciaSalidaDefault);
    },

    resetEvidenciaIngreso(state) {
      state.evidenciaIngreso = Object.assign({}, state.evidenciaIngresoDefault);
    },

    readExcel(state, file) {
      readXlsxFile(file)
        .then((rows) => {
          for (let i = 1; i <= rows.length - 1; i++) {
            const permiso = {
              dni: rows[i][0],
              nombrecompleto: rows[i][1],
              carrera: rows[i][2],
              admision: rows[i][3],
              sede: rows[i][4],
              motivo: rows[i][5],
              es_menor: rows[i][6],
              fecha_salida: rows[i][7],
              hora_salida: rows[i][8],
              fecha_ingreso: rows[i][9],
              hora_ingreso: rows[i][10],
              lugar_dirige: rows[i][11],
              suspension: rows[i][12],
            };
            state.permisosMasivos.push(permiso);
          }
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: "Ha ocurrido un error!",
          });
        });
    },

    resetDataMasivo(state){
      state.permisosMasivos = []
    }
  },

  actions: {
    getSigeAxios({ state, commit, dispatch }) {
      commit("utiles/resetConfigAxios");
      /*utiles.state.configAxios.url =
        "https://sige-stage-mw.cetemin.edu.pe/fix/student/search/" +
        state.permiso.dni;
      utiles.state.configAxios.headers.Authorization =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXRlbWluIiwiYXVkIjoid2ViIiwiaWF0IjoxNjYyMDQ0MTM1LCJleHAiOjE2NjMzNDAxMzUsImFrIjoiNWNlNTc2ZDliN2MzZjMxNjYifQ.468NjhnVKfbd_tlgfdiJbLrwXC6jcZo42QP-tAJHgBE";
      */
        utiles.state.configAxios.url =
        "https://api-estudiantes.cetemin.edu.pe/api/get-api-for-dni?dni=" +
        state.visita.dni;
      utiles.state.configAxios.headers.Authorization =
        "Bearer 1|FHwV5zZDcRuaGoUmWUuwWTmrdDgh4b05X6Cep7Kq5a64d070";

        state.loading = true;
      state.loadingBusquedaEstudiante = true;
      axios(utiles.state.configAxios)
        .then((res) => {
          if (res.status == 200) {
            /*
            state.permiso.nombrecompleto = res.data.result.nomalu;
            state.permiso.carrera = res.data.result.specialtyName;
            var yeradmin = res.data.result.period.substr(0, 4);
            var periodAdmin = res.data.result.period.substr(
              4,
              res.data.result.period.length - 1
            );
            state.permiso.admision = yeradmin + "-" + periodAdmin;
            */
            state.permiso.nombrecompleto = res.data.nombres + " " + res.data.apellido_paterno + " " + res.data.apellido_materno;
            state.permiso.carrera = res.data.carrera;
            state.permiso.admision = res.data.ano_admision + "-" + res.data.periodo_admision;
          }
          state.loading = false;
          state.loadingBusquedaEstudiante = false;
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
          state.loadingBusquedaEstudiante = false;
          state.permiso.nombre_completo = "";
          state.permiso.carrera = "";
          state.permiso.admision = "";

          state.alert.message =
            "No se encontraron datos del estudiante, escribir manualmente";
          state.alert.type = "error";
        });
    },

    getVisitaEstudiante({ state, commit, dispatch }) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        utiles.state.apiUrl + "visitaactivaestudiante";
      utiles.state.configAxios.data = state.permiso;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      utiles.state.configAxios.method = "POST";

      state.loadingBusquedaEstudiante = true;

      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            state.permiso.nombrecompleto = resp.data.visita.nombrecompleto;
            state.permiso.carrera = resp.data.visita.carrera;
            state.permiso.admision = resp.data.visita.admision;
            state.permiso.visita_id = resp.data.visita.idvisita;

            state.alert.message =
              "Correcto! El estudiante se encuentra en sede desde: " +
              resp.data.visita.fecha;
            state.alert.type = "success";
            state.studentNotSede = false;
            state.permiso.extemporaneo = 0;
          }

          state.loadingBusquedaEstudiante = false;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 500) {
            state.studentNotSede = true;
            state.permiso.extemporaneo = 1;
            //state.alert.message = `El estudiante no se encuentra en sede o el dni ${state.permiso.dni} es incorrecto!`
            state.alert.message = `El estudiante no se encuentra en sede - puede guardar el permiso como extemporáneo!`;
            state.alert.type = "error";
            state.loadingBusquedaEstudiante = false;

            dispatch("getSigeAxios");
          }
        });
    },

    getPermisoSalidasAxios({ state, commit }, loading = true) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        utiles.state.apiUrl +
        "permisos?page=" +
        state.option.page +
        "&itemsPerPage=" +
        state.option.itemsPerPage;
      utiles.state.configAxios.method = "POST";

      utiles.state.configAxios.data = state.filtersPermisos;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      state.loading = loading;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            console.log(resp);
            commit("setPaginate", resp.data.meta);
            commit("setPermiso", resp.data.data);
          }
          state.loading = false;
        })
        .catch((error) => {
          state.loading = false;
          console.log(error);
        });
    },

    /**OBTENER PERMISOS PARA EL AGENTE */

    getPermisosSalidasAgente({ state, commit }, loading = true) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "permisosagente";
      utiles.state.configAxios.method = "POST";

      utiles.state.configAxios.data = state.filtersPermisos;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      state.loading = loading;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            console.log(resp);
            commit("setPermisoAgente", resp.data.data);
          }

          state.loading = false;
        })
        .catch((error) => {
          state.loading = false;
          console.log(error);
        });
    },

    setPermisoAxios({ state, commit, dispatch }) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "permiso-salida";
      utiles.state.configAxios.method = "POST";
      state.permiso.usuario_id = store.state.user.iduser;
      utiles.state.configAxios.data = state.permiso;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      utiles.state.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      swal({
        title: "¿Desea guardar el permiso?",
        text: "Confirme",
        icon: "warning",
        buttons: ["cancelar", "CONFIRMAR"],
      }).then((confirmarcion) => {
        if (!confirmarcion) {
          return;
        }
        state.loadingSavePermiso = true;

        axios(utiles.state.configAxios)
          .then((res) => {
            if (res.status == 200) {
              dispatch("getPermisoSalidasAxios");
              commit("resetPermiso");
              visitas.state.dialogVisitaPreview.dialog = false;
              socket.emit("addPermiso");
              socket.emit("addVisita", false);

              state.permisoExistente = false;
            }
            if (res.status == 201) {
              state.permisoExistenteResponse = res.data.permiso;
              state.permisoExistente = true;
            }
            state.loadingSavePermiso = false;
            state.confirmacionCreatePermiso = true;
          })

          .catch((error) => {
            console.log(error);

            swal({
              title: "Ha ocurrido un error",
              icon: "error",
            });

            commit("resetAllDialogs");
          });
      });
    },
    //BLINDING PERMISOS Y OPEN SLIDER
    getBlindingPermisionsAndOpenSlider({ dispatch, commit }, data) {
      dispatch("getPermisoSalidasAxios");
      commit("openListPermisos");
    },

    //ANULAR PERMISO
    setAnularPermiso({ dispatch, commit, state }, idpermisosalida) {
      state.confirmClosePermisoExist = false;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "anularpermiso";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = { idpermisosalida: idpermisosalida };
      state.permiso.usuario_id = store.state.user.iduser;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿Deseas cancelar el permiso?",
        icon: "info",
        dangerMode: true,
        buttons: ["cancelar", "CONFIRMAR"],
      }).then((confirmar) => {
        if (!confirmar) {
          return;
        }

        state.loadingPeticion = true;
        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              dispatch("getPermisoSalidasAxios");
              socket.emit("addPermiso");
              socket.emit("addVisita", false);

              state.confirmClosePermisoExist = true;
              state.permisoExistente = false;
              state.confirmacionCreatePermiso = false;
              state.loadingPeticion = false;
            }
          })
          .catch((error) => {
            console.log("ERROR", error);
            state.confirmacionCreatePermiso = false;
            state.confirmClosePermisoExist = false;
            state.loadingPeticion = false;

            if (error.response.status == 400) {
              swal({
                title: "No se encontró el permiso",
                icon: "error",
              });
            } else {
              swal({
                title: "Ha ocurrido un error",
                icon: "error",
              });
            }
          });
      });
    },

    //FINALIZAR PERMISO
    setFinalizarPermiso({ dispatch, commit, state }, idpermisosalida) {
      state.confirmClosePermisoExist = false;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "finalizarpermiso";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = { idpermisosalida: idpermisosalida };
      state.permiso.usuario_id = store.state.user.iduser;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿Deseas finalizar el permiso?",
        icon: "info",
        dangerMode: true,
        buttons: ["cancelar", "CONFIRMAR"],
      }).then((confirmar) => {
        if (!confirmar) {
          return;
        }

        state.loadingPeticion = true;
        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              dispatch("getPermisoSalidasAxios");
              socket.emit("addPermiso");
              socket.emit("addVisita", false);
              state.confirmClosePermisoExist = true;
              state.permisoExistente = false;
              state.confirmacionCreatePermiso = false;
              state.loadingPeticion = false;
            }
          })
          .catch((error) => {
            console.log("ERROR", error);
            state.confirmacionCreatePermiso = false;
            state.confirmClosePermisoExist = false;
            if (error.response.status == 400) {
              swal({
                title: "No se encontró el permiso",
                icon: "error",
              });
            } else {
              swal({
                title: "Ha ocurrido un error",
                icon: "error",
              });
            }
          });
      });
    },

    //ELIMINAR DOCUMENTO DEL PERMISO
    setEliminarDocumentoPermiso({ dispatch, commit, state }, idpermisosalida) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "eliminardocumento";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = { idpermisosalida: idpermisosalida };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿Deseas eliminar el documento?",
        icon: "info",
        dangerMode: true,
        buttons: ["cancelar", "CONFIRMAR"],
      }).then((confirmar) => {
        if (!confirmar) {
          return;
        }

        state.loadingPeticion = true;
        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              dispatch("getPermisoSalidasAxios");
              state.dialgoEvidenciaSalidaPermisos.openDialog = false;
              swal({
                title: "Se eliminó el documento",
                icon: "success",
              });
              socket.emit("addPermiso", false);
              state.loadingPeticion = false;
            }
          })
          .catch((error) => {
            console.log("ERROR", error);
            state.loadingPeticion = false;
            if (error.response.status == 400) {
              swal({
                title: "No se encontró el permiso",
                icon: "error",
              });
            } else {
              swal({
                title: "Ha ocurrido un error",
                icon: "error",
              });
            }
          });
      });
    },

    //ELIMINAR EVIDENCIA  DE INGRESO
    setEliminarEvidenciaIngreso({ dispatch, commit, state }, idpermisosalida) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        utiles.state.apiUrl + "eliminarevidenciaingreso";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = { idpermisosalida: idpermisosalida };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿Deseas eliminar el documento?",
        icon: "info",
        dangerMode: true,
        buttons: ["cancelar", "CONFIRMAR"],
      }).then((confirmar) => {
        if (!confirmar) {
          return;
        }

        state.loadingPeticion = true;
        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              dispatch("getPermisoSalidasAxios");
              state.dialgoEvidenciaIngresoPermisos.openDialog = false;
              swal({
                title: "Se eliminó el documento",
                icon: "success",
              });
              socket.emit("addPermiso", false);
              state.loadingPeticion = false;
            }
          })
          .catch((error) => {
            console.log("ERROR", error);
            state.loadingPeticion = false;
            if (error.response.status == 400) {
              swal({
                title: "No se encontró el permiso",
                icon: "error",
              });
            } else {
              swal({
                title: "Ha ocurrido un error",
                icon: "error",
              });
            }
          });
      });
    },

    //ACTUALIZAR PERMISO

    setActualizarPermiso({ state, commit, dispatch }) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "actualizarpermiso";
      utiles.state.configAxios.method = "POST";
      state.permiso.usuario_id = store.state.user.iduser;
      utiles.state.configAxios.data = state.permiso;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      utiles.state.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      swal({
        title: "¿Desea actualizar el permiso?",
        text: "Confirme",
        icon: "warning",
        buttons: ["cancelar", "CONFIRMAR"],
      }).then((confirmarcion) => {
        if (!confirmarcion) {
          return;
        }
        state.loadingPeticion = true;
        axios(utiles.state.configAxios)
          .then((res) => {
            if (res.status == 200) {
              dispatch("getPermisoSalidasAxios");
              commit("resetPermiso");
              visitas.state.dialogVisitaPreview.dialog = false;
              swal({
                title: "Se actualizó el documento",
                icon: "success",
              });

              socket.emit("addPermiso", false);
              socket.emit("addVisita", false);
              state.loadingPeticion = false;
            }
          })

          .catch((error) => {
            console.log(error);
            state.loadingPeticion = false;
            swal({
              title: "Ocurrio un error!",
              icon: "error",
            });
          });
      });
    },

    //DESCARGAR REPORTE EXCEL DE PERMISO

    downloadReportePermisosSalida({ dispatch, commit, state }) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "reporte-excel";
      utiles.state.configAxios.method = "POST";

      utiles.state.configAxios.data = state.filtersPermisos;
      utiles.state.configAxios.responseType = "blob";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      state.loadingPeticion = true;

      axios(utiles.state.configAxios)
        .then((response) => {
          // Convierte la respuesta en un objeto Blob
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          // Genera una URL temporal del archivo Blob
          const url = URL.createObjectURL(blob);
          // Crea un enlace y lo simula como si fuera un clic del usuario
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte-permisos-salidas.xlsx";
          a.click();
          state.loadingEXCELReporte = false;
          state.loadingPeticion = false;
        })
        .catch((error) => {
          state.loadingPeticion = false;
          console.error(error);
          // Si la respuesta es un objeto Blob, intenta obtener un mensaje de error a partir de la respuesta
          if (error.response && error.response.data instanceof Blob) {
            error.response.data.text().then((mensaje) => {
              console.error("Error en la respuesta:", mensaje);
            });
          }
          state.loadingEXCELReporte = false;
          swal({
            title: "Ha ocurrido un error",
            text: "Intenta actualizar la página",
            icon: "error",
          });
        });
    },

    //Guardar evidencia permiso de salida

    saveEvidenciaSalida({ commit, dispatch, state }, idpermisosalida) {
      console.log(idpermisosalida);
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "evidenciasalida";
      utiles.state.configAxios.method = "POST";
      state.evidenciaSalida.idpermisosalida = idpermisosalida;
      utiles.state.configAxios.data = state.evidenciaSalida;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      utiles.state.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      state.loadingPeticion = true;

      axios(utiles.state.configAxios)
        .then((response) => {
          if (response.status == 200) {
            state.loadingPeticion = false;
            state.dialgoEvidenciaSalidaPermisos.openDialog = false;
            dispatch("getPermisoSalidasAxios");
            commit("resetEvidenciaSalida");
            swal({
              title: "Se guardó el archivo!!!",
              icon: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingPeticion = false;
          swal({
            title: "Ha ocurrido un error",
            icon: "error",
          });
        });
    },

    //Guardar evidencia permiso de ingreso

    saveEvidenciaIngreso({ commit, dispatch, state }, idpermisosalida) {
      console.log(idpermisosalida);
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "evidenciaingreso";
      utiles.state.configAxios.method = "POST";
      state.evidenciaIngreso.idpermisosalida = idpermisosalida;
      utiles.state.configAxios.data = state.evidenciaIngreso;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      utiles.state.configAxios.headers = {
        "Content-Type": "multipart/form-data",
      };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      state.loadingPeticion = true;

      axios(utiles.state.configAxios)
        .then((response) => {
          if (response.status == 200) {
            state.loadingPeticion = false;
            state.dialgoEvidenciaIngresoPermisos.openDialog = false;
            dispatch("getPermisoSalidasAxios");
            commit("resetEvidenciaIngreso");
            swal({
              title: "Se guardó el archivo!!!",
              icon: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingPeticion = false;
          swal({
            title: "Ha ocurrido un error",
            icon: "error",
          });
        });
    },

    //REGISTRAR PERMISOS MASIVOS

    savePermisosMasivos({ commit, dispatch, state }) {
        state.loadingPermisosMasivos = true
        commit("utiles/resetConfigAxios");
        utiles.state.configAxios.url = utiles.state.apiUrl + "permisos-masivos";
        utiles.state.configAxios.method = "POST";
        utiles.state.configAxios.data = state.permisosMasivos;
        utiles.state.configAxios.headers.Authorization =
          "Bearer " + store.state.user.token;
        utiles.state.configAxios.headers = {
          "Content-Type": "multipart/form-data",
        };
        utiles.state.configAxios.headers.Authorization =
          "Bearer " + store.state.user.token;

        axios(utiles.state.configAxios)
        .then((response) => {
          state.loadingPermisosMasivos = false
          state.permisosMasivos = []
          dispatch("getPermisoSalidasAxios");
          swal({
            title: "Se registraron los permisos!!!",
            icon: "success",
          });
          state.resultadoPermisosMasivos = response.data
          visitas.state.componentSlider = "resultadoPermisosMasivos";

        })

        .catch((error) => {
          console.log(error);
          state.loadingPermisosMasivos = false
          swal({
            title: "Ha ocurrido un error",
            icon: "error",
          });
        })

      
    },
  },
};
