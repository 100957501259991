import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";

export default{
    namespaced: true,
    modules:{
        utiles
    },
    state: {
 
    },

    actions:{
        getReporteVisitas({ commit, state }, idpabellon) {
            commit("utiles/resetConfigAxios");
            utiles.state.configAxios.url = utiles.state.apiUrl + "generarpdfreporte";
            utiles.state.configAxios.method = "GET";
            utiles.state.configAxios.params = store.state.visitas.filtroVisitas
            utiles.state.configAxios.responseType = "blob";
            utiles.state.configAxios.headers.Authorization =
              "Bearer " + store.state.user.token;
            swal({
              title: "Cargando...",
              text: "Por favor, espera mientras se descarga el reporte",
              icon: "info",
              buttons: false,
              closeOnClickOutside: false,
              closeOnEsc: false,
              dangerMode: true,
            });
      
            axios(utiles.state.configAxios)
              .then((res) => {
                let blob = new Blob([res.data], { type: "application/pdf" });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "reporte-visitas.pdf";
                link.click();
                swal({
                  title: "Reporte generado correctamente",
                  icon: "success",
                });
              })
              .catch((error) => {
                console.log(error);
                swal({
                  title: "Ocurrió un error, porfavor actualice la página",
                  icon: "error",
                });
              });
          },


          getReporteVisitasExcel({commit, dispatch, state}){
            commit("utiles/resetConfigAxios");
            utiles.state.configAxios.url = utiles.state.apiUrl + "getreporteexcel";
            utiles.state.configAxios.method = "POST";

            /**VERIFICAR SI TIENE ROL EDUCATIVO SOLO PODRA VER INGRESO DE ESTUDIANTES */
            let roles = store.state.user.roles.permisos;
            const hayRolEducativo = roles.some(objeto => objeto.suject == 'estudianteasistencia');

            if(hayRolEducativo){ store.state.visitas.filtroVisitas.educativo = true} 

            utiles.state.configAxios.data = store.state.visitas.filtroVisitas;
            utiles.state.configAxios.responseType = "blob";
            utiles.state.configAxios.headers.Authorization =
              "Bearer " + store.state.user.token;
            swal({
              title: "Cargando...",
              text: "Por favor, espera mientras se descarga el reporte",
              icon: "info",
              buttons: false,
              closeOnClickOutside: false,
              closeOnEsc: false,
              dangerMode: true,
            });
      
            axios(utiles.state.configAxios)
              .then((response) => {
                // Convierte la respuesta en un objeto Blob
                const blob = new Blob([response.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }); 
                // Genera una URL temporal del archivo Blob
                const url = URL.createObjectURL(blob);
                // Crea un enlace y lo simula como si fuera un clic del usuario
                const a = document.createElement("a");
                a.href = url;
                a.download = "reporte-visitas.xlsx";
                a.click();
                state.loadingEXCELReporte = false;
                swal({
                  title: "Reporte generado correctamente",
                  icon: "success",
                });
              })
              .catch((error) => {
                console.error(error);
                // Si la respuesta es un objeto Blob, intenta obtener un mensaje de error a partir de la respuesta
                if (error.response && error.response.data instanceof Blob) {
                  error.response.data.text().then((mensaje) => {
                    console.error("Error en la respuesta:", mensaje);
                  });
                }
                state.loadingEXCELReporte = false;
                swal({
                  title: "Ha ocurrido un error",
                  text: "Intenta actualizar la página",
                  icon: "error",
                });
              });
          }
    }
}