import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class BloquesSeleccionadosService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }


    async getList(data){
        const response = await this.axios.post('bloques-seleccionados-estudiantes', data)
        return response.data

    }
    async getReporte(data)
    {
        const response = await this.axios.post('/bloques-seleccionados-estudiantes-excel', data, {
            responseType: 'blob',
        })
        const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); 
          // Genera una URL temporal del archivo Blob
          const url = URL.createObjectURL(blob);
          // Crea un enlace y lo simula como si fuera un clic del usuario
          const a = document.createElement("a");
          a.href = url;
          a.download = "bloques-seleccionados-estudiantes.xlsx";
          a.click();
    }

}