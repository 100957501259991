<template>
  <v-row>
    <v-col cols="12" class="mx-auto">
      <lista-permisos />
    </v-col>
  </v-row>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import ListaPermisos from "../components/lista-permisos.vue";
export default {
  mixins: [Filters],
  components: {
    ListaPermisos,
  },

  created() {
    if (!this.$can("read", "permisos")) {
      this.$router.push({ name: "dashboarad" });
    }
  },
  mounted() {
    
  },
};
</script>
