import utiles from "@/modules/Shared/utiles";
import Medicamentos from "./Medicamentos";
import RegistrarAtencion from "./RegistrarAtencion"
import visitas from "@/modules/Visitas/store/index";
import Paciente from "./Paciente";
import Atenciones from "./Atenciones";
import silderDerechaComponent from "@/modules/Shared/utiles/silderDerechaComponent";
import MetricasMedicamentos from "./MetricasMedicamentos";
import AtencionExterna from "./AtencionExterna";
import PresuncionDiagnostica from "./PresuncionDiagnostica";


export default {
    modules: {
        RegistrarAtencion,
        Medicamentos,
        utiles,
        Paciente,
        Atenciones,
        MetricasMedicamentos, 
        AtencionExterna,
        PresuncionDiagnostica
    },
    namespaced: true,
    state: {
        presentacion: [
            'Cápsula',
            'Comprimido',
            'Tableta',
            'Jarabe',
            'Suspensiones',
            'Efervescente',
            'Inyectable',
            'Crema',
            'ungüento',
            'Insumo y/o material',
            'otro'
        ]
    },
    mutations: {
        /*
        openInsertMedicamento(state) {
            Medicamentos.state.medicamento = Object.assign({}, Medicamentos.state.medicamentoDefault)
            visitas.state.dialogVisitaPreview.dialog = true
            visitas.state.dialogVisitaPreview.width = 600
            visitas.state.componentSlider = "InsertMedicamento"
        },

        openEditMedicamento(state, medicamento) {
            visitas.state.dialogVisitaPreview.dialog = true
            visitas.state.dialogVisitaPreview.width = 600
            visitas.state.componentSlider = "InsertMedicamento"

            Medicamentos.state.medicamento = Object.assign({}, medicamento)
        },

        openInsertPaciente(state) {
            Paciente.state.paciente = Object.assign({}, Paciente.state.pacienteDefault)
            visitas.state.dialogVisitaPreview.dialog = true
            visitas.state.dialogVisitaPreview.width = 600
            visitas.state.componentSlider = "FormPacienteRegister"
        }   */

      


    },
    actions: {

    }
}