<template>
  <div>
    <!--Onerectangle-->
    <OneRectangle
      v-if="dataPabellon.selectedTemplate == 'Onerectangle'"
      :ubicacionBano="dataPabellon.selectdUbicacionBaño"
    >
      <template v-slot:espacioCamasA>
        <ListaBloques
          :onlyRead="true"
          v-for="(bloque, index) in dataPabellon.listaBloques"
          :key="index"
          :bloque="bloque"
          :cardinal="'A'"
        />
      </template>
    </OneRectangle>
    <!--Onerectangle-->

    <!--AyBrectangle-->
    <AyBrectangle
      v-if="dataPabellon.selectedTemplate == 'AyBrectangle'"
      :ubicacionBano="dataPabellon.selectdUbicacionBaño"
    >
      <template v-slot:espaciocamasA>
        <ListaBloques
          :onlyRead="true"
          v-for="(cama, index) in dataPabellon.listaBloques"
          :key="index"
          :bloque="cama"
          :cardinal="'A'"
        />
      </template>
      <template v-slot:espaciocamasB>
        <ListaBloques
          :onlyRead="true"
          v-for="(cama, index) in dataPabellon.listaBloques"
          :key="index"
          :bloque="cama"
          :cardinal="'B'"
        />
      </template>
    </AyBrectangle>
    <!--AyBrectangle-->

    <!--AByCrectangle-->
    <AByCrectangle
      v-if="dataPabellon.selectedTemplate == 'AByCrectangle'"
      :ubicacionBano="dataPabellon.selectdUbicacionBaño"
    >
      <template v-slot:espacioCamaIzquierdaA>
        <ListaBloques
          :onlyRead="true"
          v-for="(cama, index) in dataPabellon.listaBloques"
          :key="index"
          :bloque="cama"
          :cardinal="'A'"
          :horizontal="true"
        />
      </template>
      <template v-slot:espacioCamaArribaB>
        <ListaBloques
          :onlyRead="true"
          v-for="(cama, index) in dataPabellon.listaBloques"
          :key="index"
          :bloque="cama"
          :cardinal="'B'"
        />
      </template>
      <template v-slot:espacioCamaAbajoC> 
        <ListaBloques
          :onlyRead="true"
          v-for="(cama, index) in dataPabellon.listaBloques"
          :key="index"
          :bloque="cama"
          :cardinal="'C'"
        />
      </template>
    </AByCrectangle>
    <!--AByCrectangle-->

    <!--Loader--->
    <v-skeleton-loader
    v-if="!dataPabellon.selectedTemplate"
      class="mx-auto"
      max-width="100%"
      type="card"
    ></v-skeleton-loader>

    <!--Loader--->



    <!---Dialogo asignar cama--->

      <DialogHospedarCama  />

    <!---Dialogo asignar cama--->


  </div>
</template>

<script>
import ListaBloques from "./read/ListaBloques.vue";

import OneRectangle from "./creador/OneRectangleTemplate.vue";
import AyBrectangle from "./creador/AyBrectangleTemplate.vue";
import AByCrectangle from "./creador/AByCrectangleTemplate.vue";

import DialogHospedarCama from "./read/DialogoHospedarCama.vue"

import { mapState } from "vuex";
export default {
  components: {
    ListaBloques,
    OneRectangle,
    AyBrectangle,
    AByCrectangle,
    DialogHospedarCama
  },

  props: {
    dataPabellon: {
      type: Object,
      required: true,
    },

  },

  methods:{
    
  }
};
</script>
