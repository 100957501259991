<template>

    <v-container fluid>
        <v-row class="mt-1">
            <v-col cols="12" class="pa-0">
                <v-card class="deep-purple accent-2 background-disciplina" elevation="0">
                    <v-card-text class="white--text d-flex justify-space-between align-center py-13">
                        <div class="px-4">
                            <div class="font-weight-bold text-h4">Comité de disciplina </div>
                            <div class="text-subtitle-1">Gestión de casos de indisciplina</div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="deep-purple darken-4 d-flex justify-center">
                        <div>
                            <v-btn  :to="{name:'listaDeCasosRegisrados'}" text color="white" class="mr-2">Casos de indisciplina</v-btn>

                            <a v-if="$can('create', 'disciplina')" href="/disciplina/registrodecasos"  class="px-3 py-2 deep-purple accent-2 white--text mr-2 rounded-sm text-button text-decoration-none" >REGISTRAR NUEVO CASO</a>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" class="pa-0">
                <router-view name="disciplinaView"></router-view>
            </v-col>
        </v-row>
    </v-container>
</template> 


<style>
.background-disciplina{
    background-image: url('../assets/jovenes.svg');
    background-position: 100% 30%;
    background-size: 40%;
}
</style> 


<script>
    export default{
        mounted()
        {
            if(!this.$can('read', 'disciplina')){
                this.$router.push({name: 'dashboard'})
            }
        }
    }

</script>