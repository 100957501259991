<template>
    <v-dialog width="500" v-model="dialogAlert.open" persistent>
        <v-card class="teal accent-4">
            <v-card-text class="d-flex align-center justify-center py-4">
                <v-avatar class="teal accent-1" size="250">
                    <v-img :src="require('../../assets/psicologa.jpg')" height="250" contain></v-img>
                </v-avatar>
            </v-card-text>
            <v-card-text>
                <div class="text-h4 teal--text text--lighten-5 text-center font-weight-bold">Atención registrada!</div>
            </v-card-text>
            <v-card-actions class="justify-center pb-6">
                <v-btn @click="onReload()" x-large class="teal accent-1 teal--text text--darken-4 px-5" elevation="0">
                    <v-icon left size="20">mdi-stethoscope</v-icon>
                    NUEVA ATENCIÓN
                </v-btn>
                <v-btn :to="{ name: 'registroDiarioPsicologia' }" x-large
                    class="teal accent-1 teal--text text--darken-4 px-8" elevation="0">
                    <v-icon left size="25">mdi-clipboard-list-outline</v-icon>
                    IR A LISTA
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { mapState } from 'vuex';


export default {


    computed: {
        ...mapState('psicologia/DialogosAlert', ['dialogAlert'])
    },


    methods: {
        onReload() {
            window.location.reload();

        }
    }
}
</script>