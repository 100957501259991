<template>
    <v-container>
            

           <div>
            <component :is="componentReportes"></component>   
             
           </div>


    </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ListaTablaCamasReportes from '../components/ReporteCamas/ListaTablaCamasReportes.vue'
import VistaReporte from '../components/ReporteCamas/VistaReporte.vue'
export default {
    components:{
        VistaReporte,
        ListaTablaCamasReportes
    },
    computed:{
        ...mapState('hospedaje/reporteCama',['componentReportes'])
    },
    methods:{
        ...mapMutations('hospedaje/reporteCama', ['setResetComponentReportes'])
    },
    created(){
        if(!this.$can('read', 'reportes')){
            this.$router.push({name: 'dashboard'})
        }

        this.setResetComponentReportes()
    }
}
</script>