import utiles from "@/modules/Shared/utiles"
import store from "@/store"
import axios from "axios"

export default{
    namespaced: true,
    modules:{
        utiles
    },
    state:{
        ultimosHospedados:[],
        informacionReportes: [],
        loading: false
    },

    mutations:{
        setUltimosHospedados(state, payload){
            state.ultimosHospedados = Object.assign({}, payload)
        },
        setInformacionReportes(state, payload){
            state.informacionReportes = Object.assign({}, payload)
        }
    },

    actions:{
        getUltimosHospedadosAxios({commit, state}){
            state.loading = true
            commit('utiles/resetConfigAxios')
            utiles.state.configAxios.url = utiles.state.apiUrl + 'huespedesporpabellones'
            utiles.state.configAxios.method = 'GET'
            utiles.state.configAxios.params = { sedes: store.state.user.roles.sedes.split(',')}
            utiles.state.configAxios.headers.Authorization = 'Bearer ' + store.state.user.token

            axios(utiles.state.configAxios)
            .then(resp => {
                if(resp.status == 200){
                    commit('setUltimosHospedados', resp.data.huespedes)
                    state.loading = false
                }
            })
            .catch(error => {
                console.log(error)
                state.loading = false
            })
        },

        getInformacionReportesAxios({commit, state}){
            state.loading = true
            commit('utiles/resetConfigAxios')
            utiles.state.configAxios.url = utiles.state.apiUrl + 'info-reporte-pabellones'
            utiles.state.configAxios.method = 'POST'
            utiles.state.configAxios.data = { sedes: store.state.user.roles.sedes.split(',')}
            utiles.state.configAxios.headers.Authorization = 'Bearer ' + store.state.user.token
            axios(utiles.state.configAxios)
            .then(resp => {
                if(resp.status == 200){
                    commit('setInformacionReportes', resp.data.reportesPabellones                    )
                    state.loading = false
                }
            })
            .catch(error => {
                console.log(error)
                state.loading = false
            })
        }
    }
}