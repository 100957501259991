<template>
  <div>
    <v-progress-linear color="blue" indeterminate v-if="loadingRoles"></v-progress-linear>
    <v-simple-table fixed-header height="750">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 40px"></th>
            <th style="width: 20px">N°</th>
            <th style="width: 80px">Nombre Rol</th>
            <th style="width: 50px">Sedes</th>
            <th style="width: 200px">Secciones Permitidas</th>
            <!--<th style="width: 600px">PERMISOS</th>-->
            <th style="width: 80px">CREAR</th>
            <th style="width: 80px">LEER</th>
            <th style="width: 80px">EDITAR</th>
            <th style="width: 80px">BORRAR</th>

          </tr>
        </thead>
        <tbody v-for="(rol, i) in listaDeRoles" :key="i">
          <tr>
            <td :rowspan="rol.permisos.length + 1">
              <div class="d-flex justify-space-between">
                <v-btn @click="destroyRolAxios(rol.idrole)" icon class="red darken-4 white--text mr-1" x-small
                ><v-icon x-small>mdi-delete</v-icon></v-btn
              >
              <v-btn @click="setEditRol(rol)" icon class="amber darken-4 white--text" x-small
                ><v-icon x-small>mdi-pencil</v-icon></v-btn
              >
              </div>
            </td>
            <td :rowspan="rol.permisos.length + 1">{{ i + 1 }}</td>
            <td :rowspan="rol.permisos.length + 1">{{ rol.name }}</td>
            <td :rowspan="rol.permisos.length + 1">
              <v-chip
                class="ma-1 bgCTM white--text"
                x-small
                v-for="(sede, i) in rol.sedes"
                :key="i"
                >{{ sede }}</v-chip
              >
            </td>
          </tr>
          <!--
          <tr v-for="(permiso, j) in rol.permisos" :key="j">
            <td>{{ permiso.name }}</td> 
            <td>
                <v-chip v-for="(action, index) in permiso.actions" :key="index" small class="ma-2 pl-2">
                  <v-avatar class="green accent-4" left><v-icon color="white" small style="width: 10px !important;">mdi-check-bold</v-icon></v-avatar>
                  {{ action }}
                </v-chip>
            </td>
          </tr>
          -->

          <tr v-for="(permiso, j) in rol.permisos" :key="j">
            <td>{{ permiso.name }}</td>
            <td>
              <v-avatar v-if="permiso.actions.includes('create')" size="25" class="green accent-4"><v-icon small color="white" >mdi-check</v-icon></v-avatar>
              <v-avatar v-else size="25" class="red accent-4"><v-icon small color="white" >mdi-close</v-icon></v-avatar>
            </td>
            <td>
              <v-avatar v-if="permiso.actions.includes('read')" size="25" class="green accent-4"><v-icon small color="white" >mdi-check</v-icon></v-avatar>
              <v-avatar v-else size="25" class="red accent-4"><v-icon small color="white" >mdi-close</v-icon></v-avatar>
            </td>
            <td>
              <v-avatar v-if="permiso.actions.includes('update')" size="25" class="green accent-4"><v-icon small color="white" >mdi-check</v-icon></v-avatar>
              <v-avatar v-else size="25" class="red accent-4"><v-icon small color="white" >mdi-close</v-icon></v-avatar>
            </td>
            <td>
              <v-avatar v-if="permiso.actions.includes('delete')" size="25" class="green accent-4"><v-icon small color="white" >mdi-check</v-icon></v-avatar>
              <v-avatar v-else size="25" class="red accent-4"><v-icon small color="white" >mdi-close</v-icon></v-avatar>
            </td>
          </tr>

        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {};
  },

  computed: {
    ...mapState("usuarios/roles", ["listaDeRoles", "loadingRoles"]),
  },

  methods: {
    ...mapActions("usuarios/roles", ["getListRolesAxios", "destroyRolAxios"]),
    ...mapMutations("usuarios/roles", ['setEditRol'])
  },

  mounted() {
    this.getListRolesAxios();
  },
};
</script>
