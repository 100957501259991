import swal from 'sweetalert';
import MotivoAtencionService from '../services/MotivoAtencionService'

const serviceMA = new MotivoAtencionService

export default {
  namespaced: true,
  state: {
    dialogAgregar: false,
    motivoAtencion: {
      motivo: "",
      area_atencion: "",
    },
    motivoAtencionDefault: {
        motivo: "",
        area_atencion: "",
      },
    listaMotivos: [],
    loadingSaveMotivo: false,
    loadingListMotivo: false
  },
  mutations: {
    switchDialog(state) {
      state.dialogAgregar = !state.dialogAgregar;
    },

    setListaMotivos(state, payload) {
      state.listaMotivos = payload;
    },

    setMotivo(state, motivo)
    {
        state.motivoAtencion = Object.assign({}, motivo)
    },

    resetMotivo(state)
    {
        state.motivoAtencion = Object.assign({}, state.motivoAtencionDefault)
    }
  },

  actions: {

    async getAllMotivosPsico({state, commit}, params)
    {
        state.loadingListMotivo = true
        state.listaMotivos = []
        try{
            const data = await serviceMA.getMotivoP(params)
            commit('setListaMotivos', data)
            state.loadingListMotivo = false
        }
        catch(error)
        {
            console.log(error)
            state.loadingListMotivo = false

        }
    },


    async addMotivoAtencion({state, commit, dispatch}){
        state.loadingSaveMotivo = true
        try{
            const data = await serviceMA.addMotivoP(state.motivoAtencion)
            dispatch('getAllMotivosPsico')
            commit('resetMotivo')
            state.loadingSaveMotivo = false
        }
        catch(error)
        {
            console.log(error)
            state.loadingSaveMotivo = false

        }
    },


    async updateMotivoAtencion({state, commit, dispatch}){
        state.loadingSaveMotivo = true
        try{
            const data = await serviceMA.updateMotivoP(state.motivoAtencion)
            dispatch('getAllMotivosPsico')
            commit('resetMotivo')
            swal({
                title: "Se actualizó correctamente",
                icon: "success"
            })
            state.loadingSaveMotivo = false
        }
        catch(error)
        {
            console.log(error)
            state.loadingSaveMotivo = false
        }
    },

    async deleteMotivoAtencion({state, commit, dispatch}, id_motivo_ap){ 
        let confirmar = await swal({
            title: "¿Desea eliminar este motivo?",
            icon: "warning",
            buttons: ['CANCELAR', 'SI, ELIMINAR'],
            dangerMode: true
        })

        if(!confirmar) { return }
        swal({
            title: "Cargando...",
            text: "Por favor, espere un momento.",
            icon: "info",
            button: false, // Ocultar el botón de confirmación
            closeOnClickOutside: false, // Evitar que el usuario cierre la alerta haciendo clic afuera
            closeOnEsc: false, // Evitar que el usuario cierre la alerta presionando la tecla Esc
            allowOutsideClick: false // Evitar que el usuario cierre la alerta haciendo clic afuera
          });

        try{
            const data = await serviceMA.deleteMotivoP(id_motivo_ap)
            dispatch('getAllMotivosPsico')
            swal({
                title: "Se elimino correctamente",
                icon: "success"
            })
        }
        catch(error)
        {
            console.log(error)
            swal({
                title: "Ocurrio un error",
                icon: "error"
            })
        }
    }

  },
};
