<template>
  <div>
    <v-card elevation="0" class="mb-2">
      <v-card-text class="font-weight-bold black--text">
        <v-row>
          <v-col cols="12" md="4">
            <label for="">Buscar por nombre o DNI</label>
            <v-text-field v-model="filtrosBusqueda.nombredni" @keypress.enter="getListaDeUsuariosAxios()" @click:clear="filtrosBusqueda.nombredni=''; getListaDeUsuariosAxios()" dense hide-details="auto" flat outlined placeholder="Nombre / DNI " clearable>
              <template v-slot:append>
                  <v-btn @click="getListaDeUsuariosAxios()" icon class="blue-grey darken-1 white--text d-lg-none" small> <v-icon>mdi-magnify</v-icon></v-btn>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <label for="">Buscar por correo:</label>
            <v-text-field v-model="filtrosBusqueda.email"  @keypress.enter="getListaDeUsuariosAxios()" @click:clear="filtrosBusqueda.email=''; getListaDeUsuariosAxios()" dense hide-details="auto" flat outlined placeholder="email@cetemin.edu.pe " clearable>
              <template v-slot:append>
                  <v-btn @click="getListaDeUsuariosAxios()" icon class="blue-grey darken-1 white--text d-lg-none" small> <v-icon>mdi-magnify</v-icon></v-btn>
              </template>
            </v-text-field>
          </v-col>


        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table mobile-breakpoint="0" :loading="loadingList" :headers="headers" :items="listaUsers" class="elevation-0" :items-per-page="100" height="600px" fixed-header>
        <template v-slot:item.index="{index}">
            {{ index + 1 }}
        </template>
        <template v-slot:item.nombrecompleto="{item}">
            <small class="font-weight-bold grey--text text--darken-4">{{ item.nombres + ' ' + item.apellidos}}</small>
        </template>
        <template v-slot:item.email="{item}">
            <v-chip small class="font-weight-bold">{{ item.email}}</v-chip>
        </template>
        <template v-slot:item.roles="{item}">
            <v-chip small v-if="item.roles" class="light-blue lighten-4 text-uppercase rounded font-weight-bold"><small>{{ item.roles.name }}</small></v-chip>
        </template>
        <template v-slot:item.isActive="{item}">
            <v-chip small class="rounded" :color="item.isActive? 'green accent-3' : 'red darken-4 white--text'"><small class="font-weight-bold ">{{ item.isActive ? 'ACTIVO' : 'BLOQUEADO'}}</small></v-chip>
        </template>
        <template v-slot:item.actions="{item}">
            <v-btn @click="setDialogEdit(item)" icon class="amber mr-2 rounded-sm" small> <v-icon small>mdi-pencil </v-icon></v-btn>
            <v-btn @click="setChangeIsActiveAxios(item)" icon 
            :class="item.isActive ? 'grey darken-4' : 'green accent-4'" class="white--text rounded-sm" small> 
            <v-icon small>{{item.isActive ? 'mdi-account-off' : 'mdi-account'}} </v-icon>
           </v-btn>
        </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      headers: [
        {
          text: "N°",
          value: "index",
          sortable: false,
          width: "50px"
        },
         {
          text: "DNI",
          value: "dni",
          sortable: false,
          width: "50px"
        },
        {
          text: "Apellidos y Nombre",
          value: "nombrecompleto",
          sortable: false,
          width: "250px"
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
          width: "50px"
        },
        {
          text: "Cargo",
          value: "cargo",
          sortable: false,
          width: "200px"
        },
        {
          text: "Rol",
          value: "roles",
          sortable: false,
          width: "100px"
        },
        {
          text: "Activo?",
          value: "isActive",
          sortable: false,
          width: "50px"
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "100px"
        },
      ],
    };
  },

  computed:{
    ...mapState('usuarios', ['listaUsers', 'loadingList', 'filtrosBusqueda'])
  },
  methods:{
    ...mapActions('usuarios',['getListaDeUsuariosAxios', 'setChangeIsActiveAxios']),
    ...mapMutations('usuarios', ['setDialogEdit'])
  },

  mounted(){
    this.getListaDeUsuariosAxios();
    console.log(this.listaUsers)
  }
 
};
</script>
