<template>
  <v-dialog width="500" v-model="dialogAddCama.open">
    <v-card>
      <v-form v-model="validateForm">
        <div class="d-flex justify-space-between pa-3 bgCTM white--text">
          <span>{{ isEdit ? 'Editar cama / camarote' : 'Agregar nueva cama / camarote'}}</span>
          <v-avatar size="30" color="white">
            <small class="black--text font-weight-bold">{{
              ubicacionCardinal
            }}</small>
          </v-avatar>
        </div>
        <v-card-text class="black--text">
          <div class="my-2">
            <label for="">Tipo de Cama:</label>
            <v-select
              :rules="[rules.required]"
              outlined
              dense
              hide-details="auto"
              :items="tiposcama"
              placeholder="Selecciona tipo de cama"
              v-model="bloque.tipo"
            ></v-select>
          </div>

          <!-- datos de cama -->
          <div
            class="d-flex amber lighten-5 pa-2 rounded-lg mb-3"
            v-if="bloque.tipo == 'cama'"
          >
            <div class="my-2 mr-3" style="width: 50%">
              <label for="">Indentif. de cama:</label>
              <v-text-field
                :rules="[rules.required]"
                outlined
                dense
                placeholder="Letra o número"
                hide-details="auto"
                v-model="camaSimple.indexcama"
              ></v-text-field>
            </div>
            <div class="my-2 mr-3" style="width: 50%">
              <label for="">Estado de cama:</label>
              <v-select
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :items="estadoscama"
                placeholder="Selecciona tipo de cama"
                v-model="camaSimple.state"
              ></v-select>
            </div>
          </div>
          <!-- datos de cama -->

          <!-- datos de camarote -->
          <div
            class="d-flex indigo lighten-5 pa-2 rounded-lg mb-3"
            v-if="bloque.tipo == 'camarote'"
          >
            <div class="my-2 mr-3" style="width: 50%">
              <label for="">Indentif. de cama (ARRIBA):</label>
              <v-text-field
                :rules="[rules.required]"
                outlined
                dense
                placeholder="Letra o número"
                hide-details="auto"
                v-model="camaArriba.indexcama"
              ></v-text-field>
            </div>

            <div class="my-2" style="width: 50%">
              <label for="">Estado de cama (ARRIBA):</label>
              <v-select
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :items="estadoscama"
                placeholder="Selecciona tipo de cama"
                v-model="camaArriba.state"
              ></v-select>
            </div>
          </div>

          <div
            class="d-flex deep-purple lighten-5 pa-2 rounded-lg"
            v-if="bloque.tipo == 'camarote'"
          >
            <div class="my-2 mr-3" style="width: 50%">
              <label for="">Indentif. de cama (ABAJO):</label>
              <v-text-field
                :rules="[rules.required]"
                outlined
                dense
                placeholder="Letra o número"
                hide-details="auto"
                v-model="camaAbajo.indexcama"
              ></v-text-field>
            </div>

            <div class="my-2" style="width: 50%">
              <label for="">Estado de cama (ABAJO):</label>
              <v-select
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :items="estadoscama"
                placeholder="Selecciona tipo de cama"
                v-model="camaAbajo.state"
              ></v-select>
            </div>
          </div>
          <!-- end datos de camarote -->

          <!-- Comentario -->
          <div class="my-2">
            <label for="">Comentario:</label>
            <v-textarea
              outlined
              dense
              hide-details="auto"
              rows="3"
              v-model="bloque.comentario"
            ></v-textarea>
          </div>

          <v-btn :disabled="!validateForm" block color="bgCTM white--text" @click="saveBloque()">
            GUARDAR
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Filters from '@/modules/Shared/Filters';
export default {
  mixins:[
    Filters
  ],
  data: () => ({
    validateForm: false,
  }),
  computed: {
    ...mapState("pabellones", ["tiposcama", "estadoscama", , "dataPabellon"]),
    ...mapState("pabellones/creadorPabellon", [
      "camaSimple", "camaArriba", "camaAbajo",
      "bloque",
      "ubicacionCardinal",
      "dialogAddCama",
      "isEdit"
    ]),
  },

  methods: {
    ...mapActions("pabellones/creadorPabellon", ["saveBloque"]),
  },
};
</script>
