import swal from "sweetalert";
import store from "@/store";
import SancionDisciplinaService from "../services/SancionDisciplinaService";

const serviceCategoria = new SancionDisciplinaService
export default{
    namespaced:true,
    state:{
        tipo_sancion: {
            sancion: ""
        },
        tipo_sancion_default: {
            sancion: ""
        },
        listaTiposSancion:[],
        loadingSancion: false,
        loadingListSancion: false
    },

    mutations:{

        setTipoSancion(state, payload)
        {
            state.tipo_sancion = Object.assign({}, payload)
        },

        resetTipoSancion(state, payload)
        {
            state.tipo_sancion = Object.assign({}, state.tipo_sancion_default)
        },

        setListaTiposSancion(state, payload)
        {
            state.listaTiposSancion = payload
        },

        resetListaTiposSancion(state)
        {
            state.listaTiposSancion = []
        }
    },

    actions:{
        async getListTipoSancion({state, commit})
        {
            state.loadingListSancion = true
            try
            {
                const data = await serviceCategoria.get()
                console.log(data)
                commit('setListaTiposSancion', data)
                state.loadingListSancion = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingListSancion = false
            }
        },

        async addTipoSancion({state, commit, dispatch})
        {
            state.loadingSancion = true
            try
            {
                const data = await serviceCategoria.add(state.tipo_sancion)
                dispatch('getListTipoSancion')
                commit('resetTipoSancion')
                state.loadingSancion = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingSancion = false
            }
        },

        async updateTipoSancion({state, commit, dispatch})
        {
            state.loadingSancion = true
            try
            {
                const data = await serviceCategoria.update(state.tipo_sancion)
                dispatch('getListTipoSancion')
                commit('resetTipoSancion')
                state.loadingSancion = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingSancion = false
            }
        },

        async deleteTipoSancion({state, commit, dispatch}, idsancion)
        {
            const confirmar = await swal({
                title: "¿desea eliminar este elemento?",
                icon: 'warning',
                dangerMode: true,
                buttons: ['cancelar', 'CONFIRMAR']
            })

            if(!confirmar){ return }
            
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false, 
                closeOnClickOutside: false, 
                closeOnEsc: false, 
                allowOutsideClick: false 
              });
            try
            {
                const data = await serviceCategoria.delete(idsancion)
                dispatch('getListTipoSancion')
                swal({
                    title: "Eliminado correctamente",
                    icon: "success"
                })
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ocurrio un error",
                    icon: "error"
                })
            }
        }
    }
}