import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class EstudianteService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }


    async getList(params){
        const response = await this.axios.get('estudiante-comedor', {params: params})
        return response.data

    }


    async add(data){
        const response = await this.axios.post('estudiante-comedor', data)
        return response.data
        
    }


    async masive(data){
        const response = await this.axios.post('estudiante-comedor-masive', data)
        return response.data
        
    }


    async update(data){
        const response = await this.axios.put('estudiante-comedor', data)
        return response.data
        
    }


    async cambiarEstado(data){
        const response = await this.axios.post('estudiante-comedor-estado', data)
        return response.data
        
    }

    async cambiarEstadofindesemana(data){
        const response = await this.axios.post('estudiante-comedor-findesemana', data)
        return response.data
        
    }
    async resetPassword(data)
    {
        const response = await this.axios.post('reset-password-estudiante', data)
        return response.data
    }
    async getReporte(data)
    {
        const response = await this.axios.post('/estudiante-reporte-excel', data, {
            responseType: 'blob',
        })
        const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); 
          // Genera una URL temporal del archivo Blob
          const url = URL.createObjectURL(blob);
          // Crea un enlace y lo simula como si fuera un clic del usuario
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte-estudiantes-comedor.xlsx";
          a.click();
    }


}