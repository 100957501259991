<template>
    <v-chip small class="pl-2"> 
        <v-avatar left :class="[colorAvatar(), 'white--text']">
            <v-icon color="white" small>{{iconAvatar() }}</v-icon>
        </v-avatar>
         {{ prioridad }}
    </v-chip>
</template>

<script>
export default {
    props:{
        prioridad:{
            type: String,
            required: true
        }
    },

    methods: {
        colorAvatar(){
            switch(this.prioridad){
                case "BAJA":
                    return 'blue accent-4'
                
                case "MEDIA":
                    return 'deep-purple darken-4'
                
                case "ALTA":
                    return 'red darken-4'

                case "CRÍTICA":
                    return 'blue-grey darken-4'
            }
        },

        iconAvatar(){
            switch(this.prioridad){
                case "BAJA":
                    return 'mdi-arrow-down-thick'
                
                case "MEDIA":
                    return 'mdi-alert-rhombus'
                
                case "ALTA":
                    return 'mdi-alarm-light'

                case "CRÍTICA":
                    return 'mdi-bullhorn'
            }
        }
    }
}
</script>