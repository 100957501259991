<template>
    <v-card elevation="0" style="width: 100%;">
        <v-card-text v-if="loadingValidate">
                <h1 style="font-size: 100px;" class="text-center">🧐</h1>
                <br><br>
                <h2 class="text-center black--text font-weright-bold mt-5 mb-2">Validando token</h2>
                <div class="text-center">Espere un momento...</div>
        </v-card-text>
        <v-card-text v-else>
            <h1 style="font-size: 100px;" class="text-center">🤨</h1>
            <br><br>
            <h2 class="text-center black--text font-weright-bold mt-5 mb-2">No se pudo validar el token</h2>
            <div class="text-center">Comunicate con Soporte Técnico para recibir ayuda</div>
            <br><br>
            <v-btn :to="{name: 'Login'}" block text small>volver al login</v-btn>
        </v-card-text>
    </v-card>
</template>


<script>
import { mapMutations, mapState } from 'vuex'
export default {

    computed:{
        ...mapState('login/restorepass',['loadingValidate'])
    },
    methods:{
       
    },

   
}
</script>