import store from "@/store";
import AtencionExternaService from "../service/atencionExternaService"

const serviceAtencionExt = new AtencionExternaService;

export default {
    namespaced: true,
    state: {
        atencionExterna: {
            diagnostico: "",
            tratamiento: "",
            control: "",
            atencion_medica_id: "",
            descripcion_traslado: ""
        },

        atencionExternaDefault: {
            diagnostico: "",
            tratamiento: "",
            control: "",
            atencion_medica_id: "",
            descripcion_traslado: ""
        },
        loadinAEX:false,
        editar: false
    },
    mutations: {
        setIDatencionExterna(state, idatencionmedica) {
            state.atencionExterna.atencion_medica_id = idatencionmedica
        },
        setAtencionExterna(state, payload) {
            state.atencionExterna = Object.assign({}, payload)
        }, 

        resetAtencionExterna(state) {
            state.atencionExterna = Object.assign({}, state.atencionExternaDefault)
        },
        switchEditable(state){
            state.editar = !state.editar
        },

        setEditarAtencionExterna(state, atencionExterna)
        {
            state.atencionExterna = Object.assign({}, atencionExterna)
        } 
    },

    actions: {

        async setAtencionExterna({ state, commit }) {
            state.loadinAEX = true
            try {

                const data = await serviceAtencionExt.setAtencionExterna(state.atencionExterna)
                state.editar = false
                store.commit('topico/Atenciones/selectPaciente', data.paciente)
                store.commit('topico/Atenciones/selectAtencion', data)
                store.dispatch('topico/Atenciones/getListAtencionesPorPaciente')
                store.dispatch('topico/Atenciones/getListAtencionesDiarias')
                state.loadinAEX = false
            }
            catch (error) {
                console.log(error)
                state.loadinAEX = false
            }
        },


        async updateAtencionExterna({ state, commit }) {
            state.loadinAEX = true
            try {

                const data = await serviceAtencionExt.updateAtencionExterna(state.atencionExterna)
                store.commit('topico/Atenciones/selectPaciente', data.paciente)
                store.commit('topico/Atenciones/selectAtencion', data)
                store.dispatch('topico/Atenciones/getListAtencionesPorPaciente')
                store.dispatch('topico/Atenciones/getListAtencionesDiarias')
                state.editar = false
                state.loadinAEX = false
                
            }
            catch (error) {
                console.log(error)
                state.loadinAEX = false
            }
        }
    }
}