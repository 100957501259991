import utiles from "@/modules/Shared/utiles";
import registrarVisita from "./registrarVisita";
import configuracion from "./configuracion";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";
import registrarSalida from "./registrarSalida";
import registrarIngreso from "./registrarIngreso";
import reporteVisita from "./reporteVisita";
import visitasMasivas from "./visitasMasivas";
import materialesIngresados from "./materialesIngresados";

export default {
  namespaced: true,
  modules: {
    registrarVisita,
    utiles,
    configuracion,
    registrarSalida,
    registrarIngreso,
    reporteVisita,
    visitasMasivas,
    materialesIngresados,
  },
  state: {
    listaVisitasHoy: [],
    dialogVisitaPreview: {
      dialog: false,
      width: 500,
    },

    componentSlider: "",
    visita: null,

    areas: [],

    jefaturasAreas: [],

    encargadosDeArea: [],

    filtroVisitas: {
      sedes: [],
      selectSedes: [],
      fecha_inico: "",
      fecha_fin: "",
      nombreDni: "",
      tipovisita: "",
      rolestudiante: false,
      solo_ingreso: true,
      solo_salida: true,
    },

    loadingvisitas: false,

    paginate: {
      current_page: 1,
      from: 1,
      last_page: 0,
      per_page: 2,
      to: 2,
      total: 0,
    },
    option: {
      page: 1,
      itemsPerPage: 80,
    },
  },

  mutations: {
    setVisitas(state, payload) {
      state.listaVisitasHoy = payload;
    },

    openPreviewVisita(state, payload) {
      state.visita = null;
      state.dialogVisitaPreview.width = 600;
      state.dialogVisitaPreview.dialog = !state.dialogVisitaPreview.dialog;
      state.componentSlider = "VisitaPreview";
      state.visita = Object.assign({}, payload);
      console.log("visita preview", payload);
    },

    openPreviewRegistrarVisita(state) {
      store.commit("visitas/registrarVisita/getDateCurrent");
      state.dialogVisitaPreview.dialog = !state.dialogVisitaPreview.dialog;
      state.dialogVisitaPreview.width = 850;
      state.componentSlider = "FormRegistrarVisita";
      //Resetear formulario de registro de visita
      store.commit("visitas/registrarVisita/cambioDePerfil");
    },

    openRegistrarVisitasMasivas(state) {
      store.commit("visitas/visitasMasivas/resetVistaMasiva");
      store.commit("visitas/registrarVisita/getDateCurrent");
      state.dialogVisitaPreview.dialog = !state.dialogVisitaPreview.dialog;
      state.dialogVisitaPreview.width = 850;
      state.componentSlider = "cargarPlantilla";
    },

    openMarcarIngreso(state, payload) {
      store.commit("visitas/registrarVisita/getDateCurrent");
      state.dialogVisitaPreview.dialog = !state.dialogVisitaPreview.dialog;
      state.dialogVisitaPreview.width = 850;
      state.componentSlider = "MarcarIngreso";
      //Subir informacion al state de marcarIngreso
      store.commit("visitas/registrarIngreso/setVisitaIngreso", payload);
      //reset marcar Ingreso
      //store.commit('visitas/registrarIngreso/resetMarcarIngreso')
    },

    openMarcarSalida(state, payload) {
      state.dialogVisitaPreview.dialog = !state.dialogVisitaPreview.dialog;
      state.dialogVisitaPreview.width = 500;
      state.componentSlider = "MarcarSalida";
      store.commit("visitas/registrarSalida/setVisitaSalida", payload);
      store.commit("visitas/registrarSalida/clearFormSalida");
    },

    closeSlider(state) {
      state.dialogVisitaPreview.dialog = false;
    },

    setDialogVisita(state) {
      state.dialogVisitaPreview.dialog = !state.dialogVisitaPreview.dialog;
    },

    setEncargadosArea(state, event) {
      let encargados = state.areas.filter((area) => area.id == event)[0]
        .encargados;
      state.encargadosDeArea = encargados;
    },

    setPaginate(state, payload) {
      state.paginate = Object.assign({}, payload);
    },
  },

  actions: {
    getVisitasHoy({ commit, state }, loading = true) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        utiles.state.apiUrl +
        "visitas?page=" +
        state.option.page +
        "&itemsPerPage=" +
        state.option.itemsPerPage;
      utiles.state.configAxios.method = "GET";
      state.filtroVisitas.sedes =
        state.filtroVisitas.selectSedes.length == 0
          ? store.state.user.roles.sedes.split(",")
          : state.filtroVisitas.selectSedes;

      /**VERIFICAR SI TIENE ROL EDUCATIVO SOLO PODRA VER INGRESO DE ESTUDIANTES */
      let roles = store.state.user.roles.permisos;
      state.filtroVisitas.rolestudiante = roles.some(
        (objeto) => objeto.suject == "estudianteasistencia"
      );
      console.log(state.filtroVisitas);
      utiles.state.configAxios.params = state.filtroVisitas;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      state.loadingvisitas = loading;
      axios(utiles.state.configAxios)
        .then((resp) => {
          console.log(resp);
          if (resp.status == 200) {
            //SET PAGINATION
            commit("setPaginate", resp.data.meta);
            commit("setVisitas", resp.data.data);
            state.loadingvisitas = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingvisitas = false;
        });
    },

    resetFilter({ dispatch, state }) {
      state.filtroVisitas.fecha_inicio = "";
      state.filtroVisitas.fecha_fin = "";
      dispatch("getVisitasHoy");
    },

    resetFilterText({ dispatch, state }) {
      state.filtroVisitas.nombreDni = "";
      state.filtroVisitas.tipovisita = "";
      dispatch("getVisitasHoy");
    },
  },
};
