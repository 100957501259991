export default (store) => {
  const ability = store.getters.ability;

   return store.subscribe((mutation) => {
    switch (mutation.type) {
      case "login/setUser":
        try {
          let permisos = mutation.payload.user.roles.permisos.map((perm) => ({
            subject: perm.suject,
            action: perm.actions.split(','),
          }));
          ability.update(permisos);

          
        
        } catch (error) {
          console.error("Error al actualizar permisos: ", error);
        }
        break;
      case "login/logout":
        ability.update([{ action: "read", subject: "login" }]);
        break;
    }
  });
};
