export default
{
    namespaced: true,
    state:{
        dialogAlert:{
            open: false,
            component: ""
        },
        dialogAlertDefault:{
            open: false,
            component: ""
        }
    },
    mutations:{
        openDialogAlert(state, payload)
        {
            state.dialogAlert.open = false
            state.dialogAlert = Object.assign({}, payload)
        },

        resetDialogAlert(state)
        {
            state.dialogAlert.open = null
        }
    },
    actions:{

    }
}