import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class MenuSemanalService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token

              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }

    async getList(params){
        const response = await this.axios.post('lista-menu-semanal', {params: params})
        return response.data

    }


    async getDocumento(data){
        const response = await this.axios.post('get-menu-semanal', data)
        return response.data
        
    }

    async cargarDocumento(data){
        const response = await this.axios.post('cargar-menu-semanal', data)
        return response.data
        
    }


    async eliminarDocumento(data){
        const response = await this.axios.post('eliminar-menu-semanal', data)
        return response.data
        
    }

}