<template>
    <v-row class="px-5">
        <v-col cols="12" class="d-flex align-center justify-space-between">
            <h3>Configurar contenido</h3>
            <v-btn :to="{name: 'visitas-home'}" class="bgCTM white--text mt-2" elevation="0" small >IR A VISITAS</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="">
            <areas-component/>
        </v-col>
        <v-col cols="12" md="4" class="">
            <areasVisitasComponent />
        </v-col>
        <v-col cols="12" md="4" class="">
            <motivosPredeComponent />
        </v-col>
        <v-col cols="12" md="4" class="">
            <areasTercerosComponent />
        </v-col>
    </v-row>
</template>

<script>
import areasComponent from "../components/configuracion/areasComponents.vue";
import areasVisitasComponent from "../components/configuracion/areasVisitasComponent.vue";
import motivosPredeComponent from "../components/configuracion/motivosPredeComponent.vue";
import areasTercerosComponent from "../components/configuracion/areasTercerosComponent.vue";
export default {
    components:{
        areasComponent,
        areasVisitasComponent,
        motivosPredeComponent,
        areasTercerosComponent
    }
}
</script>