<template>
  <div class="d-flex" style="height: 100vh">
    <v-card elevation="0" width="100%" class="transparent">
      <v-card-text class="text-center black--text py-0 py-lg-4">
        <div class="font-weight-bold text-subtitle-1 mb-2 ">
          PERMISOS DE SALIDA A ESTUDIANTES
        </div>
        <div class="d-flex flex-wrap justify-space-between font-weight-bold mb-2">
          <div class="text-left" style="width: 49%" v-if="multipleSede()">
            <label for="">Filtrar por sede</label>
            <v-select
              dense
              hide-details="auto"
              flat
              outlined
              placeholder="Selecciona un estado"
              clearable
              :items="sedes"
              v-model="filtersPermisos.sedes"
              @change="getPermisosSalidasAgente()"
              multiple
              
            ></v-select>
          </div>

          <div class="text-left" style="width: 49%">
            <label for="">Filtrar por DNI y nombre</label>
            <v-text-field
              dense
              hide-details="auto"
              flat
              outlined
              placeholder="DNI / NOMBRE"
              clearable
              v-model="filtersPermisos.nombreDni"
              @change="getPermisosSalidasAgente()"
              @keypress.enter="getPermisosSalidasAgente()"
              @click:clear="filtersPermisos.nombreDni = ''; getPermisosSalidasAgente()"
            ></v-text-field>
          </div>
        </div>
        <div class="py-2 d-flex justify-space-between">
          <div class="font-weight-bold text-subtitle-1">Permisos del día de hoy</div>
          <v-btn @click="getPermisosSalidasAgente()" elevation="0" small class="blue darken-4 white--text"><v-icon left>mdi-reload</v-icon>ACTUALIZAR</v-btn>
        </div>
        <v-data-table
          :headers="headers"
          :items="lista_permisos_agente"
          fixed-header
          height="600"
          no-data-text="No hay permisos en esta sede"
          :loading="loading"
          mobile-breakpoint="0"
          :items-per-page="80"
        >
          <template v-slot:item.index="{ index }">{{ index + 1 }}</template>
          <template v-slot:item.fecha_salida="{ item }">{{
            formatFechaCustom(item.fecha_salida + ' ' + item.hora_salida, "DD/MM/YY")
          }}</template>
          <template v-slot:item.dni="{ item }"
            ><small>{{ item.dni }}</small></template
          >
          <template v-slot:item.detalle="{ item }">
            <div class="py-3">
              <small class="d-block" :class="[item.estado == 'anulado' ? 'text-decoration-line-through' :'']">{{ item.dni }}</small>
              <span :class="[item.estado == 'anulado' ? 'text-decoration-line-through' :'']">{{ item.nombrecompleto }}</span>
              <small class="d-block font-weight-bold text-caption" :class="[item.estado == 'anulado' ? 'text-decoration-line-through' :'']">{{
                item.carrera + "  " + item.admision
              }}</small>
             <v-chip x-small class="rounded text-uppercase font-weight-bold mr-2">{{
                item.sede
              }}</v-chip>
              <v-chip v-if="item.estado != 'enviado'" x-small :class="estadoChipColor(item.estado)" class="rounded text-uppercase font-weight-bold">{{
                item.estado
              }}</v-chip>
               <v-chip v-else x-small :class="estadoChipColor(item.estado)" class="rounded text-uppercase font-weight-bold">NUEVO</v-chip> 
            </div>
          </template>
          <template v-slot:item.ver="{ item }">
            <v-btn
              @click="openPreviewPermisoAgente(item)"
              small
              class="blue-grey darken-2 rounded"
              elevation="0"
              icon
              ><v-icon color="white" size="20">mdi-open-in-new</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import { socket } from "@/socket-cliente";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins: [Filters],
  data() {
    return {
      headers: [
        {
          text: "N°",
          value: "index",
          width: "10px",
          sortable: false,
        },
        {
          text: "FECHA DE SALIDA",
          value: "fecha_salida",
          width: "40px",
          sortable: false,
        },

        {
          text: "DESCRIPCIÓN",
          value: "detalle",
          width: "430px",
          sortable: false,
        },
        {
          text: "VER",
          value: "ver",
          width: "30px",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapState("permisos", ["lista_permisos_agente", "loading", 'filtersPermisos']),
    ...mapState("visitas/utiles", ['sedes'])
  },

  methods: {
    ...mapMutations("permisos", ["openPreviewPermisoAgente"]),
    ...mapActions("permisos", ["getPermisosSalidasAgente"]),
    multipleSede(){
      let sedes = this.$store.state.user.roles.sedes.split(',');
      return sedes.length > 1;
    },
    estadoChipColor(estado){
            if(estado == 'enviado') return 'blue darken-1 white--text'
            if(estado == 'iniciado') return 'green accent-3 white--text'
            if(estado == 'finalizado') return 'light-blue darken-3 white--text'
            if(estado == 'cancelado') return 'grey darken-4 white--text'
        },
  },
  mounted() {
    //this.subscribeToEvents();

    this.filtersPermisos.sedes = this.$store.state.user.roles.sedes.split(',');
    this.getPermisosSalidasAgente()

      socket.on('LOAD_ALL_PERMISOS', (data) => { 
        this.getPermisosSalidasAgente(data)
      })
  },
};
</script>
