<template>
    <v-dialog width="400" v-model="dialog">
        <v-card elevation="0">
            <v-card-title class="text-title">
                Cambiar contraseña
            </v-card-title>
            <v-card-subtitle class="pb-0">Ingrese los siguientes datos:</v-card-subtitle>
            <v-card-text class="black--text font-weight-bold">
                <v-form v-model="validateform">
                <div class="pa-2">
                    <label for="">Contraseña Actual: </label>
                    <v-text-field 
                    :rules="[rules.required]"
                    v-model="changepass.currentpassword" :type="hiddenpass1? 'password' : 'text'" outlined dense hide-details="auto">
                        <template  v-slot:append><v-btn @click="hiddenpass1 = !hiddenpass1" icon class="bgCTM white--text" small><v-icon small>{{ hiddenpass1? 'mdi-eye': 'mdi-eye-off'}}</v-icon></v-btn></template>
                    </v-text-field>
                </div>

                <div class="pa-2">
                    <label for="">Nueva Contraseña: </label>
                    <v-text-field 
                    :rules="[rules.required]"
                    v-model="changepass.newpassword" :type="hiddenpass2? 'password' : 'text'" outlined dense hide-details="auto">
                        <template v-slot:append><v-btn @click="hiddenpass2 = !hiddenpass2" icon class="bgCTM white--text" small><v-icon small>{{ hiddenpass2? 'mdi-eye': 'mdi-eye-off'}}</v-icon></v-btn></template>
                    </v-text-field>
                </div>

                <v-btn :disabled="!validateform" @click="updatePassword()" block class="bgCTM white--text mt-2">CAMBIAR CONTRASEÑA</v-btn>

                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Filters from '@/modules/Shared/Filters'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
    mixins:[
        Filters
    ],
    data(){
        return{
            hiddenpass1: true,
            hiddenpass2: true,
            validateform: true
        }
    },

    computed:{
        ...mapState('login/restorepass',['changepass', 'dialogChangepass']),

        dialog:{
            get(){
                return this.dialogChangepass
            },

            set(){
                this.setDialogChangePass()
            }
        }
    },

    methods: {
        ...mapActions('login/restorepass', ['updatePassword']),
        ...mapMutations('login/restorepass',['setDialogChangePass'])
    }
}
</script>