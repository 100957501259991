import swal from "sweetalert";
import EvaluacionesService from "../services/EvaluacionesService";
import router from "@/router";
import { v4 as uuidv4 } from "uuid";

const evaluacionSevice = new EvaluacionesService();
export default {
  namespaced: true,
  state: {
    listaEvaluaciones: [],
    examen: {
      titulo_examen: "Título de evaluación",
      descripcion: "",
      items: [],
      tipo_calificacion: "puntaje_respuesta",
      examen_publicado: false,
      color_thema: "",
      calculo_resultado: "",
    },
    examenDefault: {
      titulo_examen: "Título de evaluación",
      descripcion: "",
      items: [],
      tipo_calificacion: "puntaje_respuesta",
      examen_publicado: false,
      color_thema: "",
      calculo_resultado: "",
    },
    seccion: {
      tipo_item: "seccion",
      value: "Titulo ",
      descripcion: "",
      index: 0,
      iditem: "",
    },
    texto: {
      tipo_item: "texto",
      value: "Texto ",
      index: 0,
      iditem: "",
    },
    imagen: {
      tipo_item: "imagen",
      value: "",
      index: 0,
      iditem: "",
    },
    video: {
      tipo_item: "video",
      value: "",
      descripcion: "",
      index: 0,
      iditem: "",
    },
    pregunta: {
      tipo_item: "question",
      value: "Pregunta ",
      tipo_pregunta: "v-checkbox",
      nota_pregunta: "",
      requerido: false,
      grupo_id: null,
      opciones: [],
      index: 0,
      iditem: "",
    },
    opcion: {
      value: "Opcion 1",
      nota: 0,
      resp_correcta: false,
      idopcion: "",
    },
    gruposDeExamen: [],
    grupo: {
      nombre: "",
    },
    indexItem: 0,

    snackbar: {
      open: false,
      text: "",
      color: "",
      loading: false,
    },



    listaniveldeRiesgoEvaluaciones:[
      {
        nivel: "Sin riesgo",
        min: 0,
        max: 0
      },
      {
        nivel: "Riesgo bajo",
        min: 10,
        max: 0
      },
      {
        nivel: "Riesgo medio",
        min: 20,
        max: 0
      },
      {
        nivel: "Riesgo alto",
        min: 0,
        max: 0
      },
    ],

    
    listaniveldeRiesgoEvaluacionesDefault:[
      {
        nivel: "Sin riesgo",
        min: 0,
        max: 0
      },
      {
        nivel: "Riesgo bajo",
        min: 0,
        max: 0
      },
      {
        nivel: "Riesgo medio",
        min: 0,
        max: 0
      },
      {
        nivel: "Riesgo alto",
        min: 0,
        max: 0
      },
    ],
    CalcularResultado: [
      {
        text: "Suma por dimensiones y total",
        value: "suma_dimension_total",
      },
      {
        text: "Cálculo nivel de riesgo",
        value: "calcular_nivel_riesgo",
      },
    ],

    RESULTADONIVELRIESGO:"",
   
    loadingGroud: false,
    loadingListaEvaluaciones: false,
    loadingcalculonivelriesgo: false,

    nivel_riesgo: ""

  },

  mutations: {
    setListaDeEvaluaciones(state, payload) {
      state.listaEvaluaciones = payload;
    },
    addItem(state, payload) {
      let contador = 0;
      const { index, tipo } = payload;
      state.indexItem = state.examen.items.length == 0 ? 0 : index + 1;

      let item = Object.assign({}, state[tipo]);

      item.iditem = uuidv4();

      if (tipo == "pregunta") {
        item.opciones = [];
        state.opcion.value = "Opcion 1";
        state.opcion["idopcion"] = uuidv4();
        item.opciones.push(Object.assign({}, state.opcion));
      }

      state.examen.items.splice(state.indexItem, 0, Object.assign({}, item));
      console.log(item);
    },

    deleteItem(state, index) {
      state.examen.items.splice(index, 1);
    },

    addOpcion(state, indexpregunta) {
      let count = state.examen.items[indexpregunta].opciones.length + 1;

      state.opcion.value = `Opcion ${count}`;

      state.opcion.idopcion = uuidv4();

      state.examen.items[indexpregunta].opciones.push(
        Object.assign({}, state.opcion)
      );
    },

    deleteOpcion(state, payload) {
      const { indexpregunta, indexopcion } = payload;
      state.examen.items[indexpregunta].opciones.splice(indexopcion, 1);
    },

    addGrupo(state) {
      if (state.grupo.nombre == "") {
        return;
      }
      state.gruposDeExamen.push(Object.assign({}, state.grupo));
      state.grupo.nombre = "";
    },

    setListGrupos(state, payload) {
      state.gruposDeExamen = payload;
    },

    deleteGrupo(state, index) {
      state.gruposDeExamen.splice(index, 1);
    },

    setExamen(state, payload) {
      state.examen = Object.assign({}, payload);

      if(payload.calculo_nivel_riesgo_evaluacion && payload.calculo_nivel_riesgo_evaluacion.length == 4)
        {
          state.listaniveldeRiesgoEvaluaciones = payload.calculo_nivel_riesgo_evaluacion
        }else{
          state.listaniveldeRiesgoEvaluaciones = state.listaniveldeRiesgoEvaluacionesDefault
        }
    },

    setItemsExamen(state, payload) {
      state.examen.items = [];
      state.examen.items = payload;
    },

    snackbar(state, payload) {
      state.snackbar = Object.assign({}, payload);
    },

    resetExamen(state) {
      state.examen = Object.assign({}, state.examenDefault);
      state.examen.items = [];
      state.gruposDeExamen = [];
    },

    addNotaPorGrupo(state) {
      state.NotasPorgrupo.push({
        grupo_id: "",
        nota_min: 0,
        nota_max: 0,
      });
    },

    deleteNotaPorGrupo(state, index) {
      state.NotasPorgrupo.splice(index, 1);
    },


    calculaNivelRiesgo(state, puntajetotal) {
      state.nivel_riesgo = ""
      if (isNaN(puntajetotal)) {
          this.nivel_riesgo = "no hay puntaje";
          return
      }

      const niveles = state.listaniveldeRiesgoEvaluaciones;

      for (const nivel of niveles) {
          if (puntajetotal >= nivel.min && puntajetotal <= nivel.max) {
            state.nivel_riesgo = nivel.nivel; // Nivel encontrado
              return
          }
      }

      state.nivel_riesgo = null; // No se encontró el nivel de riesgo
      return
  },
  resetlistaniveldeRiesgoEvaluaciones(state)
  {
    state.listaniveldeRiesgoEvaluaciones = []
  }

  },

  actions: {
    /**
     *
     * Obtener todas las evaluaciones creadas
     */

    async getAllEvaluaciones({ state, commit, dispatch }) {
      state.loadingListaEvaluaciones = true;
      try {
        const data = await evaluacionSevice.getAll();
        console.log(data);
        commit("setListaDeEvaluaciones", data);
        state.loadingListaEvaluaciones = false;
      } catch (error) {
        console.log(error);
        state.loadingListaEvaluaciones = false;
      }
    },

    /**
     *
     * GENERAR UN NUEVO FORMULARIO
     */
    async generateEvaluacion({ state, commit, dispatch }) {
      swal({
        title: "Cargando...",
        text: "Por favor, espere un momento.",
        icon: "info",
        button: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        allowOutsideClick: false,
      });
      try {
        const data = await evaluacionSevice.generate(state.examen);
        commit("setExamen", data);
        swal.close();
        router.push({
          name: "generadoDeEvaluaciones",
          params: { key: data.key },
        });
      } catch (error) {
        console.log(error);
        swal({
          title: "Ha ocurrido un error",
          icon: "error",
        });
      }
    },

    /**
     *
     * OBTENER UN FORMULARIO PREVIAMENTE CREADO
     */
    async showEvaluacion({ state, commit, dispatch }, key) {
      swal({
        title: "Cargando...",
        text: "Por favor, espere un momento.",
        icon: "info",
        button: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        allowOutsideClick: false,
      });
      try {
        const data = await evaluacionSevice.show({ key });
        commit("setExamen", data);
        console.log(data)
        swal.close();
      } catch (error) {
        console.log(error);
        router.push({ name: "listaEvaluaciones" });

        swal({
          title: "No se encontró la evaluación ",
          icon: "error",
        });
      }
    },

    /**
     *
     * ACTUALIZAR LA CABECERA
     */
    async updatedHead({ state, commit }) {
      state.snackbar.loading = true;
      try {
        const data = await evaluacionSevice.updateHeader(state.examen);
        //commit("setExamen", data);

        commit("snackbar", {
          open: true,
          text: "Se actualizó con exito",
          color: "green accent-4",
          loading: false,
        });
      } catch (error) {
        console.log(error);

        commit("snackbar", {
          open: true,
          text: "Ocurrio un error",
          color: "red darken-4",
          loading: false,
        });
      }
    },

    /**
     *
     * ACTUALIZAR LOS ITEMS
     */
    async addOrUpdateItems({ state, commit }) {
      state.snackbar.loading = true;
      try {
        const data = await evaluacionSevice.addOrUpdateItems(state.examen);
        //commit('setItemsExamen', data.items)
        commit("snackbar", {
          open: true,
          text: "Se actualizó con exito",
          color: "green accent-4",
          loading: false,
        });
      } catch (error) {
        console.log(error);

        commit("snackbar", {
          open: true,
          text: "Ocurrio un error",
          color: "red darken-4",
          loading: false,
        });
      }
    },

    /**
     *
     * GRUPOS
     */

    async getGrupo({ state, commit, dispatch }, idevaluacion) {
      state.loadingGroud = true;
      try {
        const data = await evaluacionSevice.getGrupos({
          idevaluacion: idevaluacion,
        });
        console.log(data);
        commit("setListGrupos", data);
        state.loadingGroud = false;
      } catch (error) {
        state.loadingGroud = false;
        console.log(error);
      }
    },

    async addGrupo({ state, commit, dispatch }, idevaluacion) {
      state.loadingGroud = true;
      try {
        state.grupo["evaluacion_id"] = idevaluacion;
        const data = await evaluacionSevice.addGrupo(state.grupo);
        dispatch("getGrupo", idevaluacion);
        state.grupo.nombre = "";
        state.loadingGroud = false;
        dispatch("addOrUpdateItems");
      } catch (error) {
        console.log(error);
        state.loadingGroud = false;
      }
    },

    async deleteGrupo({ state, commit, dispatch }, payload) {
      state.loadingGroud = true;
      try {
        const { idgrupo, key } = payload;
        const data = await evaluacionSevice.deleteGrupo(idgrupo);
        dispatch("getGrupo", state.examen.idevaluacion);
        dispatch("showEvaluacion", key);
        state.loadingGroud = false;
      } catch (error) {
        state.loadingGroud = false;
        console.log(error);
      }
    },



    async guardarCalculoNivelRiesgo({state, commit, dispatch}) 
    {
      state.loadingcalculonivelriesgo = true;
      try
      {
        const data = await evaluacionSevice.calculoNivelRiesgo({resultados: state.listaniveldeRiesgoEvaluaciones, evaluacion_id: state.examen.idevaluacion})
        state.loadingcalculonivelriesgo = false
        commit("snackbar", {
          open: true,
          text: "Se actualizó con exito",
          color: "green accent-4",
          loading: false,
        });
      }
      catch(error)
      {
        console.log(error)
        state.loadingcalculonivelriesgo = false
        commit("snackbar", {
          open: true,
          text: "Ocurrio un error",
          color: "red darken-4",
          loading: false,
        });

      }
    },

    //obtiene todas las clasificaciones para calcular el resultado de una evaluacion


    async getClasificacionResultados({state, commit}, idevaluacion)
    {
      state.listaniveldeRiesgoEvaluaciones = []
      try
      {
        const data = await evaluacionSevice.getClasificacionResultados({evaluacion_id: idevaluacion})
        
        state.listaniveldeRiesgoEvaluaciones =data

      }
      catch(error)
      {
        console.log(error)
        state.listaniveldeRiesgoEvaluaciones = []
      }
    },



    async ObtenerNivelDeRiesgoDeNotaFinal({state, dispatch, commit}, payload)
    {
      const {idevaluacion, notaTotal} = payload
     
      try{
        state.nivel_riesgo = ""
       await dispatch('getClasificacionResultados', idevaluacion)
       await commit('calculaNivelRiesgo', notaTotal)

      }
      catch(error)
      {
        console.log(error)
      }
    },


    //ELIMINAR EVALUACION

    async DeleteEvaluacion({state, dispatch}, key) {
      try {
        const inputValue = await swal({
          title: "¿Estás seguro? RECUERDA que se eliminarán todos los resultados de esta evaluación.",
          text: "Escribe 'ELIMINAR' para confirmar",
          content: {
            element: "input",
            attributes: {
              placeholder: "Escribe 'ELIMINAR'",
              type: "text",
            },
          },
          buttons: true,
          dangerMode: true,
        });
    
        if (inputValue && inputValue.toUpperCase() === "ELIMINAR") {
          // Acción para eliminar el elemento
          const data = await evaluacionSevice.delete(key);
          swal({
            title: "Cargando...",
            text: "Por favor, espere un momento.",
            icon: "info",
            button: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            allowOutsideClick: false,
          });
          swal("Eliminado", "El elemento ha sido eliminado con éxito.", "success");
          router.push({name:'evaluaciones'})
        } else if (inputValue !== null) {
          swal("Error", "Debes escribir 'ELIMINAR' para confirmar", "error");
        }
      } catch (error) {
        console.error("Error al eliminar la evaluación:", error);
        swal("Error", "Ha ocurrido un error al eliminar la evaluación.", "error");
      }
    }
    
    
}



  
};
