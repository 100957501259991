import axios from "axios";
import utiles from "@/modules/Shared/utiles";
import swal from "sweetalert";
import store from "@/store";
import reportesHospedados from "./reportesHospedados";
import reporteCama from "./reporteCama";
import dashboard from "./dashboard";
import desocuparMasivos from "./desocuparMasivos";
import hospedarMasivos from "./hospedarMasivos";
import consolidadoGeneral from "./consolidadoGeneral";


export default {
  namespaced: true,
  modules: {
    utiles,
    reportesHospedados,
    reporteCama,
    dashboard,
    desocuparMasivos,
    hospedarMasivos,
    consolidadoGeneral
  },
  state: {
    pabellon: {},
    hospedar: {
      cama_id: -1,
      user_id: -1,
      dni: "",
      tipo: "estudiante",
      nombrecompleto: "",
      edad: 0,
      carrera: "",
      admision: "",
      jefatura: "",
      cargo: "",
      fecha_ingreso: "",
      fecha_salida: "",
      dias_semana: 5,
      almohada: false,
      frazada: false,
      sabana: false,
      funda_almohada: false,
      locker: false,
    },

    hospedarDefault: {
      cama_id: -1,
      user_id: -1,
      tipo: "estudiante",
      nombrecompleto: "",
      edad: 0,
      carrera: "",
      admision: "",
      jefatura: "",
      cargo: "",
      fecha_ingreso: "",
      fecha_salida: "",
      dias_semana: 5,
      almohada: false,
      frazada: false,
      sabana: false,
      funda_almohada: false,
      locker: false,
    },

    camaSeleccionada: {
      cama: {},
      tipo: "",
    },

    dialogCama: false,

    loading: false,
    alert: {
      message: "Ingresa el numero de DNI y busca",
      state: "info",
    },

    alertDefault: {
      message: "Ingresa el numero de DNI y busca",
      state: "info",
    },

    loadingLista: false,
    loadingoverlay: false,
    loadingFilter: false,
    listaHuespedes: [],

    isEditable: false,

    componentDialog: "FormAsingacion",

    listacamas: [],
    loadingListaCamas: false,

    cambioDeCama: {
      cama_id: 0,
      idhuesped: 0,
    },

    cambioDeCamaDefault: {
      cama_id: 0,
      idhuesped: 0,
    },

    filter: {
      nombreDni: "",
    },
  },

  mutations: {
    setPabellon(state, payload) {
      state.pabellon = Object.assign({}, payload);
    },

    resetPabellon(state, payload) {
      state.pabellon = Object.assign({}, {});
    },
    openDialogCama(state, val) {
      state.dialogCama = val;
    },
    selectedCama(state, payload) {
      const { cama, tipo, $ability } = payload;

      //Reset
      state.alert = Object.assign({}, state.alertDefault);
      state.hospedar = Object.assign({}, state.hospedarDefault);

      store.commit("hospedaje/reporteCama/resetReporte");

      state.dialogCama = true;
      state.hospedar.cama_id = cama.idcama;
      state.camaSeleccionada.cama = Object.assign({}, cama);
      state.camaSeleccionada.tipo = tipo;
      state.isEditable = false;
      if (cama.state != "inoperativa") {
        let permiso = $ability.can("create", "hospedaje")
          ? "FormAsingacion"
          : "CamaVaciaNoRol";
        state.componentDialog = cama.huesped ? "ViewDataHospedado" : permiso;
      } else {
        state.componentDialog = "ViewReporte";
      }
    },

    editHospedado(state, hospedado) {
      state.dialogCama = true;
      state.componentDialog = "FormAsingacion";
      state.isEditable = true;
      //reset
      state.alert = Object.assign({}, state.alertDefault);
      //asignar
      state.hospedar = Object.assign({}, hospedado);
      state.camaSeleccionada.cama = Object.assign({}, hospedado.cama);
      state.camaSeleccionada.tipo = hospedado.tipo_cama;
    },

    cambiarCamaHospedado(state, { idpabellon, hospedado }) {
      state.dialogCama = true;
      state.componentDialog = "CambiarCamaAsignado";

      state.hospedar = Object.assign({}, hospedado);
      state.camaSeleccionada.cama = Object.assign({}, hospedado.cama);
      state.camaSeleccionada.tipo = hospedado.tipo_cama;
      state.listacamas = [];
      store.dispatch("hospedaje/getListCamasAxios", {
        idpabellon: idpabellon,
        estadocama: "disponible",
      });
    },

    reportarProblemaCama(state, payload) {
      state.dialogCama = true;
      console.log(payload);
      const { $ability } = payload;
      if ($ability.can("create", "reportes")) {
        state.componentDialog = "FormReporteCama";
      } else {
        swal({
          title: "No tienes permiso para reportar una cama",
          icon: "warning",
        });
      }
    },

    verReporteCama(state) {
      state.componentDialog = "ViewReporte";
    },

    /**
     *
     * RESETS STATES
     */

    resetselectedCama(state) {
      state.dialogCama = false;
      state.camaSeleccionada.cama = Object.assign({}, payload.cama);
      state.camaSeleccionada.tipo = payload.tipo;
    },

    resetDataHospedar(state) {
      state.hospedarDefault.dni = state.hospedar.dni;
      state.hospedar = Object.assign({}, state.hospedarDefault);
      state.hospedar.cama_id = state.camaSeleccionada.cama.idcama;
    },

    resetHospedados(state) {
      state.listaHuespedes = [];
    },
  },

  actions: {
    getAxiosPabellon({ commit, state }, idpabellon) {
      commit("utiles/resetConfigAxios");
      commit("resetPabellon");
      utiles.state.configAxios.url =
        utiles.state.apiUrl + "pabellones/" + idpabellon;
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      axios(utiles.state.configAxios)
        .then((resp) => {
          console.log(resp);
          if (resp.status == 200) {
            commit("setPabellon", resp.data.pabellon);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    /**
     * OBTENER UNA LISTA DE TODOS LOS HOSPEDADOS EN EL PABELLÓN
     */

    getHosepdadosAxios({ commit, state }, { idpabellon, nombreDni = "" }) {
      state.loadingFilter = true;
      commit("utiles/resetConfigAxios");
      commit("resetHospedados");
      console.log(idpabellon, nombreDni);
      state.loadingLista = true;
      utiles.state.configAxios.url = utiles.state.apiUrl + "huesped";
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.params = {
        idpabellon: idpabellon,
        nombreDni: nombreDni,
      };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            state.listaHuespedes = res.data.huespedes;
            state.loadingLista = false;
            state.loadingFilter = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingLista = false;
          state.loadingFilter = false;
        });
    },

    /***
     * Busqueda API RENIEC
     */

    getApiReniecAxios({ commit, state }) {
      state.loading = true;
      commit("utiles/resetConfigAxios");
      let token = "d674134c-526d-4c04-bdc7-47eb94ab5358"
      utiles.state.configAxios.url = utiles.state.apiUrl+ 'dniconsulta';
      utiles.state.configAxios.data = {dni: state.hospedar.dni}
      utiles.state.configAxios.method = "POST";

     axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            console.log(resp)
            state.alert.state = "success";
            state.alert.message =
              "Validación Reniec! - completa los datos que faltan";
            state.hospedar.nombrecompleto = resp.data.data.data.ap_paterno + " " + resp.data.data.data.ap_materno + " " + resp.data.data.data.nombres;
            state.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.alert.state = "error";
          state.alert.message =
            "No se encontró DNI - Ingresa verifica el número de DNI";
          state.loading = false;
          commit("resetDataHospedar");
        });
    },

    /***
     * OBTENER DATA DE LOS ESTUDIANTES DESDE API DEL SIGE
     */

    getDataEstudiantesApi({ commit, dispatch, state }) {
      state.loading = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        "https://sige-stage-mw.cetemin.edu.pe/fix/student/search/" +
        state.hospedar.dni;
      utiles.state.configAxios.headers.Authorization =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXRlbWluIiwiYXVkIjoid2ViIiwiaWF0IjoxNjYyMDQ0MTM1LCJleHAiOjE2NjMzNDAxMzUsImFrIjoiNWNlNTc2ZDliN2MzZjMxNjYifQ.468NjhnVKfbd_tlgfdiJbLrwXC6jcZo42QP-tAJHgBE";

      axios(utiles.state.configAxios)
        .then((res) => {
          if (res.status == 200) {
            state.alert.state = "success";
            state.alert.message =
              "Estudiante encontrado! - completa los datos que faltan";
            state.hospedar.nombrecompleto = res.data.result.nomalu;
            state.hospedar.carrera = res.data.result.specialtyName;
            var yeradmin = res.data.result.period.substr(0, 4);
            var periodAdmin = res.data.result.period.substr(
              4,
              res.data.result.period.length - 1
            );

            state.hospedar.admision = yeradmin + "-" + periodAdmin;
            state.loading = false;
          }
        })
        .catch((error) => {
          //dispatch('getApiReniecAxios')
          state.loading = false;
          state.alert.state = "error";
          state.alert.message = "No se encontro al estudiante!";
          commit("resetDataHospedar");
        });
    },

    /***
     * OBTENER DATA DE LOS COLABORADORES DESDE API DEL ctm-colaboradores.cetemin.edu.pe/api/v1/
     */


    getDataColaboradorApi({commit, dispatch, state}){
      state.loading = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = "https://ctm-colaboradores.cetemin.edu.pe/api/v1/colaborador/" + state.hospedar.dni;
      utiles.state.configAxios.method = "GET"
      utiles.state.configAxios.headers.Authorization = "Bearer " + "3|G7fWXbLjroLuJdQCaa7IP1DJewgnCHNLSb4tIs9a"

      axios(utiles.state.configAxios)
      .then(resp => {
        console.log(resp)
        if(resp.status === 200){
          state.loading = false;
        
          state.hospedar.nombrecompleto = resp.data.nombrecompleto
          
          if(resp.data.area == "ADM. Y FINANZAS"){
            state.hospedar.jefatura = "ADMINISTRACIÓN Y FINANZAS" 
          }else{
            state.hospedar.jefatura = resp.data.area
          }

          state.hospedar.cargo = resp.data.cargo

          state.alert.state = "success";
          state.alert.message =
              "Colaborador encontrado!";
        }
      })
      .catch(error => {
        console.log(error)
        state.loading = false;
        state.hospedar.nombrecompleto = ""
        state.hospedar.jefatura = ""
        state.alert.state = "error";
        state.alert.message = "No se encontro al colaborador!";
      })
    },



    /**
     * HOSPEDAR A ESTUDIANTE O COLABORADOR
     */

    setHospedarAxios({ commit, dispatch, state }, idpabellon) {
      state.loadingoverlay = true;
      commit("utiles/resetConfigAxios");

      let path = "";
      if (state.isEditable) {
        path = "/" + state.hospedar.idhuesped;
      }

      //Add user_id
      state.hospedar.user_id = store.state.user.iduser;

      utiles.state.configAxios.url = utiles.state.apiUrl + "huesped" + path;

      utiles.state.configAxios.method = state.isEditable ? "PUT" : "POST";
      utiles.state.configAxios.data = state.hospedar;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((res) => {
          if (res.status == 200) {
            dispatch("getAxiosPabellon", idpabellon);
            dispatch("getHosepdadosAxios", { idpabellon: idpabellon });

            swal({
              title: state.isEditable
                ? "Se actualizó la información del huesped"
                : "Se agregó un nuevo huesped",
              icon: "success",
            });

            state.loadingoverlay = false;
            state.dialogCama = false;
          }
        })

        .catch((error) => {
          console.log(error);
          if (error.response.status == 500) {
            swal({
              title: state.isEditable
                ? "Ha ocurrido un error!"
                : "Ya existe un huesped hospedado con ese DNI",
              icon: "error",
            });
            state.dialogCama = false;
            state.loadingoverlay = false;
            return;
          }

          swal({
            title: "Ha ocurrido un error!",
            icon: "error",
          });

          state.dialogCama = false;
          state.loadingoverlay = false;
        });
    },

    /***
     * DESALOJO DE HUESPED
     */

    setDesalojoHuespedAxios(
      { commit, dispatch, state },
      { idpabellon, idhuesped }
    ) {
      commit("utiles/resetConfigAxios");
      console.log(idpabellon, idhuesped);
      swal({
        title: "¿DESEA DESOCUPAR CAMA?",
        text: "confirme la acción",
        buttons: ["cancelar", "CONFIRMAR"],
        dangerMode: true,
        icon: "warning",
      }).then((confirmacion) => {
        if (!confirmacion) {
          return;
        }

        state.dialogCama = false;
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });

        utiles.state.configAxios.url = utiles.state.apiUrl + "desalojohuesped";
        utiles.state.configAxios.method = "POST";
        utiles.state.configAxios.data = { idhuesped: idhuesped };
        utiles.state.configAxios.headers.Authorization =
          "Bearer " + store.state.user.token;
        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              console.log(resp);
              swal({
                title: "Se desocupó la cama",
                icon: "success",
              });
              dispatch("getAxiosPabellon", idpabellon);
              dispatch("getHosepdadosAxios", { idpabellon: idpabellon });
            }
          })
          .catch((error) => {
            console.log(error);
            swal({
              title: "Huebo un error vuelva a intentar",
              icon: "error",
            });
          });
      });
    },

    /**
     * OBTENER LISTA DE CAMAS
     */

    getListCamasAxios({ commit, state }, { idpabellon, estadocama }) {
      state.loadingListaCamas = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "camas";
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.params = {
        idpabellon: idpabellon,
        state: estadocama,
      };
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            state.loadingListaCamas = false;
            state.listacamas = resp.data.camas;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingListaCamas = false;
        });
    },

    /**
     *
     * REALIZAR EL CAMBIO DE CAMA
     */

    setCambioCamaAxios({ commit, dispatch, state }, idpabellon) {
      state.cambioDeCama.idhuesped = state.hospedar.idhuesped;

      swal({
        title: "¿Desea realizar el cambio de cama?",
        icon: "warning",
        buttons: ["CANCELAR", "CONFIRMAR"],
        dangerMode: true,
      }).then((confirmar) => {
        {
          if (!confirmar) {
            return;
          }
          state.dialogCama = false;
          swal({
            title: "Cargando...",
            text: "Por favor, espera mientras se realiza la petición.",
            icon: "info",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            dangerMode: true,
          });
          commit("utiles/resetConfigAxios");
          utiles.state.configAxios.url = utiles.state.apiUrl + "cambiocama";
          utiles.state.configAxios.method = "PUT";
          utiles.state.configAxios.data = state.cambioDeCama;
          utiles.state.configAxios.headers.Authorization =
            "Bearer " + store.state.user.token;
          axios(utiles.state.configAxios)
            .then((resp) => {
              if (resp.status == 200) {
                console.log(resp);
                swal({
                  title: "Se realizó el cambio",
                  icon: "success",
                });
                dispatch("getAxiosPabellon", idpabellon);
                dispatch("getHosepdadosAxios", { idpabellon: idpabellon });
              }
            })
            .catch((error) => {
              console.log(error);
              state.dialogCama = false;
              swal({
                title: "Ocurrió un error",
                icon: "error",
              });
            });
        }
      });
    },
  },
};
