<template>
    <component :is="masivoComponent" />
</template>

<script>
import { mapMutations, mapState } from "vuex";
import SubirMasivoHospedaje from "../components/HospedajeDesocuparMasivos/hospedaje/SubirMasivoHospedaje.vue";
import LoadingDataHospedaje from "../components/HospedajeDesocuparMasivos/hospedaje/LoadingDataHospedaje.vue";
import TablaResultadosHospedaje from "../components/HospedajeDesocuparMasivos/hospedaje/TablaResultadosHospedaje.vue";
import InformeFinalHospedaje from '../components/HospedajeDesocuparMasivos/hospedaje/InformeFinalHospedaje.vue';
export default {
  components: {
    SubirMasivoHospedaje,
    LoadingDataHospedaje,
    TablaResultadosHospedaje,
    InformeFinalHospedaje
  },
  data() {
    return {};
  },

  computed: {
    ...mapState("hospedaje/hospedarMasivos", ["masivoComponent"]), 
  },
  methods:{
    ...mapMutations("hospedaje/hospedarMasivos",["resetAllMasivo"])
  },

  mounted(){
    this.resetAllMasivo();
  }
};
</script>
