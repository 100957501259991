import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class MetricaMedicamentosService{
    constructor(){
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );
    }

    async getMayorDemanda(params)
    {
       const response = await this.axios.get('mayordemanda', {params: params})
       return response.data;
    }


    async getStockminimo(params)
    {
       const response = await this.axios.get('stockminimo', {params: params})
       return response.data;
    }

    async getMedicamentosPorTipoPaciente(params)
    {
       const response = await this.axios.get('medicamentoporpaciente', {params: params})
       return response.data;
    }

    async getAtencionesMensuales(params)
    {
       const response = await this.axios.get('atencionesmensuales', {params: params})
       return response.data;
    }
}