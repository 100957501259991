import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { Ability } from "@casl/ability";
import abilityPlugin from '@/plugins/defineAbility'
import silderDerechaComponent from '@/modules/Shared/utiles/silderDerechaComponent';

Vue.use(Vuex)


const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ user: state.user }),
}); 
export default new Vuex.Store({
  plugins: [ 
    vuexLocal.plugin,
    abilityPlugin
  ], 
  state: {
    user: {
      nombre: "",
      email: "",
      token: ""
    },
    userDefault: {
      nombre: "",
      email: "",
      token: ""
    },

    nav:{
      drawer: true
    },

    overlayGeneral: false,
  
  
  },
  
  getters: {
    getterLogginIn(state) { 
      return state.user != null && state.user.token;
    },
    ability() {
      return new Ability([], {
        subjectName(subject) {
          return !subject || typeof subject === "string"
            ? subject
            : subject[TYPE_KEY];
        },
      });
    },
  },
  mutations: {


    
  },
  actions: {
  },
  modules: {
    silderDerechaComponent
  }
})
