import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/suspended-service',
    name: "suspended",
    component: () => import('@/modules/Shared/views/suspended-service.vue'),
    meta: {
      title: "Servicio suspendido temporalmente"
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
  var IsLoggeIn = store.getters.getterLogginIn;
  document.title = "Operatic | " + to.meta.title;
  if (requiresAuth && !IsLoggeIn) {
    next("/login");
  } else if (requiresGuest && IsLoggeIn) {
    next("/");
  } else {
    next();
  }
});

export default router
