import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class EvaluacionesService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }

    async getAll(params)
    {
       const response = await this.axios.get('/evaluaciones-creadas', {params: params})
       return response.data;
    }  

    async generate(data)
    {
        const response = await this.axios.post('/evaluacion-generate', data)
        return response.data
    }

    async show(params)
    {
        const response = await this.axios.get('/evaluacion-show', {params: params})
        return response.data
    }

    async delete(key)
    {
        const response = await this.axios.delete('/eliminar-evaluacion?key=' + key)
        return response.data
    }

    /**
     * 
     * ACTUALIZAR CADA PARTE DE LA EVALUACION
     * 
     */




    //CABECERA 
    async updateHeader(data)
    {
        const response = await this.axios.put('/evaluacion-update-head', data)
        return response.data
    }

    //ITEMS

    async addOrUpdateItems(data)
    {
        const response = await this.axios.post('/evaluacion-addupdate-items', data)
        return response.data
    }

    //OPCIONES
    async addOrUpdateOpciones(data)
    {
        const response = await this.axios.post('/evaluacion-addupdate-opciones', data)
        return response.data
    }

     //GET GRUPOS
     async getGrupos(params)
     {
         const response = await this.axios.get('/evaluacion-get-grupo', {params: params})
         return response.data
     }

    //ADD GRUPO
    async addGrupo(data)
    {
        const response = await this.axios.post('/evaluacion-add-grupo', data)
        return response.data
    }


    //DELETE GRUPO
    async deleteGrupo(idgrupo)
    {
        const response = await this.axios.delete('/evaluacion-delete-grupo?idgrupo=' + idgrupo)
        return response.data
    }

    //CALCULO DE NIVEL DE RIESGO

    async calculoNivelRiesgo(data)
    {
        const response = await this.axios.post('/calculo-nivel-riesgo', data)
        return response.data
    }


    async getClasificacionResultados(params)
    {
        const response = await this.axios.get('/lista-calculo-nivel-riesgo', {params: params})
        return response.data
    }

    

}