import swal from "sweetalert";
import HorarioComedorService from "../services/HorarioComedorService";

const HorarioComedorSV = new HorarioComedorService();
export default {
  namespaced: true,
  state: {
    listaHorarios: [],
    filterHorario: {
      sede: "",
    },
    horarioComedor: {
      sede: "",
      horarios: [
        {
          tipo_comida: "DESAYUNO",
          dias_semana: "",
          inicio: "",
          fin: "",
        },
        {
          tipo_comida: "ALMUERZO",
          dias_semana: "",
          inicio: "",
          fin: "",
        },
        {
          tipo_comida: "CENA",
          dias_semana: "",
          inicio: "",
          fin: "",
        },
      ],
    },
    horarioComedorDefault: {
      sede: "",
      horarios: [
        {
          tipo_comida: "DESAYUNO",
          dias_semana: "",
          inicio: "",
          fin: "",
        },
        {
          tipo_comida: "ALMUERZO",
          dias_semana: "",
          inicio: "",
          fin: "",
        },
        {
          tipo_comida: "CENA",
          dias_semana: "",
          inicio: "",
          fin: "",
        },
      ],
    },
    formRegister: {
      open: false,
    },

    

    

    
    loadingList: false,
    loadingAdd: false,
  },
  mutations: {
    setListHorarios(state, payload) {
      state.listaHorarios = payload;
    },

    setHorario(state, payload) {
      const { sede, horarios } = payload;
      state.formRegister.open = true;
      state.horarioComedor.sede = sede;
      state.horarioComedor.horarios = horarios;
    },

    resetHorario(state) {
      state.horarioComedor = null;
      state.horarioComedor = Object.assign({}, state.horarioComedorDefault);
    },

    

   
  },

  actions: {
    async get({ state, commit }) {
      state.loadingList = true;
      try {
        const data = await HorarioComedorSV.getList(state.filterHorario);
        commit("setListHorarios", data);
        console.log(data);
        state.loadingList = false;
      } catch (error) {
        console.log(error);
        state.loadingList = false;
      }
    },

    async add({ state, commit, dispatch }) {
      state.loadingAdd = true;
      try {
        const data = await HorarioComedorSV.add(state.horarioComedor);
        dispatch("get");
        commit("resetHorario");
        console.log(data);
        state.loadingAdd = false;
        state.formRegister.open = false;
      } catch (error) {
        console.log(error);
        state.loadingAdd = false;

        if (error.response.status === 502) {
          swal({
            title: "Ya existe un horario para esta sede",
            icon: "error",
          });
        }
      }
    },
    async update({ state, commit, dispatch }) {
      state.loadingAdd = true;
      try {
        const data = await HorarioComedorSV.update(state.horarioComedor);
        dispatch("get");
        state.loadingAdd = false;
        state.formRegister.open = false;
        commit("resetHorario");
      } catch (error) {
        console.log(error);
        state.loadingAdd = false;
      }
    },

    
  },
};
