import swal from "sweetalert";
import MenuSemanalService from "../services/menuSemanalService";

const menuSemanalSv = new MenuSemanalService();

export default {
  namespaced: true,
  state: {
    menuSemanal: {
      sede: "",
      file: null,
    },
    listaDocumentos: [],
    filter: {
      sede: "",
    },

    loadingDocumentos: false,
    loadingCargarDocumento: false,
  },

  mutations: {
    setFile(state, event) {
      if (
        event != null &&
        !(
          event.type == "image/jpeg" ||
          event.type == "image/png" ||
          event.type == "image/jpg" ||
          event.type == "application/pdf"
        )
      ) {
        swal({
          title: "ARCHIVO NO PERMITIDO",
          text: "Porfavor solo agregre archivos: png, jpg, pdf",
          icon: "error",
        });

        state.menuSemanal.file = null;
        return;
      }
      if (event != null) {
        state.menuSemanal.file = event;
      }
    },

    resetFile(state) {
      state.menuSemanal.sede = "";
      state.menuSemanal.file = null;
    },

    
  },

  actions: {
    async getList({ state, commit }) {
      state.loadingDocumentos = true;
      try {
        const data = await menuSemanalSv.getList(state.filter);
        state.listaDocumentos = data;
        state.loadingDocumentos = false;
      } catch (error) {
        state.loadingDocumentos = false;
        console.log(error);
      }
    },

    async cargarDocumento({ state, dispatch, commit }) {
      state.loadingCargarDocumento = true;
      try {
        const data = await menuSemanalSv.cargarDocumento(state.menuSemanal);
        state.loadingCargarDocumento = false;
        dispatch("getList");
        commit("resetFile");
        swal({
          title: "Se cargó el documento",
          icon: "success",
        });
      } catch (error) {
        state.loadingCargarDocumento = false;
        console.log(error);
        if (error.response.status === 505) {
          swal({
            title: error.response.data.message,
            icon: "error",
          });
          return
        }
        swal({
          title: "Ha ocurrido un error",
          icon: "error",
        });
      }
    },

    async eliminarDocumento({ state, dispatch, commit }, idmenusemanal ) {

        const confirm = await swal({
            title : "¿Desea eliminar este documento?",
            icon: "warning",
            dangerMode: true,
            buttons:['CANCELAR', 'CONFIRMAR']
        })
        if(!confirm){ return }

        try {
          const data = await menuSemanalSv.eliminarDocumento({idmenusemanal: idmenusemanal});
          dispatch("getList");
          swal({
            title: "Se eliminó el documento",
            icon: "success",
          });
        } catch (error) {
          state.loadingCargarDocumento = false;
          console.log(error);
          swal({
            title: "Ha ocurrido un error",
            icon: "error",
          });
        }
      },


  },
};
