<template>
  <v-card elevation="0">
     <v-form>
    <v-card-title class="text-subtitle-1 font-weight-bold"
      >Crear Nuevo Rol</v-card-title
    >
    <v-card-text class="black--text font-weight-bold">
     
      <label for="">Nombre Rol:</label>
      <v-text-field
        outlined
        hide-details="auto"
        dense
        placeholder="Administrador / Monitor"
        v-model="rol.name"
        :rules="[rules.required]"
      ></v-text-field>
        <v-select
          outlined
          hide-details="auto"
          dense
          label="Seleccionar sedes:"
          multiple
          class="white my-2"
          small-chips
          :items="sedes"
          v-model="rol.sedes"
          :rules="[rules.required]"
        ></v-select>
      <h3>Permisos a secciones</h3>
      <span class="text-subtitle-2">Seleccione que seccion puede tener acceso</span>

      <div class="rounded-lg blue lighten-5 pa-4 ">
        <v-select
          outlined
          hide-details="auto"
          dense
          label="Secciones:"
          :items="secciones"
          @change="getPermisosDeSeccion()"
          class="white my-2"
          v-model="permiso.suject"
        ></v-select>

        <v-select
          outlined
          hide-details="auto"
          dense
          label="Permisos:"
          class="white"
          :items="permisos"
          v-model="permiso.actions"
          multiple
        ></v-select>

        <v-btn :disabled="permiso.actions.length < 1" @click="addPermiso()" block class="bgCTM white--text mt-2">AGREGAR PERMISO</v-btn>

      </div>

      <v-card class="rounded-md my-3 blue lighten-5" elevation="0" v-for="(permiso, index) in rol.permisos" :key="index">
          <v-card-text class="black--text pb-0">
              <h4 class="font-weight-bold">{{ permiso.name }}</h4>
              <div class="d-flex">
                <v-chip class="bgCTM white--text mx-1" small v-for="(action, index) in permiso.actions" :key="index">{{action }}</v-chip>
              </div>
          </v-card-text>
          <v-card-actions class="mt-0 justify-end d-flex">
              <v-btn @click="deletePermisoList(permiso)" icon class="red darken-4 white--text" x-small elevation="0"><v-icon x-small>mdi-delete</v-icon></v-btn>
          </v-card-actions>
      </v-card>
    
    </v-card-text>
    <v-card-actions class="justify-end px-4">
        <v-btn class="white" elevation="0" small>CANCELAR</v-btn>
        <v-btn @click="setRolAxios()" :disabled="rol.nombreRol == '' || rol.sedes.length < 1 || rol.permisos.length < 1" class="bgCTM white--text" elevation="0" small>GUARDAR ROL</v-btn>
    </v-card-actions>
    </v-form>
  </v-card>
</template>


<script>
import Filters from '@/modules/Shared/Filters'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  mixins:[Filters],
  data(){
    return{
    }
  },
    computed:{

        ...mapState('usuarios/utiles', ['sedes']),
        ...mapState('usuarios/roles', ['secciones', 'permisos', 'rol', 'permiso'])
    },

    methods:{
      ...mapMutations('usuarios/roles',['getPermisosDeSeccion', 'addPermiso', 'deletePermisoList']),
      ...mapActions('usuarios/roles', ['setRolAxios'])
    },

}
</script>