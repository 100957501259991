<template>
    <v-card elevation="2">
        <v-card-title class="bgCTM white--text pa-2 text-subtitle-1 ">
            <span class="font-weight-bold d-block">ÁREAS</span>
            <small class="white--text d-block ml-2">Para colaboradores</small>
        </v-card-title>
        <v-card-text class="black--text py-4">
            <v-form>
                <div>
                    <label for="" class="font-weight-bold">Nombre Área</label>
                    <v-form v-model="formvalidate">
                        <div class="d-flex">
                        <v-text-field :rules="[rules.required]" :loading="loadingAreasColaboradorText" v-model="addAreasColaborador.nombrearea" dense hide-details="auto" outlined class="grey lighten-4 font-weight-bold"></v-text-field>
                        <v-btn :disabled="!formvalidate" @click="setApiAreasColaborador()" :loading="loadingAreasColaboradorText" style="min-width: 30px !important; min-height: 40px !important;" elevation="0" class="px-0 primary ml-1"><v-icon>mdi-plus</v-icon></v-btn>
                        </div>
                    </v-form>
                </div>
            </v-form>

            <v-data-table mobile-breakpoint="0" :items-per-page="2000" :loading="loadingAreasColaboradorTable" height="400" :items="areascolaborador" fixed-header :headers="headers" hide-default-footer>
                <template v-slot:item.index="{index}">{{ index + 1 }}</template>
                <template v-slot:item.actions="{ item }"> 
                    <v-btn @click="delApiAreasColaborador(item.idareacolaborador)" x-small icon ><v-icon small color="red">mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>


<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapState } from 'vuex'
export default {
    mixins:[
        Filters
    ],
    data(){
        return {
            formvalidate: true,
            headers:[
                {
                    text: "",
                    value: "index",
                    sortable: false,
                    width: 30
                },
                {
                    text: "Nombre área",
                    value: "nombrearea",
                    sortable: false,
                    width: 250
                },
                {
                    text: "",
                    value: "actions",
                    sortable: false,
                    width: 40
                }
            ]
        }
    },

    computed:{

        ...mapState('visitas/configuracion', ['loadingAreasColaboradorText', 'loadingAreasColaboradorTable', 'areascolaborador', 'addAreasColaborador'])
    },

    methods:{
        ...mapActions('visitas/configuracion', ['setApiAreasColaborador', 'delApiAreasColaborador', 'getApiAreasColaborador'])
    },

    mounted(){
        this.getApiAreasColaborador();
    }
}
</script>