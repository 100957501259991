<template>
    <div>
    <v-row style="height: 100vh; " class="bg-residencias">
        <v-col cols="9" class="bg-residencias d-none d-lg-block">

        </v-col>
        <v-col cols="11" lg="3" class="d-flex justify-center align-center mx-auto mx-lg-none"
        :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl? 'white' : ''">
                <!--<FormUpdatePass />-->
                
                <component :is="componentRestorepass" />
        </v-col>
    </v-row>
</div>
</template>
<script>
import FormUpdatePass from '../components/FormUpdatePass.vue'
import ErrorValidateToken from '../components/ErrorValidateToken.vue'
import { mapActions, mapState } from 'vuex'
import swal from 'sweetalert'
export default {
    components:{
        FormUpdatePass,
        ErrorValidateToken
    },

    computed:{

        ...mapState('login/restorepass',['componentRestorepass'])
    },

    methods:{
        ...mapActions('login/restorepass',['validateToken', ])
    },

    mounted(){
        if(this.$route.params.token && this.$route.params.email){
            this.validateToken({token: this.$route.params.token, email: this.$route.params.email});
        }

    }
}
</script>


<style scoped>
.bg-residencias{
    background-image: url('~@/assets/residencias.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>