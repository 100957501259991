<template>
    <v-row>
        <v-col cols="12" md="4" class="d-flex align-center justify-center mx-auto" :style="$vuetify.breakpoint.lg ? 'height: 92vh;' : 'height: 89vh;'">
            <v-card elevation="0" width="500px">
                <v-card-text class="text-center mt-4">
                    <v-avatar size="190" class="mx-auto">
                        <v-img :src="require('@/modules/Psicologia/assets/usuario.png')"  class="mx-auto"></v-img>
                    </v-avatar>
                </v-card-text>
                <v-card-text>
                    <div class="font-weight-bold text-h6 black--text py-0 text-center">
                        Bienvenido a la evaluación
                    </div>
                </v-card-text>
                <v-card-text class="black--text pt-0">
                    <div>
                        <div>
                            <label class="text-subtitle-1">Ingresa tu DNI</label>
                            <v-text-field v-model="evaluacionlogin.dni" :rules="[rules.required]" outlined dense hide-details="auto"></v-text-field>
                        </div>
                        <div>
                            <v-btn @click="verificarIntentoExamen($route.params.key)" class="blue accent-4 white--text my-4" block elevation="0">INGRESAR</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>


<script>
import {mapActions, mapState} from 'vuex'
import Filters from '@/modules/Shared/Filters';
export default{
    mixins: [Filters],
    data()
    {
        return{
            validateform: true
        }
    },
    methods:{
        ...mapActions('psicologia/RespuestasEvaluaciones', ['verificarIntentoExamen'])
    },
    computed:
    {
        ...mapState('psicologia/RespuestasEvaluaciones',['evaluacionlogin'])
    }
}
</script>