import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";


export default class AtencionExternaService{
    constructor(){
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer ' + store.state.user.token
            },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );
    }



    async setAtencionExterna(data) {

        const response = await this.axios.post('atencionexterna', data)
        return response.data
    }

    async updateAtencionExterna(data) {

        const response = await this.axios.put('atencionexterna', data)
        return response.data
    }

     
}