import Module from './Module.vue';
import PsicologiaView from './views/psicologiaView.vue';

const moduleRoute = {
    path: "/psicologia",
    component: Module,
    children: [
      {
        path: "/",
        redirect: "atenciones/registro-diario",
        component: PsicologiaView,
        name: 'atencionesPsicologicas',
        meta: {
          requiresAuth: true,
          title: "Atenciones Psicología",
          seccion: "atenciones"
        },
      },
      {
        path: "atenciones/registro-diario",
        component: PsicologiaView,
        children:[
          { 
            path: "",
            components: {
              psicologiaView: () => import('@/modules/Psicologia/components/RegistroDiarioComponent.vue'),
            },
            name: "registroDiarioPsicologia",
            meta: {
              requiresAuth: true,
              title: "Psicología - Registro diario",
              seccion: "atenciones"
            },
          }
        ],

      },

      {
        path: "atenciones/nueva-atencion",
        component: PsicologiaView,
        children:[
          {
            path: "",
            components: {
              psicologiaView: () => import('@/modules/Psicologia/components/RegistrarNuevaAtencionComponent.vue'),
            },
            name: "nuevaAtencionPsicologica",
            meta: {
              requiresAuth: true,
              title: "Psicología - Nueva atención",
              seccion: "atenciones"
            },
          }
        ],

      },

      {
        path: "atenciones/editar-atencion/:idatencionpsicologica",
        component: PsicologiaView,
        children:[
          {
            path: "",
            components: {
              psicologiaView: () => import('@/modules/Psicologia/components/RegistrarNuevaAtencionComponent.vue'),
            },
            name: "editarAtencionPsicologica",
            meta: {
              requiresAuth: true,
              title: "Psicología - Editar atención",
              seccion: "atenciones"
            },
          }
        ],

      },

      {
        path: "atenciones/historias",
        component: PsicologiaView,
        children:[
          {
            path: "",
            components: {
              psicologiaView: () => import('@/modules/Psicologia/components/HistoriasPacientesComponent.vue'),
            },
            name: "historiasPacientesPsicologia",
            meta: {
              requiresAuth: true,
              title: "Psicología - Historias",
              seccion: "atenciones"
            },
          }
        ],

      },

      {
        path: "evaluaciones",
        redirect: "evaluaciones/lista-evaluaciones",
        name: "evaluaciones",
      },

      {
        path: "evaluaciones/lista-evaluaciones",
        component: PsicologiaView,
        children:[
          {
            path: "",
            components: {
              psicologiaView: () => import('@/modules/Psicologia/components/evaluaciones/ListaEvaluacionesComponent.vue'),
            },
            name: "listaEvaluaciones",
            meta: {
              requiresAuth: true,
              title: "Psicología - Evaluaciones",
              seccion: "evaluaciones"
            },
          }
        ],

      },

      {
        path: "evaluaciones/generador/:key",
        component: PsicologiaView,
        children:[
          {
            path: "",
            components: {
              psicologiaView: () => import('@/modules/Psicologia/components/evaluaciones/GeneradorDeEvaluacionesComponent.vue'),
            },
            name: "generadoDeEvaluaciones",
            meta: {
              requiresAuth: true,
              title: "Psicología - Generador de evaluaciones",
              seccion: "evaluaciones"
            },
          }
        ],

      },
      {
        path: "evaluaciones/resultados",
        component: PsicologiaView,
        children:[
          {
            path: "",
            components: {
              psicologiaView: () => import('@/modules/Psicologia/components/evaluaciones/ResultadosDeEvaluaciones.vue'),
            },
            name: "resultadoDeEvaluaciones",
            meta: {
              requiresAuth: true,
              title: "Psicología - Resultado de evaluaciones",
              seccion: "evaluaciones"
            },
          }
        ],

      },
      {
        path: "evaluaciones/preview/:key",
        component: () => import('@/modules/Psicologia/components/evaluaciones/vistaPreviaExamenComponent.vue'),
        name: "previewEvaluacion",
            meta: {
              //requiresGuest: true,
              title: "Psicología - Preview Evaluacion",
              seccion: "evaluaciones"
            },

      },

      {
        path: "evaluacion/estudiante/:key",
        component: () => import('@/modules/Psicologia/views/examenEstudianteView.vue'),
        name: "evaluacionEstudiantes",
            meta: {
              //requiresGuest: true,
              title: "Psicología - Evaluación psicológica",
              seccion: "evaluaciones"
            },

      },
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };