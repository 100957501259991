import swal from "sweetalert";
import store from "@/store";
import CategoriaDisciplinaService from "../services/CategoriaDisciplinaService";

const serviceCategoria = new CategoriaDisciplinaService
export default{
    namespaced:true,
    state:{
        categoria: {
            categoria: ""
        },
        categoria_default: {
            categoria: ""
        },
        listaCategorias:[],
        loadingCategoria: false,
        loadingListCategoria: false
    },

    mutations:{

        setCategoria(state, payload)
        {
            state.categoria = Object.assign({}, payload)
        },

        resetCategoria(state, payload)
        {
            state.categoria = Object.assign({}, state.categoria_default)
        },

        setListaCategoria(state, payload)
        {
            state.listaCategorias = payload
        },

        resetListaCategoria(state)
        {
            state.listaCategorias = []
        }
    },

    actions:{
        async getListaCategoria({state, commit})
        {
            state.loadingListCategoria = true
            try
            {
                const data = await serviceCategoria.get()
                console.log(data)
                commit('setListaCategoria', data)
                state.loadingListCategoria = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingListCategoria = false
            }
        },

        async addCategoria({state, commit, dispatch})
        {
            state.loadingCategoria = true
            try
            {
                const data = await serviceCategoria.add(state.categoria)
                dispatch('getListaCategoria')
                commit('resetCategoria')
                state.loadingCategoria = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingCategoria = false
            }
        },

        async updateCategoria({state, commit, dispatch})
        {
            state.loadingCategoria = true
            try
            {
                const data = await serviceCategoria.update(state.categoria)
                dispatch('getListaCategoria')
                commit('resetCategoria')
                state.loadingCategoria = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingCategoria = false
            }
        },

        async deleteCategoria({state, commit, dispatch}, idcategoria)
        {
            const confirmar = await swal({
                title: "¿desea eliminar este elemento?",
                icon: 'warning',
                dangerMode: true,
                buttons: ['cancelar', 'CONFIRMAR']
            })

            if(!confirmar){ return }
            
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false, 
                closeOnClickOutside: false, 
                closeOnEsc: false, 
                allowOutsideClick: false 
              });
            try
            {
                const data = await serviceCategoria.delete(idcategoria)
                dispatch('getListaCategoria')
                swal({
                    title: "Eliminado correctamente",
                    icon: "success"
                })
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ocurrio un error",
                    icon: "error"
                })
            }
        }
    }
}