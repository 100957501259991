import Module from './Module.vue';
import DashboardView from './views/DashboardView.vue'
const moduleRoute = {
    path: "/",
    component: Module,
    children: [
      {
        path: "/",
        component: DashboardView,
        name: 'dashboard',
        meta: {
          requiresAuth: true,
          title: "Dashboard",
        },
      },
      
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };