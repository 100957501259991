<template>
  <div>
    <v-card class="ma-3" elevation="0" >
      <v-card-subtitle class="black--text font-weight-bold"
        >Selecciona una plantilla para el pabellón</v-card-subtitle
      >
      <v-item-group v-model="radioTemplate">
        <v-row>
          <v-col cols="4" v-for="(menu, index) in items" :key="index">
            <v-item v-slot="{ active, toggle }" :value="menu.value">
              <v-card elevation="0" :class="active? 'bgCTM' : ''"  @click="toggle">
                <v-card-text class="pa-2">
                  <v-img
                    :src="require('../assets/' + menu.urlimg)"
                    aspect-ratio="2"
                    contain
                  ></v-img>
                  <div class="d-block text-center" :class="active ? 'font-weight-bold  white--text' : ''">{{ menu.title }}</div>
                </v-card-text>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data: () => ({
    items: [
      {
        title: "Una sola Fila de camas",
        urlimg: "template-pabellon-1A.png",
        value: "Onerectangle",
      },
      {
        title: "Dos filas de camas",
        urlimg: "template-pabellon-1.png",
        value: "AyBrectangle",
      },
      {
        title: "Tres filas de camas",
        urlimg: "template-pabellon-2.png",
        value: "AByCrectangle",
      },
    ],
  }),

  computed: {
    ...mapState("pabellones/creadorPabellon", [
      "dataPabellon",
    ]),
    radioTemplate: {
      get() {
        return this.dataPabellon.selectedTemplate;
      },
      set(val) {
        this.changeTemplate(val);
      },
    },
  },

  methods: {
    ...mapMutations("pabellones/creadorPabellon", ["changeTemplate"]),
  },
};
</script>
