import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class RespuestasEvaluacionesService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );

    }


    async verifyExistEvaluacion(key)
    {
        const response = await this.axios.get('evaluacion-estudiante-verificar', {params: { key}})
        return response.data
    }


    async verifyIntentoEvaluacion(params)
    {
        const response = await this.axios.get('evaluacion-intento-verificar', {params})
        return response.data
    }


    async sendEvaluacion(data)
    {
        const response = await this.axios.post('send-evaluacion-estudiante', data)
        return response.data
    }


    /**
     * OBTENER TODAS LAS EVALUACIONES RESUELTAS POR ESTUDIANES
     */

    async getResultadosEvaluacionPorEstudiantes(params)
    {
        const response = await this.axios.get('get-resultados-evaluaciones', {params: params})
        return response.data
    } 

/**
     * VER TODAS LAS RESPUESTAS DE UNA EVALUACION RESUELTA
     */

    async getRespuestasDeEvaluacion(evaluacion_resuelta_id)
    {
        const response = await this.axios.get('get-respuestas-evaluacion', {params: {evaluacion_resuelta_id}})
        return response.data
    }

    /**
     * ELIMINAR INTENTO DE EVALUACION RESUELTA
     */

    async deleteIntentoEvaluacion(idevaluacionresuelta)
    {
        const response = await this.axios.delete('delete-intento-evaluacion?idevaluacionresuelta=' + idevaluacionresuelta)
        return response.data
    }

    /**
     * CALCULAR NOTA POR GRUPO
     */
    async calcularNotaPorGrupo(evaluacion_resuelta_id)
    {
        const response = await this.axios.get('calcular-notas-grupo', {params:{ evaluacion_resuelta_id}})
        return response.data
    }

    /**
     * CALCULAR NOTA GLOBAL
     */

    async calcularNotaTotal(evaluacion_resuelta_id)
    {
        const response = await this.axios.get('calcular-total-notas', {params:{ evaluacion_resuelta_id}})
        return response.data
    }


    /**
     * RESULTADOS POR AREA - CANTIDAD DE SI Y NO
     */

    async getResultadosPorArea(params)
    {
        const response = await this.axios.get('/calculo-si-no-areas', {params: params})
        return response.data
    }


    /**
     * ACTUALIZAR RESULTADO DE TODOS LOS INTENTOS
     */

    async updatedResultadosIntentos(params)
    {
        const response = await this.axios.get('/actualizar-resultado-intento', {params: params})
        return response.data
    }
}