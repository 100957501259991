import store from "@/store";
import AtencionMedicaService from "../service/atencionMedicaService"
import Paciente from "./Paciente";
import swal from "sweetalert";
import router from "@/router";

const AtencionMedicaSV = new AtencionMedicaService;

export default
    {
        namespaced: true,
        state: {
            medicamento:
            {
                cantidad: 0,
                producto: "",
            },
            medicamentoDefault:
            {
                cantidad: 0,
                producto: "",
                indicacion: ""
            },
            medicamentosentregados: [],
            nuevosmedicamentosagregados : [],
            medicamentosAeliminar: [],
            isumo:
            {
                cantidad: 0,
                producto: "",
                descontado: "NO"
            },
            isumoDefault:
            {
                cantidad: 0,
                producto: "",
                descontado: "NO"
            },
            insumosEntregados: [],
            atencion_medica: {
                fecha: "",
                nivel_riesgo: "",
                presuncion_diagnostica: "",
                descripcion_tratamiento: "",
                peso: "",
                talla: "",
                saturacion: "",
                frecuencia: "",
                presion: "",
                temperatura: "",
                requiere_medico: "no",
                paciente_id: -1,
                sede: "",
                tipo_atencion: "",
                atendido_por: ""
            },
            atencion_medica_default: {
                fecha: "",
                nivel_riesgo: "",
                presuncion_diagnostica: "",
                descripcion_tratamiento: "",
                peso: "",
                talla: "",
                saturacion: "",
                frecuencia: "",
                presion: "",
                temperatura: "",
                requiere_medico: "no",
                paciente_id: -1,
                sede: "",
                tipo_atencion: "",
                atendido_por: ""

            },

            confirmRegister: false,
            loadingRegister: false,
            errorRegister: false

        },
        mutations: {
            setListMedicamentos(state) {
                let medicina = Object.assign({}, state.medicamento.producto)
                medicina['cantidad'] = state.medicamento.cantidad
                state.medicamentosentregados.push(medicina)

                state.medicamento = Object.assign({}, state.medicamentoDefault)
            },

           async deleteMedicamentoList(state, medicamento) {
                let confirm = await swal({
                    title: "¿Desea eliminar el médicamento?",
                    text: medicamento.name_producto + ' -  cant.' + medicamento.cantidad,
                    icon: "warning",
                    buttons: ['CANCELAR', 'CONFIRMAR'],
                    dangerMode: true,

                })
                if(!confirm){ return }

                let index = state.medicamentosentregados.indexOf(medicamento)
                state.medicamentosentregados.splice(index, 1)
            },


            //METODOS PARA AGREGAR Y ELIMINAR  MEDICAMENTOS (EDICION DE ATENCION MEDICA)
            agregarNuevoMedicamento(state)
            {
                let medicina = Object.assign({}, state.medicamento.producto)
                medicina['cantidad'] = state.medicamento.cantidad

                state.medicamentosentregados.push(medicina)
                state.nuevosmedicamentosagregados.push(medicina)

                state.medicamento = Object.assign({}, state.medicamentoDefault)
            },

            deleteMedicamentoEnEdicion(state, medicamento)
            {
                store.commit('topico/RegistrarAtencion/deleteMedicamentoList', medicamento)
                state.medicamentosAeliminar.push(medicamento)

                let index = state.nuevosmedicamentosagregados.indexOf(medicamento)
                state.nuevosmedicamentosagregados.splice(index, 1)
            },

            resetAtencionMedica(state) {
                state.atencion_medica = Object.assign({}, state.atencion_medica_default)
                state.medicamentosentregados = []
            },

            resetDialogConfirm(state) {
                state.confirmRegister = false
                state.loadingRegister = false
                state.errorRegister = false
            },

            setAtencionMedicaForEdit(state, data){
                state.atencion_medica = Object.assign({}, data)
                state.atencion_medica.nivel_riesgo = parseInt(data.nivel_riesgo)
                delete state.atencion_medica.medicamentos_entregados
                state.medicamentosentregados = []
                data.medicamentos_entregados.forEach(m => {
                    m.medicamento['cantidad'] = m.cantidad
                    state.medicamentosentregados.push(m.medicamento)
                });

                store.commit('topico/Paciente/setPaciente', data.paciente) 
            },

            
        },
        actions: {

            async guardar({ commit, dispacth, state }, editar = false) {
                commit('resetDialogConfirm')
                state.confirmRegister = false
                state.loadingRegister = true
                state.errorRegister = false

                state.atencion_medica.paciente_id = Paciente.state.paciente.idpaciente;
                try {

                    let data = null;
                    if(!editar)
                    {
                        data = await AtencionMedicaSV.guardar({ atencion_medica: state.atencion_medica, medicamentosentregados: state.medicamentosentregados })
                    }else{
                        data = await AtencionMedicaSV.update({ atencion_medica: state.atencion_medica, nuevosmedicamentos: state.nuevosmedicamentosagregados, eliminarmedicamentos: state.medicamentosAeliminar })
                    }

                    commit('resetAtencionMedica')
                    store.commit('topico/Paciente/resetPaciente')

                    state.confirmRegister = true
                    state.loadingRegister = false
                    
                    console.log(state.confirmRegister)

                } 
                catch (error) {
                    console.log(error)
                    state.confirmRegister = false
                    state.loadingRegister = false
                    state.errorRegister = true
                }
            },


            //Obtener atencion medica por id

            async getAtencionM({commit, dispacth, state}, idAtencionMedica){
                commit('resetDialogConfirm')
                swal({
                    title: "Cargando...",
                    text: "Por favor, espere un momento.",
                    icon: "info",
                    button: false, // Ocultar el botón de confirmación
                    closeOnClickOutside: false, // Evitar que el usuario cierre la alerta haciendo clic afuera
                    closeOnEsc: false, // Evitar que el usuario cierre la alerta presionando la tecla Esc
                    allowOutsideClick: false // Evitar que el usuario cierre la alerta haciendo clic afuera
                  });
                try {
                    const data = await AtencionMedicaSV.show(idAtencionMedica)
                    commit('setAtencionMedicaForEdit', data)
                   
                    swal.close()
                }
                catch (error) {
                    console.log(error)
                    swal({
                        title: "No se encontró la atención medica",
                        text: "Por favor, vuelva a intentar.",
                        icon: "error",
                        buttons: {
                          confirm: {
                            text: "Aceptar",
                            value: true,
                            visible: true,
                            className: "btn-primary",
                            closeModal: true,
                          },
                        },
                      }).then((value) => {
                        if (value) {
                          router.push({name: 'historias'})
                        }
                      });
                }
            }
        }
    }