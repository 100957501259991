import Module from './Module.vue';
import UsuariosView from './views/UsuariosView.vue'
import RolesView from './views/RolesView.vue'
const moduleRoute = {
    path: "/usuarios",
    component: Module,
    children: [
      {
        path: "/",
        component: UsuariosView,
        name: 'usuarios',
        meta: {
          requiresAuth: true,
          title: "Usuarios",
        },
      },
      {
        path: "roles",
        component: RolesView,
        name: 'rolesdeusuario',
        meta: {
          requiresAuth: true,
          title: "Roles de usuario",
        },
      },
      
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };