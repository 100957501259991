import utiles from "@/modules/Shared/utiles"
import registrarCasos from "./registrarCasos"
import DialogosAlert from "./DialogosAlert"
import documentosAdjuntos from "./documentosAdjuntos"
import SancionDisciplina from "./SancionDisciplina"
import CategoriaDisciplina from "./CategoriaDisciplina"


export default{
    namespaced: true,
    modules:{
        registrarCasos,
        utiles,
        DialogosAlert,
        documentosAdjuntos,
        SancionDisciplina,
        CategoriaDisciplina
    },
    state:{},
    mutations:{},
    actions:{}
}