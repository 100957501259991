import store from "@/store";
import MedicamentoService from "../service/medicamentoService"
import visitas from "@/modules/Visitas/store/index";
import swal from "sweetalert";
const MedicamentoSV = new MedicamentoService;

export default
{
    namespaced: true,
    state:{
        listaMedicamentos: [],
        listaInsumos: [],
        medicamento: {
            name_producto: "",
            principio_activo: "",
            descripcion: "",
            fecha_vencimiento: "",
            presentacion: "",
            cantidad_inicial: 1,
            lote: "",
            sede: "",
            tipo: "medicamento"
        },

        medicamentoDefault: {
            name_producto: "",
            principio_activo: "",
            descripcion: "",
            fecha_vencimiento: "",
            presentacion: "",
            cantidad_inicial: 1,
            lote: "",
            sede: "",
            tipo: "medicamento"
        },

        filtersMedicamentos: {
            name_producto: "",
            principio_activo: "",
            presentacion: "",
            sede: "",
            tipo: ""
        },

        loading: false,
        loadingSearch: false,
        loadingReporteMedicamento: false
    },
    mutations:{
        setListMedicamentos(state, payload)
        {
            state.listaMedicamentos = payload
        },

        setListMedicamentosSearch(state, payload)
        {
            state.listaMedicamentosSearch = payload
        },

        setListInsumos(state, payload)
        {
            state.listaInsumos = payload
        },

        resetMedicamento(state)
        {
            state.medicamento = Object.assign({}, state.medicamentoDefault)
        },

        setEditMedicamento(state, item){
            state.medicamento = Object.assign({}, item)
        }
    },
    actions:{

        async getMedicamentos({commit, state})
        {
            state.loading = true
            try{
                
                const data = await MedicamentoSV.getMedicamento(state.filtersMedicamentos);
                commit('setListMedicamentos', data)
                state.loading = false
            }
            catch(error)
            {
                console.log(error)
                state.loading = false
            }
        },
 

        async getMedicamentosWithStock({commit, state}, sede)
        {
            state.loading = true
            try{

                const data = await MedicamentoSV.getMedicamento({stock: true, sede: sede});
                console.log(data)
                commit('setListMedicamentos', data)
                state.loading = false
            }
            catch(error)
            {
                console.log(error)
                state.loading = false
            }
        },

        async getInsumosWithStock({commit, state}, sede)
        {
            state.loading = true
            try{

                const data = await MedicamentoSV.getMedicamento({stock: true, sede: sede, tipo:'insumo' });
                console.log(data)
                commit('setListMedicamentos', data)
                state.loading = false
            }
            catch(error)
            {
                console.log(error)
                state.loading = false
            }
        },

        async addMedicamento({commit, dispatch, state})
        {
            state.loading = true

            try{

                const data = await MedicamentoSV.addMedicamento(state.medicamento)
                dispatch('getMedicamentos')

                commit('resetMedicamento')
                state.loading = false
                visitas.state.dialogVisitaPreview.dialog=false
                swal({
                    title: "Registrado con exito",
                    icon: "success"
                })
            }
            catch(error)
            {
                state.loading = false
                console.log(error)
                swal({
                    title: "Ha ocurrido un error",
                    icon: "error"
                })
            }
        },

        async updateMedicamento({commit, dispatch, state})
        {
            state.loading = true

            try{

                const data = await MedicamentoSV.updateMedicamento(state.medicamento)
                dispatch('getMedicamentos')

                commit('resetMedicamento')
                state.loading = false
                visitas.state.dialogVisitaPreview.dialog=false
                swal({
                    title: "Modificado con exito",
                    icon: "success"
                })
            }
            catch(error)
            {
                state.loading = false
                console.log(error)
                swal({
                    title: "Ha ocurrido un error",
                    icon: "error"
                })
            }
        },
        async deleteMedicamento({commit, dispatch, state}, medicamento)
        {
            try{
                let confirmar = await swal({
                    title: "¿Desea eliminar este medicamento?",
                    text: medicamento.name_producto,
                    icon: "warning",
                    buttons: ['CANCELAR', 'SI, ELIMINAR'],
                    dangerMode: true
                })

                if(!confirmar) { return }
                swal({
                    title: "Cargando...",
                    text: "Por favor, espere un momento.",
                    icon: "info",
                    button: false, // Ocultar el botón de confirmación
                    closeOnClickOutside: false, // Evitar que el usuario cierre la alerta haciendo clic afuera
                    closeOnEsc: false, // Evitar que el usuario cierre la alerta presionando la tecla Esc
                    allowOutsideClick: false // Evitar que el usuario cierre la alerta haciendo clic afuera
                  });
                const data = await MedicamentoSV.deleteMedicamento(medicamento.idmedicamento)
                dispatch('getMedicamentos')
                state.loading = false
                visitas.state.dialogVisitaPreview.dialog=false
                swal({
                    title: "Medicamento Eliminado",
                    icon: "success"
                })
            }
            catch(error)
            {
                state.loading = false
                console.log(error)
                swal({
                    title: "Ha ocurrido un error",
                    icon: "error"
                })
            }
        },


        async getReporteExcel({state, commit, dispatch})
        {
            state.loadingReporteMedicamento = true
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false, // Ocultar el botón de confirmación
                closeOnClickOutside: false, // Evitar que el usuario cierre la alerta haciendo clic afuera
                closeOnEsc: false, // Evitar que el usuario cierre la alerta presionando la tecla Esc
                allowOutsideClick: false // Evitar que el usuario cierre la alerta haciendo clic afuera
              });
            try
            {
                const data = await MedicamentoSV.getReporte(state.filtersMedicamentos)
                swal({
                    title: "Reporte generado correctamente",
                    icon: "success",
                  });
                state.loadingReporteMedicamento = false
            }
            catch(error)
            {
                console.log(error)
                state.loadingReporteMedicamento = false
                swal({
                    title: "Ha ocurrido un error, vuelve a intentar",
                    icon: "error",
                  });

            }
        }
    }
}