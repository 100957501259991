import store from "@/store";
import { ref } from "vue";
import AuthEstudianteEvaluacionComponent from "../components/evaluaciones/EstudianteEvaluacion/AuthEstudianteEvaluacionComponent.vue";
import RespuestasEvaluacionesService from "../services/RespuestasEvaluacionesService";
import ExamenNoEncontradoComponent from "../components/evaluaciones/EstudianteEvaluacion/ExamenNoEncontradoComponent.vue";
import LoadingEvaluacionComponent from "../components/evaluaciones/EstudianteEvaluacion/LoadingEvaluacionComponent.vue";
import PacienteNoEncontradoComponent from "../components/evaluaciones/EstudianteEvaluacion/PacienteNoEncontradoComponent.vue";
import ErrorEvaluacionYafueResueltaComponent from "../components/evaluaciones/EstudianteEvaluacion/ErrorEvaluacionYafueResueltaComponent.vue";
import ErrorGeneralComponent from "../components/evaluaciones/EstudianteEvaluacion/ErrorGeneralComponent.vue";
import FORMULARIOEXAMEN from "../components/evaluaciones/EstudianteEvaluacion/FORMULARIO_EXAMEN.vue";
import EvaluacionEnviadaComponent from "../components/evaluaciones/EstudianteEvaluacion/EvaluacionEnviadaComponent.vue";
import swal from "sweetalert";

const evaluacionEstudianteSv = new RespuestasEvaluacionesService();

export default {
  namespaced: true,
  state: {
    EvaluacionesResueltasPorEstudiantes: [],
    respuestaEvaluacion: [],
    evaluacionAUTH: {
      component: ref(AuthEstudianteEvaluacionComponent),
    },
    evaluacionlogin: {
      dni: "",
    },

    estudianteEvaluado: {
      nombre_completo: "",
      paciente_id: "",
      carrera: "",
      admision: "",
    },

    estudianteEvaluadoDefault: {
      nombre_completo: "",
      paciente_id: "",
      carrera: "",
      admision: "",
    },
    respuestasDeEvaluacion: null,
    loadingEvaluacionesResueltas: false,
    loadingRespuestas: false,

    NotasPorgrupo: [
      {
        grupo_id: "",
        nota_min: 0,
        nota_max: 0,
      },
    ],

    NotasGlobal: {
      total_notas: 0,
      total_grupos: 0,
      total_preguntas: 0,
    },

    NotasGlobalDefault: {
      total_notas: 0,
      total_grupos: 0,
      total_preguntas: 0,
    },
    NotasPorGrupo: [],

    ResultadoPorAreasSINO: [],

    filterEvaluacionesResueltas: {
      dni: "",
      admision: "",
      carrera: "",
      resultado: "",
    },

    filterEvaluacionesResueltasDefault: {
      dni: "",
      admision: "",
      carrera: "",
      resultado: "",
    },

    loadingNotasGlobal: false,
    loadingNotaPorGrupo: false,

    loadingCalculoPorAreaSINO: false,
  },

  mutations: {
    inicializarRespuestas(state, items) {
      console.log(items);
      state.respuestaEvaluacion = items
        .map((item, index) => {
          return {
            index: index,
            item_id: item.iditem,
            value: "",
            opcion_id: [],
            tipo_item: item.tipo_item,
          };
        })
        .filter((respuesta) => respuesta !== null);

      console.log(state.respuestaEvaluacion);
    },

    setEstudianteEvaluado(state, payload) {
      state.estudianteEvaluado = Object.assign({}, payload);
    },

    setEvaluacionesResueltas(state, payload) {
      state.EvaluacionesResueltasPorEstudiantes = payload;
    },

    resetEvaluacionesResueltas(state) {
      state.EvaluacionesResueltasPorEstudiantes = [];
    },

    setRespuestasEvaluacion(state, payload) {
      state.respuestasDeEvaluacion = payload;
    },

    resetNotasCalculo(state) {
      state.NotasGlobal = Object.assign({}, state.NotasGlobalDefault);
      state.NotasPorGrupo = [];
    },

    resetFiltersResultados(state) {
      state.filterEvaluacionesResueltas = Object.assign(
        {},
        state.filterEvaluacionesResueltasDefault
      );
    },
  },

  actions: {
    async verificarSiExisteExamen({ state, commit, dispatch }, key) {
      state.evaluacionAUTH.component = ref(LoadingEvaluacionComponent);
      try {
        const data = await evaluacionEstudianteSv.verifyExistEvaluacion(key);
        state.evaluacionAUTH.component = ref(AuthEstudianteEvaluacionComponent);
      } catch (error) {
        console.log(error);
        state.evaluacionAUTH.component = ref(ExamenNoEncontradoComponent);
      }
    },

    async verificarIntentoExamen({ state, commit, dispatch }, key) {
      state.evaluacionAUTH.component = ref(LoadingEvaluacionComponent);
      try {
        state.evaluacionlogin["key"] = key;
        const data = await evaluacionEstudianteSv.verifyIntentoEvaluacion(
          state.evaluacionlogin
        );
        commit(
          "setEstudianteEvaluado",
          Object.assign({}, data.paciente, {
            evaluacionresuelta_id: data.evaluacionresuelta_id,
          })
        );
        state.evaluacionAUTH.component = ref(FORMULARIOEXAMEN);
      } catch (error) {
        console.log(error);
        if (error.response.status == 404) {
          //paciente no encontrado
          state.evaluacionAUTH.component = ref(PacienteNoEncontradoComponent);
          return;
        }
        if (error.response.status == 505) {
          //Evaluacion ya fue respondida por el estudiante
          state.evaluacionAUTH.component = ref(
            ErrorEvaluacionYafueResueltaComponent
          );
          return;
        }

        if (error.response.status == 500) {
          //Evaluacion ya fue respondida por el estudiante
          state.evaluacionAUTH.component = ref(ErrorGeneralComponent);
          return;
        }
      }
    },

    async sendRespuestasEvaluacion({ state, commit, dispatch }) {
      const confirmEvaluacion = await swal({
        title: "¿Desea enviar la evaluacion?",
        icon: "warning",
        buttons: ["cancelar", "CONFIRMAR"],
        dangerMode: true,
      });
      if (!confirmEvaluacion) {
        return;
      }
      swal({
        title: "Cargando...",
        text: "Por favor, espere un momento.",
        icon: "info",
        button: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        allowOutsideClick: false,
      });
      try {
        let respuestas = state.respuestaEvaluacion.filter(
          (respuesta) => respuesta.tipo_item === "question"
        );

        const data = await evaluacionEstudianteSv.sendEvaluacion({
          respuestas: respuestas,
          evaluacion_resuelta_id:
            state.estudianteEvaluado.evaluacionresuelta_id,
        });
        swal.close();
        state.evaluacionAUTH.component = ref(EvaluacionEnviadaComponent);
      } catch (error) {
        console.log(error);
        swal({
          title: "Ha ocurrido un grave error",
          icon: "error",
        });
      }
    },

    /***
     * RESULTADOS DE EVALUACIONES POR ESTUDIANTES
     */

    async getResultadosEvaluacionPorEstudiantes(
      { state, commit, dispatch },
      idevaluacion
    ) {
      commit("resetEvaluacionesResueltas");
      state.loadingEvaluacionesResueltas = true;
      try {
        let sedes = store.state.user.roles.sedes.split(",");

        const data =
          await evaluacionEstudianteSv.getResultadosEvaluacionPorEstudiantes({
            idevaluacion,
            filterEvaluacionesResueltas: state.filterEvaluacionesResueltas,
            sedes,
          });
        commit("setEvaluacionesResueltas", data);
        console.log(data);
        state.loadingEvaluacionesResueltas = false;
      } catch (error) {
        console.log(error);
        state.loadingEvaluacionesResueltas = false;
      }
    },

    async getRespuestasDeEvaluacion({ state, commit, dispatch }, payload) {
      const { evaluacion_resuelta_id, paciente_psicologia } = payload;
      state.respuestasDeEvaluacion = [];
      state.estudianteEvaluado = Object.assign(
        {},
        state.estudianteEvaluadoDefault
      );
      state.loadingRespuestas = true;
      try {
        const data = await evaluacionEstudianteSv.getRespuestasDeEvaluacion(
          evaluacion_resuelta_id
        );
        commit("setRespuestasEvaluacion", data);
        commit("setEstudianteEvaluado", paciente_psicologia);
        console.log(data);
        state.loadingRespuestas = false;
      } catch (error) {
        console.log(error);
        state.loadingRespuestas = false;
      }
    },

    async deleteIntentoEvaluacion({ state, commit, dispatch }, payload) {
      const { idevaluacionresuelta, idevaluacion } = payload;
      const confirmEvaluacion = await swal({
        title: "¿Desea eliminar el intento?",
        icon: "warning",
        buttons: ["cancelar", "CONFIRMAR"],
        dangerMode: true,
      });
      if (!confirmEvaluacion) {
        return;
      }
      swal({
        title: "Cargando...",
        text: "Por favor, espere un momento.",
        icon: "info",
        button: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        allowOutsideClick: false,
      });
      try {
        const data = await evaluacionEstudianteSv.deleteIntentoEvaluacion(
          idevaluacionresuelta
        );
        dispatch("getResultadosEvaluacionPorEstudiantes", idevaluacion);
        swal.close();
      } catch (error) {
        console.log(error);
        swal({
          title: "Ha ocurrido un error",
          icon: "error",
        });
      }
    },

    async calcularNotaPorGrupo(
      { state, commit, dispatch },
      evaluacion_resuelta_id
    ) {
      state.NotasPorGrupo = [];
      state.loadingNotaPorGrupo = true;
      try {
        const data = await evaluacionEstudianteSv.calcularNotaPorGrupo(
          evaluacion_resuelta_id
        );
        state.NotasPorGrupo = Object.assign({}, data);
        state.loadingNotaPorGrupo = false;
      } catch (error) {
        console.log(error);
        state.loadingNotaPorGrupo = false;
      }
    },

    async calcularNotaTotal(
      { state, commit, dispatch },
      evaluacion_resuelta_id
    ) {
      //state.NotasGlobal = Object.assign({}, state.NotasGlobalDefault);
      commit('resetNotasCalculo')
      state.loadingNotasGlobal = true;
      try {
        const data = await evaluacionEstudianteSv.calcularNotaTotal(
          evaluacion_resuelta_id
        );
        state.NotasGlobal = Object.assign({}, data);
        state.loadingNotasGlobal = false;
      } catch (error) {
        console.log(error);
        state.loadingNotasGlobal = false;
      }
    },

    async calcularResultadoPorArea(
      { state, commit, dispatch },
      evaluacion_resuelta_id
    ) {
      state.loadingCalculoPorAreaSINO = true;
      state.ResultadoPorAreasSINO = [];

      try {
        const data = await evaluacionEstudianteSv.getResultadosPorArea({
          evaluacion_resuelta_id,
        });
        state.ResultadoPorAreasSINO = data;
        state.loadingCalculoPorAreaSINO = false;
      } catch (error) {
        console.log(error);
        state.loadingCalculoPorAreaSINO = false;
      }
    },

    /**
     * Actualizar resultado de todos los intentos
     */
    async updatedResultadosIntentos({ state, commit, dispatch }, idevaluacion) {
      swal({
        title: "Cargando...",
        text: "Por favor, espere un momento.",
        icon: "info",
        button: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        allowOutsideClick: false,
      });
      try {
        const data = await evaluacionEstudianteSv.updatedResultadosIntentos({
          idevaluacion,
        });
        dispatch("getResultadosEvaluacionPorEstudiantes", idevaluacion);
        swal.close();
      } catch (error) {
        console.log(error);
        swal({
          title: "Ocurrio un error",
          icon: "error",
        });
      }
    },
  },
};
