import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";
import router from "@/router";
import restorepass from "./restorepass";
export default {
    modules: {
      utiles,
      restorepass
    },
    namespaced: true,
    state:{
      componentComponent : 'FormLogin',
      loginFileds: {
        email: "",
        password: "",
      },
      errorLogin:{
        type: null,
        message: ""
      },

      errorLoginDefault:{
        type: null,
        message: ""
      },
      
    },
    mutations:{
      switchComponent(state, val){
        state.componentComponent = val
      },

      setToken(state, payload) {
        store.state.token = payload;
      },
      setUser(state, {user, token}) {
        store.state.user = Object.assign({}, user, {token: token});
        setTimeout(() => {
          store.state.overlayGeneral = false;
        }, 1000);
      },

      setInfoUser(state, payload) {
        store.state.infoUser = Object.assign({}, payload);
      },
      logout(state) {
        state.overlayGeneral = true;
        store.state.user = Object.assign({}, store.state.userDefault);
        localStorage.removeItem('vuex')
        location.reload();
      },
  
      badErrorAuth(state, error) {
        console.log(error);
        if (error.response.status == 401) {
          store.state.overlayGeneral = true;
          store.state.user = Object.assign({}, store.state.userDefault);
          location.reload();
        }
      },

      resetErroLogin(state){
        state.errorLogin = Object.assign({}, state.errorLoginDefault)
      }
     
    },
    actions:{
      loginUser({ commit, state }) {
        commit("utiles/resetConfigAxios");
        store.state.overlayGeneral = true;
        utiles.state.configAxios.url = state.utiles.apiUrl + "login";
        utiles.state.configAxios.method = "POST";
        utiles.state.configAxios.data = state.loginFileds;
        axios(utiles.state.configAxios)
          .then((res) => {
            commit("setToken", res.data.token);
            commit("setUser", {user:res.data.user, token: res.data.token});
            commit("setInfoUser", res.data.user);
  
            commit('resetErroLogin')
            state.loggeIn = true;
            router.push({ name: "dashboard" });
          })
          .catch((error) => {
            if(error && error.response.status == 400){
              state.errorLogin.type = "error";
              state.errorLogin.message = "Credenciales invalidas!";
            }
            if(error &&  error.response.status == 500){
              state.errorLogin.type = "warning";
              state.errorLogin.message = "Esta cuenta está suspendida!";
            }
            console.log(error)
            state.loggeIn = false;
            store.state.overlayGeneral = false;
            commit("badErrorAuth", error);
          });
      },

      logout({ commit, state }) {
        store.state.overlayGeneral = true;
        commit("utiles/resetConfigAxios");
        state.utiles.configAxios.url = state.utiles.apiUrl + "logout";
        state.utiles.configAxios.method = "POST";
        state.utiles.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
        axios(state.utiles.configAxios)
          .then((res) => {
            if (res.status == 201) {
              commit("logout");
            }
          })
          .catch((error) => {
            console.log("ERRORR", error);
            store.state.overlayGeneral = false;
            commit("badErrorAuth", error);
          });
      },
  
    }
  };