import axios from "axios";
import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import swal from "sweetalert";
import { socket } from "@/socket-cliente";

export default {
  modules: {
    utiles,
  },
  namespaced: true,
  state: {
    motivos: [],
    areascolaborador: [],
    areasvisitas: [],
    empresasTerceros: [],
    loadingMotivoText: false,
    loadingMotivoTable: false,
    loadingAreasColaboradorText: false,
    loadingAreasColaboradorTable: false,
    loadingAreasVisitaTable: false,
    loadingAreasVisitaText: false,
    loadingEmpresasTercerosTable: false,
    loadingEmpresasTercerosText: false,
    addmotivo: { motivo: "" },
    addAreasColaborador: { nombrearea: "" },
    addAreasVisita: { nombrearea: "" },
    addEmpresasTerceros: { nombre: "" },
  },

  mutations: {
    setMotivos(state, payload) {
      state.motivos = payload;
    },

    setAreasColaborador(state, payload) {
      state.areascolaborador = payload;
    },

    setAreasVisita(state, payload) {
      state.areasvisitas = payload;
    },

    setEmpresasTerceros(state, payload) {
      state.empresasTerceros = payload;
    },
  },

  actions: {
    getApiMotivos({ commit, state }, loading=true) {
      state.loadingMotivoTable = loading;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "motivos";
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((resp) => {
          state.loadingMotivoTable = false;
          if (resp.status == 200) {
            commit("setMotivos", resp.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingMotivoTable = false;
        });
    },

    setApiMotivo({ commit, dispatch, state }) {
      state.loadingMotivoText = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "motivos";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = state.addmotivo;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((resp) => {
          state.loadingMotivoText = false;
          console.log(resp);
          if (resp.status == 200) {
            state.addmotivo.motivo = "";
            dispatch("getApiMotivos");
            socket.emit('conf_motivos', false)
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingMotivoText = false;
        });
    },

    delApiMotivo({ commit, dispatch, state }, idmotivo) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        utiles.state.apiUrl + "motivos/" + idmotivo;
      utiles.state.configAxios.method = "DELETE";

      swal({
        title: "¿DESEA ELIMINAR?",
        icon: "warning",
        dangerMode: true,
        buttons: ["cancelar", "CONFIRMAR"],
      }).then((confirma) => {
        if (!confirma) {
          state.loadingMotivoTable = false;
          return;
        }

        state.loadingMotivoTable = true;
        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              state.loadingMotivoTable = false;
              dispatch("getApiMotivos");
              socket.emit('conf_motivos', false)
            }
          })

          .catch((err) => {
            console.log(err);
            state.loadingMotivoTable = false;
          });
      });
    },

    //AREAS COLABORADOR

    getApiAreasColaborador({ commit, dispatch, state }, loading=true) {
      commit("utiles/resetConfigAxios");
      state.loadingAreasColaboradorTable = loading;
      utiles.state.configAxios.url = utiles.state.apiUrl + "areascolaborador";
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            state.loadingAreasColaboradorTable = false;
            commit("setAreasColaborador", resp.data.data);
            
          }
        })
        .catch((error) => {
          console.log(error);
          state.loadingAreasColaboradorTable = false;
        });
    },

    setApiAreasColaborador({ commit, dispatch, state }) {
      state.loadingAreasColaboradorText = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "areascolaborador";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = state.addAreasColaborador;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            state.addAreasColaborador.nombrearea = "";
            state.loadingAreasColaboradorText = false;
            dispatch("getApiAreasColaborador");
            socket.emit('conf_area_colaboradores', false)
          }
        })

        .catch((error) => {
          state.loadingAreasColaboradorText = false;
          console.log(error);
        });
    },

    delApiAreasColaborador({ commit, dispatch, state }, idareaascolaborador) {
      state.loadingAreasColaboradorTable = true;
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        utiles.state.apiUrl + "areascolaborador/" + idareaascolaborador;
      utiles.state.configAxios.method = "DELETE";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿Deseas eliminar?",
        icon: "warning",
        buttons: ["cancelar", "CONFIRMAR"],
        dangerMode: true,
      }).then((confirmar) => {
        if (!confirmar) {
          state.loadingAreasColaboradorTable = false;
          return;
        }

        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              state.loadingAreasColaboradorTable = false;
              dispatch("getApiAreasColaborador");
              socket.emit('conf_area_colaboradores', false)
            }
          })
          .catch((error) => {
            state.loadingAreasColaboradorTable = false;
            console.log(error);
          });
      });
    },

    //AREAS PARA VISITAR

    getApiAreasVisita({ commit, state }, loading=true) {
      commit("utiles/resetConfigAxios");
      state.loadingAreasVisitaTable = loading;
      utiles.state.configAxios.url = utiles.state.apiUrl + "areasvisitas";
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            commit("setAreasVisita", resp.data.data);
            state.loadingAreasVisitaTable = false;
          }
        })
        .catch((error) => {
          state.loadingAreasVisitaTable = false;
          console.log(error);
        });
    },

    setApiAreasVisita({ commit, dispatch, state }) {
      commit("utiles/resetConfigAxios");
      state.loadingAreasVisitaText = true;
      utiles.state.configAxios.url = utiles.state.apiUrl + "areasvisitas";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = state.addAreasVisita;
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            state.loadingAreasVisitaText = false;
            state.addAreasVisita.nombrearea = "";
            dispatch("getApiAreasVisita");
            socket.emit('conf_areas_visita', false)
          }
        })
        .catch((error) => {
          state.loadingAreasVisitaText = false;
          console.log(error);
        });
    },

    delApiAreasVisita({ commit, dispatch, state }, idareavisita) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        utiles.state.apiUrl + "areasvisitas/" + idareavisita;
      utiles.state.configAxios.method = "DELETE";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      console.log(idareavisita);

      swal({
        title: "¿Deseas eliminar?",
        icon: "warning",
        buttons: ["cancelar", "CONFIRMAR"],
        dangerMode: true,
      }).then((confirmar) => {
        if (!confirmar) {
          state.loadingAreasVisitaTable = false;
          return;
        }

        state.loadingAreasVisitaTable = true;
        axios(utiles.state.configAxios)
          .then((resp) => {
            console.log(resp);
            if (resp.status == 200) {
              state.loadingAreasVisitaTable = false;
              dispatch("getApiAreasVisita");
              socket.emit('conf_areas_visita', false)
            }
          })
          .catch((error) => {
            state.loadingAreasVisitaTable = false;
            console.log(error);
          });
      });
    },

    //EMPRESAS TERCEROS

    getApiEmpresasTerceros({ commit, state }, loading=true) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "empresaterceros";
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      state.loadingEmpresasTercerosTable = loading;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            commit("setEmpresasTerceros", resp.data.data);
            state.loadingEmpresasTercerosTable = false;
          }
        })
        .catch((error) => {
          state.loadingEmpresasTercerosTable = false;
          console.log(error);
        });
    },

    setApiEmpresasTerceros({ commit, dispatch, state }) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "empresaterceros";
      utiles.state.configAxios.method = "POST";
      utiles.state.configAxios.data = state.addEmpresasTerceros
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;
      state.loadingEmpresasTercerosText = true;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            state.loadingEmpresasTercerosText = false;
            state.addEmpresasTerceros.nombre = ""
            dispatch("getApiEmpresasTerceros");
            socket.emit('conf_empresas_terceros', false)
          }
        })
        .catch((error) => {
          state.loadingEmpresasTercerosText = false;
          console.log(error);
        });
    },

    delApiEmpresasTerceros({ commit, dispatch, state }, idempresasterceros) {
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url =
        utiles.state.apiUrl + "empresaterceros/" + idempresasterceros;
      utiles.state.configAxios.method = "DELETE";
      utiles.state.configAxios.headers.Authorization =
        "Bearer " + store.state.user.token;

      swal({
        title: "¿Deseas eliminar?",
        icon: "warning",
        buttons: ["cancelar", "CONFIRMAR"],
        dangerMode: true,
      }).then((confirmar) => {
        if (!confirmar) {
          state.loadingEmpresasTercerosTable = false;
          return;
        }

        state.loadingEmpresasTercerosTable = true;
        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              state.loadingEmpresasTercerosTable = false;
              dispatch("getApiEmpresasTerceros");
              socket.emit('conf_empresas_terceros', false)
            }
          })
          .catch((error) => {
            state.loadingEmpresasTercerosTable = false;
            console.log(error);
          });
      });
    },
  },
};
