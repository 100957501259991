import presuncionDiagnosticaService from "../service/presuncionDiagnosticaService";
import swal from "sweetalert";

const presuncionDiagnosticaSV = new presuncionDiagnosticaService();

export default {
  namespaced: true,
  state: {
    presuncionDiagnostica: {
      presuncion: "",
    },

    presuncionDiagnosticaDf: {
        presuncion: "",
      },
    
    presuncionDialog: false,
    listaPresuncion: [],
    loadingCreate: false,
    loadingList: false
  },

  mutations: {
    setListPresuncion(state, payload) {
      state.listaPresuncion = payload;
    },

    setEditPresuncion(state, payload) {
      state.presuncionDiagnostica = Object.assign({}, payload);
    },

    resetPresuncion(state) 
    {
        state.presuncionDiagnostica = Object.assign({}, state.presuncionDiagnosticaDf)
    },

    switchDialog(state)
    {
        state.presuncionDialog = !state.presuncionDialog
    }
  },

  actions: {
    async getPresuncions({ state, commit }) {
        state.loadingList = true
      try {
        const data = await presuncionDiagnosticaSV.get();
        console.log(data)
        commit("setListPresuncion", data);
        state.loadingList = false
      } catch (error) {
        console.log(error);
        state.loadingList = false
      }
    },

    async setPresuncion({ state, commit, dispatch }) {
      state.loadingCreate = true
      try {
        const data = await presuncionDiagnosticaSV.add(state.presuncionDiagnostica);
        dispatch("getPresuncions");
        commit('resetPresuncion');
        state.loadingCreate = false
      } catch (error) {
        console.log(error);
        state.loadingCreate = false

      }
    },

    async updatePresuncion({state, commit, dispatch})
    {
        state.loadingCreate = true
        try {
            const data = await presuncionDiagnosticaSV.update(state.presuncionDiagnostica);
            dispatch("getPresuncions");
            commit('resetPresuncion')
            state.loadingCreate = false
          } catch (error) {
            console.log(error);
            state.loadingCreate = false
          }
    },

    async deletePresuncion({state, commit, dispatch}, idpresuncion)
    {
        let confirmar = await swal({
            title: "¿Desea eliminar esta presuncion?",
            icon: "warning",
            buttons: ['CANCELAR', 'SI, ELIMINAR'],
            dangerMode: true
        })

        if(!confirmar) { return }
        swal({
            title: "Cargando...",
            text: "Por favor, espere un momento.",
            icon: "info",
            button: false, // Ocultar el botón de confirmación
            closeOnClickOutside: false, // Evitar que el usuario cierre la alerta haciendo clic afuera
            closeOnEsc: false, // Evitar que el usuario cierre la alerta presionando la tecla Esc
            allowOutsideClick: false // Evitar que el usuario cierre la alerta haciendo clic afuera
          });
        try {
            const data = await presuncionDiagnosticaSV.delete(idpresuncion);
            dispatch("getPresuncions");
            commit('resetPresuncion')
            swal({
                title: "Se elimino correctamente",
                icon: "success"
            })
          } catch (error) {
            console.log(error);
            swal({
                title: "Ocurrio un error",
                icon: "error"
            })
          }
    }

  },
};
