import Module from './Module.vue';
import Login from './views/Login';
import RestorePass from './views/RestorePass.vue'
const moduleRoute = {
    path: "/login",
    component: Module,
    children: [
      {
        path: "/",
        component: Login,
        name: "Login",
        meta: {
          requiresGuest: true,
          title: "Iniciar Sesión"
        }
      },
      {
        path: "/restorepassword/:token?/:email?",
        component: RestorePass,
        name: "RestorePass",
        meta: {
          requiresGuest: true,
          title: "Restablecer contraseña"
        }
      }
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };