<template>
  <div style="height: 100vh" class="d-flex align-center justify-center">
    <v-card v-if="!visita.tipoVisita" elevation="0" height="100%" rounded="0"
      class="d-flex align-center justify-center">
      <div class="text-center">
        <v-img class="mb-3" :src="require('@/modules/Visitas/assets/guardia.png')"
          :lazy-src="require('@/modules/Visitas/assets/guardia.png')" width="200"></v-img>
        <h1>Cargando formulario...</h1>
      </div>
    </v-card>
    <v-card elevation="0" height="100%" rounded="0" v-else>

      <v-card-title v-if="visita.programar_visita"
        class="justify-center text-subtitle-2 font-weight-bold text-uppercase pt-1 pt-lg-4 green accent-4 white--text">
        PROGRAMAR NUEVA VISITA
      </v-card-title>


      <v-card-title v-if="!visita.programar_visita"
        class="justify-center text-subtitle-2 font-weight-bold text-uppercase pt-1 pt-lg-4">
        {{
      visita.idvisita
        ? "EDITAR VISITA"
        : "REGISTRAR NUEVA VISITA"
    }}
      </v-card-title>

      <v-form v-model="formvalidate">
        <v-card-text class="black--text font-weight-bold px-1 px-lg-4">
          <v-row class="px-5">
            <v-col cols="12" md="6" class="py-0">
              <v-switch inset label="PROGRAMAR VISITA" color="indigo darken-4"
                v-model="visita.programar_visita"></v-switch>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <div class="d-flex flex-column">
                <small>FECHA</small>
                <v-text-field v-if="!visita.programar_visita" readonly type="date" dense hide-details="auto"
                  class="grey lighten-4" flat outlined :rules="[rules.required]" :value="dateCurrent('YYYY-MM-DD')"
                  v-model="visita.fecha">
                </v-text-field>
                <v-text-field v-else type="date" dense hide-details="auto" class="grey lighten-4" flat outlined
                  :rules="[rules.required, rules.fechaposterior]" :value="dateCurrent('YYYY-MM-DD')"
                  v-model="visita.fecha">
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="py-0" v-if="!visita.programar_visita">
              <div class="d-flex flex-column">
                <small>Hora</small>
                <v-text-field readonly type="time" dense hide-details="auto" class="grey lighten-4" flat outlined
                  :rules="[rules.required]" :value="timeCurrent()" v-model="visita.hora_ingreso">
                </v-text-field>
              </div>
            </v-col>

            <v-col cols="12" md="3" class="py-0" v-if="visita.programar_visita">
              <div class="d-flex flex-column">
                <small>Hora aprox. (opcional)</small>
                <v-text-field type="time" dense hide-details="auto" class="grey lighten-4" flat outlined
                  :value="timeCurrent()" v-model="visita.hora_aproximada">
                </v-text-field>
              </div>
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <div>
                <small>Tipo Visita:</small>
                <v-select dense hide-details return-object v-model="visita.tipoVisita" :items="tipoVisita" flat outlined
                  class="grey lighten-4">
                  <template v-slot:selection="{ item }">
                    <v-list-item dense class="px-0" style="min-height: 20px !important">
                      <v-list-item-icon class="ma-2">
                        <v-avatar size="25" class="bgCTM"><v-icon color="white" size="20">{{
      item.icon
    }}</v-icon></v-avatar>
                      </v-list-item-icon>
                      <v-list-item-content class="py-1">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-list-item-icon class="mr-2">
                      <v-avatar size="25" class="bgCTM"><v-icon color="white" size="20">{{
      item.icon
    }}</v-icon></v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value != 'proveedores'">
              <div>
                <small>{{
      visita.tipoVisita.value == "visitas" ||
        visita.tipoVisita.value == "terceros"
        ? "SELECCIONA EL TIPO DE DOCUMENTO"
        : "DNI"
    }}</small>
                <v-text-field autofocus v-model="visita.dni" @keypress.enter="onClickBuscarDNI()"
                  :rules="[rules.required]" class="grey lighten-4" :class="[
      visita.tipoVisita.value == 'visitas' ||
        visita.tipoVisita.value == 'terceros'
        ? 'input-dni-group'
        : '',
    ]" dense hide-details="auto" placeholder="Ingrese DNI" flat outlined ref="FieldDNI">
                  <template v-slot:prepend-inner v-if="visita.tipoVisita.value == 'visitas' ||
      visita.tipoVisita.value == 'terceros'
      ">
                    <v-select v-model="visita.tipodocumento" dense filled flat
                      class="elevation-0 text-center input-select-dnice" hide-details="auto" :items="['DNI', 'CE']"
                      style="width: 90px; height: 20px"></v-select>
                  </template>
                  <template v-slot:append>
                    <v-btn icon :loading="loading" :disabled="visita.idvisita > 0">
                      <v-icon @click="onClickBuscarDNI()">mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value != 'proveedores'">
              <div>
                <small>APELLIDOS Y NOMBRES</small>
                <v-text-field v-model="visita.nombrecompleto" dense hide-details="auto" class="grey lighten-4" flat
                  outlined :placeholder="alert.state == 'no-found' ? alert.message : ''" :rules="[rules.required]">
                  <template v-slot:append>
                    <v-avatar size="25" :class="alert.state == 'success' ? 'green accent-3' : 'grey'
      ">
                      <v-icon color="white" small>{{
      alert.state == "success"
        ? "mdi-shield-check"
        : "mdi-alert-circle"
    }}</v-icon>
                    </v-avatar>
                  </template>
                </v-text-field>
              </div>
            </v-col>

            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value == 'colaboradores'">
              <div>
                <small>ÁREAS</small>
                <v-select v-model="visita.areaColaborador" dense hide-details="auto" class="grey lighten-4" flat
                  outlined :items="areascolaborador" item-text="nombrearea" item-value="nombrearea"
                  placeholder="Seleccione el área" :rules="[rules.required]"></v-select>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value == 'proveedores'">
              <div>
                <small>RUC</small>
                <v-text-field v-model="visita.ruc" dense hide-details="auto" class="grey lighten-4" flat outlined
                  :rules="[rules.required]" @keypress.enter="getApiSunatAxios()">
                  <template v-slot:append>
                    <v-btn icon :loading="loadingRuc">
                      <v-icon @click="getApiSunatAxios()">mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value == 'proveedores'">
              <div>
                <small>RAZÓN SOCIAL</small>
                <v-text-field v-model="visita.entidad" dense hide-details="auto" class="grey lighten-4" flat outlined
                  :rules="[rules.required]">
                  <template v-slot:append>
                    <v-avatar size="25" :class="alert.state == 'success' ? 'green accent-3' : 'grey'
      ">
                      <v-icon color="white" small>{{
      alert.state == "success"
        ? "mdi-shield-check"
        : "mdi-alert-circle"
    }}</v-icon>
                    </v-avatar>
                  </template>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value == 'proveedores' ||
      visita.tipoVisita.value == 'visitas' ||
      visita.tipoVisita.value == 'terceros'
      ">
              <div>
                <small>ÁREA A VISITAR</small>
                <v-select v-model="visita.area" dense hide-details="auto" class="grey lighten-4" flat outlined
                  :items="areasvisitas" item-text="nombrearea" item-value="nombrearea" placeholder="Seleccione una área"
                  :rules="[rules.required]"></v-select>
              </div>
            </v-col>

            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value == 'terceros'">
              <div>
                <small>EMPRESA PROVEE. SERV.</small>
                <v-select v-model="visita.empresaservicios" dense hide-details="auto" class="grey lighten-4" flat
                  outlined :items="empresasTerceros" item-text="nombre" item-value="nombre"
                  placeholder="Seleccione una área" :rules="[rules.required]"></v-select>
              </div>
            </v-col>

            <v-col cols="12" class="py-0" v-if="visita.tipoVisita.value == 'proveedores'">
              <div>
                <small>AGREGAR PERSONAL A INGRESAR</small>
                <v-form v-model="formValidatePersonal">
                  <div class="d-flex align-center">
                    <v-text-field v-model="personal.dni" dense hide-details class="grey lighten-4 mr-1 input-dni-group"
                      flat outlined style="width: 30%" placeholder="Insertar DNI" @keypress.enter="onClickDNIPersonal()"
                      :rules="[rules.required]" clearable>
                      <template v-slot:prepend-inner>
                        <v-select v-model="visita.tipodocumento" dense filled flat
                          class="elevation-0 text-center input-select-dnice" hide-details="auto" :items="['DNI', 'CE']"
                          style="width: 90px; height: 5px"></v-select>
                      </template>
                      <template v-slot:append>
                        <v-btn icon :loading="loading">
                          <v-icon @click="onClickDNIPersonal()">mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>

                    <v-text-field v-model="personal.nombrecompleto" dense hide-details class="grey lighten-4 mr-1" flat
                      outlined style="width: 60%" :placeholder="alert.state == 'no-found' ? alert.message : ''
      " :rules="[rules.required]" clearable>
                      <template v-slot:append>
                        <v-avatar size="25" :class="alert.state == 'success' ? 'green accent-3' : 'grey'
      ">
                          <v-icon color="white" small>{{
      alert.state == "success"
        ? "mdi-shield-check"
        : "mdi-alert-circle"
    }}</v-icon>
                        </v-avatar>
                      </template>
                    </v-text-field>

                    <v-btn @click="setPersonal()" :disabled="!formValidatePersonal" elevation="0" height="41"
                      width="40px" class="bgCTM px-0" style="min-width: 40px !important"><v-icon
                        color="white">mdi-plus</v-icon></v-btn>
                  </div>
                </v-form>
              </div>

              <v-data-table v-if="visita.personal.length > 0" :headers="headerPersonal" :items="visita.personal" dense
                hide-default-footer fixed-header :height="visita.personal.length > 3 ? '150' : ''"
                mobile-breakpoint="0">
                <template v-slot:item.actions="{ item }">
                  <v-btn @click="delPersonal(item)" x-small icon class="red">
                    <v-icon x-small color="white">mdi-close-thick</v-icon></v-btn>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value == 'estudiantes'">
              <div>
                <small>ADMISIÓN:</small>
                <v-text-field v-model="visita.admision" dense hide-details="auto" class="grey lighten-4" flat
                  outlined></v-text-field>
              </div>
            </v-col>

            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value == 'estudiantes'">
              <div>
                <small>CARRERA:</small>
                <v-text-field v-model="visita.carrera" dense hide-details="auto" class="grey lighten-4" flat
                  outlined></v-text-field>
              </div>
            </v-col>

            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value == 'estudiantes'">
              <div>
                <small>TIPO DE INGRESO:</small>
                <v-select v-model="visita.tipoIngreso" dense hide-details="auto" class="grey lighten-4" flat outlined
                  :items="tipoIngreso" :rules="[rules.required]"></v-select>
              </div>
            </v-col>

            <v-col cols="12" v-if="visita.tipoVisita.value == 'estudiantes'">
              <v-alert border="top" colored-border type="info" elevation="0" dense
                v-if="visita.tipoIngreso === 'ingreso_principal'" class="mb-0">
                <div class="text-caption font-weight-bold">INGRESO PRINCIPAL A SEDE</div>
                <div class="text-caption">
                  Esta opción únicamente si se trata del primer ingreso a la sede durante la semana
                </div>
              </v-alert>
              <v-alert border="top" colored-border type="info" elevation="0" dense
                v-if="visita.tipoIngreso === 'otro_ingreso'" class="mb-0">
                <div class="text-caption font-weight-bold">OTRO TIPO DE INGRESO</div>
                <div class="text-caption">
                  Esta opción solo para identificar un ingreso distinto al ingreso principal, como el regreso después
                  del almuerzo o
                  tras un permiso para realizar compras.
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12" md="6" class="py-0" v-if="visita.tipoVisita.value == 'estudiantes' &&
      visita.programar_visita
      ">
              <div>
                <small>IMPEDIR INGRESO:</small>
                <v-radio-group v-model="visita.impedir_ingreso" row hide-details denseO
                  class="mt-0 justify-center align-center">
                  <v-radio label="SI" value="SI"></v-radio>
                  <v-radio label="NO" value="NO"></v-radio>
                </v-radio-group>
              </div>
            </v-col>

            <v-col cols="12" class="py-0" v-if="visita.tipoVisita.value == 'proveedores' ||
      visita.tipoVisita.value == 'visitas'
      ">
              <div>
                <small>MOTIVO</small>
                <v-autocomplete v-model="visita.motivo" dense hide-details="auto" class="grey lighten-4" flat outlined
                  :rules="[rules.required]" item-text="motivo" :items="motivos" auto-select-first
                  :menu-props="{ maxHeight: 200 }" clearable item-value="motivo"></v-autocomplete>
              </div>
            </v-col>

            <v-col cols="12" class="py-0">
              <div>
                <small>AGREGAR MATERIALES A INGRESAR</small>

                <v-form v-model="formValidateMaterial">
                  <div class="d-flex align-center">
                    <v-text-field type="number" style="width: 10%" value="0" dense hide-details
                      class="grey lighten-4 mr-1" flat outlined v-model="material.cantidad"
                      :rules="[rules.required]"></v-text-field>
                    <v-text-field style="width: 50%" placeholder="Descripción" dense hide-details
                      class="grey lighten-4 mr-1" flat outlined v-model="material.descripcion"
                      :rules="[rules.required]"></v-text-field>
                    <v-text-field style="width: 20%" placeholder="Marca" dense hide-details="auto"
                      class="grey lighten-4 mr-1" flat outlined v-model="material.marca"></v-text-field>
                    <v-text-field style="width: 20%" placeholder="Serie" dense hide-details="auto"
                      class="grey lighten-4 mr-1" flat outlined v-model="material.codigo"></v-text-field>
                    <v-btn :disabled="!formValidateMaterial" @click="setMaterial()" height="41" width="40px"
                      class="bgCTM px-0" style="min-width: 40px !important"><v-icon
                        color="white">mdi-plus</v-icon></v-btn>
                  </div>
                </v-form>
              </div>
              <v-data-table v-if="visita.materialesIngresados.length > 0" dense :headers="headers" hide-default-footer
                fixed-header :height="visita.materialesIngresados.length > 2 ? '100' : ''" mobile-breakpoint="0"
                :items="visita.materialesIngresados">
                <template v-slot:item.cantidad="{ item }"><span>{{ item.cantidad }}</span></template>
                <template v-slot:item.descripcion="{ item }"><span>{{ item.descripcion }}</span></template>
                <template v-slot:item.marca="{ item }"><span>{{ item.marca }}</span></template>
                <template v-slot:item.codigo="{ item }"><span>{{ item.codigo }}</span></template>
                <template v-slot:item.actions="{ item }">
                  <v-btn @click="delMaterial(item)" x-small icon class="red">
                    <v-icon x-small color="white">mdi-close-thick</v-icon></v-btn>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="6" class="py-0" v-if="visita.tipoVisita.value == 'colaboradores'">
              <div>
                <small>Marca Vehículo:</small>
                <v-text-field v-model="visita.marca_vehiculo" dense hide-details="auto" class="grey lighten-4" flat
                  outlined></v-text-field>
              </div>
            </v-col>

            <v-col cols="6" class="py-0" v-if="visita.tipoVisita.value == 'colaboradores'">
              <div>
                <small>Placa Vehículo:</small>
                <v-text-field v-model="visita.placa_vehiculo" dense hide-details="auto" class="grey lighten-4" flat
                  outlined></v-text-field>
              </div>
            </v-col>

            <v-col cols="12" class="py-0">
              <div>
                <small>Observación: (opcional)</small>
                <v-text-field v-model="visita.observacion" dense hide-details="auto" class="grey lighten-4" flat
                  outlined></v-text-field>
              </div>
            </v-col>

            <v-col cols="6" class="py-1">
              <small>Cargar Evidencia (opcional)</small>
              <v-file-input @change="addEvidenciaVisita" :value="visita.imagen" truncate-length="15" outlined
                class="grey lighten-4" hide-details="auto" dense accept="image/png, image/jpeg, image/jpg"
                name="evidencia_visita[]"></v-file-input>
            </v-col>
            <v-col cols="6" class="py-2" v-if="visita.evidencia_visita.length > 0">
              <div class="d-flex justify-space-between">
                <small class="font-weight-bold">Imagenes cargadas</small>
                <v-btn x-small elevation="0" dark @click="quitarTodasImages()"><v-icon left
                    x-small>mdi-delete</v-icon>Quitas
                  fotos</v-btn>
              </div>
              <div>
                <v-chip small class="ma-1" style="max-width: 150px" v-for="(img, i) in visita.evidencia_visita"
                  :key="i">
                  <v-icon left small>mdi-image</v-icon>
                  <span class="text-truncate">{{ img.name }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center px-5 mx-5">
          <v-btn @click="setApiVisita()" :disabled="!formvalidate" class="bgCTM white--text" width="50%"
            elevation="0">MARCAR
            INGRESO</v-btn>
          <v-btn @click="cambioDePerfil()" class="grey lighten-5 text-CTM" width="50%" elevation="0">
            LIMPIAR</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins: [Filters],
  data() {
    return {
      formvalidate: true,

      name: "",

      formValidatePersonal: true,

      formValidateMaterial: true,

      headers: [
        {
          text: "Cant.",
          value: "cantidad",
          sortable: false,
          width: 30,
        },
        {
          text: "Descripción.",
          value: "descripcion",
          sortable: false,
          width: 150,
        },
        {
          text: "Marca.",
          value: "marca",
          sortable: false,
          width: 90,
        },
        {
          text: "Serie.",
          value: "codigo",
          sortable: false,
          width: 90,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: 40,
        },
      ],

      headerPersonal: [
        {
          text: "DNI",
          value: "dni",
          sortable: false,
          width: 40,
        },
        {
          text: "APELLIDOS Y NOMBRES",
          value: "nombrecompleto",
          sortable: false,
          width: 250,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: 40,
        },
      ],

      //showTextField: false
    };
  },

  watch: {
    "visita.tipoVisita": {
      handler() {

        this.cambioDePerfil();

      },
    },
    "visita.dni": {
      handler() {
        if (this.visita.dni.length >= 8) {
          this.onClickBuscarDNI();
        }
      },
    },
  },
  computed: {
    ...mapState("visitas/configuracion", [
      "areascolaborador",
      "areasvisitas",
      "empresasTerceros",
      "motivos",
    ]),

    ...mapState("visitas/registrarVisita", [
      "visita",
      "loading",
      "loadingRuc",
      "alert",
      "material",
      "tipoVisita",
      "personal",
      "loadingForm",
      "tipoIngreso",
    ]),
  },

  methods: {
    ...mapMutations("visitas", ["setEncargadosArea"]),
    ...mapMutations("visitas/registrarVisita", [
      "setMaterial",
      "resetDataVisita",
      "setPersonal",
      "cambioDePerfil",
      "delPersonal",
      "delMaterial",
      "addEvidenciaVisita",
      "quitarTodasImages",
      "initialize",
    ]),
    ...mapActions("visitas/registrarVisita", [
      "getApiReniecAxios",
      "getDataEstudiantesApi",
      "getApiTipoVisitas",
      "setApiVisita",
      "getApiSunatAxios",
      "getDataColaboradorApi",
      "getApiCEAxios",
    ]),

    onClickBuscarDNI() {
      if (this.visita.tipoVisita.value == "estudiantes") {
        this.getDataEstudiantesApi(); 
        return;
      }

      if (this.visita.tipoVisita.value == "colaboradores") {
        this.getDataColaboradorApi();
        return;
      }

      if (
        this.visita.tipoVisita.value == "visitas" ||
        this.visita.tipoVisita.value == "terceros"
      ) {
        if (this.visita.tipodocumento === "CE") {
          this.getApiCEAxios();
        }

        if (this.visita.tipodocumento === "DNI") {
          this.getApiReniecAxios();
        }
      }
    },

    onClickDNIPersonal() {
      if (this.visita.tipoVisita.value == "proveedores") {
        if (this.visita.tipodocumento === "CE") {
          this.getApiCEAxios();
        }

        if (this.visita.tipodocumento === "DNI") {
          this.getApiReniecAxios();
        }
      }
    },
  },

  mounted() {
  }
};
</script>
<style>
.input-dni-group .v-input__control .v-input__slot {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.input-dni-group .v-input__control .v-input__slot .v-input__prepend-inner,
.input-dni-group .v-input__control .v-input__slot .v-input__append-inner {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.input-select-dnice .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  justify-content: center !important;
}

.input-select-dnice .v-input__control .v-input__slot .v-select__slot .v-select__selections input {
  display: none !important;
}
</style>
