<template>
    <v-card elevation="0" style="width: 100%;">
    <v-card-text>
      <span class="black--text"
        >Cambia tu contraseña</span
      >
      <v-form v-model="validateForm">
        <div class="py-3">
          <v-text-field
            :type="hiddenpass? 'password' : 'text'"
            outlined
            dense
            hide-details="auto"
            label="nueva contraseña"
            v-model="updatepass.password"
            :rules="[rules.required]"
          >
            <template v-slot:append>
                <v-btn @click="hiddenpass = !hiddenpass" elevation="0" icon small class="bgCTM white--text"><v-icon size="20">{{ hiddenpass ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon></v-btn>
            </template>
          </v-text-field>
        </div>

        <div class="py-3">
          <v-text-field
            :type="hiddenpass2? 'password' : 'text'"
            outlined
            dense
            hide-details="auto"
            label="confirma tu contraseña"
            v-model="updatepass.password_confirmation"
            :rules="[rules.required]"
          >
          <template v-slot:append>
                <v-btn @click="hiddenpass2 = !hiddenpass2" elevation="0" icon small class="bgCTM white--text"><v-icon size="20">{{ hiddenpass2 ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon></v-btn>
            </template>
          </v-text-field>
        </div>

        <div class="py-3 red--text" v-if="alertpass">Ambas contraseñas no coinciden </div>
        <div class="py-3">
          <v-btn @click="setUpdatePasswordAxios($route.params.email)" :disabled="!validateForm" block elevation="0" class="blue white--text">
                CAMBIAR CONTRASEÑA
          </v-btn>
        </div>

        <v-btn :to="{name: 'Login'}" block text small>volver al login</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: "FormRestorePass",
  mixins:[Filters],
  data(){
    return{

      validateForm: true,
      hiddenpass: true,
      hiddenpass2: true,

      alertpass: false

    }
  },
  watch:{
    'updatepass.password_confirmation':{
        handler(val){
            if(val && this.updatepass.password == val){
                this.validateForm = true
                this.alertpass = false
                return
            }

            this.validateForm = false
            this.alertpass = true
        }
    }
  },
  computed:{
    ...mapState('login/restorepass', ['updatepass'])
  },
  methods:{
    ...mapActions('login/restorepass', [ 'setUpdatePasswordAxios'])
  }
};
</script>
  