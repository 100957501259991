<template>
  <v-card elevation="0">
    <v-card-text>
      <span class="black--text"
        >Ingresa tu email para poder recibir los pasos para restaurar la
        Contraseña</span
      >
      <v-form v-model="validateForm">
        <div class="py-3">
          <v-text-field
            type="email"
            outlined
            dense
            hide-details="auto"
            label="Email"
            v-model="restorepass.email"
            :rules="[rules.required]"
          ></v-text-field>
        </div>
        <div class="py-3">
          <v-btn :disabled="!validateForm" @click="sendEmailRestorePass()" block elevation="0" class="blue white--text">
            Enviar enlace
          </v-btn>
        </div>

        <v-btn @click="switchComponent('FormLogin')" block text small>volver al login</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: "FormRestorePass",
  mixins:[Filters],
  data(){
    return{

      validateForm: true

    }
  },
  computed:{
    ...mapState('login/restorepass', ['restorepass'])
  },
  methods:{
    ...mapMutations('login',['switchComponent']),
    ...mapActions('login/restorepass', ['sendEmailRestorePass'])
  }
};
</script>
  