import Module from './Module.vue';
import HospedarPabellonView from './views/HospedarPabellonView.vue';
import ReportesCamasView from './views/ReportesCamasView.vue';
import DashboardHospedajeView from './views/DashboardHospedajeView.vue'
import HospedarPabellonMasivo from './views/HospedarPabellonMasivo.vue'
const moduleRoute = {
    path: "/hospedaje",
    component: Module,

    children: [
      {
        path: "/",
        redirect: "pabellones",
        name: 'hospedaje',
        meta: {
          requiresAuth: true,
          title: "Hospedaje Dashboard",
        },
      },
      {
        path: "pabellones",
        component: DashboardHospedajeView,
        children:[{
          path: "",
          components: {
            hospedajePabellones: () => import('@/modules/Hospedaje/components/DashboardHospedaje/CardPabellonesList.vue'),
          },
          name: 'hospedaje-principal',
          meta: {
            requiresAuth: true,
            title: "Hospedaje - Pabellones",
            tipo: "pabellon"
          },
        }],
        
        
      },

      {
        path: "reporte-general-pabellones",
        component: DashboardHospedajeView,
        children:[{
          path: "",
          components: {
            hospedajePabellones: () => import('@/modules/Hospedaje/components/DashboardHospedaje/CardReporteCantidades.vue'),
          },
          name: 'reporteGeneralPabellones',
          meta: {
            requiresAuth: true,
            title: "Hospedaje - Reporte general de pabellones",
            tipo: "pabellon"
          },
        }],
        
        
      },


      {
        path: "pabellon-:id",
        component: HospedarPabellonView,
        name: 'pabellonView',
        meta: {
          requiresAuth: true,
          title: "Pabellón a hospedar",
        },
      },
      {
        path: "masivo-:id",
        component: HospedarPabellonMasivo,
        name: 'hospedarMasivo',
        meta: {
          requiresAuth: true,
          title: "Hospedaje masivo",
        },
      },


      {
        path: "hospedados",
        component: DashboardHospedajeView,
        children:[{
          path: "",
          components: {
            hospedajePabellones: () => import('@/modules/Hospedaje/components/DashboardHospedaje/CardHospedadosReporte.vue'),
          },
          name: 'hospedados-reporte',
          meta: {
            requiresAuth: true,
            title: "Hospedaje - Reporte general de pabellones",
            tipo: "hospedado"
          },
        }],
        
        
      },


      
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };