import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class AtencionMedicaService {
    constructor() {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer ' + store.state.user.token
            },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );
    }
    async all(params) {
        const response = await this.axios.get('/atencionmedica', { params: params })
        return response.data;
    }

    async getDay(params) {
        const response = await this.axios.get('/atencionmedicadiaria', { params: params })
        return response.data
    }

    async show(idAtencionMedica){
        const response = await this.axios.get('/showatencionmedica', { params: {id_atencion_medica: idAtencionMedica} })
        return response.data
    }

    async guardar(data) {
        const response = await this.axios.post('/atencionmedica', data)
        return response.data;
    }

    async update(data) {
        const response = await this.axios.post('/editaratencionmedica', data)
        return response.data;
    }

    //GENERATE REPORTE PDF

    async generatePDFAtencionMedica(data) {
        const response = await this.axios.post('/generatepdfatencionmedica', data, {
            responseType: 'arraybuffer',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download = 'Reporte_Atencion_Medica.pdf';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        return response.data;
    }


    async printReporteAtencionMedica(data) {

        const response = await this.axios.post('/generatepdfatencionmedica', data, {
            responseType: 'arraybuffer',  
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(blob);

        const embedElement = document.createElement('embed');
        embedElement.src = pdfUrl;
        embedElement.type = 'application/pdf';
        embedElement.width = '100%';
        embedElement.height = '100%';

        document.body.appendChild(embedElement);

        window.print();

        document.body.removeChild(embedElement);

        URL.revokeObjectURL(pdfUrl);

        return response.data;

    }


    async getReporte(data)
    {
        const response = await this.axios.post('/generarexcelatencionmedica', data, {
            responseType: 'blob',
        })
        const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); 
          // Genera una URL temporal del archivo Blob
          const url = URL.createObjectURL(blob);
          // Crea un enlace y lo simula como si fuera un clic del usuario
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte-atenciones-medicas.xlsx";
          a.click();
    }


}