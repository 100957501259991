<template>
  <v-card
    elevation="0"
    :width="horizontal ? '100%' : 'auto'"
    :max-height="horizontal? '':'190px'"
    class="mx-1 my-1 d-flex justify-center blue-grey lighten-2 pa-1"
    :class="[isHorizontal]"
    v-if="bloquecardinal == cardinal"
  >
    <div v-if="!onlyRead" class="d-flex justify-center align-center mx-auto" 
    :class="horizontal || bloque.tipo == 'vacio'? 'flex-row': 'flex-column'"
    style="position: absolute; top: 0px; left: 0px; z-index:10;">
      <v-btn elevation="0" icon @click="deleteBloque(bloque.idbloque)" x-small color="red" class="rounded-0 px-0" :class="horizontal?'horizontal-btn':'vertical-btn'" ><v-icon color="white" x-small>mdi-close-thick</v-icon></v-btn>
      <v-btn elevation="0" icon @click="editBloque(bloque)" x-small color="amber" class="rounded-0" :class="horizontal?'horizontal-btn':'vertical-btn'" ><v-icon color="white" x-small>mdi-pencil</v-icon></v-btn>
    </div>

    <!--- PARED Y VACIO --->
    <div
      class="d-flex justify-center align-center text-center white--text"
      :class="horizontal? '' : 'texto-vertical'"
      
    >
      <small v-if="tipo == 'muro'">PARED</small>
      <small class="d-block pa-2" v-if="tipo == 'vacio'" style="height: 80% !important;" >{{ comentario ? comentario : 'ESPACIO LIBRE' }}</small>
    </div>

    <!--- PARED Y VACIO --->
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    bloquecardinal: {
      type: String,
      required: true,
    },

    tipo: {
      type: String,
      required: true,
    },

    horizontal: {
      type: Boolean,
      default: false,
    },

    cardinal: {
      type: String,
      required: true,
    },
    comentario: {
      type: String,
      default: ""
    },
    bloque: {
      type: Object,
      required: true,
    },
     onlyRead:{
      type: Boolean,
      default: false
    }
  },


  computed: {
    isHorizontal() {
      return this.horizontal ? "flex-row " : "flex-column";
    },
  },

  methods:{
    ...mapActions('pabellones/creadorPabellon',['editBloque', 'deleteBloque'])
  }
};
</script>


<style scoped>
.texto-vertical {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
</style>