import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class MotivoAtencionService{

    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              },
        })


        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );


        
    }

    async getMotivoP(params)
    {
       const response = await this.axios.get('/motivoatencion', {params: params})
       return response.data;
    }  


    async addMotivoP(data)
    {
        const response = await this.axios.post('/motivoatencion', data)
        return response.data
    }

    async updateMotivoP(data)
    {
        const response = await this.axios.put('/motivoatencion', data)
        return response.data
    }


    async deleteMotivoP(id_motivo_ap)
    {
        const response = await this.axios.delete('/motivoatencion?id_motivo_ap='+id_motivo_ap)
        return response.data
    }

}