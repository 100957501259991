import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class RegistroIndisciplinaService{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.apiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer '+ store.state.user.token
              }, 
        })

 
        store.watch(
            (state) => state.user,
            (newUser) => {
                this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
        );

    }

    async get(params)
    {

       const response = await this.axios.get('/registroindisciplina', {params: params})
       return response.data;
    }  

    async show(params)
    {

       const response = await this.axios.get('/showregistrodisciplina', {params: params})
       return response.data;
    }  

    async add(data)
    {
        const response = await this.axios.post('/registroindisciplina', data)
        return response.data
    }

    async update(data)
    {
        const response = await this.axios.put('/registroindisciplina', data)
        return response.data
    }


    async delete(idregistro)
    {
        const response = await this.axios.delete('/registroindisciplina?idregistro='+idregistro)
        return response.data
    }


    async generarPDF(data)
    {
        const response = await this.axios.post('/generar-pdf-disciplina', data, {
            responseType: 'arraybuffer',
        })
        
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download = 'Reporte_Caso-indisciplina.pdf';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        return response.data;
    }


    async getReporteExcel(data)
    {
        const response = await this.axios.post('generar-excel-disciplina', data, {
            responseType: 'blob',
        })
        const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); 
          // Genera una URL temporal del archivo Blob
          const url = URL.createObjectURL(blob);
          // Crea un enlace y lo simula como si fuera un clic del usuario
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte-registro-indisciplina.xlsx";
          a.click();
    }

}