<template>
  <v-container class="px-1">
    <div class="d-flex justify-space-between">
      <p>CREAR NUEVO PABELLON</p>
      <v-btn
        :to="{ name: 'pabellones' }"
        small
        elevation="0"
        color="bgCTM white--text"
        >LISTA DE PABELLONES</v-btn
      >
    </div>
    <v-card elevation="0">
      <form-crear-pabellon />
      <selected-template />
      <creador-pabellon />
      <v-card-actions>
        <v-btn @click="genararPabellon()" color="bgCTM" class="white--text">GENERAR PABELLÓN</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import FormCrearPabellon from "../components/FormCrearPabellon.vue";
import selectedTemplate from "../components/selectedTemplatePabellon.vue";
import CreadorPabellon from "../components/CreadorPabellon.vue";
import { mapActions } from 'vuex';
export default {
  name: "PabellonesView",
  components: {
    FormCrearPabellon,
    CreadorPabellon,
    selectedTemplate,
  },
  data: () => ({
    step: 1,
  }),

  methods: {
    ...mapActions('pabellones/creadorPabellon',['genararPabellon'])
  },

  created(){
    if(!this.$can('create', 'pabellones')){
      this.$router.push({name: "dashboarad"})
    }
  }
};
</script>
