<template>
  <div>
    <v-chip
      v-if="!block"
      x-small
      pill
      class="pl-2 white"
      style="opacity: 1 !important"
    >
      <v-icon
        x-small
        left
        color="white"
        :class="colorEstado"
        class="rounded-circle"
        style="padding: 2px"
        >{{ iconEstado }}</v-icon
      >
      <small
        class="text-uppercase font-weight-bold text-truncate black--text"
        >{{ reduceText(estado, 4) }}</small
      >
    </v-chip>

    <div v-else @click="callback" class="d-flex flex-column justify-center align-center" :style="estado == 'reporte'? 'cursor:pointer':''">
      <v-avatar  rounded="lg" class="colorEstado" size="30">
        <v-icon
          size="20"
          color="white"
          :class="colorEstado"
          class="rounded-circle"
          style="padding: 2px"
          >{{ iconEstado }}</v-icon
        >
      </v-avatar>
      <v-chip
        color="white"
        class="black--text text-uppercase font-weight-bold text-truncate"
        small
      >
        {{ estado }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  props: {
    estado: {
      type: String,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },

    callback:{
      type: Function,
      default: () => false
    }
  },
  computed: {
    iconEstado() {
      switch (this.estado) {
        case "disponible":
          return "mdi-check-bold";
        case "reporte":
          return "mdi-information";
        case "inoperativa":
          return "mdi-alert-octagon";
        case "ocupada":
          return "mdi-account";
      }
    },

    colorEstado() {
      switch (this.estado) {
        case "disponible":
          return "light-green accent-4";
        case "reporte":
          return "amber darken-4";
        case "inoperativa":
          return "red darken-4";
        case "ocupada":
          return "blue darken-1";
      }
    },
  },
  methods: {
    reduceText(text, long) {
      return text && text.length > long ? text.substring(0, long) : text;
    },

  },
};
</script> 
