<template>
  <div class="d-flex rounded-lg blue-grey lighten-4">
    <div class="d-none d-lg-flex pa-5 plano lineasDiagonales justify-center align-center" style="width: 2%;" >
            {{ ubicacionBano == 'bañoderecha' ? 'SALIDA' : 'BAÑOS' }}
    </div>
    <div class="pabellonABCrectangle">
      <div id="derecha" style="width: 25%" class="pr-2">
        <div class="d-flex flex-column">
          <slot name="espacioCamaIzquierdaA"></slot>
        </div>
      </div>
      <div
        class="pa-3 blue-grey lighten-2 white--text d-block text-center texto-vertical"
        style="width: 48px"
      >
        PASADIZO IZQUIERDA
      </div>

      <div id="arribayabajo" style="width: 75%" class="d-flex flex-column">
        <div class="d-flex my-2" style="height: 100%; ">
          <slot name="espacioCamaArribaB"></slot>
        </div>
        <div
          class="pa-3 blue-grey lighten-2 white--text d-block text-center"
          style="width: 80vw;"
        >
          PASADIZO CENTRO
        </div>
        <div class="d-flex" style="height: 100%;">
          <slot name="espacioCamaAbajoC"></slot>
        </div>
      </div>
    </div>
     <div class="d-none d-lg-flex pa-5 plano lineasDiagonales justify-center align-center" style="width: 2%;" >
            {{ ubicacionBano == 'bañoderecha' ? 'BAÑOS' : 'SALIDA' }}
    </div>
  </div>
</template>

<script>
export default {
    props:{
        ubicacionBano:{
            type: String,
            required: true
        }
    }
}
</script>

<style>
.pabellonABCrectangle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-height: 100%;
  overflow-x:auto;
}

.texto-vertical {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.plano {
  margin: 5px;
  border: dotted 5px #78909c;
  border-radius: 5px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: center;
  font-weight: bold;
  color: #78909c;
  font-size: 20px;
}

.lineasDiagonales {
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0) 25px,
    rgba(0, 0, 0, 0) 40px,
    #b0bec5 40px,
    #b0bec5 60px
  );
}
</style>
