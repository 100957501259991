<template>
  <v-container style="height: 80vh" class="d-flex justify-center align-center">
    <v-card elevation="0" height="500" width="100%">
      <v-card-title> DATOS PROCESADOS CON EXITO! </v-card-title>
      <v-card-subtitle>Revisa los detalles y verifica si se han cargado todos los huespedes</v-card-subtitle>
      <v-card-text class="text-center black--text">
        <v-row>
          <v-col cols="12" md="6" class="d-flex justify-center align-center">
            <div>
                <div class="pa-2">CANTIDAD DE PERSONAS ALOJADAS</div>
            <h2>
              Se alojaron un total de
              {{ estadoFinal.huespedes_alojados }} personas
            </h2>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>DETALLES</div>
            <div style="height: 350px; overflow-y: auto;">
              <v-list dense>
                <v-list-item
                  dense
                  v-for="(info, index) in estadoFinal.informe"
                  :key="index"
                >
                  <v-list-item-icon class="mr-2">
                    <v-avatar size="20" class="grey"
                      ><v-icon small color="white">mdi-check</v-icon></v-avatar
                    >
                  </v-list-item-icon>
                  <v-list-item-content class="text-left">
                    <small>{{ info }}</small>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn :to="{name: 'pabellonView', params:{ id: idpabellon }}" elevation="0" class="bgCTM white--text" block>Ir al pabellon</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
    data(){
        return{
            idpabellon: -1
        }
    },
  computed: {
    ...mapState("hospedaje/hospedarMasivos", ["estadoFinal"]),
  },
  mounted(){
    if(this.$route.params.id){
        this.idpabellon = this.$route.params.id
    }
  }
};
</script>
